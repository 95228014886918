import { LeftMenu } from "./LeftMenuAdmin";
import { useState, useEffect } from "react";
import $ from 'jquery';
import { ApiGetCall } from "../js/connector";
import { CDNURL } from "../js/constants";
import { ApiPutCall } from "../js/connector";
import { ApiPostCall } from "../js/connector";
import { ApiDeleteCall } from "../js/connector";
import ModalPopup from "./ModalPopup";
import { VerifyLoginToken } from "../js/connector";
export function CouponCode() {
    const [show, setShow] = useState(false);
    const [btnshow, setBtnshow] = useState(false);
    const [modalHead, setmodalHead] = useState("");
    const [modalBody, setModalBody] = useState("");

    const [overallstate, setoverallstate] = useState({});
    const [checkvalue, setCheckvalue] = useState(true);
    const [CodeDetails, setCodeDetails] = useState([]);
    const [title, settitle] = useState("");
    const [code, setcode] = useState("");
    const [expirydate, setexpirydate] = useState("");
    const [discount, setdiscount] = useState("");
    const [leadsource, setleadsource] = useState("");

    const [blankCode, setblankCode] = useState("");
    const [blankExpiryDate, setblankExpiryDate] = useState("");
    const [blankDiscount, setblankDiscount] = useState("");
    const [TempCheck, setTempCheck] = useState(false);
    useEffect(() => {
        window.scroll(0, 0);
        VerifyLoginToken(showhidedivfunc);
        return () => {
            setoverallstate({});
        };
    }, []);
    const closePopupHandler = () => {
        setShow(false);
    };
    const showhidedivfunc = () => {
        setTempCheck(true);
        var PathName = window.location.pathname;
        var pathsplit = PathName.split('/');
        var prodActive = pathsplit[2];
        if (prodActive == "AddCode") {
            showadddiv();
        } else if (prodActive == "UpdateCode") {
            showupdatediv();
        }
        else {
            getCouponCodeList();
        }
    }
    const getCouponCodeList = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/CouponCode/GetCouponCodeDetail").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.couponDetails;
                if (responseRs.status == "success") {
                    if (responseRs.couponDetails != "") {
                        setCodeDetails(sugData);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const ShowAddCodeDiv = () => {
        window.location.href = "/CouponCodes/AddCode";
    }
    const showadddiv = () => {
        $("#Overlay").show();
        $("#LoderId").show();
        setTimeout(function () {
            $("#GridDivShow").addClass("d-none");
            $("#AddCodeDivShow").removeClass("d-none");
            $("#Overlay").hide();
            $("#LoderId").hide();
        }, 500);
    }
    const cancelbutton = () => {
        window.location = "/CouponCodes";
    }
    const AddSaveCode = async () => {
        var temp = 0;
        if (discount == "") {
            setblankDiscount("Required!");
            $("#DiscountID").focus();
        } else {
            setblankDiscount("");
            temp++;
        }
        if (expirydate == "") {
            setblankExpiryDate("Required!");
            $("#ExpiryDate").focus();
        } else {
            setblankExpiryDate("");
            temp++;
        }
        if (code == "") {
            setblankCode("Required!");
            $("#CouponCode").focus();
        } else {
            setblankCode("");
            temp++;
        }
        var discounttype = $(".DiscountTypeClass").attr('staticval');
        if (temp >= 3) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                Title: title,
                Code: code,
                ExpiryDate: expirydate,
                DiscountType: discounttype,
                Discount: discount,
                LeadSource: leadsource
            });
            await ApiPostCall("/CouponCode/SaveCouponCode", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Coupon Added Successfully.");
                        setTimeout(function () {
                            window.location = "/CouponCodes";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }
    }
    const setDiscountType = (val) => {
        $(".DiscountTypeClass").attr('staticval', val);
    }

    const setUpdateDiscountType = (val) => {
        $(".DiscountTypeUpdateClass").attr('staticval', val);
    }
    const changeIsActive = async (e) => {
        var vId = parseInt(e.currentTarget.attributes[3].value);
        var vStatus;
        if ($("#VisibleId_" + vId).is(":checked")) {
            $("#VisibleId_" + vId).prop("checked", true);
            vStatus = 1;
        } else {
            $("#VisibleId_" + vId).removeAttr("checked");
            vStatus = 0;
        }
        $("#Overlay").show();
        $("#LoderId").show();
        var raw = JSON.stringify({
            Status: vStatus,
        });
        await ApiPutCall("/CouponCode/VisibleCouponCode/" + vId, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "Success") {
                    $(".alert-success").show();
                    $("#AlertMsg").text("Updated Successfully.");
                    setTimeout(function () {
                        $(".alert-success").hide();
                        $("#AlertMsg").text("");
                    }, 1500);
                    if (responseRs.categoryData.isActive == true) {
                        setCheckvalue(true);
                    } else {
                        setCheckvalue(false);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });

    }
    const DeleteCode = async () => {
        var vid = $("#HdnUserId").val();
        $("#Overlay").show();
        $("#LoderId").show();
        vid = parseInt(vid);
        var raw = "";
        await ApiDeleteCall("/CouponCode/DeleteCouponCode/" + vid, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "success") {
                    $(".alert-success").show();
                    $("#AlertMsg").text("Code Deleted Succesfully.");
                    setTimeout(function () {
                        window.location = "/CouponCodes";
                    }, 1500);
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const UpdateCode = (vid) => {
        window.location.href = "/CouponCodes/UpdateCode/?id=" + vid;
    }
    const showupdatediv = async () => {
        var vId = parseInt(getUrlParameter("id"));
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/CouponCode/GetSingleCode/" + vId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.codeDetails;
                if (responseRs.status == "Success") {
                    $("#GridDivShow").addClass("d-none");
                    $("#UpdateCodeDivShow").removeClass("d-none");
                    settitle(sugData.title);
                    setcode(sugData.code);
                    var input = sugData.expiryDate;
                    var date = new Date(input + 'Z');
                    var output = date.toISOString().substr(0, 10);
                    setexpirydate(output);
                    setleadsource(sugData.leadSource);
                    setdiscount(sugData.discount);
                    if (sugData.discountType == 1) {
                        $("#PercentageId").attr('checked', true);
                    } else {
                        $("#FlatId").attr('checked', true);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };
    const isShowPopup = (vId) => {
        $("#HdnUserId").val(vId);
        setShow(true);
        setmodalHead("Delete Coupon Code");
        setModalBody("Are you sure you want to delete this Coupon Code?");
        setBtnshow(1);
    };
    const UpdateSaveCode = async () => {
        var temp = 0;
        if (discount == "") {
            setblankDiscount("Required!");
            $("#DiscountUpdateID").focus();
        } else {
            setblankDiscount("");
            temp++;
        }
        if (expirydate == "") {
            setblankExpiryDate("Required!");
            $("#ExpiryUpdateDate").focus();
        } else {
            setblankExpiryDate("");
            temp++;
        }
        if (code == "") {
            setblankCode("Required!");
            $("#CouponUpdateCode").focus();
        } else {
            setblankCode("");
            temp++;
        }
        var vid = parseInt(getUrlParameter("id"));
        var discounttype = $(".DiscountTypeUpdateClass").attr('staticval');
        if (temp >= 3) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                Id: vid,
                Title: title,
                Code: code,
                ExpiryDate: expirydate,
                DiscountType: discounttype,
                Discount: discount,
                LeadSource: leadsource
            });
            await ApiPostCall("/CouponCode/SaveCouponCode", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Coupon Updated Successfully.");
                        setTimeout(function () {
                            window.location = "/CouponCodes";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }
    }
    return (
        <>
            {TempCheck == true ?
                <>
                    <LeftMenu />
                    <input type="hidden" id="HdnUserId" />
                    <div fixed="true" className='container-fluid mainContainer'>
                        <div className="content-wrapper" id="GridDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv">
                                            <div className="cardDiv-header">
                                                <div className="row AlignItems">
                                                    <div className="col-4">
                                                        <h4>Coupon Codes</h4>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="card-action">
                                                            <button type="submit" className="loginBtn" onClick={ShowAddCodeDiv}>
                                                                Add Code
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 gridHeader">
                                                    <div className="row">
                                                        <div className="col-3 text-center ">
                                                            <b>Title</b>
                                                        </div>
                                                        <div className="col-3 text-center px-0">
                                                            <b>Code</b>
                                                        </div>
                                                        <div className="col-1 text-center px-0">
                                                            <b>Is Active?</b>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <b>Expiry Date</b>
                                                        </div>
                                                        <div className="col-2 text-center ">
                                                            <b>Action</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="scrolly-63">
                                                    {CodeDetails.map((item, i) => {
                                                        var returData;
                                                        returData = (
                                                            <div className="col-12 grid" key={i} rowcount={i + 1} id={`MyClass_${item.id}`}>
                                                                <div className="row" key={i}>
                                                                    <div className="col-3 mb-2 text-center">
                                                                        {item.title}
                                                                    </div>
                                                                    <div className="col-3 mb-2 text-center">
                                                                        {item.code}
                                                                    </div>
                                                                    <div className="col-1 text-center text-center">
                                                                        {item.isActive == true ? (
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type="checkbox"
                                                                                defaultChecked={checkvalue}
                                                                                id={`VisibleId_${item.id}`}
                                                                                userid={item.id}
                                                                                onClick={changeIsActive}
                                                                            />
                                                                        ) : (
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type="checkbox"
                                                                                id={`VisibleId_${item.id}`}
                                                                                userid={item.id}
                                                                                onClick={changeIsActive}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div className="col-3 mb-2 text-center">
                                                                        {item.expiryDate.replace('T', ' at ')}
                                                                    </div>
                                                                    <div className="col-2 text-center">
                                                                        <span>
                                                                            <img
                                                                                src="/images/editIcon.svg"
                                                                                title="Edit Code"
                                                                                className="iconClass me-1"
                                                                                onClick={(e) => UpdateCode(item.id)}
                                                                                alt="Edit Code"
                                                                            />
                                                                        </span>
                                                                        <span>
                                                                            <img
                                                                                src="./images/deleteIcon.svg"
                                                                                className="iconClass"
                                                                                title="Delete Code"
                                                                                onClick={(e) => isShowPopup(item.id)}
                                                                                alt="Delete Code"
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                        return returData;
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="AddCodeDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/Zonetax">
                                                            <img src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" alt="Back" />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Add New Coupon Code
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 pt-3 pb-5">
                                                    <div className="row pb-5">
                                                        <div className="col-4">
                                                            <span className="grayTxt">Title</span>
                                                            <input type="text" className="form-control" value={title} onChange={(e) => settitle(e.target.value)} />
                                                            <br />
                                                        </div>
                                                        <div className="col-4">
                                                            <span className="grayTxt">Code</span>
                                                            <span className="txtRed">*</span>
                                                            <input type="text" className="form-control" id="CouponCode" value={code} onChange={(e) => setcode(e.target.value)} />
                                                            <span className="txtRed">{blankCode}</span>
                                                            <br />
                                                        </div>
                                                        <div className="col-4">
                                                            <span className="grayTxt">Expiry Date</span>
                                                            <span className="txtRed">*</span>
                                                            <input type="date" className="form-control" id="ExpiryDate" value={expirydate} onChange={(e) => setexpirydate(e.target.value)} />
                                                            <span className="txtRed">{blankExpiryDate}</span>
                                                            <br />
                                                        </div>
                                                        <div className="col-12 pt-1 d-flex align-items-center">
                                                            <label className="form-check-label me-4 my-1">
                                                                <input type="radio" defaultChecked={checkvalue} onClick={(e) => setDiscountType(1)} staticval="1" className=" form-check-input me-3 DiscountTypeClass" name="ordertype" />
                                                                Percentage
                                                            </label><br />
                                                            <label className="form-check-label mx-4 my-1" >
                                                                <input type="radio" staticval="2" onClick={(e) => setDiscountType(2)} className="form-check-input me-3 DiscountTypeClass" name="ordertype" />
                                                                Flat
                                                            </label>
                                                        </div>
                                                        <div className="col-4 pt-2 ">
                                                            <span className="grayTxt">Discount</span>
                                                            <span className="txtRed">*</span>
                                                            <input type="text" className="form-control" id="DiscountID" value={discount} onChange={(e) => setdiscount(e.target.value)} />
                                                            <span className="txtRed">{blankDiscount}</span>
                                                            <br />
                                                        </div>
                                                        <div className="col-4 pt-2">
                                                            <span className="grayTxt">LeadSource</span>
                                                            <input type="number" className="form-control" id="leadsource" value={leadsource} onChange={(e) => setleadsource(e.target.value)} />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={cancelbutton}>Cancel</span>
                                                    <button type="submit" className="loginBtn" onClick={AddSaveCode}>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="UpdateCodeDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/Zonetax">
                                                            <img src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" alt="Back" />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Update Coupon Code
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 pt-3 pb-5">
                                                    <div className="row pb-5">
                                                        <div className="col-4">
                                                            <span className="grayTxt">Title</span>
                                                            <input type="text" className="form-control" value={title} onChange={(e) => settitle(e.target.value)} />
                                                            <br />
                                                        </div>
                                                        <div className="col-4">
                                                            <span className="grayTxt">Code</span>
                                                            <span className="txtRed">*</span>
                                                            <input type="text" className="form-control" id="CouponUpdateCode" value={code} onChange={(e) => setcode(e.target.value)} />
                                                            <span className="txtRed">{blankCode}</span>
                                                            <br />
                                                        </div>
                                                        <div className="col-4">
                                                            <span className="grayTxt">Expiry Date</span>
                                                            <span className="txtRed">*</span>
                                                            <input type="date" className="form-control" id="ExpiryUpdateDate" defaultValue={expirydate} onChange={(e) => setexpirydate(e.target.value)} />
                                                            <span className="txtRed">{blankExpiryDate}</span>
                                                            <br />
                                                        </div>
                                                        <div className="col-12 pt-1 d-flex align-items-center">
                                                            <label className="form-check-label me-4 my-1">
                                                                <input type="radio" id="PercentageId" defaultChecked={checkvalue} onClick={(e) => setUpdateDiscountType(1)} staticval="1" className=" form-check-input me-3 DiscountTypeUpdateClass" name="updatename" />
                                                                Percentage
                                                            </label><br />
                                                            <label className="form-check-label mx-4 my-1" >
                                                                <input type="radio" id="FlatId" staticval="2" onClick={(e) => setUpdateDiscountType(2)} className="form-check-input me-3 DiscountTypeUpdateClass" name="updatename" />
                                                                Flat
                                                            </label>
                                                        </div>
                                                        <div className="col-4 pt-2 ">
                                                            <span className="grayTxt">Discount</span>
                                                            <span className="txtRed">*</span>
                                                            <input type="text" className="form-control" id="DiscountUpdateID" value={discount} onChange={(e) => setdiscount(e.target.value)} />
                                                            <span className="txtRed">{blankDiscount}</span>
                                                            <br />
                                                        </div>
                                                        <div className="col-4 pt-2">
                                                            <span className="grayTxt">LeadSource</span>
                                                            <input type="number" className="form-control" id="leadsource" value={leadsource} onChange={(e) => setleadsource(e.target.value)} />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={cancelbutton}>Cancel</span>
                                                    <button type="submit" className="loginBtn" onClick={UpdateSaveCode}>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalPopup
                        class="MainModal"
                        dataShowhide={btnshow}
                        show={show}
                        data={modalHead}
                        modalBody={modalBody}
                        onHide={closePopupHandler}
                        onClick={DeleteCode}
                    ></ModalPopup>
                </>
                :
                <></>
            }
        </>
    )
}