import { useEffect } from "react"
import $ from 'jquery';
import React from "react";
import { CDNURL } from "../js/constants";

export function LeftMenu() {
    useEffect(() => {
        localStorage.setItem('shownotification', '0');
        var PathName = window.location.pathname;
        var pathsplit = PathName.split('/');
        var prodActive = pathsplit[1];
        $(".linkClass").removeClass('active');
        if (PathName == "/NavigationMenu" || prodActive == "HomeBanner" || prodActive == "Customization" || prodActive == "CustomizeFaq" || prodActive == "FaqAddEdit" || prodActive == "ColorsAdmin" || prodActive == "TableClothesAdmin" || prodActive == "CouponCodes") {
            $("#Active_navbarAdminDropdown").addClass('active');
            $("#Active_navbarAdminDropdown").children('a').removeClass('adminlink');
        } else if (prodActive == "ProductCategory" || prodActive == "Orderproperties") {
            $("#Active_navbarAdminProducts").addClass('active');
            $("#Active_navbarAdminProducts").children('a').removeClass('adminlink');
        } else if (prodActive == "UploadImages") {
            $("#ActiveId_UploadImages").addClass('active');
            $("#ActiveId_UploadImages").children('a').removeClass('adminlink');
        } else if (prodActive == "Zonetax") {
            $("#ActiveId_Zone").addClass('active');
            $("#ActiveId_Zone").children('a').removeClass('adminlink');
        } else if (prodActive == "Customerreview") {
            $("#ActiveId_Reviews").addClass('active');
            $("#ActiveId_Reviews").children('a').removeClass('adminlink');
        } else if (prodActive == 'customers'){
            $("#ActiveId_Customers").addClass('active');
            $("#ActiveId_Customers").children('a').removeClass('adminlink');
        }
        
    }, []);
    const viewWebsite = () => {
        window.open("/home");
    }
    return (
        <div className="container-fluid px-0">
            <nav className="navbar navbar-light navbar-expand-lg adminsitenavbar">
                <div className="container-fluid px-0 py-2" >
                    <a className="navbar-brand"></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse  justify-content-center" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link adminlogolink" aria-current="page" href="/NavigationMenu" >
                                <img src={CDNURL + "Localimages/Final_Logo_2.svg"} className="img-fluid pt-2" alt="Logo"/>
                                </a>
                            </li>
                            <li className="sidebar-item nav-item dropdown mt-4 mx-3" id="Active_navbarAdminDropdown">
                                <a className="sidebar-link dropdown-toggle ">
                                    Customization
                                </a>
                                <ul className="dropdown-menu dropdown-content" >
                                    <li><a className="dropdown-item adminlink" href="/Customization">Static Content</a></li>
                                    <li><a className="dropdown-item adminlink" href="/NavigationMenu">Navigation Menu</a></li>
                                    <li><a className="dropdown-item adminlink" href="/HomeBanner">Banners</a></li>
                                    <li><a className="dropdown-item adminlink" href="/CustomizeFaq">FAQ</a></li>
                                    <li><a className="dropdown-item adminlink" href="/ColorsAdmin">Colors</a></li>
                                    <li><a className="dropdown-item adminlink" href="/TableClothesAdmin">Table Clothes</a></li>
                                    <li><a className="dropdown-item adminlink" href="/CouponCodes">Coupon Codes</a></li>
                                </ul>
                            </li>
                            <li className="sidebar-item nav-item dropdown mt-4 mx-3" id="Active_navbarAdminProducts">
                                <a className="sidebar-link dropdown-toggle ">
                                    Our Products
                                </a>
                                <ul className="dropdown-menu dropdown-content">
                                    <li><a className="dropdown-item adminlink" href="/ProductCategory">Products</a></li>
                                    <li><a className="dropdown-item adminlink" href="/Orderproperties">Properties</a></li>
                                </ul>
                            </li>
                            <li className="sidebar-item linkClass my-3 mx-3" id="ActiveId_UploadImages">
                                <a className="sidebar-link adminlink" href="/UploadImages">
                                    Gallery
                                </a>
                            </li>
                            <li className="sidebar-item linkClass my-3 mx-3" id="ActiveId_Customers">
                                <a className="sidebar-link adminlink" href="/customers">
                                    Customers
                                </a>
                            </li>
                            <li className="sidebar-item linkClass my-3 mx-3" id="ActiveId_Zone">
                                <a className="sidebar-link adminlink" href="/Zonetax">
                                    Taxes
                                </a>
                            </li>
                            <li className="sidebar-item linkClass my-3 mx-3" id="ActiveId_Reviews">
                                <a className="sidebar-link adminlink" href="/Customerreview">
                                    Reviews
                                </a>
                            </li>
                            <li className="nav-item my-3">
                                <a className="nav-link" aria-current="page" href="/logout">
                                    <img src="/images/logout.png" className="img-fluid iconClass" title="Logout" alt="Logout"/>
                                </a>
                            </li>
                            <li className="nav-item my-3" onClick={viewWebsite}>
                                <a className="nav-link" aria-current="page">
                                    <img src="/images/viewwebsite.png" className="img-fluid iconClass crsr-Pointer" title="View Website" alt="View Website"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}