import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import $ from "jquery";
import { CDNURL } from "../js/constants";
import { WhatCustomerThink } from "./components/WhatCustomerThink";
import React, { useState, useEffect } from "react";
import { ApiGetCall } from "../js/connector";
import CountSetInterval from "./components/CountMethods";
import DeliverQuality from "./components/DeliverQuality";
import HelmetMetaTag from "./components/HelmetMetaTag";
export function Aboutus() {
  const [overallstate, setoverallstate] = useState({});
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  const [sectionContent, setsectionContent] = useState("");
  const [countnumber1, setcountnumber1] = useState(0);
  const [countnumber2, setcountnumber2] = useState(0);
  const [countnumber3, setcountnumber3] = useState(0);
  $("#yourElementId").text("About Berger's Table Pad Factory");

  useEffect(() => {
    window.scroll(0, 0);
    window.addEventListener("scroll", isSticky);
    myFunction();
    return () => {
      setoverallstate({});
    };
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[22].value);
        setsectionDesciption(propDetails[23].value);
        setsectionCTAtext(propDetails[24].value);
        setsectionContent(propDetails[25].value);
      }
    });
  };
  const gotonavbar = () => {
    $("html, body").animate(
      {
        scrollTop: $("#GotoNavbar").offset().top,
      },
      100
    );
  };
  const isSticky = () => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 200) {
      setcountnumber1(20);
      setcountnumber2(48);
      setcountnumber3(200);
    }
  };
  return (
    <>
      <NavigationBar />
      <HelmetMetaTag
        title="About Berger's Table Pad Factory"
        desc="Learn more about Berger's Table Pads & what sets our high-quality custom table pads apart from our competitors. Contact us & order your table pad today!"
      />
      <div className="container-fluid px-0  colorHeroSection">
        <div className="row">
          <div
            className="col-md-7 m-auto pt-3"
            style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
          >
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6
              style={{ fontSize: "16px", fontWeight: "600" }}
              className="py-4"
              dangerouslySetInnerHTML={{ __html: sectionDesciption }}
            ></h6>
            <button className="ColorBtn" onClick={gotonavbar}>
              {sectionCTAtext}
              <img
                src={CDNURL + "Localimages/colorIcon.png"}
                className="img-fluid ms-3"
                alt="Learn More"
              />
            </button>
          </div>
          <div className="col-md-5 pb-3 text-center">
            <img
              src={CDNURL + "Localimages/AboutUs.png"}
              className="img-fluid text-end"
              alt="Hero Image"
            />
          </div>
        </div>
      </div>
      <div className="container px-0 py-4 colorPara" id="GotoNavbar">
        <div className="col-12 pt-5 px-3">
          <div className="text-center">
            <h2>Who We Are!</h2>
            <p className="prodhr mt-1" />
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: sectionContent }}
            className="pt-3"
          ></div>
        </div>
      </div>

      {/* counter div */}
      <div className="container-fluid px-0 py-4 counterrClass">
        <div className="bgF6F6F6 px-5 py-5 ">
          <div className="text-center">
            <h2>Some Numeric Facts!</h2>
            <p className="prodhr mt-1" />
          </div>
          <div className="pt-3 row pt-5">
            <div className="col-md-3 py-2 px-4 d-flex justify-content-center">
              <div className="counterCard">
                <img
                  src="images/experience.png"
                  className="img-fluid"
                  alt="Years Of Experience"
                />
                <h2 className="brownTxt fs-700 mb-0 py-3 h1-font">
                  <CountSetInterval
                    label={"count"}
                    number={countnumber1}
                    duration={parseInt(2)}
                  />
                  +
                </h2>
                <span className="grayTxt">Years Of Experience</span>
              </div>
            </div>
            <div className="col-md-3 py-2 px-4 d-flex justify-content-center">
              <div className="counterCard">
                <img
                  src="images/state.png"
                  className="img-fluid"
                  alt="States We Deliver To"
                />
                <h2 className="brownTxt fs-700 mb-0 py-3 h1-font">
                  <CountSetInterval
                    label={"count"}
                    number={countnumber2}
                    duration={parseInt(2)}
                  />
                  +
                </h2>
                <span className="grayTxt">States We Deliver To</span>
              </div>
            </div>
            <div className="col-md-3 py-2 px-4 d-flex justify-content-center">
              <div className="counterCard">
                <img
                  src="images/team.png"
                  className="img-fluid"
                  alt="Experts Team"
                />
                <h2 className="brownTxt fs-700 mb-0 py-3 h1-font">
                  <CountSetInterval
                    label={"count"}
                    number={countnumber3}
                    duration={parseInt(2)}
                  />
                  +
                </h2>
                <span className="grayTxt">Experts Team</span>
              </div>
            </div>
            <div className="col-md-3 py-2 px-4 d-flex justify-content-center">
              <div className="counterCard">
                <img
                  src="images/tablepadsdelivered.png"
                  className="img-fluid"
                  alt="Tablepads Delivered"
                />
                <h2 className="brownTxt fs-700 mb-0 py-3 h1-font">
                  <CountSetInterval label={"count"} number={1} />
                  M+
                </h2>
                <span className="grayTxt">Tablepads Delivered</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container px-0 py-4">
        <div className="col-12 pt-5 px-3">
          <div className="text-center">
            <h2>Manufacturing Process</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara pt-3">
            <li className="pt-3 ps-2 liBullet">
              All table pad patterns, if needed, are scanned, sent to a computer
              where they are cleaned up and sent to automated CNC router to be
              perfectly cut to the size and shape of your table. All other
              factories manually cut the table pads.
            </li>
            <li className="pt-3 ps-2 liBullet">
              Vinyl material top and DuraVelvet material bottom are manually cut
              to the properly fit each table pad section.
            </li>
            <li className="pt-3 ps-2 liBullet">
              Each table pad section is manually slit around the perimeter to
              allow material to be tucked, thereby covering the entire substrate
              (1/2 inch thick Fiberboard) with top and bottom material by our
              highly skilled tuckers. Tuckers also insert magnets and steel
              plates beneath the DuraVelvet.
            </li>
            <li className="pt-3 ps-2 liBullet">
              All material is run through a heated roller-type glue machine,
              covering the bottom with adhesive to permanently adhere to the
              Fiberboard.
            </li>
            <li className="pt-3 ps-2 liBullet">
              Each finished section is inspected before shipping.
            </li>
            <li className="pt-3 ps-2 liBullet">
              Each pad is boxed with foam cushioning in all corners, awaiting to
              be picked up by FedEx to deliver to your home
            </li>
          </ul>
        </div>
      </div>

      <div className="container px-0 py-4">
        <div className="col-12 pt-5 px-3">
          <div className="text-center">
            <h2>Table Pad Components And Advantages</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara pt-3">
            <li className="pt-3 ps-2 liBullet">
              Solid Fiberboard core, not layered material used by others that
              can be susceptible to moisture entrapment, minimizing odor/mildew
              potential over time.
            </li>
            <li className="pt-3 ps-2 liBullet">
              Lighter weight than all others for ease handling.
            </li>
            <li className="pt-3 ps-2 liBullet">
              Exclusive and patented magnetic locking system (learn more)with
              link to magnetic page?
            </li>
            <li className="pt-3 ps-2 liBullet">
              Heavy-duty vinyl top and DuraVelvet bottom that lays on your
              finished table surface. Exclusive DuraVelvet bottom is embossed,
              creating air pockets to allow your surface to properly breath
              (which is important) *to minimize water condensation if pad is
              left on for extended periods of time.
            </li>
          </ul>
        </div>
        <p className="pt-3 colorPara">
          *If leaving your pad on your table for any extended period of time, it
          is recommended that you periodically remove it to minimize any
          moisture build-up that could cause table damage (depending upon
          humidity conditions)
        </p>
      </div>

      <DeliverQuality isH1Tag="no" />
      <div className="container px-0 py-4">
        <div className="col-12 pt-5 px-3">
          <div className="text-center">
            <h2>MagnaLoc™ Benefits</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara pt-3">
            <li className="pt-3 ps-2 liBullet">
              Minimizes pad separation and sliding
            </li>
            <li className="pt-3 ps-2 liBullet">
              100% user-friendly (nothing to think about - nothing to do - they
              just work)
            </li>
            <li className="pt-3 ps-2 liBullet">
              Unlike other exposed locking systems, MagnaLoc™ is completely
              concealed whether the pads are in use or not.
            </li>
            <li className="pt-3 ps-2 liBullet">
              Will not come loose, fall off or break (as exposed locking systems
              may)
            </li>
            <li className="pt-3 ps-2 liBullet">
              Eliminates potential table damage that can be caused by other
              exposed metal or plastic locking devices
            </li>
            <li className="pt-3 ps-2 liBullet">
              Can be fully functional up to 100 years
            </li>
          </ul>
        </div>
        <p className="pt-3 colorPara">
          Every one of our{" "}
          <a className="paraLink" href="/products/table-pads">
            table pads
          </a>{" "}
          is built to order, directly from our factory.
        </p>
      </div>
      <WhatCustomerThink isH1Tag="no" />
      <Footer />
    </>
  );
}
