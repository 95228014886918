import React from "react";
import { NavigationBar } from "../Navigationbar";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import { Footer } from "../Footer";
import ImgData from "../components/ImgData";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CDNURL } from "../../js/constants";
import { useEffect } from "react";
import $ from 'jquery';

export default function OtherFurniturePads() {
  useEffect(() => {
    $('#yourElementId').text('Table Pads for Other Furniture - Conference, Buffet, Pool, Piano');
  });
  return (
    <>
      <NavigationBar />
      <div className="container-fluid px-0 colorpageimage">
        <LazyLoadImage
          src={CDNURL + "Localimages/ProductsHeadImg/OtherFurniturePadsHead.jpg"}
          className="img-fluid w-100"
        />
        <span className="carousel-caption1">
          <p className="typingTxt">Table Pads for Other Furniture</p>
          <br />
          <div className="col-2 offset-5">
            <hr className="hrClass" />
          </div>
        </span>
      </div>

      <div className="container py-5 colorPara ps-0">
        <ul>
          <li className="liBullet ps-2">
            In addition to table pads for dining tables, we make custom pads for
            many applications including conference tables, pool tables, coffee
            tables, desks, buffet tables, pianos, and more. If you have a flat
            surface that needs to be protected, we can make a pad to keep it
            protected.
          </li>
          <li className="pt-4 liBullet ps-2">
            When you are ordering table pads for one of the above applications,
            it is important that dimensions and shapes are double checked for
            accuracy. Follow this link to{" "}
            <a className="paraLink" href="/how-to-measure-for-a-table-pad">
              learn how to measure your table.
            </a>{" "}
            - whether it is a coffee table or a piano, we've got instructions
            for you. You can also{" "}
            <a className="paraLink" href="/contact">
              contact us
            </a>{" "}
            or order one of our{" "}
            <a className="paraLink" href="/free-kit-request">
              free measuring kits.
            </a>{" "}
            You can get more information about our{" "}
            <a className="paraLink" href="/magnetic-locking-system">
              MagnaLoc™ patented magnetic locking system
            </a>
            , our{" "}
            <a className="paraLink" href="/customized-monograms">
              custom monograms
            </a>
            , or view a full list of our available{" "}
            <a className="paraLink" href="/colors">
              top and bottom colors
            </a>
            .
          </li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 col-12">
            <ImgData
              img1="OtherFurniturePadsImg1"
              shadowImg="OtherFurniturePadsImg1-shadow"
              id="ofp-img1"
              // mt=""
              listData={`Keep your conference or banquet table protected, regardless of size or shape. These pads are crafted to our highest standards, the same as our Ultra Elite Dining Table Pads - 1/2" thick with durable vinyl top and Dura-Velvet bottoms.`}
              data1="Conference Table Pad"
              dataDesign3="true"
              popupImg="OtherFurniturePadsImg1-big"
              dataurl="/store/conference-table-pads"
              dataid="20"
              datastaticpath="conferencepads"
            />
          </div>
          <div className="col-lg-6 col-12">
            <ImgData
              img1="OtherFurniturePadsImg2"
              shadowImg="OtherFurniturePadsImg2-shadow"
              id="ofp-img2"
              // mt=""
              listData={`Transform your pool or billiard table into a dining and entertainment center surface. It is our Ultra Elite 1/2" table pad, with the vinyl top and Dura-Velvet bottom. A custom support kit is included as well.`}
              data1="Pool Table Pad"
              dataDesign3="true"
              popupImg="OtherFurniturePadsImg2-big"
              dataurl="/store/pool-table-pads"
              dataid="18"
              datastaticpath="pooltable"
            />
          </div>
          <div className="col-lg-6 col-12">
            <ImgData
              img1="OtherFurniturePadsImg3"
              shadowImg="OtherFurniturePadsImg3-shadow"
              id="ofp-img3"
              // mt="mt-5"
              listData={`Feel free to stop using coasters! Our coffee table pads can be made for any shape, size, or surface type.`}
              data1="Coffee Table Pad"
              dataDesign3="true"
              popupImg="OtherFurniturePadsImg3-big"
              dataurl="/store/coffee-table-pads"
              dataid="15"
              datastaticpath="coffeepads"
            />
          </div>
          <div className="col-lg-6 col-12">
            <ImgData
              img1="OtherFurniturePadsImg4"
              shadowImg="OtherFurniturePadsImg4-shadow"
              id="ofp-img4"
              // mt="mt-5"
              listData={`Buffet and sideboard pads are made as a single, foldable piece. They're easy to handle and store when not in use. Serving dinner using your buffet table is no longer something to worry about.
            `}
              data1="Buffet Pad"
              dataDesign3="true"
              popupImg="OtherFurniturePadsImg4-big"
              dataurl="/store/buffet-table-pads"
              dataid="14"
              datastaticpath="buffetpads"
            />
          </div>
          <div className="col-lg-6 col-12">
            <ImgData
              img1="OtherFurniturePadsImg5"
              shadowImg="OtherFurniturePadsImg5-shadow"
              id="ofp-img5"
              // mt="mt-5"
              listData={`Keep your expensive piano protected from pets and hazards while maintaining the high end look. Custom made to fit your piano exactly, you can rest easy knowing this investment is safe.`}
              data1="Piano Pad"
              dataDesign3="true"
              popupImg="OtherFurniturePadsImg5-big"
              dataurl="/store/piano-pads"
              dataid="17"
              datastaticpath="pianopads"
            />
          </div>
        </div>
      </div>
      {/* what our clients think start*/}
      <WhatCustomerThink />
      {/* what our clients think end*/}
      <Footer />
    </>
  );
}
