import React from "react";
import { NavigationBar } from "../Navigationbar";
import { Footer } from "../Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CDNURL } from "../../js/constants";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import { useEffect } from "react";
import $ from 'jquery';

export default function PadsforTables() {
  useEffect(() => {
    $('#yourElementId').text('Pads for Tables');
  });
  return (
    <>
      <NavigationBar />
      <div className="container-fluid px-0 colorpageimage">
        <LazyLoadImage
          src={CDNURL + "Localimages/ProductsHeadImg/PadforTablesHead.jpg"}
          className="img-fluid w-100"
        />
        <span className="carousel-caption1">
          <h1 className="firstHead">Pads for Tables</h1>
          <div className="col-2 offset-5">
            <hr className="hrClass" />
          </div>
        </span>
      </div>

      <div className="container py-5 colorPara ps-0">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Pads for Tables</h1>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <ul>
          <li className="liBullet ps-2 pt-3">
            At Berger’s Table Pads, we manufacture top quality{" "}
            <a className="paraLink" href="/products/table-pads">
              pads for tables
            </a>{" "}
            of every kind, from dining room and coffee tables to pool tables and
            conference tables. If you have a beautiful piece of furniture you’d
            like to protect and preserve for your children or grandchildren,
            consider looking into our products.
          </li>
        </ul>
      </div>

      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Why We Are Different</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              Other table pad websites want your business but lack the resources
              to provide great customer service to their online visitors. At
              Berger’s, we believe a great shopping experience hinges on
              exceptional customer service, which is why we offer multiple
              options for connecting with us. Our knowledgeable product
              specialists are available via Live Chat, phone, email
              communication, and in-house assistance.
            </li>
            <li className="pt-4 liBullet ps-2">
              We serve many areas around the US where we can come into your home
              to take measurements. Feel free to call us to inquire as to
              whether this service is available where you live. In areas where
              we do not have a representative available for an in-house visit, a
              two-minute phone call with one of our experts will ensure you get
              the correct measurement.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>
              Quality Designed to Last a Lifetime
            </h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              These are more than mere words at Berger’s. When we say our pads
              for tables will last throughout many generations, we mean just
              that. Our manufacturing process is what sets us apart from our
              competition. Other companies are satisfied offering layered pads
              that break down quickly and retain moisture, resulting in a
              product that will mold and mildew in a short amount of time.
            </li>
            <li className="pt-4 liBullet ps-2">
              Our solid core fiberboard construction does not trap moisture and
              is not subject to molding. We use precision CNC cutting to deliver
              a more precise product that fits and looks better. Store upright
              when not in use without worrying that pads will bend or bow.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>
              Berger’s MagnaLoc Keeps Table Pads Where They Belong
            </h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              There’s no sliding or pad separation with our products like you
              typically experience with inferior quality pads for tables. This
              is due to the fact that we have designed and crafted the perfect
              locking system to keep pads connected. We call it the MagnaLoc
              system, and it works while remaining 100% concealed.
            </li>
            <li className="pt-4 liBullet ps-2">
              Our patented MagnaLoc pad connectors are designed to remain
              functional for up to 100 years. Your grandchildren’s children will
              still be using your Berger’s table pads to protect antique
              furniture that has been passed down to them through the years.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Ordering From Berger’s is Easy</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              We guarantee the right fit, so rest easy knowing we’re by your
              side from start to finish. Request a free measuring kit on our
              website, and we’ll send you a kit with instructions on measuring
              your table, or simply reach out to one of our reps through website
              chat or telephone, and we’ll help you with the measuring process.
            </li>
            <li className="pt-4 liBullet ps-2">
              For many customers, a manufacturer’s model on the bottom of their
              table is all that is needed for us to custom-manufacture their
              table pads. Order with confidence that we stand behind all pads
              for tables and furnishings- your 100% satisfaction is our goal.
            </li>
          </ul>
        </div>
      </div>
      <WhatCustomerThink />
      <Footer />
    </>
  );
}
