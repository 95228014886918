import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { ApiGetCall } from "../js/connector";
import moment from "moment";
export function PreviousOrders() {
    const [overallstate, setoverallstate] = useState({});
    var UserCode = localStorage.getItem("DealersUserCode");
    const [OrderDeatils, setOrderDeatils] = useState([]);
    const [norecord, setnorecord] = useState("");
    useEffect(() => {
        window.scroll(0, 0);
        GetPreviousOrders();
        return () => {
            setoverallstate({});
        };
    }, []);
    const GetPreviousOrders = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/DealersLogin/GetNewPreviousOrders/" + UserCode).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.status == "Success") {
                    if (responseRs.orderDetails.orders.length == 0) {
                        var sugArray = [];
                        sugArray.push(
                            <div className="col-12 p-5 text-center">
                                <label className="p-3">No Record Found</label>
                            </div>
                        );
                        setnorecord(sugArray);
                        setOrderDeatils([]);
                        $("#Overlay").hide();
                        $("#LoderId").hide();
                    } else {
                        setnorecord([]);
                        setOrderDeatils(responseRs.orderDetails.orders);
                        $("#Overlay").hide();
                        $("#LoderId").hide();
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.Message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const OrderDetailsDiv = (orderid) => {
        if (!$("#OrderDetailsDiv_" + orderid).hasClass('d-none')) {
            $("#UpRoundImg_" + orderid).addClass('d-none');
            $("#DownRoundImg_" + orderid).removeClass('d-none');
            $("#OrderDetailsDiv_" + orderid).fadeOut();
            $("#OrderDetailsDiv_" + orderid).fadeOut("slow");
            $("#OrderDetailsDiv_" + orderid).fadeOut(3000);
            $("#OrderDetailsDiv_" + orderid).addClass('d-none');
        } else {
            $("#UpRoundImg_" + orderid).removeClass('d-none');
            $("#DownRoundImg_" + orderid).addClass('d-none');
            $("#OrderDetailsDiv_" + orderid).fadeIn();
            $("#OrderDetailsDiv_" + orderid).fadeIn("slow");
            $("#OrderDetailsDiv_" + orderid).fadeIn(3000);
            $("#OrderDetailsDiv_" + orderid).removeClass('d-none');
        }
    }
    return (
        <>
            <NavigationBar />
            <div className="py-3">
                <div className="container px-5">
                    <div className="col-12 pb-2">
                        <h3 className="fs-600">Previous Order details</h3>
                    </div>
                    <div className="row pt-3" style={{ backgroundColor: "#F7F8FA", borderRadius: "10px" }}>
                        <div className="col-12 pb-2" style={{ borderBottom: "0.7px solid rgba(21, 24, 33, 0.25)" }}>
                            <div className="row px-4">
                                <div className="col-3">
                                    <b>Order No. #</b>
                                </div>
                                <div className="col-2">
                                    <b>Order Date</b>
                                </div>
                                <div className="col-3">
                                    <b>Customer Name</b>
                                </div>
                                <div className="col-3">
                                    <b>Email</b>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>
                        <div>
                            {OrderDeatils.map((item, i) => {
                                var returData;
                                returData = (
                                    <div className="row px-4 grid pb-2" key={i} onClick={(e) => OrderDetailsDiv(item.order.id)} >
                                        <div className="col-3">
                                            {item.order.orderNumber}
                                        </div>
                                        <div className="col-2">
                                            {moment(item.order.orderDate).utc().format('MM-DD-YYYY')}
                                        </div>
                                        <div className="col-3">
                                            {item.customAddressCustomer.length == 0 ?
                                                <>-</>
                                                :
                                                <>
                                                    {item.customAddressCustomer[0].firstName} {item.customAddressCustomer[0].lastName}
                                                </>
                                            }
                                        </div>
                                        <div className="col-3">
                                            {item.customAddressCustomer.length == 0 ?
                                                <>-</>
                                                :
                                                <>
                                                    {item.customAddressCustomer[0].email}
                                                </>
                                            }
                                        </div>
                                        <div className="col-1 text-center">
                                            <img src="/images/UpRoundArrow.svg" className="img-fluid d-none" id={`UpRoundImg_${item.order.id}`} />
                                            <img src="/images/DownRoundArrow.svg" className="img-fluid" id={`DownRoundImg_${item.order.id}`} />
                                        </div>
                                        <div className="row my-4 px-5 d-none" id={`OrderDetailsDiv_${item.order.id}`}>
                                            <div className="card p-2">
                                                <div className="row px-0">
                                                    <h5 className="mb-2">Order Details</h5>
                                                    <img src="/images/HrLine.svg" className="img-fluid" />
                                                </div>
                                                <div>
                                                    {item.customAddressCustomer.length == 0 ?
                                                        <div className="col-12 text-center p-5">
                                                            <label>No Record Found</label>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            <div className="col-md-6 pe-2">
                                                                {item.lineItems.length == 0 ?
                                                                    <div className="col-12 text-center p-5">
                                                                        <label>No Record Found</label>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {item.lineItems.map((lineitem, i) => {
                                                                                var OrderData;
                                                                                OrderData = (
                                                                                    <>
                                                                                        {lineitem.lineItemProductType != 'Payment' ?
                                                                                            <div key={i} className="p-2" style={{ textAlign: "justify" }}>
                                                                                                <div className="col-12 pb-2 row">
                                                                                                    <div className="col-md-4">
                                                                                                        <b className="brownTxt">Product Type :</b>
                                                                                                    </div>
                                                                                                    <div className="col-md-8">
                                                                                                        {lineitem.lineItemProductType}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 pb-2 row">
                                                                                                    <div className="col-md-4">
                                                                                                        <b className="brownTxt pe-2">Quantity :</b>
                                                                                                    </div>
                                                                                                    <div className="col-md-8">
                                                                                                        {lineitem.lineItemQuantity}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 pb-2 row">
                                                                                                    <div className="col-md-4">
                                                                                                        <b className="brownTxt pe-2">Description :</b>
                                                                                                    </div>
                                                                                                    <div className="col-md-8">
                                                                                                        {lineitem.lineItemDescription}
                                                                                                    </div>
                                                                                                </div>

                                                                                                <img src="/images/SmallHorizontalLine.svg" className="img-fluid" />

                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                        }

                                                                                    </>

                                                                                );
                                                                                return OrderData;
                                                                            })
                                                                        }
                                                                    </>
                                                                }

                                                            </div>
                                                            <div className="col-md-6 ">
                                                                <div className="col-12 p-2">
                                                                    <b className="brownTxt pe-2 pb-2">Billing Address</b><br />
                                                                    {item.customAddressCustomer.length == 0 ?
                                                                        <>-</>
                                                                        :
                                                                        <>
                                                                            {item.customAddressCustomer[0].address1},
                                                                            {item.customAddressCustomer[0].address2 == "" ?
                                                                                <></>
                                                                                :
                                                                                <>{item.customAddressCustomer[0].address2},</>
                                                                            }
                                                                            {item.customAddressCustomer[0].city}, {item.customAddressCustomer[0].state},
                                                                            {item.customAddressCustomer[0].countryCode}, {item.customAddressCustomer[0].zip}
                                                                            {item.customAddressCustomer[0].dayPhone != "" ?
                                                                                <div className="col-12">
                                                                                    <span className=" pe-2">Contact : </span>
                                                                                    {item.customAddressCustomer[0].dayPhone}
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className="col-12 p-2">
                                                                    <b className="brownTxt pe-2 pb-2">Shipping Address</b><br />
                                                                    {item.customAddressCustomer.length == 0 ?
                                                                        <></>
                                                                        :
                                                                        <>
                                                                            {item.customAddressCustomer[1].address1},
                                                                            {item.customAddressCustomer[1].address2 == "" ?
                                                                                <></>
                                                                                :
                                                                                <>{item.customAddressCustomer[1].address2},</>
                                                                            }
                                                                            {item.customAddressCustomer[1].city}, {item.customAddressCustomer[1].state},
                                                                            {item.customAddressCustomer[1].countryCode}, {item.customAddressCustomer[1].zip}
                                                                            {item.customAddressCustomer[1].dayPhone != "" ?
                                                                                <div className="col-12 py-1">
                                                                                    <span className=" pe-2">Contact : </span>
                                                                                    {item.customAddressCustomer[1].dayPhone}
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </>
                                                                    }
                                                                    <img src="/images/SmallHorizontalLine.svg" className="img-fluid" />
                                                                </div>
                                                                <div className="col-12 px-2">
                                                                    {item.lineItems.map((lineitem, i) => {
                                                                        var OrderData;
                                                                        var StringLineitem = lineitem.lineItemPrice.toString().replace("-", "");
                                                                        OrderData = (
                                                                            <>
                                                                                {lineitem.lineItemProductType == 'Payment' ?
                                                                                    <h4 className="brownTxt pe-2 pb-2 fs-600">Total  : {lineitem.lineItemPrice == null ? <>-</> : <>${StringLineitem}</>} </h4>
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </>
                                                                        );
                                                                        return OrderData;
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                                return returData;
                            })}
                            {norecord}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}