import React from "react";
import { NavigationBar } from "../Navigationbar";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import { Footer } from "../Footer";
import ImgData from "../components/ImgData";
import { CDNURL } from "../../js/constants";
import $ from "jquery";
import { useEffect, useState } from "react";
import { ApiGetCall } from "../../js/connector";
import HelmetMetaTag from "../components/HelmetMetaTag";

export default function StorageBags() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  useEffect(() => {
    $('#yourElementId').text('Storage Bags for Table Pads');
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[55].value);
        setsectionDesciption(propDetails[56].value);
        setsectionCTAtext(propDetails[57].value);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  return (
    <>
      <HelmetMetaTag
        title="Storage Bags for Table Leaves & Table Pads | Berger's"
        desc="Berger's Table Pads storage bags are made from reinforced acrylic vinyl to protect your table leaf or custom table pad when not in use. Order yours today!"
      />
      <NavigationBar />
      <div className="container-fluid px-0  tablepaddiesSection">
        <div className="row">
          <div className="col-md-6 m-auto pt-3 pb-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More" />
            </button>
          </div>
          <div className="col-md-6 pb-3 text-center">
            <img src={CDNURL + "Localimages/storagenewbags.png"} className="img-fluid text-end" alt="Hero Image" />
          </div>
        </div>
      </div>
      <div className="container py-5 pb-1 colorPara ps-0">
        <ul>
          <li className="liBullet ps-2">
            It is very important that when not in use, your table pads are
            properly protected and stored. We have separate bags for the pads as
            well as for the removable extension leaves to ensure everything is
            properly protected, stored together, and easy to find.
          </li>
          <li className="pt-4 liBullet ps-2">
            Making sure your <a className="paraLink" href="/products/table-pads">table pads</a> are properly stored will help guarantee their long life. In addition to our normal table pads, as well as all of <a className="paraLink" href="/products/other-furniture-pads">our other furniture pads,</a> should be properly protected by storing in these protective bags.
          </li>
        </ul>
        <div className="px-5">
          <ul className="mt-3">
            <li>Protects insert leaves & table pads while in storage</li>
            <li>Made from reinforced acrylic vinyl</li>
            <li>Inside fleece lining protects finished table surface</li>
            <li>Flat-top closure with Velcro fastener</li>
          </ul>
        </div>
      </div>
      <div className="container">
        <div className="row py-3 px-5">
          <div className="col-lg-6">
            <div data-url="/store/table-pad-storage-bag" data-id="28" data-staticpath="TABLEPADSTORAGEBAG" className="subProductOrderNow">
              <button className="p-2 orderNowBtn floatright mt-3 px-4 py-2">
                <img className="mx-2" src={CDNURL + "Localimages/products_Cate/basket.png"} alt="Basket Image" />
                TABLE PAD STORAGE BAG
              </button>
            </div>
          </div>
          <div className="col-lg-6">
            <div data-url="/store/table-leaf-storage-bag" data-id="29" data-staticpath="TABLELEAFSTORAGEBAG" className="subProductOrderNow">
              <button className="p-2 orderNowBtn floatLeft mt-3 px-4 py-2">
                <img className="mx-2" src={CDNURL + "Localimages/products_Cate/basket.png"} alt="Basket Image" />
                TABLE LEAF STORAGE BAG
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-4 col-12">
            <ImgData
              img1="StorageBagsImg1"
              shadowImg="StorageBagsImg1-shadow"
              id="sb-img1"
              // mt=""
              pb="none"
              listData=""
              data1="Conference Table Pad"
              dataDesign3=""
              popupImg="StorageBagsImg1-big"
            />
          </div>
          <div className="col-lg-4 col-12">
            <ImgData
              img1="StorageBagsImg2"
              shadowImg="StorageBagsImg2-shadow"
              id="sb-img2"
              // mt=""
              pb="none"
              listData=""
              data1="Conference Table Pad"
              dataDesign3=""
              popupImg="StorageBagsImg2-big"
            />
          </div>
          <div className="col-lg-4 col-12">
            <ImgData
              img1="StorageBagsImg3"
              shadowImg="StorageBagsImg3-shadow"
              id="sb-img3"
              // mt=""
              pb="none"
              listData=""
              data1="Conference Table Pad"
              dataDesign3=""
              popupImg="StorageBagsImg3-big"
            />
          </div>
        </div>
      </div>
      {/* what our clients think start*/}
      <WhatCustomerThink isH1Tag="no" />
      {/* what our clients think end*/}
      <Footer />
    </>
  );
}
