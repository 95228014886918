import React from "react";
import { NavigationBar } from "../Navigationbar";
// import { ListData } from "../components/ListData";
import { Footer } from "../Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CDNURL } from "../../js/constants";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import $ from "jquery";
import { useEffect } from "react";

export default function TablesPads() {
  useEffect(() => {
    $('#yourElementId').text('Tables Pads');
  });
  return (
    <>
      <NavigationBar />
      <div className="container-fluid px-0 colorpageimage">
        <LazyLoadImage
          src={CDNURL + "Localimages/ProductsHeadImg/TablesPadsHead.jpg"}
          className="img-fluid w-100"
        />
        <span className="carousel-caption1">
          <h1 className="firstHead">Tables Pads</h1>
          <div className="col-2 offset-5">
            <hr className="hrClass" />
          </div>
        </span>
      </div>

      <div className="container py-5 colorPara ps-0">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Tables Pads</h1>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <ul>
          <li className="liBullet ps-2">
            There are times when you want your table to have a little different
            look. Here at Table Pads, we have the pads you need for all your
            tables and more. This includes{" "}
            <a className="paraLink subProductOrderNow" data-url="/store/pool-table-pads" data-id="18" data-staticpath="pooltable">
              pool tables
            </a>{" "}
            , pianos, and buffet tables. You may try the{" "}
            <a className="paraLink" href="/products/table-pads">
              tables pads
            </a>{" "}
            at Berger’s Table Pads and Table Cloth. We have pads for dining
            tables, buffet tables, coffee and end tables, pool tables, and
            conference tables. We also have pads that can{" "}
            <a className="paraLink" href="/products/table-extension-pad">
              extend the table
            </a>{" "}
            and have some monogrammed{" "}
            <a className="paraLink" href="/products/table-paddies">
              table paddies
            </a>{" "}
            . These pads not only add a different look to your table but help to
            protect it from heat damage, scratches and spills. Our pads are made
            from fiberboard. This makes them resistant to humidity and warping.
          </li>
          <li className="pt-4 liBullet ps-2">
            Our boards are one solid piece rather than many layers as some of
            our competitors use. This prevents them from bending or warping, and
            they can be stored upright without any problems. We use MagnaLoc on
            our table tops. This means the covers will not break or come loose.
            These pads can last for up to 100 years.
          </li>

          <li className="pt-4 liBullet ps-2">
            Our Tables Pads come in two different grades and a variety of
            colors. The grades are Ultra Elite and Economy. Each one
            carries our guarantee that if it does not fit, we will remake it at
            no cost to you. Our pads are custom-made, so this does not happen
            real often. There is a 90-day quality guarantee.
          </li>
          <li className="pt-4 liBullet ps-2">
            The Ultra Elite cover comes with MagnaLocs and is designed for use
            every day. They are a half inch thick and protect up to 550 degrees of heat. The vinyl comes in the choice of colors in
            woodgrain, solid color or leather tone. The Ultra Elite pad has a
            lifetime limited manufacturer warranty.
          </li>
          <li className="pt-4 liBullet ps-2">
            manufacturer warranty. The last grade of pads is the economy tables
            pads. These pads are one-half inch thick. Our competitors only make
            this grade pads to be one-quarter inch thick. It is meant for
            occasional use. The color choices are the stone and laminate surface
            colors. This pad has a five-year limited manufacturer warranty.
          </li>
          <li className="pt-4 liBullet ps-2">
            Several color choices will match your decor. In the Leather Tones,
            you have eight options: Cranberry, chestnut, opal, slate, natural,
            Mocha, Palomino, and saddle. Wood grain colors have eight choices as
            well and look like natural wood. It comes in Mahogany, walnut, oak,
            cherry, brandy, pecan, maple and hickory. Then you have the solids
            in the four colors of black, bone white (ivory), white and cocoa.
            Lastly, there is the Dura-Velvet in brown, tan, burgundy, black and
            green. With all these colors to choose from, there is one that will
            dress up your table. These colors can be the same on top and bottom
            or can be different on top than on the bottom.
          </li>
        </ul>
      </div>
      <WhatCustomerThink />
      <Footer />
    </>
  );
}
