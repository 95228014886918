import React from "react";
import { useRef, useEffect, useState } from "react";
import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { CDNURL } from "../js/constants";
import $ from 'jquery';
import { ApiGetCall } from "../js/connector";

export function Colors() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  const [colorCategory, setcolorCategory] = useState([]);
  const [coloritems, setcoloritems] = useState([]);
  useEffect(() => {
    $('#yourElementId').text('Table Pad Colors');
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        setsectionTitle(propDetails[4].value);
        setsectionDesciption(propDetails[5].value);
        setsectionCTAtext(propDetails[6].value);
        getColorCategory();
      }
    });
  }
  const LinkClickActive = (e) => {
    $(".colorLink").removeClass('activeColorLink');
    var curId = e.currentTarget.attributes[1].value;
    $("#" + curId).addClass('activeColorLink');
    if (curId == "all") {
      $(".commonClassHdn").removeClass('d-none');
    } else if (curId == "LeatherTone") {
      $(".commonClassHdn").addClass('d-none');
      $("#LeatherTonesId").removeClass('d-none');
    } else if (curId == "WoodGrains") {
      $(".commonClassHdn").addClass('d-none');
      $("#WoodGrainsId").removeClass('d-none');
    } else if (curId == "Solids") {
      $(".commonClassHdn").addClass('d-none');
      $("#SolidId").removeClass('d-none');
    } else if (curId == "Dura-Velvet") {
      $(".commonClassHdn").addClass('d-none');
      $("#DuraVelvetId").removeClass('d-none');
    }
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  const getColorCategory = async () => {
    await ApiGetCall("/Colors/GetColorCategory").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var sugData = responseRs.colorCategories;
        setcolorCategory(sugData);
        GetColorMenu();
      }
    });
  }
  const GetColorMenu = async () => {
    await ApiGetCall("/Colors/GetColors").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var sugData = responseRs.data;
        setcoloritems(sugData);
      }
    });
  }

  return (
    <>
      <NavigationBar />
      <div className="container-fluid px-0  colorHeroSection">
        <div className="row">
          <div className="col-md-6 m-auto pt-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar}>{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More"/>
            </button>
          </div>
          <div className="col-md-6 pt-4 pb-3 text-center">
            <img src={CDNURL + "Localimages/ColorNewGroup.png"} className="img-fluid text-end" alt="Hero Image"/>
          </div>
        </div>
      </div>

      {/* paragraph start */}
      <div className="container py-5 colorPara" >
        <div className="pt-3">
          <ul>
            <li className="liBullet ps-2">
              These are the colors available for the top surface of the table pad only: leather tones, wood grains, and solids, as well as
              the bottom velvet colors (Dura-Velvet™).
            </li>
            <li className="pt-4 liBullet ps-2">
              Because of the settings on your computer monitor, the colors below may not look exactly the same as your table pads.
              If you require a more accurate table pad color swatch for your table pad, please <a className="paraLink" href="/contact">contact us</a> to submit your request.
            </li>
            <li className="pt-4 liBullet ps-2" id="GotoNavbar">
              All of these top and bottom colors can be mixed and matched on all of our <a className="paraLink" href="/products/table-pads">table pads</a>, our <a className="paraLink" href="/products/table-extension-pad">extension pads</a>, any of our
              <a className="paraLink" href="/products/other-furniture-pads"> other furniture pads</a>, and a select number are available on our <a className="paraLink" href="/products/table-paddies">Table Paddies</a>.
            </li>
          </ul>
        </div>
      </div>
      {/* paragraph end */}

      {/* navbar menu start */}
      <nav className="navbar navbar-expand-lg navbar-light ColorNavbar" >
        <div className="container-fluid">
          <a className="navbar-brand" href="#"></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#ColorDropdown" aria-controls="ColorDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="ColorDropdown">
            <ul className="navbar-nav brdrBtm">
              <li className="nav-item px-3 mb-2 crsr-pointer">
                <a className="nav-link">
                  <span className="activeColorLink colorLink" id="all" onClick={LinkClickActive}>All</span></a>
              </li>
              {colorCategory.map((item, i) => {
                var returData;
                {
                  item.isActive == true ?
                    returData = <li className="nav-item px-3 crsr-pointer" key={i}>
                      <a className="nav-link" >
                        <span className="colorLink" onClick={LinkClickActive} id={item.name.replace(" ", "")}>{item.name}</span></a>
                    </li>
                    :
                    <></>
                }
                return returData;
              })}
            </ul>
          </div>
        </div>
      </nav>
      {/* navbar menu end */}


      {/* Leather Tones start */}
      <div id="LeatherTonesId" className="commonClassHdn">
        <div className="container-fluid pt-5">
          <div className="text-center">
            <h2>Leather Tones</h2>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <div className="container py-5">
          <div className="col-12 px-5">
            <div className="row">
              {coloritems.map((item, i) => {
                var returData;
                var colorImage = CDNURL + "Gallery/" + item.colorImage;
                var colorTableImage = CDNURL + "Gallery/" + item.colorTableImage;
                returData = (<>
                  {item.isActive == 1 && item.categoryId == 1 ?
                    <div className="col-md-3 my-3 col-sm-6 col-12" key={i}>
                      {item.colorTableImage != null ?
                        <div className="boxshadowdiv">
                          <div className="flip-card" key={i}>
                            <div className="flip-card-inner">
                              <div className="flip-card-front">
                                <img src={colorImage} className="img-fluid w-100" style={{ height: "180px" }} alt="LeatherTones Image"/>
                              </div>
                              <div className="flip-card-back">
                                <img src={colorTableImage} className="img-fluid w-100 h-100" alt="LeatherTones Image"/>
                              </div>
                            </div>
                          </div>
                          <div className="flipcardPara p-2 py-4">
                            <h3>{item.name}</h3>
                            <h6>{item.shortDescription}</h6>
                          </div>
                        </div>
                        :
                        <div className="Colorcard" key={i}>
                          <img src={colorImage} className="img-fluid w-100" style={{ height: "180px" }} alt="LeatherTones Image"/>
                          <div className="LeatherContent p-2 py-4">
                            <h3>{item.name}</h3>
                            <h6>{item.shortDescription}</h6>
                          </div>
                        </div>
                      }
                    </div>
                    :
                    <></>
                  }
                </>
                )
                return returData;
              })}
            </div>
          </div>

        </div>
      </div>
      {/* Leather Tones end */}

      {/* Wood Grains start */}
      <div id="WoodGrainsId" className="commonClassHdn">
        <div className="container-fluid pt-5">
          <div className="text-center">
            <h2>Wood Grains</h2>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <div className="container py-5">
          <div className="col-12 px-5">
            <div className="row">
              {coloritems.map((item, i) => {
                var returData;
                var colorImage = CDNURL + "Gallery/" + item.colorImage;
                var colorTableImage = CDNURL + "Gallery/" + item.colorTableImage;
                returData = (<>
                  {item.isActive == 1 && item.categoryId == 2 ?
                    <div className="col-md-3 my-3 col-sm-6 col-12" key={i}>
                      {item.colorTableImage != null ?
                        <div className="boxshadowdiv">
                          <div className="flip-card" key={i}>
                            <div className="flip-card-inner">
                              <div className="flip-card-front">
                                <img src={colorImage} className="img-fluid w-100" style={{ height: "180px" }} alt="WoodGrains Image"/>
                              </div>
                              <div className="flip-card-back">
                                <img src={colorTableImage} className="img-fluid w-100 h-100" alt="WoodGrains Image"/>
                              </div>
                            </div>
                          </div>
                          <div className="flipcardPara p-2 py-4">
                            <h3>{item.name}</h3>
                            <h6>{item.shortDescription}</h6>
                          </div>
                        </div>
                        :
                        <div className="Colorcard" key={i}>
                          <img src={colorImage} className="img-fluid w-100" style={{ height: "180px" }} alt="WoodGrains Image"/>
                          <div className="LeatherContent p-2 py-4">
                            <h3>{item.name}</h3>
                            <h6>{item.shortDescription}</h6>
                          </div>
                        </div>
                      }
                    </div>
                    :
                    <></>
                  }
                </>
                )
                return returData;
              })}
            </div>
          </div>

        </div>
      </div>
      {/* Wood Grains end */}

      {/* Solid start */}
      <div id="SolidId" className="commonClassHdn">
        <div className="container-fluid pt-5">
          <div className="text-center">
            <h2>Solids</h2>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <div className="container py-5">
          <div className="col-12 px-5">
            <div className="row">
              {coloritems.map((item, i) => {
                var returData;
                var colorImage = CDNURL + "Gallery/" + item.colorImage;
                var colorTableImage = CDNURL + "Gallery/" + item.colorTableImage;
                returData = (<>
                  {item.isActive == 1 && item.categoryId == 3 ?
                    <div className="col-md-3 my-3 col-sm-6 col-12" key={i}>
                      {item.colorTableImage != null ?
                        <div className="boxshadowdiv">
                          <div className="flip-card" key={i}>
                            <div className="flip-card-inner">
                              <div className="flip-card-front">
                                <img src={colorImage} className="img-fluid w-100" style={{ height: "180px" }} alt="Solids Image"/>
                              </div>
                              <div className="flip-card-back">
                                <img src={colorTableImage} className="img-fluid w-100 h-100" alt="Solids Image"/>
                              </div>
                            </div>
                          </div>
                          <div className="flipcardPara p-2 py-4">
                            <h3>{item.name}</h3>
                            <h6>{item.shortDescription}</h6>
                          </div>
                        </div>
                        :
                        <div className="Colorcard" key={i}>
                          <img src={colorImage} className="img-fluid w-100" style={{ height: "180px" }} alt="Solids Image"/>
                          <div className="LeatherContent p-2 py-4">
                            <h3>{item.name}</h3>
                            <h6>{item.shortDescription}</h6>
                          </div>
                        </div>
                      }
                    </div>
                    :
                    <></>
                  }
                </>
                )
                return returData;
              })}
            </div>
          </div>

        </div>
      </div>
      {/* Solid end */}

      {/* Dura-Velvet start */}
      <div id="DuraVelvetId" className="commonClassHdn">
        <div className="container-fluid pt-5">
          <div className="text-center">
            <h2>Dura-Velvet</h2>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <div className="container py-5">
          <div className="col-12 px-5">
            <div className="row">
              {coloritems.map((item, i) => {
                var returData;
                var colorImage = CDNURL + "Gallery/" + item.colorImage;
                var colorTableImage = CDNURL + "Gallery/" + item.colorTableImage;
                returData = (<>
                  {item.isActive == 1 && item.categoryId == 4 ?
                    <div className="col-md-3 my-3 col-sm-6 col-12" key={i}>
                      {item.colorTableImage != null ?
                        <div className="boxshadowdiv">
                          <div className="flip-card" key={i}>
                            <div className="flip-card-inner">
                              <div className="flip-card-front">
                                <img src={colorImage} className="img-fluid w-100" style={{ height: "180px" }} alt="Dura-Velvet Image"/>
                              </div>
                              <div className="flip-card-back">
                                <img src={colorTableImage} className="img-fluid w-100 h-100" alt="Dura-Velvet Image"/>
                              </div>
                            </div>
                          </div>
                          <div className="flipcardPara p-2 py-4">
                            <h3>{item.name}</h3>
                            <h6>{item.shortDescription}</h6>
                          </div>
                        </div>
                        :
                        <div className="Colorcard" key={i}>
                          <img src={colorImage} className="img-fluid w-100" style={{ height: "180px" }} alt="Dura-Velvet Image"/>
                          <div className="LeatherContent p-2 py-4">
                            <h3>{item.name}</h3>
                            <h6>{item.shortDescription}</h6>
                          </div>
                        </div>
                      }
                    </div>
                    :
                    <></>
                  }
                </>
                )
                return returData;
              })}
            </div>
          </div>

        </div>
      </div>
      {/* Dura-Velvet end */}

      <Footer />
    </>
  )
}