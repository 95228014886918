import React from "react";
import { NavigationBar } from "./Navigationbar";
import { Home } from "./Home";
import { Footer } from "./Footer";
import { LiveChat } from "./livechat";
import { useState, useEffect } from "react";
import HelmetMetaTag from "./components/HelmetMetaTag";

export function DashBoard() {
  const [overallstate, setoverallstate] = useState({});
  useEffect(() => {
    window.scroll(0, 0);
    return () => {
      setoverallstate({});
    };
  }, []);
  return (
    <>
      <HelmetMetaTag
        title="Custom High-Quality Table Pads | Berger's Table Pads"
        desc="Berger's Table Pads offers high-quality, custom-made table pads to protect a variety of furniture, including dining tables & desks. Contact us today!"
      />
      <LiveChat />
      <NavigationBar />
      <Home />
      <Footer />
    </>
  )
}