import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import $ from 'jquery';
import { CDNURL } from "../js/constants"
import React, { useState, useEffect } from "react";
import DeliverQuality from "./components/DeliverQuality";
import { ApiGetCall } from "../js/connector";
export function Manufacturing() {
    const [overallstate, setoverallstate] = useState({});
    const [sectionTitle, setsectionTitle] = useState("");
    const [sectionDesciption, setsectionDesciption] = useState("");
    const [sectionCTAtext, setsectionCTAtext] = useState("");
    useEffect(() => {
        $('#yourElementId').text("Our Manufacturing Process at Berger's");
        myFunction();
        return () => {
            setoverallstate({});
        };
    }, []);
    const myFunction = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                var propDetails = responseRs.propertyDetails;
                $("#Overlay").hide();
                $("#LoderId").hide();
                setsectionTitle(propDetails[16].value);
                setsectionDesciption(propDetails[17].value);
                setsectionCTAtext(propDetails[18].value);
            }
        });
    };
    const gotonavbar = () => {
        $('html, body').animate({
            scrollTop: $("#GotoNavbar").offset().top
        }, 100);
    }
    return (
        <>
            <NavigationBar />
            <div className="container-fluid px-0  colorHeroSection">
                <div className="row">
                    <div className="col-md-6 m-auto pt-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
                        <h1 className="availbleColorTxt">{sectionTitle}</h1>
                        <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
                        <button className="ColorBtn mb-2" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
                            <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More"/>
                        </button>
                    </div>
                    <div className="col-md-6 pb-2 text-center">
                        <img src={CDNURL + "Localimages/ManufacturingGroup.png"} className="img-fluid text-end" alt="Hero Image"/>
                    </div>

                </div>
            </div>

            <div className="container py-5">
                <div className="col-12 pt-3 colorPara">
                    <ul>
                        <li className="pt-3 ps-2 liBullet">Solid fiberboard core compared to layered material that is susceptible to moisture entrapment, minimizing odor/mildew potential</li>
                        <li className="pt-3 ps-2 liBullet">Lighter weight for ease of handling</li>
                        <li className="pt-3 ps-2 liBullet">Exclusive precision CNC machine cutting compared to much less accurate band saw cutting</li>
                        <li className="pt-3 ps-2 liBullet">Will not bend or bow when carried or stored upright as layered material will</li>
                    </ul>
                </div>
                <DeliverQuality />

                <div className="col-12 pt-5 px-3">
                    <div className="text-center">
                        <h2>MagnaLoc™ Benefits</h2>
                        <p className="prodhr mt-1" />
                    </div>
                    <ul className="colorPara pt-3">
                        <li className="pt-3 ps-2 liBullet">Minimizes pad separation and sliding</li>
                        <li className="pt-3 ps-2 liBullet">100% user-friendly (nothing to think about - nothing to do - they just work)</li>
                        <li className="pt-3 ps-2 liBullet">Unlike other exposed locking systems, MagnaLoc™ is completely concealed whether the pads are in use or not.</li>
                        <li className="pt-3 ps-2 liBullet">Will not come loose, fall off or break (as exposed locking systems may)</li>
                        <li className="pt-3 ps-2 liBullet">Eliminates potential table damage that can be caused by other exposed metal or plastic locking devices</li>
                        <li className="pt-3 ps-2 liBullet">Can be fully functional up to 100 years</li>
                    </ul>
                </div>
                <p className="pt-3 colorPara">Every one of our <a className="paraLink" href="/products/table-pads">table pads</a> is built to order, directly from our factory.</p>
            </div>
            <Footer />
        </>
    )
}