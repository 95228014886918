import React from "react";
import { NavigationBar } from "../Navigationbar";
import { Footer } from "../Footer";
import { CDNURL } from "../../js/constants";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import { useEffect,useState } from "react";
import $ from 'jquery';
import { ApiGetCall } from "../../js/connector";
export default function CustomTablePads() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  useEffect(() => {
    $('#yourElementId').text('Custom Table Pads');
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[49].value);
        setsectionDesciption(propDetails[50].value);
        setsectionCTAtext(propDetails[51].value);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  return (
    <>
      <NavigationBar />
      <div className="container-fluid px-0  tablepaddiesSection">
        <div className="row">
          <div className="col-md-6 m-auto pt-3 pb-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More"/>
            </button>
          </div>
          <div className="col-md-6 pb-3 text-center">
            <img src={CDNURL + "Localimages/customtablepads.png"} className="img-fluid text-end" alt="Hero Image"/>
          </div>
        </div>
      </div>

      <div className="container py-5 colorPara ps-0">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Custom Table Pads</h1>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <ul>
          <li className="liBullet ps-2 pt-3">
            Protect your furnishings with beautiful, functional custom table
            pads from Berger’s Table Pads. We carry 100% made-in-the-USA quality
            table pads sold at factory direct prices that are guaranteed to be
            the lowest on the Web. Order from us night or day from the comfort
            of your home, by phone with the assistance of a product specialist.
          </li>
        </ul>
      </div>

      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Preserve Your Furniture for Future Generations</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              Whether you have children or pets in your home or want to be sure
              that spills and everyday wear will not compromise the beautiful
              finish of your tables. Protection from{" "}
              <a className="paraLink" href="/products/table-pads">
                custom table pads
              </a>{" "}
              is an inexpensive way to eliminate water rings, dents, chips,
              gouges, and other accidents that can occur.
            </li>
            <li className="pt-4 liBullet ps-2">
              We carry pads for dining tables, coffee and end tables,{" "}
              <a className="paraLink" href="/products/table-extension-pad">
                table extensions
              </a>{" "}
              , conference tables, banquet tables, pool tables, and more.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Easy to Order</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              Request a free table pad measuring kit from Berger’s and receive a
              product brochure, tracing paper, easy instructions on how to
              measure your table, and a postage-paid return envelope for your
              convenience.
            </li>
            <li className="pt-4 liBullet ps-2">
              Our goal is your 100% satisfaction with your purchase. If you have
              additional questions, Live chat is available on our website. Feel
              free to reach out to us through chat or by phone.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Trust Our Reputation</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              We have earned the respect of our loyal customers through offering
              a product that the industry describes as ‘the finest custom table
              pads available today.’ Our strict quality standards and commitment
              to our customers has resulted in a product you can be proud to
              display in your home year after year, with the expectation that
              our table pads will withstand the elements of your home.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Our Guarantee to You</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              If your custom table pads do not fit within a 3/16” clearance,
              we’ll remake your pads at no cost to you whatsoever. We assist
              customers in measuring tables and furnishings and utilize product
              codes to custom-manufacture a product that looks as great as it
              functions.
            </li>
            <li className="pt-4 liBullet ps-2">
              Please take a closer look at some of our website testimonials or
              type our name into your preferred directory and see what customers
              are saying about our pads. Unbiased reviews can be found on sites
              like Yelp, Angie’s List, and Google.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>
              Use Our Custom Table Pads to Give New Life to Old Tables
            </h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              Refresh the look of any table with a beautiful and functional
              protective pad from Berger’s. We offer products in a range of
              colors and finishes to complement your existing decor. If you’re
              thinking about replacing a worn-out looking table, consider one of
              our pads for significant savings.
            </li>
            <li className="pt-4 liBullet ps-2">
              Custom table pads protect from heat, moisture, and impact. You can
              learn more about the benefits of our products by clicking the
              ‘Products’ link on our homepage and select the appropriate pad
              from the menu. Our staff is available by phone and chat to answer
              any questions you may have or to assist with the measuring
              process.
            </li>
          </ul>
        </div>
      </div>
      <WhatCustomerThink isH1Tag="no" />
      <Footer />
    </>
  );
}
