import React from "react";
import { NavigationBar } from "../Navigationbar";
import { Footer } from "../Footer";
import { ApiGetCall } from "../../js/connector";
import { CDNURL } from "../../js/constants";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import { useEffect, useState } from "react";
import $ from 'jquery';
import ImgData from "../components/ImgData";
import HelmetMetaTag from "../components/HelmetMetaTag";

export default function CustomTablePad() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  const [regularprice, setregularprice] = useState("");
  const [discountprice, setdiscountprice] = useState("");
  useEffect(() => {
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[40].value);
        setsectionDesciption(propDetails[41].value);
        setsectionCTAtext(propDetails[42].value);
        GetPrice();
      }
    });
  }
  const GetPrice = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/OrderNow/ProductOrderById/" + 26 + "?path=null").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        console.log(responseRs)
        var regularprice = parseFloat(responseRs.allProducts.regularPrice * 100).toFixed(2);
        var salesprice = parseFloat(responseRs.allProducts.salesPrice * 100).toFixed(2);
        setregularprice(regularprice);
        setdiscountprice(salesprice);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  return (
    <>
      <HelmetMetaTag
        title="Custom-Made Table Pads | Berger's Table Pads"
        desc="Berger's custom table pads are manufactured from high-quality materials to protect your unique table, regardless of size or shape. Contact us today!"
      />
      <NavigationBar />
      <div className="container-fluid px-0  tablepaddiesSection">
        <div className="row">
          <div className="col-md-6 m-auto pt-3 pb-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More" />
            </button>
          </div>
          <div className="col-md-6 pb-3 text-center">
            <img src={CDNURL + "Localimages/customtablepadNew.png"} className="img-fluid text-end" alt="Hero Image" />
          </div>
        </div>
      </div>

      <div className="container py-5 colorPara ps-0">
        <div className="container-fluid">
          <div className="text-center">
            <h2 className="h1">Custom Table Pad</h2>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <ul>
          <li className="liBullet ps-2">
            We usually pay very close attention to what furniture we purchase;
            we are very much concerned about the material quality, about how the
            product looks and feels, and about the overall manufacturing result,
            the construction details. Except when it comes to tables. People
            make the mistake of taking tables for granted, not realizing that
            they are essential additions to the room’s esthetics if nothing
            more. This is one mistake we, at Berger’s Table Pad Company, will
            never make.
          </li>
          <li className="pt-4 liBullet ps-2">
            A custom <a className="paraLink" href="/products/table-pads"> table pads </a>
            is, in reality, a subtle but necessary tool in the arsenal of anyone
            preoccupied not only with how their furniture looks, but how to
            protect its integrity for longer. Ideally, you should preserve your
            furniture in peak condition as much as you can and, sometimes, that
            requires resorting to innovative methods. In this case, getting your
            table pad, custom made to meet your preferences, both regarding
            design and regarding the quality and the resilience.
          </li>
          <li className="pt-4 liBullet ps-2">
            Seeing how we use tables every day, either for serving our meals,
            sharing a drink with a couple of guests or maybe playing some board
            games with the family and friends, it is imperative to do our best
            to protect the wooden surface, especially if we’re talking about a
            high-quality one.
          </li>
        </ul>
      </div>

      <div className="container pb-3 ps-0">
        <div className="">
          <div className="text-center">
            <h2>
              This requires a table pad for several reasons:
            </h2>
            <p className="prodhr mt-1" />
          </div>
          <div className="px-5">
            <ul className="colorPara">
              <li className="pt-4  ps-2">
                <span className="font-weight-bold">
                  It will refresh the room’s style  –{" "}
                </span>
                There are plenty of table pads available for sale and finding the one that meets your Taste is just a matter of time. There is no doubt a lot of satisfaction in ordering a custom table pad to meet your exact expectations, primarily when it is meant to last a lifetime.
              </li>
              <li className="pt-4  ps-2">
                <span className="font-weight-bold">Protecting the table –</span>{" "}
                There are a lot of unpredictable situations that may cause your
                table to sustain sometimes irreparable damages. These may occur
                due to spilling liquids on the surface of the table, subjecting
                the wood to high temperatures, an example being those inflicted
                by excessively hot food plates, or due to carelessly knifing
                products it or having pets to scratch it beyond repair. A table
                pad will easily prevent all these occurrences.
              </li>
              <li className="pt-4  ps-2">
                <span className="font-weight-bold">
                  Comfortable and easy to use –
                </span>{" "}
                Since table pads are detachable, you can easily mount and
                dismount them whenever you like or move them from one table to
                another equally effortless.
              </li>
              <li className="pt-4  ps-2">
                <span className="font-weight-bold">
                  A vast range of products –
                </span>{" "}
                If you are looking for versatility and diversity, we are the
                go-to providers. We have an immense variety of pads to offer,
                including <a className="paraLink" href="/products/table-extension-pad">table extender pads,</a> pads meant for <a className="paraLink subProductOrderNow" href="/store/piano-pads" data-id="17" data-staticpath="pianopads">pianos</a> and <a className="paraLink subProductOrderNow" href="/store/pool-table" data-id="18" data-staticpath="pooltable">pool
                tables,</a> monogrammed <a className="paraLink" href="/products/table-paddies">table paddies</a> and so on.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container colorPara ps-0">
        <ul>
          <li className="pt-4 liBullet ps-2">
            It is not easy to maintain your furniture in perfect condition for
            years to come, that much less when it comes to tables. As explained
            above, many factors may come together to alter their appearance,
            sometimes for good. In this context, a custom table pad can prove to
            be priceless in the long run.
          </li>
          <li className="pt-4 liBullet ps-2">
            At Berger’s Table Pad Company, we only deal with high-quality
            products, meant to satisfy your most pretentious needs. There is no
            other way of prolonging the life of your table, while also
            maintaining it in peak condition.
          </li>
        </ul>
      </div>
      <div className="container pt-3">
        <div className="row">
          <div className="col-lg-6 ">
            <ImgData
              img1="elite-dining-table-pad"
              shadowImg="elite-dining-table-pad-shadow"
              id="tp-img1"
              listData=""
              dataDesign1="true"
              popupImg="elite-dining-table-pad-big2"
              regular={regularprice}
              discount={discountprice}
            />
            <div className="container row my-4">
              <ul className="ps-5">
                <li className=" liBullet ps-2 h5">
                  Includes our exclusive, patented MagnaLocs
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Fine dining / Daily use
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Protects delicate finishes and antiques
                </li>
                <li className=" pt-2 liBullet ps-2 h5">1/2" thick</li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Protects against accidental heat sources up to 550° F
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Choice of any woodgrain, leathertone, or solid color vinyl top
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Choice of Exclusive Breathable Dura-Velvet™ bottom color
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Lifetime limited manufacturer warranty!
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  100% Money Back Guarantee
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 ">
            <ImgData
              img1="economy-table-pad"
              shadowImg="economy-table-pad-shadow"
              id="tp-img2"
              listData=""
              dataDesign11="true"
              popupImg="economy-table-pad-big"
            />
            <div className="container row  my-4">
              <ul className="ps-5 mt-2">
                <li className=" liBullet ps-2 h5">
                  1/2 inch thick vs. 1/4 inch competitor pad
                </li>
                <li className=" pt-2 liBullet ps-2 h5">Occasional use</li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Protects against accidental heat sources up to 550° F
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Bone White top color and Green bottom color
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  5 Year limited manufacturer warranty
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <WhatCustomerThink isH1Tag="no" />
      <Footer />
    </>
  )
}
