import React from "react";
import { useRef, useEffect, useState } from "react";
import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { CDNURL } from "../js/constants";
import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";
import { ApiPostCall } from "../js/connector";
import { ApiGetCall } from "../js/connector";
export function FreeMeasuringKit() {
    const [sectionTitle, setsectionTitle] = useState("");
    const [sectionDesciption, setsectionDesciption] = useState("");
    const [sectionCTAtext, setsectionCTAtext] = useState("");
    const [state, setState] = useState({});
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [dayphone, setDayphone] = useState("");
    const [nightphone, setNightphone] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [comments, setComments] = useState("");
    const sitekeyVal = process.env.REACT_APP_Google_ReCaptcha_SiteKey;
    const [blankCaptcha, setblankCaptcha] = useState("");

    const [blankFname, setBlankFname] = useState("");
    const [blankLname, setBlankLname] = useState("");
    const [blankEmail, setBlankEmail] = useState("");
    const [blankAddress, setBlankAddress] = useState("");
    const [blankCity, setBlankCity] = useState("");
    const [blankZip, setBlankZip] = useState("");
    useEffect(() => {
        window.scroll(0, 0);
        myFunction();
        return () => {
            setState({});
        };
    }, []);
    const myFunction = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                var propDetails = responseRs.propertyDetails;
                $("#Overlay").hide();
                $("#LoderId").hide();
                setsectionTitle(propDetails[64].value);
                setsectionDesciption(propDetails[65].value);
                setsectionCTAtext(propDetails[66].value);
            }
        });
    }
    const something = () => {
        if (fname != "") {
            setBlankFname("");
        }

        if (lname != "") {
            setBlankLname("");
        }

        if (email != "") {
            setBlankEmail("");
        }

        if (address != "") {
            setBlankAddress("");
        }

        if (city != "") {
            setBlankCity("");
        }

        if (zipcode != "") {
            setBlankZip("");
        }
    };
    const SaveContact = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        var temp = 0;
        var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        var humanKey = $("#hdnHumanKey").val();
        {
            if (fname == "" || fname.length >= 100) {
                setBlankFname("Invalid Input");
            } else {
                setBlankFname("");
                temp++;
            }

            if (lname == "" || lname.length >= 100) {
                setBlankLname("Invalid Input");
            } else {
                setBlankLname("");
                temp++;
            }

            if (email == "" || email.length >= 100) {
                setBlankEmail("Invalid Input");
            } else {
                if (!pattern.test(email)) {
                    setBlankEmail("Enter Valid Email");
                } else {
                    setBlankEmail("");
                    temp++;
                }
            }

            if (address == "" || address.length >= 250) {
                setBlankAddress("Invalid Input");
            } else {
                setBlankAddress("");
                temp++;
            }

            if (city == "" || city.length >= 50) {
                setBlankCity("Invalid Input");
            } else {
                setBlankCity("");
                temp++;
            }

            if (zipcode == "") {
                setBlankZip("Invalid Input");
            } else {
                setBlankZip("");
                temp++;
            }

            if (humanKey == "") {
                setblankCaptcha("Before you proceed, please complete the captcha");
            } else {
                setblankCaptcha("");
                temp++;
            }
        }
        if (temp >= 7) {
            var splitAdd = address.split(",");
            var address1 = splitAdd[0];
            var address2 = splitAdd[1];
            var Stateid = $("#StateId option:selected").val();
            var countrycode = "United States";
            var utmSourcesession = sessionStorage.getItem('utm_source');
            if (utmSourcesession == null) {
                utmSourcesession = null;
            }
            var raw = JSON.stringify({
                UTM_Source: utmSourcesession,
                FirstName: fname,
                LastName: lname,
                Email: email,
                DayPhone: dayphone,
                NightPhone: nightphone,
                Address1: address1,
                Address2: address2,
                City: city,
                State: Stateid,
                Zip: zipcode,
                CountryCode: countrycode,
                Attention: comments,
                GoogleCaptcha: humanKey
            });
            await ApiPostCall("/FreeMeasuringKit/SaveFreeKit", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        window.location = "/thank-you";
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        } else {
            $("#Overlay").hide();
            $("#LoderId").hide();
        }
    };
    function onChange(value) {
        const humanKey = value;
        $("#hdnHumanKey").val(humanKey);
    }
    const gotonavbar = () => {
        $('html, body').animate({
            scrollTop: $("#GotoNavbar").offset().top
        }, 100);
    }
    return (
        <>
            <NavigationBar />
            <input type="hidden" id="hdnHumanKey" />
            <div className="container-fluid px-0  extensionSection">
                <div className="row">
                    <div className="col-md-7 m-auto pt-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
                        <h1 className="availbleColorTxt">{sectionTitle}</h1>
                        <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
                        <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
                            <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More"/>
                        </button>
                    </div>
                    <div className="col-md-5 text-center">
                        <img src={CDNURL + "Localimages/FreeKitGroup.png"} className="img-fluid text-end" alt="Hero Image"/>
                    </div>
                </div>
            </div>


            <div className="container py-5 colorPara px-4">
                <div className="pt-3">
                    <ul>
                        <li className="liBullet ps-2">
                            Please fill out the form below to have us mail you one of our measuring kits, a brochure, and a paid return envelope. You will receive:
                            <ul className="pt-3">
                                <li>Elegant brochure</li>
                                <li>Tracing paper and EASY instructions</li>
                                <li>Postage-paid return envelope</li>
                            </ul>
                        </li>
                        <li className="pt-4 liBullet ps-2">
                            Is there anything else you need us to send you? Just ask!
                        </li>
                        <li className="pt-4 liBullet ps-2">
                            (After you order, please give your kit to a friend or family member. The reason why we protect more tables than anyone else is that we get more COMPLIMENTS than anyone else. Thanks!)
                        </li>
                    </ul>
                </div>
            </div>

            <div className="container py-5 px-5">
                <div className="row col-md-10 offset-md-1">
                    <div className="col-md-6 col-12 mb-4">
                        <label for="firstname">First Name
                            <span className="txtRed">*</span>
                        </label>
                        <input name="firstname" type="text" className="form-control formError" value={fname}
                            onKeyDown={(e) => something(e)} onChange={(e) => setFname(e.target.value)} />
                        <span className="txt9d0909">{blankFname}</span>
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                        <label for="lastname">Last Name
                            <span className="txtRed">*</span>
                        </label>
                        <input name="lastname" type="text" className="form-control formError" value={lname}
                            onKeyDown={(e) => something(e)} onChange={(e) => setLname(e.target.value)} />
                        <span className="txt9d0909">{blankLname}</span>
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                        <label for="email">Email Address
                            <span className="txtRed">*</span>
                        </label>
                        <input name="email" type="email" className="form-control formError" value={email}
                            onKeyDown={(e) => something(e)} onChange={(e) => setEmail(e.target.value)} />
                        <span className="txt9d0909">{blankEmail}</span>
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                        <label for="dayphone">Day Phone</label>
                        <input name="dayphone" type="text" className="form-control formError" value={dayphone} onChange={(e) => setDayphone(e.target.value)} />
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                        <label for="eveningphone">Evening Phone</label>
                        <input name="eveningphone" type="text" className="form-control formError" value={nightphone} onChange={(e) => setNightphone(e.target.value)} />
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                        <label for="address">Address
                            <span className="txtRed">*</span>
                        </label>
                        <textarea name="address" rows="1" className="w-100 form-control formError" value={address}
                            onKeyDown={(e) => something(e)} onChange={(e) => setAddress(e.target.value)}></textarea>
                        <span className="txt9d0909">{blankAddress}</span>
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                        <label for="city">City
                            <span className="txtRed">*</span>
                        </label>
                        <input name="city" type="text" className="form-control formError" value={city} onKeyDown={(e) => something(e)}
                            onChange={(e) => setCity(e.target.value)} />
                        <span className="txt9d0909">{blankCity}</span>
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                        <label for="state">State
                            <span className="txtRed">*</span>
                        </label>
                        <select name="state" id="StateId" className="form-select formError">
                            <option value="AL">AL</option>
                            <option value="AK">AK</option>
                            <option value="AZ">AZ</option>
                            <option value="AR">AR</option>
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DE">DE</option>
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="HI">HI</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>
                            <option value="IA">IA</option>
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="ME">ME</option>
                            <option value="MD">MD</option>
                            <option value="MA">MA</option>
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NV">NV</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NY">NY</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VT">VT</option>
                            <option value="VA">VA</option>
                            <option value="WA">WA</option>
                            <option value="WV">WV</option>
                            <option value="WI">WI</option>
                            <option value="WY">WY</option>
                        </select>
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                        <label for="zipcode">Zip Code
                            <span className="txtRed">*</span>
                        </label>
                        <input name="zipcode" type="text" className="form-control formError" value={zipcode}
                            onKeyDown={(e) => something(e)} onChange={(e) => setZipcode(e.target.value)} />
                        <span className="txt9d0909">{blankZip}</span>
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                        <label for="country">Country
                            <span className="txtRed">*</span>
                        </label>
                        <input name="country" type="text" className="form-control" value="United States" disabled
                        />
                    </div>
                    <div className="col-12 mb-4">
                        <label for="comments">Comments</label>
                        <textarea name="comments" rows="3" className="w-100 form-control formError"
                            value={comments} onChange={(e) => setComments(e.target.value)}></textarea>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <ReCAPTCHA sitekey={sitekeyVal} onChange={onChange} /><br />
                    </div>
                    <div className="col-12 mb-4 text-center">
                        <span className="txt9d0909">{blankCaptcha}</span>
                    </div>
                    <div className="col-12 text-center pb-5">
                        <button type="submit" className="loginBtn" onClick={SaveContact}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}