import { useEffect, useState } from "react";
import { NavigationBar } from "../Navigationbar";
import { Footer } from "../Footer";
import { CDNURL } from "../../js/constants";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import $ from "jquery";
import React from "react";
import { ApiGetCall } from "../../js/connector";
import ImgData from "../components/ImgData";
import HelmetMetaTag from "../components/HelmetMetaTag";

export default function TablePad() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  const [regularprice, setregularprice] = useState("");
  const [discountprice, setdiscountprice] = useState("");
  useEffect(() => {
    $('#yourElementId').text('Table Pad');
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[52].value);
        setsectionDesciption(propDetails[53].value);
        setsectionCTAtext(propDetails[54].value);
        GetPrice();
      }
    });
  }
  const GetPrice = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/OrderNow/ProductOrderById/" + 26 + "?path=null").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        console.log(responseRs)
        var regularprice = responseRs.allProducts.regularPrice * 100;
        var salesprice = responseRs.allProducts.salesPrice * 100;
        setregularprice(regularprice);
        setdiscountprice(salesprice);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }

  return (
    <>
      <HelmetMetaTag
        title="Quality Table Pads | Berger's Table Pads"
        desc="Berger's Table Pads manufactures high-quality, custom-made table pads to protect your table from scratches, gouges, moisture, breakage, and burns."
      />
      <NavigationBar />
      <div className="container-fluid px-0  tablepaddiesSection">
        <div className="row">
          <div className="col-md-6 m-auto pt-3 pb-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More" />
            </button>
          </div>
          <div className="col-md-6 pb-3 text-center">
            <img src={CDNURL + "Localimages/TablePadNewGroup.png"} className="img-fluid text-end" alt="Hero Image" />
          </div>
        </div>
      </div>

      <div className="container pb-5 colorPara ps-0">
        <ul>
          <li className="pt-4 liBullet ps-2">
            Buying a quality{" "}
            <a className="paraLink" href="/products/table-pads">
              table pad
            </a>{" "}
            is one of the best investments you could make when it comes to
            protecting your furniture. Table pads also have a second advantage
            that goes beyond preserving expensive furniture for future
            generations. If you own a table with a worn out surface and have
            been thinking about replacing it, a table pad can save you a lot of
            money versus replacement.
          </li>
        </ul>
      </div>

      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>
              Berger’s Table Pads is Committed to Delivering Quality
            </h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              If you’re shopping for a table pad, you have your choice of
              practically thousands of online manufacturers, all trying to gain
              your business. It can be a time-consuming process trying to choose
              from them so many competitors.
            </li>
            <li className="pt-4 liBullet ps-2">
              At Berger’s, we offer a better shopping experience- and
              we guarantee a product that is superior to all others available.
              Rather than using layered material that other table pad
              manufacturers use, we use a solid
              fiberboard core that offers the following benefits:
            </li>
          </ul>
          <div className="px-5">
            <ul className="colorPara ">
              <li className="pt-2  ps-2">
                A solid fiberboard core will not trap moisture like layered
                materials, minimizing potential moisture causing warping and mildew.
              </li>
              <li className="pt-2  ps-2">
                Our pads can be stored upright (unlike others) without bending or bowing.
              </li>
              <li className="pt-2  ps-2">
                Berger’s pads are surprisingly lightweight, for much easier handling.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>
              CNC Machine Cutting Delivers Better Results
            </h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              Unlike outdated band saw cutting still being used by other table
              pad manufacturers, we use an automated CNC machine to cut,
              guarantying a much more accurate fit for your table. At Berger’s, we’ve
              invested in the latest equipment and machinery in order to
              guarantee a product that exceeds your expectations.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Patented MagnaLoc Advantages</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              1.	Our exclusive hidden magnetic locking system holds table pad sections in place without the possibility of  damaging your furniture surfaces or coming loose and falling out  over time. You can expect the MagnaLoc system to last just as long as your table pad- up to 100 years. Ask the competition if their table pad locking systems are designed to perform under everyday conditions for that length of time.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>
              Better Service Means Higher Satisfaction
            </h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              1.	Our policy at Berger’s Table Pads is to assist customers in every way possible to ensure correct measurements. We offer several easy ways to get connected with our representatives.  They can quickly walk you through the process. Live Chat, telephone assistance, and in-home service are all available options. If you prefer, you can request a free measuring kit from us that will arrive with easy instructions on how to measure your table..
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>See What Others Are Saying</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              You can read actual customer testimonials on the Berger’s website
              to see how we are doing when it comes to meeting the needs of our
              customers. You can write or read a review for Berger’s on Yelp,
              the BBB, Facebook, Google, YP, or on our website.
            </li>
            <li className="pt-4 liBullet ps-2">
              We believe offering better service results in a higher rate of
              customer satisfaction- and we are committed to exceeding your
              expectations each time you do business with us, whether it’s
              simply talking on the phone with a representative or ordering a
              table pad to protect your furniture.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pt-3">
        <div className="row">
          <div className="col-lg-6 ">
            <ImgData
              img1="elite-dining-table-pad"
              shadowImg="elite-dining-table-pad-shadow"
              id="tp-img1"
              listData=""
              dataDesign1="true"
              popupImg="elite-dining-table-pad-big2"
              regular={regularprice}
              discount={discountprice}
            />
            <div className="container row my-4">
              <ul className="ps-5">
                <li className=" liBullet ps-2 h5">
                  Includes our exclusive, patented MagnaLocs
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Fine dining / Daily use
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Protects delicate finishes and antiques
                </li>
                <li className=" pt-2 liBullet ps-2 h5">1/2" thick</li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Protects against accidental heat sources up to 550° F
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Choice of any woodgrain, leathertone, or solid color vinyl top
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Choice of Exclusive Breathable Dura-Velvet™ bottom color
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Lifetime limited manufacturer warranty!
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  100% Money Back Guarantee
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 ">
            <ImgData
              img1="economy-table-pad"
              shadowImg="economy-table-pad-shadow"
              id="tp-img2"
              listData=""
              dataDesign11="true"
              popupImg="economy-table-pad-big"
            />
            <div className="container row  my-4">
              <ul className="ps-5 mt-2">
                <li className=" liBullet ps-2 h5">
                  1/2 inch thick vs. 1/4 inch competitor pad
                </li>
                <li className=" pt-2 liBullet ps-2 h5">Occasional use</li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Protects against accidental heat sources up to 550° F
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Bone White top color and Green bottom color
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  5 Year limited manufacturer warranty
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <WhatCustomerThink isH1Tag="no" />
      <Footer />
    </>
  );
}
