import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

class ModalPopup extends Component {
  render() {
    var YesBtn;
    var NoBtn;
    var uploadbtn;
    if (this.props.dataShowhide == 1) {
      YesBtn = (
        <button type="submit" className="loginBtn" onClick={() => this.props.onClick()}>
          Yes
        </button>
      );
      NoBtn = (
        <span className="me-3 crsr-Pointer" onClick={() => this.props.onHide({ msg: "Cross Icon Clicked!" })}>No</span>
      );
    }
    if (this.props.dataShowhide == 3) {
      uploadbtn = (
        <button type="submit" className="loginBtn" onClick={() => this.props.onClick()} id="uploadBtn">
          Upload
        </button>
      );
      NoBtn = (
        <span className="me-3 crsr-Pointer" onClick={() => this.props.onHide({ msg: "Cross Icon Clicked!" })}>Cancel</span>
      );
    }

    return (
      <div>
        <Modal
          show={this.props.show}
          className={this.props.class}
          onHide={() => this.props.onHide({ msg: "Cross Icon Clicked!" })}
        >
          <Modal.Header
            closeButton
            className={`modalHead ${this.props.ImgCloseBtn}`}
          >
            <Modal.Title>
              <h5>{this.props.data}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.modalBody}</Modal.Body>
          {this.props.dataShowhide == 1 ? (
            <>
              <Modal.Footer>
                {NoBtn}
                {YesBtn}
              </Modal.Footer>
            </>
          ) : (
            ""
          )}
          {this.props.dataShowhide == 3 ? (
            <>
              <Modal.Footer>
                {NoBtn}
                {uploadbtn}
              </Modal.Footer>
            </>
          ) : (
            ""
          )}
        </Modal>
      </div>
    );
  }
}
export default ModalPopup;
