import "./App.css";
import "./css/common.css";
import "./css/products.css";
import "./web.config";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DashBoard } from "./Files/dashboard";
import { Loginpage } from "./Files/login";
import { AdminPage } from "./Files/HomeBanner";
import { Logout } from "./Files/logoutComponent";
import { Colors } from "./Files/colors";
import { NavigationMenu } from "./Files/NavigationMenu";
import { ProductCategory } from "./Files/ProdCategory";
import { Tablecloth } from "./Files/tablecloth";
import { MeasuringTables } from "./Files/MeasuringTables";
import { PatentedLocks } from "./Files/PatentedLocks";
import { Manufacturing } from "./Files/ourManufacturing";
import { Aboutus } from "./Files/aboutus";
import Contact from "./Files/Contact";
import { Dealerinquiries } from "./Files/DealerInquiries";
import { Errorfunc } from "./Files/404";
import { Customerpage } from "./Files/customerdetails";
import { Thankyou } from "./Files/thankyou";
import TablePads from "./Files/Products/TablePads";
import TablePaddies from "./Files/Products/TablePaddies";
import TableExtensionPad from "./Files/Products/TableExtensionPad";
import OtherFurniturePads from "./Files/Products/OtherFurniturePads";
import StorageBags from "./Files/Products/StorageBags";
import DeskPads from "./Files/Products/DeskPads";
import CustomTablePad from "./Files/Products/CustomTablePad";
import CustomTablePads from "./Files/Products/CustomTablePads";
import TablesPad from "./Files/Products/TablesPad";
import TablesPads from "./Files/Products/TablesPads";
import DiningRoomTablePad from "./Files/Products/DiningRoomTablePad";
import DiningTablePads from "./Files/Products/DiningTablePads";
import PadsforTables from "./Files/Products/PadsforTables";
import TablePad from "./Files/Products/TablePad";
import BestTablePad from "./Files/Products/BestTablePad";
import OrderNow from "./Files/OrderNow";
import CustomizedMonograms from "./Files/Products/CustomizedMonograms";
import OrderProperties from "./Files/Orderproperties";
import UploadImages from "./Files/UploadImages";
import Cartpage from "./Files/CartPage";
import Checkout from "./Files/checkout";
import Confirmorder from "./Files/ConfirmOrder";
import ZoneTax from "./Files/ZoneTax";
import Testimonials from "./Files/testimonials";
import CustomerReviews from "./Files/customerReviews";
import TestimonialsReviews from "./Files/testimonialReviews";
import PrivacyPolicy from "./Files/PrivacyPolicy";
import SiteMap from "./Files/SiteMap";
import { FreeMeasuringKit } from "./Files/FreeMeasuringKit";
import UltraEliteDining from "./Files/UltraEliteDiningTablePad";
import Customization from "./Files/Customization";
import { CustomizeFaq } from "./Files/CustomizeFaq";
import { AddEditFaq } from "./Files/AddEditFaq";
import { TableClothAdmin } from "./Files/TableClothAdmin";
import { ColorAdmin } from "./Files/ColorAdmin";
import { CouponCode } from "./Files/CouponCode";
import { DealersLogin } from "./Files/DealersLogin";
import { PreviousOrders } from "./Files/PreviousOrders";
import { DealerLogout } from "./Files/components/DealerLogout";
import GoodEconomyTablePads from "./Files/GoodEconomyTablePads";
import StandardEconomyTablePads from "./Files/StandardEconomyTablePads";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };
  }
  componentDidMount() {
    const loggedInUser = window.localStorage.getItem("DealersUserCode");
    if (loggedInUser) {
      this.setState({ isLoggedIn: true });
    } else {
      this.setState({ isLoggedIn: false });
    }
  }
  loginUser = (isUserLoggedIn) => {
    this.setState({ isLoggedIn: isUserLoggedIn });
  };

  render() {
    window.scroll(0, 0);
    return (
      <>
        <Router>
          <Routes>
            {/* {this.state.isLoggedIn && (
              <> */}
            <Route path="/HomeBanner/*" element={<AdminPage />} />
            <Route path="/NavigationMenu" element={<NavigationMenu />} />
            <Route path="/ProductCategory/*" element={<ProductCategory />} />
            <Route path="/customers" element={<Customerpage />} />
            <Route path="/Orderproperties/*" element={<OrderProperties />} />
            <Route path="/UploadImages" element={<UploadImages />} />
            <Route path="/Zonetax/*" element={<ZoneTax />} />
            <Route path="/Customerreview" element={<CustomerReviews />} />
            <Route path="/Customization/*" element={<Customization />} />
            <Route path="/CustomizeFaq" element={<CustomizeFaq />} />
            <Route path="/FaqAddEdit/*" element={<AddEditFaq />} />
            <Route path="/TableClothesAdmin/*" element={<TableClothAdmin />} />
            <Route path="/ColorsAdmin/*" element={<ColorAdmin />} />
            <Route path="/CouponCodes/*" element={<CouponCode />} />
            {/* </>
            )} */}
            <Route path="/" element={<DealersLogin />} />
            {this.state.isLoggedIn ? (
              <>
                <Route path="/" element={<DealersLogin />} />
                <Route path="/home" element={<DashBoard />} />
                <Route path="/ryan-tp-panel" element={<Loginpage />} />
                <Route path="/colors" element={<Colors />} />
                <Route path="/table-cloth" element={<Tablecloth />} />
                <Route path="/how-to-measure-for-a-table-pad" element={<MeasuringTables />} />
                <Route path="/magnetic-locking-system" element={<PatentedLocks />} />
                <Route path="/our-manufacturing-process" element={<Manufacturing />} />
                <Route path="/about-us" element={<Aboutus />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/become-a-dealer" element={<Dealerinquiries />} />
                <Route path="/thank-you" element={<Thankyou />} />
                <Route path="*" element={<Errorfunc />} />
                <Route path="/products/table-pads" element={<TablePads />} />
                <Route path="/products/table-paddies" element={<TablePaddies />} />
                <Route path="/products/table-extension-pad" element={<TableExtensionPad />} />
                <Route path="/products/other-furniture-pads" element={<OtherFurniturePads />} />
                <Route path="/products/storage-bags" element={<StorageBags />} />
                <Route path="/products/desk-pads" element={<DeskPads />} />
                <Route path="/products/custom-table-pad" element={<CustomTablePad />} />
                <Route path="/products/custom-table-pads" element={<CustomTablePads />} />
                <Route path="/products/tables-pad" element={<TablesPad />} />
                <Route path="/products/tables-pads" element={<TablesPads />} />
                <Route path="/products/dining-room-table-pad" element={<DiningRoomTablePad />} />
                <Route path="/products/dining-table-pads" element={<DiningTablePads />} />
                <Route path="/products/pads-for-tables" element={<PadsforTables />} />
                <Route path="/products/table-pad" element={<TablePad />} />
                {/* <Route path="/products/best-table-pad" element={<BestTablePad />} /> */}
                <Route path="/customized-monograms" element={<CustomizedMonograms />} />
                <Route path="/store/*" element={<OrderNow />} />
                <Route path="/cart" element={<Cartpage />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/confirm-order" element={<Confirmorder />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/testimonial" element={<TestimonialsReviews />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/sitemap" element={<SiteMap />} />
                <Route path="/free-kit-request" element={<FreeMeasuringKit />} />
                <Route path="/products/ultra-elite-dining-table-pad/*" element={<UltraEliteDining />} />
                <Route path="/previous-orders" element={<PreviousOrders />} />
                <Route path="/dealers-logout" element={<DealerLogout />} />
                <Route path="/products/economy-table-pad/*" element={<GoodEconomyTablePads />} />
                <Route path="/products/standard-economy-table-pad/*" element={<StandardEconomyTablePads />} />
              </>
            )
              :
              <Route path="*" element={<DealersLogin />} />
            }
          </Routes>
        </Router>
      </>
    );
  }
}

export default App;
