import { useState, useEffect } from 'react';
import Sortable from "sortablejs";
import $ from 'jquery';
import React from "react";
import { LeftMenu } from './LeftMenuAdmin';
import { ApiGetCall, ApiPostCall } from '../js/connector';
import { ApiPutCall } from '../js/connector';
import { CDNURL } from '../js/constants';
import { VerifyLoginToken } from '../js/connector';
export function NavigationMenu() {
    const [checkvalue, setCheckvalue] = useState(true);
    const [menuitems, setMenuItems] = useState([]);
    const [submenuitems, setSubMenuItems] = useState([]);
    const [droppableRef, seteRef] = useState("");
    const [overallstate, setoverallstate] = useState({});
    const [documents, setdocuments] = useState("");
    const [TempCheck, setTempCheck] = useState(false);
    useEffect(() => {
        window.scroll(0, 0);
        VerifyLoginToken(GetNavigationMenu);
        return () => {
            setoverallstate({});
        };
    }, []);
    const GetNavigationMenu = async () => {
        setTempCheck(true);
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/NavigationMenu/GetMenuItems").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.menuItems;
                var submenu = responseRs.subMenuItems;
                if (responseRs.menuItems != "") {
                    setMenuItems(sugData);
                }
                if (responseRs.subMenuItems != "") {
                    setSubMenuItems(submenu);
                }
            }
        });
    }
    var sortable;
    var tempVar = 1;
    const EnableDragDrop = () => {
        tempVar = 1;
        if (droppableRef) {
            sortable = Sortable.create(droppableRef, {
                easing: "cubic-bezier(0.2, 0, 0, 1)",
                animation: 150,
                className: "sortable-list",
                ghostClass: "dragging",
                scrollFn: function () {
                    return false;
                },
                scroll: true,
                disabled: false,
                scrollSensitivity: 100,
                bubbleScroll: true,
                onEnd: function () {
                    $(".draggableGrid").each(function () {
                        var thisid = $(this).attr('id');
                        $("#" + thisid).attr('rowcount', tempVar);
                        tempVar++;
                    });
                },
            });

            $(".dragdropdotsimg").removeClass('d-none');
            $(".draggableGrid").css('cursor', 'grabbing');
            $("#hdnCancelLink").removeClass('d-none');
            $("#SaveOrderHdnBtn").removeClass('d-none');
            $("#changeOrderHdnBtn").addClass('d-none');
        }
    }
    const CancelDragDrop = () => {
        sortable.option("disabled", true);
        $(".dragdropdotsimg").addClass('d-none');
        $(".draggableGrid").css('cursor', 'pointer');
        $("#hdnCancelLink").addClass('d-none');
        $("#changeOrderHdnBtn").removeClass('d-none');
        $("#SaveOrderHdnBtn").addClass('d-none');
    }
    const SaveOrderOfMenu = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        var GridArray = [];
        $(".draggableGrid").each(function () {
            var rowcnt = parseInt($(this).attr('rowcount'));
            var thisid = $(this).attr('id');
            var vsplit = thisid.split('_');
            var finalVal = parseInt(vsplit[1]);
            var NewJson = { 'rowid': finalVal, 'roworder': rowcnt };
            GridArray.push(NewJson);
        });
        var raw = JSON.stringify({
            "SliderOrder": GridArray
        });
        await ApiPostCall("/NavigationMenu/MenuOrder", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "Success") {
                    $(".alert-success").show();
                    $("#AlertMsg").text('Order Change Succesfully.');
                    setTimeout(function () {
                        $(".alert-success").hide();
                        $("#AlertMsg").text('');
                    }, 1500);
                    sortable.option("disabled", true);
                    $(".dragdropdotsimg").addClass('d-none');
                    $(".draggableGrid").css('cursor', 'pointer');
                    $("#hdnCancelLink").addClass('d-none');
                    $("#changeOrderHdnBtn").removeClass('d-none');
                    $("#SaveOrderHdnBtn").addClass('d-none');
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const ChangeStatus = async (e) => {
        var vId = parseInt(e.currentTarget.attributes[3].value);
        var vStatus;
        if ($("#VisibleId_" + vId).is(":checked")) {
            $("#VisibleId_" + vId).prop('checked', true);
            vStatus = 1;
        } else {
            $("#VisibleId_" + vId).removeAttr('checked');
            vStatus = 0;
        }
        $("#Overlay").show();
        $("#LoderId").show();
        var raw = JSON.stringify({
            "Status": vStatus
        });
        await ApiPutCall("/NavigationMenu/VisibleMenu/" + vId, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "Success") {
                    $(".alert-success").show();
                    $("#AlertMsg").text('Updated Successfully.');
                    setTimeout(function () {
                        $(".alert-success").hide();
                        $("#AlertMsg").text('');
                    }, 1500);
                    if (responseRs.menuData.status == 1) {
                        setCheckvalue(true);
                    } else {
                        setCheckvalue(false);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const editMenuItem = (itemId, itemname) => {
        var InputHtml = "<input type='text' class='form-control' id='navName_" + itemId + "' value='" + itemname + "' />";
        $("#ItemName_" + itemId).addClass('d-none');
        $("#HtmlId_" + itemId).append(InputHtml);
        var itemNavID = "navName_" + itemId;
        $('.' + itemNavID + '.saveIcon').removeClass('d-none');
        $("." + itemNavID + '.cancelIcon').removeClass('d-none');
        $('.' + itemNavID + '.editIcon').addClass('d-none');
        $("#" + itemNavID).focus();
    }
    const saveMenuName = async (itemIdOrigin) => {
        $("#Overlay").show();
        $("#LoderId").show();
        var itemID = "navName_" + itemIdOrigin;
        var namMenuName = $("#" + itemID).val();
        var raw = JSON.stringify({
            "id": itemIdOrigin,
            "name": namMenuName
        });
        await ApiPostCall("/NavigationMenu/EditMenuItem", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "Success") {
                    $(".alert-success").show();
                    $("#AlertMsg").text('Navigation Menu Updated Succesfully.');
                    setTimeout(function () {
                        $(".alert-success").hide();
                        $("#AlertMsg").text('');
                        window.location = "/NavigationMenu";
                    }, 1500);
                    // $("#ItemName_" + itemIdOrigin).removeClass('d-none');
                    // $("#HtmlId_" + itemIdOrigin).html("");
                    // $('.' + itemID + '.saveIcon').addClass('d-none');
                    // $('.' + itemID + '.editIcon').removeClass('d-none');
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const CloseMenuName = (itemIdOrigin) => {
        var itemID = "navName_" + itemIdOrigin;
        $("#ItemName_" + itemIdOrigin).removeClass('d-none');
        $("#HtmlId_" + itemIdOrigin).html("");
        $('.' + itemID + '.saveIcon').addClass('d-none');
        $("." + itemID + '.cancelIcon').addClass('d-none');
        $('.' + itemID + '.editIcon').removeClass('d-none');
    }
    return (
        <>
            {TempCheck == true ?
                <>
                    <LeftMenu />
                    <div fixed="true" className='container-fluid mainContainer'>
                        <div className='content-wrapper' id="GridDivShow">
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='cardDiv'>
                                            <div className='cardDiv-header'>
                                                <div className='row AlignItems'>
                                                    <div className='col-md-4'>
                                                        <h4>Navigation Menu</h4>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <div className="card-action me-2 d-none" id="SaveOrderHdnBtn">
                                                            <button type="submit" className="loginBtn btnhover" onClick={SaveOrderOfMenu}>Save</button>
                                                        </div>
                                                        <div className="card-action " id="changeOrderHdnBtn">
                                                            <button type="submit" className="loginBtn btnhover" onClick={EnableDragDrop}>Change Order</button>
                                                        </div>
                                                        <div className="card-action me-2 mt-1 d-none" id="hdnCancelLink">
                                                            <label className="crsr-Pointer" onClick={CancelDragDrop}>Cancel</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-body pl-3 pr-3'>
                                                <div className='col-12 gridHeader'>
                                                    <div className='row'>
                                                        <div className='col-1'>
                                                        </div>
                                                        <div className='col-4'>
                                                            <b>Name</b>
                                                        </div>
                                                        <div className='col-3 pe-0'>
                                                            <b>URL</b>
                                                        </div>
                                                        <div className='col-2 text-center'>
                                                            <b>Is Visible?</b>
                                                        </div>
                                                        <div className='col-2 text-center'>
                                                            <b>Action</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='sortable-list scrolly-63' ref={(el) => seteRef(el)} id="sortableDivId">
                                                    {menuitems.map((item, i) => {
                                                        var returData;
                                                        returData = <div key={i} className="col-12 grid draggableGrid" rowcount={i + 1} id={`MyClass_${item.id}`}>
                                                            <div className='row'>
                                                                <div className='col-1'>
                                                                    <img src="./images/dragdrop.png" alt='Drag Icon' title="Drag to reorder" className='w-25 d-none dragdropdotsimg' />
                                                                </div>
                                                                <div className='col-4 pb-2'>
                                                                    <span id={`ItemName_${item.id}`}>{item.name}</span>
                                                                    <div id={`HtmlId_${item.id}`} dangerouslySetInnerHTML={{ __html: documents }}>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3 pe-0'>
                                                                    {item.menuUrl}
                                                                </div>
                                                                <div className='col-2 text-center d-flex justify-content-center align-items-center'>
                                                                    {(item.status == 1) ?
                                                                        <input className="form-check-input me-2" defaultChecked={checkvalue} onClick={ChangeStatus} id={`VisibleId_${item.id}`} type="checkbox" value="" userid={item.id} />
                                                                        :
                                                                        <input className="form-check-input me-2" id={`VisibleId_${item.id}`} onClick={ChangeStatus} type="checkbox" value="" userid={item.id} />
                                                                    }

                                                                </div>
                                                                <div className='col-2 text-center ps-0'>
                                                                    <img src="/images/editIcon.svg" alt='Edit' title="Edit" onClick={(e) => editMenuItem(item.id, item.name)} className={`navName_${item.id} iconClass editIcon me-1 crsr-pointer `} />
                                                                    <img src="/images/saveIcon.png" alt='Save' title="Save" onClick={(e) => saveMenuName(item.id)} className={`navName_${item.id} saveIcon iconClass d-none me-1 crsr-pointer `} />
                                                                    <img src={CDNURL + "LocalImages/closeicon.png"} alt='Cancel' title="Cancel" className={`navName_${item.id} img-fluid w-10 d-none cancelIcon crsr-pointer `} onClick={(e) => CloseMenuName(item.id)} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        return returData;
                                                    }
                                                    )}
                                                    <h2 className='ps-5 pt-4 pb-2 newBrdrBtm'>Sub products</h2>
                                                    {submenuitems.map((item, i) => {
                                                        var returData;
                                                        returData = <div className="col-12 grid draggableGrid" key={i} rowcount={i + 1} id={`MyClass_${item.id}`}>
                                                            <div className='row'>
                                                                <div className='col-1'>
                                                                    <img src="./images/dragdrop.png" alt='Drag' title="Drag to reorder" className='w-25 d-none dragdropdotsimg' />
                                                                </div>
                                                                <div className='col-4 pb-2'>
                                                                    <span id={`ItemName_${item.id}`}>{item.name}</span>
                                                                    <div id={`HtmlId_${item.id}`} dangerouslySetInnerHTML={{ __html: documents }}>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3 pe-0'>
                                                                    {item.menuUrl}
                                                                </div>
                                                                <div className='col-2 text-center d-flex justify-content-center align-items-center'>
                                                                    {(item.status == 1) ?
                                                                        <input className="form-check-input me-2" defaultChecked={checkvalue} onClick={ChangeStatus} id={`VisibleId_${item.id}`} type="checkbox" value="" userid={item.id} />
                                                                        :
                                                                        <input className="form-check-input me-2" id={`VisibleId_${item.id}`} onClick={ChangeStatus} type="checkbox" value="" userid={item.id} />
                                                                    }

                                                                </div>
                                                                <div className='col-2 text-center ps-0'>
                                                                    <img src="/images/editIcon.svg" alt='Edit' title="Edit" onClick={(e) => editMenuItem(item.id, item.name)} className={`navName_${item.id} iconClass editIcon me-1 crsr-pointer `} />
                                                                    <img src="/images/saveIcon.png" alt='Save' title="Save" onClick={(e) => saveMenuName(item.id)} className={`navName_${item.id} saveIcon iconClass d-none me-1 crsr-pointer `} />
                                                                    <img src={CDNURL + "LocalImages/closeicon.png"} alt='Cancel' title="Cancel" className={`navName_${item.id} img-fluid w-10 d-none cancelIcon crsr-pointer `} onClick={(e) => CloseMenuName(item.id)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        return returData;
                                                    }
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <></>
            }
        </>
    )
}