import React from "react";
import { NavigationBar } from "../Navigationbar";
import { Footer } from "../Footer";
import ImgData from "../components/ImgData";
import { CDNURL } from "../../js/constants";
import { ApiGetCall } from "../../js/connector";
import { useEffect, useState } from "react";
import $ from "jquery";
export default function CustomizedMonograms() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  useEffect(() => {
    $('#yourElementId').text('Customized Monograms for Table Pads');
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[61].value);
        setsectionDesciption(propDetails[62].value);
        setsectionCTAtext(propDetails[63].value);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  return (
    <div>
      <NavigationBar />
      <div className="container-fluid px-0  extensionSection">
        <div className="row">
          <div className="col-md-7 m-auto pt-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More"/>
            </button>
          </div>
          <div className="col-md-5 text-center">
            <img src={CDNURL + "Localimages/MonoGramsGroup.png"} className="img-fluid text-end" alt="Hero Image"/>
          </div>
        </div>
      </div>

      <div className="container py-5 colorPara ps-0">
        <ul>
          <li className="liBullet ps-2">
            We offer the world's first monogrammed{" "}
            <a className="paraLink" href="/products/table-pads">
              table pads
            </a>
            . Each 1/2 pad will have your letter slightly recessed, diagonal to
            each other, facing opposite directions, and placed out of reach of
            normal table settings. Each table pad comes with one letter as shown
            in the image below, unless otherwise requested. Buffet pads come
            with one letter as shown below, unless otherwise requested. Many of{" "}
            <a className="paraLink" href="/products/other-furniture-pads">
              our other furniture
            </a>{" "}
            pads have monograms as well. Our{" "}
            <a className="paraLink" href="/products/table-paddies">
              Table Paddies
            </a>{" "}
            have the monogram either in the center (oval shape) or top left
            corner (rectangular shape). If you have any questions about our
            monograms, please do not hesitate to{" "}
            <a className="paraLink" href="/contact">
              contact us
            </a>{" "}
            today.
          </li>
        </ul>
      </div>

      <div className="container py-5 px-5">
        <div className="row">
          <div className="col-lg-6 col-12">
            <ImgData
              img1="CustomizedMonogramsImg1"
              shadowImg="CustomizedMonogramsImg1-shadow"
              id="mg-img1"
              // mt=""
              pb="none"
              listData=""
              data1="Conference Table Pad"
              dataDesign3=""
              popupImg="CustomizedMonogramsImg1-big"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-6 col-12">
            <ImgData
              img1="CustomizedMonogramsImg2"
              shadowImg="CustomizedMonogramsImg2-shadow"
              id="mg-img2"
              // mt=""
              pb="none"
              listData=""
              data1="Conference Table Pad"
              dataDesign3=""
              popupImg="CustomizedMonogramsImg2-big"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="container colorPara">
        <ul>
          <li className="pt-4 liBullet ps-2">
            Our monograms come in a Classic style only, as shown below.
          </li>
        </ul>
        <div className="col-lg-6 col-12 text-center">
          <img src={CDNURL + "Localimages/products_Cate/monogram-classic.jpg"} className="img-fluid" alt="Monogram Classic"/>
        </div>
      </div>
      <div className="container colorPara pb-5">
        <ul>
          <li className="pt-4 liBullet ps-2">
            Our monogrammed table pads are perfect for gifts!
          </li>
        </ul>
        <div className="px-5">
          <ul className="">
            <li>Weddings</li>
            <li>Christmas and Hanukkah</li>
            <li>Birthdays</li>
            <li>Mother's and Father's Day</li>
            <li>Anniversaries</li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}
