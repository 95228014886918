import React from "react";
import { NavigationBar } from "../Navigationbar";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import { Footer } from "../Footer";
import { ApiGetCall } from "../../js/connector";
import ImgData from "../components/ImgData";
import $ from "jquery";
import { useEffect, useState } from "react";
import { CDNURL } from "../../js/constants";
import HelmetMetaTag from "../components/HelmetMetaTag";

export default function TableExtensionPad() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  useEffect(() => {
    $('#yourElementId').text('Table Extension Pads and Table Extenders');
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[46].value);
        setsectionDesciption(propDetails[47].value);
        setsectionCTAtext(propDetails[48].value);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  return (
    <>
      <HelmetMetaTag
        title="Table Extension Pads & Table Extenders | Berger's"
        desc="Berger's Table Pads manufactures table extenders made of the highest-quality materials. These table extensions can add up to 100% more seating area."
      />
      <NavigationBar />
      <div className="container-fluid px-0  extensionSection">
        <div className="row">
          <div className="col-md-6 m-auto pt-3 text-center" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More" />
            </button>
          </div>
          <div className="col-md-6 text-center">
            <img src={CDNURL + "Localimages/TableExtensionGroup.png"} className="img-fluid text-end" alt="Hero Image" />
          </div>
        </div>
      </div>
      <div className="container py-5 colorPara ps-0" id="GotoNavbar">
        <ul>
          <li className="liBullet ps-2">
            Not enough room on your table during large family meals?  You need our table extender pads! These pads can add up to 100% more seating as well as additional leg room for everyone at the table.
          </li>
          <li className="pt-4 liBullet ps-2">
            Table extender pads are very sturdy and provide additional weight capacity. They do this without the use of  unsightly and flimsy legs (which are traditionally associated with table pad extenders.) Because we do not use legs, additional seating capacity is not compromised and everyone around the table will be more comfortable.
          </li>
          <li className="pt-4 liBullet ps-2">
            There are a number of ways to ensure you're going to get the proper Table Pad Extender for your specific table. Follow this link to <a className="paraLink" href="/how-to-measure-for-a-table-pad">learn how to measure your table</a>. You can also <a className="paraLink" href="/contact">contact us</a> to set up a consultation or order one of our <a className="paraLink" href="/free-kit-request">free measuring kits</a>.  View our full list of available <a className="paraLink" href="/colors">top and bottom colors</a>.
          </li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6">
            <ImgData
              img1="TableExtensionPadImg1"
              shadowImg="TableExtensionPad-ShadowImg1"
              id="texp-img1"
              mt=""
              className="img-fluid"
              listData=""
              dataDesign4="true"
              popupImg="TableExtensionPadImg1-big"
            />
          </div>
          <div className="col-lg-6">
            <ImgData
              img1="TableExtensionPadImg2"
              shadowImg="TableExtensionPad-ShadowImg2"
              id="texp-img2"
              mt=""
              className="img-fluid"
              listData=""
              dataDesign4="true"
              popupImg="TableExtensionPadImg2-big"
            />
          </div>
          <div className="col-lg-6 mt-5">
            <ImgData
              img1="NewTableExtensionPadImg3"
              shadowImg="NewTableExtensionPadImg3-ShadowImg3"
              id="texp-img3"
              mt="mt-5"
              className="img-fluid"
              listData=""
              dataDesign4="true"
              popupImg="NewTableExtensionPadImg3"
            />
          </div>
        </div>
      </div>

      {/* what our clients think start*/}
      <WhatCustomerThink isH1Tag="no" />
      {/* what our clients think end*/}
      <Footer />
    </>
  );
}
