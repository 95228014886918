import { LeftMenu } from "./LeftMenuAdmin";
import $ from 'jquery';
import React from "react";
import { useState, useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Example from "./ckeditor";
import { ApiGetCall } from "../js/connector";
import { ApiPostCall } from "../js/connector";
import { ApiPutCall } from "../js/connector";
import { CDNURL } from "../js/constants";
import { VerifyLoginToken } from "../js/connector";
const OrderProperties = () => {
    const [checkvalue, setCheckvalue] = useState(true);
    const [neweditorState, setneweditorState] = useState("");
    let idcount2 = [];
    let radiocount1 = [];
    const [tempdropdown, settempdropdown] = useState([]);
    const [defaultVal, setdefaultVal] = useState("");
    const [overallstate, setoverallstate] = useState({});
    const [idcount, setidcount] = useState([1]);
    const [radiocount, setradiocount] = useState([1, 2]);
    const [errortype, setErrorType] = useState("");
    const [nameError, setnameError] = useState("");
    const [NumberMinVal, setNumberMinVal] = useState("");
    const [NumberMaxVal, setNumberMaxVal] = useState("");
    const [displayName, setdisplayName] = useState("");
    const [typeoption, Settypeoption] = useState([]);
    const [blankrowdlt, setblankrowdlt] = useState("");
    const [blankrowradio, setblankrowradio] = useState("");
    const [blankrownumber, setblankrownumber] = useState("");
    const [optionDetailsVar, setoptionDetailsVar] = useState([]);
    const [norecord, setNorecord] = useState("");
    const [Minrowid, setMinrowid] = useState("");
    const [Maxrowid, setMaxrowid] = useState("");
    const [TempCheck, setTempCheck] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);
        VerifyLoginToken(showhidedivfunc);
        return () => {
            setoverallstate({});
        };
    }, []);
    const showhidedivfunc = () => {
        setTempCheck(true);
        var PathName = window.location.pathname;
        var pathsplit = PathName.split('/');
        var prodActive = pathsplit[2];
        if (prodActive == "AddProperty") {
            showadddiv();
        } else if (prodActive == "UpdateProperty") {
            showupdatediv();
        }
        else {
            getOptionDetails();
        }
    }
    const getOptionDetails = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Properties/GetOptionValues").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.optionDetails;
                var sugArray = [];
                var i = 1;
                if (responseRs.status == "success") {
                    if (responseRs.optionDetails != "") {
                        setNorecord("");
                        setoptionDetailsVar(sugData);
                    } else {
                        sugArray.push(
                            <div className="col-12 gridHeader text-center" key={i}>
                                <label>No Record Found</label>
                            </div>
                        );
                        setNorecord(sugArray);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const GetOptionType = async () => {
        await ApiGetCall("/Properties/GetOptionTypes").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var sugData = responseRs.optionTypes;
                if (responseRs.status == "success") {
                    if (responseRs.optionTypes.length != 0) {
                        Settypeoption(sugData);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    };
    const showadddiv = () => {
        $("#hdnStatus").val(1);
        $("#Overlay").show();
        $("#LoderId").show();
        setTimeout(function () {
            $("#GridDivShow").addClass("d-none");
            $("#AddCategoryDivShow").removeClass("d-none");
            $("#Overlay").hide();
            $("#LoderId").hide();
            GetOptionType();
        }, 500);
    }
    const AddOptions = () => {
        window.location.href = "/Orderproperties/AddProperty";
    }
    const chooseUpdateType = () => {
        var vVal = $("#TypeUpdateId option:selected").val();
        if (vVal == 0) {
            setErrorType("Select one option");
        } else {
            setErrorType("");
        }
        if (vVal == 1) {
            $(".commonUpdateHdnDiv").addClass('d-none');
            $("#dropdownUpdateDiv").removeClass('d-none');
        } else {
            $("#dropdownUpdateDiv").addClass('d-none');
        }

        if (vVal == 2) {
            $(".commonUpdateHdnDiv").addClass('d-none');
            $("#radioBtnUpdateDiv").removeClass('d-none');
        } else {
            $("#radioBtnUpdateDiv").addClass('d-none');
        }

        if (vVal == 3) {
            $(".commonUpdateHdnDiv").addClass('d-none');
            $("#NumberBtnUpdateDiv").removeClass('d-none');
        } else {
            $("#NumberBtnUpdateDiv").addClass('d-none');
        }
    }
    const chooseType = () => {
        var vVal = $("#TypeId option:selected").val();
        if (vVal == 0) {
            setErrorType("Select one option");
        } else {
            setErrorType("");
        }

        if (vVal == 1) {
            $(".commonHdnDiv").addClass('d-none');
            $("#dropdownDiv").removeClass('d-none');
        } else {
            $("#dropdownDiv").addClass('d-none');
        }

        if (vVal == 2) {
            $(".commonHdnDiv").addClass('d-none');
            $("#radioBtnDiv").removeClass('d-none');
        } else {
            $("#radioBtnDiv").addClass('d-none');
        }

        if (vVal == 3) {
            $(".commonHdnDiv").addClass('d-none');
            $("#NumberBtnDiv").removeClass('d-none');
        } else {
            $("#NumberBtnDiv").addClass('d-none');
        }
    }
    const AddNewRow = () => {
        var tempvar = 1;
        tempvar++;
        idcount2.push(tempvar);
        setidcount(oldArray => [...oldArray, idcount2]);
        setblankrowdlt("");

    }
    const AddNewRadioInput = () => {
        var tempvar = 1;
        tempvar++;
        radiocount1.push(tempvar);
        setradiocount(oldArray => [...oldArray, radiocount1]);
        setblankrowradio("");
    }
    const RemoveRow = (e) => {
        var vlen = $(".editorClass:visible").length;
        if (vlen > 1) {
            $("#EditorDiv_" + e).hide();
        } else {
            setblankrowdlt("You Can't delete first row");
        }
    }
    const RemoveUpdateRow = (e) => {
        var vlen = $(".editorUpdateClass:visible").length;
        if (vlen > 1) {
            $("#EditorUpdateDiv_" + e).hide();
            $("#EditorUpdateDiv_" + e).attr('status', 3);
        } else {
            setblankrowdlt("You Can't delete first row");
        }
    }
    const RemoveRadioRow = (e) => {
        var vlen = $(".radioclass:visible").length;
        if (vlen > 1) {
            $("#EditorRadioDiv_" + e).hide();
        } else {
            setblankrowradio("You Can't delete first row");
        }
    }
    const RemoveUpdateRadioRow = (e) => {
        var vlen = $(".radioUpdateclass:visible").length;
        if (vlen > 1) {
            $("#EditorUpdateRadioDiv_" + e).hide();
            $("#EditorUpdateRadioDiv_" + e).attr('status', 3);
        } else {
            setblankrowradio("You Can't delete first row");
        }
    }
    const saveoptiondeatils = async () => {
        var temp = 0;
        if (displayName == "") {
            setnameError("Input Field is Required!")
        } else {
            setnameError("");
            temp++;
        }

        if ($("#TypeId option:selected").val() == 0) {
            setErrorType("Select one option");
        } else {
            setErrorType("");
            temp++;
        }
        var html = $("#hdnEditorField").val();

        var typeid = parseInt($("#TypeId option:selected").val());
        var PropertyText = "";
        if (typeid == 1) {
            $(".grid:visible").each(function () {
                var thisid = $(this).attr("id");
                var vsplit = thisid.split("_");
                var finalVal = parseInt(vsplit[1]);
                var optiontxt = $("#OptionText_" + finalVal).val();
                if (optiontxt != "") {
                    if (PropertyText == "") {
                        PropertyText = optiontxt;
                    } else {
                        PropertyText = PropertyText + "," + optiontxt;
                    }
                }
            });
            if (PropertyText == "") {
                setblankrowdlt("Select at least one option");
            } else {
                setblankrowdlt("");
                temp++;
            }
        }

        else if (typeid == 2) {
            $(".grid:visible").each(function () {
                var thisid = $(this).attr("id");
                var vsplit = thisid.split("_");
                var finalVal = parseInt(vsplit[1]);
                var optiontxt = $("#RadioText_" + finalVal).val();
                if (optiontxt != "") {
                    if (PropertyText == "") {
                        PropertyText = optiontxt;
                    } else {
                        PropertyText = PropertyText + "," + optiontxt;
                    }
                }
            });
            if (PropertyText == "") {
                setblankrowradio("Select at least one option");
            } else {
                setblankrowradio("");
                temp++;
            }
        } else if (typeid == 3) {
            var minval = $("#MinValueTxt").val();
            var maxval = $("#MaxValueTxt").val();
            if ($("#MinValueTxt").val() == "") {
                setblankrownumber("Required");
            } else {
                if ($("#MaxValueTxt").val() == "") {
                    setblankrownumber("Required");
                } else {
                    setblankrownumber("");
                    temp++;
                    if (PropertyText == "") {
                        PropertyText = minval + "," + maxval;
                    }
                }
            }
        }
        if (temp >= 3) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                PropName: displayName,
                TypeId: typeid,
                Description: html,
                PropText: PropertyText
            });
            await ApiPostCall("/Properties/SaveOptionValue", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Option Added Succesfully.");
                        setTimeout(function () {
                            window.location = "/Orderproperties";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }

    }
    const something = () => {
        if (nameError != "") {
            setnameError("");
        }
        if (NumberMinVal != "") {
            setblankrownumber("");
        }
        if (NumberMaxVal != "") {
            setblankrownumber("");
        }
    };
    const ChangeStatus = async (e) => {
        var vId = parseInt(e.currentTarget.attributes[3].value);
        var vStatus;
        if ($("#VisibleId_" + vId).is(":checked")) {
            $("#VisibleId_" + vId).prop("checked", true);
            vStatus = 1;
        } else {
            $("#VisibleId_" + vId).removeAttr("checked");
            vStatus = 0;
        }
        $("#Overlay").show();
        $("#LoderId").show();
        var raw = JSON.stringify({
            Status: vStatus,
        });
        await ApiPutCall("/Properties/VisibleProperties/" + vId, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "Success") {
                    $(".alert-success").show();
                    $("#AlertMsg").text("Updated Successfully.");
                    setTimeout(function () {
                        $(".alert-success").hide();
                        $("#AlertMsg").text("");
                    }, 1500);
                    if (responseRs.PropertyData.visiblestatus == 1) {
                        setCheckvalue(true);
                    } else {
                        setCheckvalue(false);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    };
    const EditOptionsDiv = (e) => {
        var vId = parseInt(e.currentTarget.attributes[3].value);
        window.location.href = "/Orderproperties/UpdateProperty/?id=" + vId;
    };
    const showupdatediv = async () => {
        var vId = parseInt(getUrlParameter("id"));
        $("#hdnStatus").val(2);
        $("#HdnUserId").val(vId);
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Properties/GetPropertyUpdateDetails/" + vId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.propertyDetails;
                var sugpropvalues = sugData.propertyValues;
                if (responseRs.status == "Success") {
                    $("#GridDivShow").addClass("d-none");
                    $("#UpdatePropertyDivShow").removeClass("d-none");
                    setdisplayName(sugData.propertyName);
                    setdefaultVal(sugData.propertyType);

                    if (sugData.propertyType == 1) {
                        $("#dropdownUpdateDiv").removeClass('d-none');
                    } else if (sugData.propertyType == 2) {
                        $("#radioBtnUpdateDiv").removeClass('d-none');
                    } else if (sugData.propertyType == 3) {
                        $("#NumberBtnUpdateDiv").removeClass('d-none');
                        setNumberMinVal(sugData.propertyValues[0].propertyText);
                        setNumberMaxVal(sugData.propertyValues[1].propertyText);
                        setMinrowid(sugData.propertyValues[0].id);
                        setMaxrowid(sugData.propertyValues[1].id);
                    }
                    setneweditorState(<Example content={sugData.description} />);
                    $("#hdnEditorField").val(sugData.description);
                    settempdropdown(sugpropvalues);
                    GetOptionType();
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };
    const UpdateSaveDetails = async () => {
        var userid = parseInt($("#HdnUserId").val());
        var temp = 0;
        if (displayName == "") {
            setnameError("Input Field is Required!")
        } else {
            setnameError("");
            temp++;
        }

        if ($("#TypeUpdateId option:selected").val() == 0) {
            setErrorType("Select one option");
        } else {
            setErrorType("");
            temp++;
        }
        var html = $("#hdnEditorField").val();
        var typeid = parseInt($("#TypeUpdateId option:selected").val());
        var PropertyText = [];
        if (typeid == 1) {
            $(".gridUpdate").each(function () {
                var thisid = $(this).attr("id");
                var vsplit = thisid.split("_");
                var finalVal = parseInt(vsplit[1]);
                var optiontxt = $("#OptionUpdateText_" + finalVal).val();
                var statusflag = $(this).attr('status');
                var NewJson = { rowid: finalVal, roworder: optiontxt, status_flag: statusflag };
                if (optiontxt != "") {
                    PropertyText.push(NewJson);
                }
            });
            if (PropertyText.length == 0) {
                setblankrowdlt("Select at least one option");
            } else {
                setblankrowdlt("");
                temp++;
            }
        }
        else if (typeid == 2) {
            $(".gridRadioUpdate").each(function () {
                var thisid = $(this).attr("id");
                var vsplit = thisid.split("_");
                var finalVal = parseInt(vsplit[1]);
                var optiontxt = $("#RadioUpdateText_" + finalVal).val();
                var statusflag = $("#EditorUpdateRadioDiv_" + finalVal).attr('status');
                var NewJson = { rowid: finalVal, roworder: optiontxt, status_flag: statusflag };
                if (optiontxt != "") {
                    PropertyText.push(NewJson);
                }
            });
            if (PropertyText == "") {
                setblankrowradio("Select at least one option");
            } else {
                setblankrowradio("");
                temp++;
            }
        }
        else if (typeid == 3) {
            if ($("#MinUpdateValueTxt").val() == "") {
                setblankrownumber("Required");
            } else {
                if ($("#MaxUpdateValueTxt").val() == "") {
                    setblankrownumber("Required");
                } else {
                    setblankrownumber("");
                    temp++;
                    $(".gridNumberUpdate").each(function () {
                        var finalVal = $(this).attr('rowid');
                        var optiontxt = $(this).val();
                        var NewJson = { rowid: finalVal, roworder: optiontxt, status_flag: 2 };
                        if (optiontxt != "") {
                            PropertyText.push(NewJson);
                        }
                    });
                }
            }
        }
        if (temp >= 3) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                PropName: displayName,
                TypeId: typeid,
                Description: html,
                customPropText: PropertyText
            });
            await ApiPutCall("/Properties/UpdateSaveOptions/" + userid, raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Option Updated Succesfully.");
                        setTimeout(function () {
                            window.location = "/Orderproperties";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });

        }
    }
    const cancelBtnClick = () => {
        window.location.href = "/Orderproperties";
    }
    return (
        <>
            {TempCheck == true ?
                <>
                    <input type="hidden" id="hdnStatus" />
                    <input type="hidden" id="HdnUserId" />
                    <LeftMenu />
                    <div fixed="true" className='container-fluid mainContainer'>
                        <div className="content-wrapper" id="GridDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv">
                                            <div className="cardDiv-header">
                                                <div className="row AlignItems">
                                                    <div className="col-4">
                                                        <h4>Product Properties</h4>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="card-action">
                                                            <button type="submit" className="loginBtn" onClick={AddOptions}>
                                                                Add Property
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 gridHeader">
                                                    <div className="row">
                                                        <div className="col-4 pe-0">
                                                            <b>Display Name</b>
                                                        </div>
                                                        <div className="col-4 text-center px-0">
                                                            <b>Type</b>
                                                        </div>
                                                        <div className="col-2 text-center">
                                                            <b>Is Visible?</b>
                                                        </div>
                                                        <div className="col-2 text-center ps-0">
                                                            <b>Action</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="scrolly-63">
                                                    {optionDetailsVar.map((item, i) => {
                                                        var returData;
                                                        returData = (<div className="col-12 grid" key={i}>
                                                            <div className="row" key={i}>
                                                                <div className="col-4 mb-2">
                                                                    {item.propertyName}
                                                                </div>
                                                                <div className="col-4 text-center">
                                                                    {(item.propertyType == 1) ?
                                                                        <span>Dropdown</span>
                                                                        : (item.propertyType == 2) ?
                                                                            <span>Radio</span>
                                                                            :
                                                                            <span>Number</span>
                                                                    }
                                                                </div>
                                                                <div className="col-2 text-center">
                                                                    {item.active == 1 ? (
                                                                        <input
                                                                            className="form-check-input me-2"
                                                                            defaultChecked={checkvalue}
                                                                            id={`VisibleId_${item.id}`}
                                                                            onClick={ChangeStatus}
                                                                            type="checkbox"
                                                                            value=""
                                                                            userid={item.id}
                                                                        />
                                                                    ) : (
                                                                        <input
                                                                            className="form-check-input me-2"
                                                                            id={`VisibleId_${item.id}`}
                                                                            onClick={ChangeStatus}
                                                                            type="checkbox"
                                                                            value=""
                                                                            userid={item.id}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="col-2 text-center">
                                                                    <span>
                                                                        <img src="/images/editIcon.svg" title="Edit Option" className="iconClass me-1"
                                                                            userid={item.id}
                                                                            onClick={EditOptionsDiv}
                                                                            alt="Edit"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        );
                                                        return returData;
                                                    })}
                                                    {norecord}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="AddCategoryDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/Orderproperties">
                                                            <img src={CDNURL + "/Localimages/BackBtn.svg"} alt="Back" className="h-25px" title="Back" />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Add New Property
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="row">
                                                    <div className="col-12 pt-3">
                                                        <div className="row">
                                                            <div className="col-md-6 col-12 mb-4">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Display Name"
                                                                    value={displayName}
                                                                    onChange={(e) => setdisplayName(e.target.value)}
                                                                    onKeyDown={(e) => something(e)}
                                                                />
                                                                <span className="txt9d0909">{nameError}</span>
                                                            </div>
                                                            <div className="col-md-6 col-12 mb-4">
                                                                <select id="TypeId" className="form-select formError" onChange={chooseType}>
                                                                    <option value="0">Type</option>
                                                                    {typeoption.map((item, i) => {
                                                                        var returData;
                                                                        returData = (
                                                                            <option value={item.typeId} key={i}>
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                        return returData;
                                                                    })}
                                                                </select>
                                                                <span className="txt9d0909">{errortype}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <Example />
                                                        <span>Description</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-5 d-none commonHdnDiv" id="dropdownDiv">
                                                    <div className="col-12">
                                                        <div className="ps-4">
                                                            <h5>Add Option values</h5>
                                                        </div>
                                                        <div className="card-body pl-3 pr-3 pt-0">
                                                            <div>
                                                                {idcount.map((item, i) => {
                                                                    var returData;
                                                                    returData = <div className="col-12 grid editorClass" key={i} id={`EditorDiv_${i}`}>
                                                                        <div className='row'>
                                                                            <div className="col-6 mb-4">
                                                                                <input type="text" className="form-control" id={`OptionText_${i}`} placeholder="Option Value" />
                                                                            </div>
                                                                            <div className='col-6 text-end'>
                                                                                <button type="button" className="btn btn-danger" onClick={() => RemoveRow(i)}>Remove</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>;
                                                                    return returData;
                                                                })}
                                                                <span className="txt9d0909">{blankrowdlt}</span>
                                                            </div>
                                                            <div className="col-12 text-center my-3">
                                                                <button type="button" className="btn btn-primary" onClick={AddNewRow}>Add Option Value</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-5 d-none commonHdnDiv" id="radioBtnDiv">
                                                    <div className="col-12">
                                                        <div className="ps-4">
                                                            <h5>Add Radio Button values</h5>
                                                        </div>
                                                        <div className="card-body pl-3 pr-3 pt-0">
                                                            {radiocount.map((item, i) => {
                                                                var returData;
                                                                returData = <div className="col-12 grid radioclass" key={i} id={`EditorRadioDiv_${i}`}>
                                                                    <div className='row'>
                                                                        <div className="col-6 mb-4">
                                                                            <input type="text" className="form-control" id={`RadioText_${i}`} placeholder="Option Value" />
                                                                        </div>
                                                                        <div className='col-6 text-end'>
                                                                            <button type="button" className="btn btn-danger" onClick={() => RemoveRadioRow(i)}>Remove</button>
                                                                        </div>
                                                                    </div>
                                                                </div>;
                                                                return returData;
                                                            })}
                                                            <span className="txt9d0909">{blankrowradio}</span>
                                                            <div className="col-12 text-center my-3">
                                                                <button type="button" className="btn btn-primary" onClick={AddNewRadioInput}>Add Option Value</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={cancelBtnClick}>Cancel</span>
                                                    <button type="submit" className="loginBtn" onClick={saveoptiondeatils}>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="UpdatePropertyDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/Orderproperties">
                                                            <img src={CDNURL + "/Localimages/BackBtn.svg"} alt="Back" className="h-25px" title="Back" />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Update Property
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="row">
                                                    <div className="col-12 pt-3">
                                                        <div className="row">
                                                            <div className="col-md-6 col-12 mb-4">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Display Name"
                                                                    value={displayName}
                                                                    onChange={(e) => setdisplayName(e.target.value)}
                                                                    onKeyDown={(e) => something(e)}
                                                                />
                                                                <span className="txt9d0909">{nameError}</span>
                                                            </div>
                                                            <div className="col-md-6 col-12 mb-4">
                                                                <select id="TypeUpdateId" className="form-select formError" onChange={chooseUpdateType} disabled>
                                                                    <option value="0">Type</option>
                                                                    {typeoption.map((item, i) => {
                                                                        var returData;
                                                                        returData = (
                                                                            (defaultVal == item.typeId) ?
                                                                                <option value={item.typeId} key={i} selected>
                                                                                    {item.name}
                                                                                </option>
                                                                                :
                                                                                <option value={item.typeId} key={i}>
                                                                                    {item.name}
                                                                                </option>
                                                                        );
                                                                        return returData;
                                                                    })}
                                                                </select>
                                                                <span className="txt9d0909">{errortype}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        {neweditorState}
                                                        <span>Description</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-5 d-none commonUpdateHdnDiv" id="dropdownUpdateDiv">
                                                    <div className="col-12">
                                                        <div className="ps-4">
                                                            <h5>Add Option values</h5>
                                                        </div>
                                                        <div className="card-body pl-3 pr-3 pt-0">
                                                            <div id="DropdownId">
                                                                {tempdropdown.map((item, i) => {
                                                                    var returData;
                                                                    returData = <div className="col-12 grid editorUpdateClass gridUpdate" status="2" key={i} id={`EditorUpdateDiv_${item.id}`}>
                                                                        <div className='row'>
                                                                            <div className="col-6 mb-4">
                                                                                <input type="text" defaultValue={item.propertyText} className="form-control" id={`OptionUpdateText_${item.id}`} placeholder="Option Value" />
                                                                            </div>
                                                                            <div className='col-6 text-end'>
                                                                                <button type="button" className="btn btn-danger" onClick={() => RemoveUpdateRow(item.id)}>Remove</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>;
                                                                    return returData;
                                                                })}
                                                                {idcount.map((item, i) => {
                                                                    var returData;
                                                                    returData = <div className="col-12 grid editorUpdateClass gridUpdate" status="1" key={i} id={`EditorUpdateDiv_${i}`}>
                                                                        <div className='row'>
                                                                            <div className="col-6 mb-4">
                                                                                <input type="text" className="form-control" id={`OptionUpdateText_${i}`} placeholder="Option Value" />
                                                                            </div>
                                                                            <div className='col-6 text-end'>
                                                                                <button type="button" className="btn btn-danger" onClick={() => RemoveUpdateRow(i)}>Remove</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>;
                                                                    return returData;
                                                                })}
                                                                <span className="txt9d0909">{blankrowdlt}</span>
                                                            </div>
                                                            <div className="col-12 text-center my-3">
                                                                <button type="button" className="btn btn-primary" onClick={AddNewRow}>Add Option Value</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-5 d-none commonUpdateHdnDiv" id="radioBtnUpdateDiv">
                                                    <div className="col-12">
                                                        <div className="ps-4">
                                                            <h5>Add Radio Button values</h5>
                                                        </div>
                                                        <div className="card-body pl-3 pr-3 pt-0">
                                                            {tempdropdown.map((item, i) => {
                                                                var returData;
                                                                returData = <div className="col-12 grid radioUpdateclass editorUpdateClass gridRadioUpdate" status="2" key={i} id={`EditorUpdateRadioDiv_${item.id}`}>
                                                                    <div className='row'>
                                                                        <div className="col-6 mb-4">
                                                                            <input type="text" className="form-control" defaultValue={item.propertyText} id={`RadioUpdateText_${item.id}`} placeholder="Option Value" />
                                                                        </div>
                                                                        <div className='col-6 text-end'>
                                                                            <button type="button" className="btn btn-danger" onClick={() => RemoveUpdateRadioRow(item.id)}>Remove</button>
                                                                        </div>
                                                                    </div>
                                                                </div>;
                                                                return returData;
                                                            })}
                                                            {idcount.map((item, i) => {
                                                                var returData;
                                                                returData = <div className="col-12 grid radioUpdateclass editorUpdateClass gridRadioUpdate" status="1" key={i} id={`EditorUpdateRadioDiv_${i}`}>
                                                                    <div className='row'>
                                                                        <div className="col-6 mb-4">
                                                                            <input type="text" className="form-control" id={`RadioUpdateText_${i}`} placeholder="Option Value" />
                                                                        </div>
                                                                        <div className='col-6 text-end'>
                                                                            <button type="button" className="btn btn-danger" onClick={() => RemoveUpdateRadioRow(i)}>Remove</button>
                                                                        </div>
                                                                    </div>
                                                                </div>;
                                                                return returData;
                                                            })}
                                                            <span className="txt9d0909">{blankrowradio}</span>
                                                            <div className="col-12 text-center my-3">
                                                                <button type="button" className="btn btn-primary" onClick={AddNewRadioInput}>Add Option Value</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row mt-5 d-none commonUpdateHdnDiv" id="NumberBtnUpdateDiv">
                                            <div className="col-12">
                                                <div className="ps-3">
                                                    <h5>Add Input Number values</h5>
                                                </div>
                                                <div className="card-body pl-3 pr-3 pt-0">
                                                    <div className="col-12 py-3">
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <input type="text" defaultValue={NumberMinVal} rowid={Minrowid} className="form-control gridNumberUpdate" id="MinUpdateValueTxt" placeholder="Min Value" />
                                                            </div>
                                                            <div className='col-3'>
                                                                <input type="text" defaultValue={NumberMaxVal} rowid={Maxrowid} className="form-control gridNumberUpdate" id="MaxUpdateValueTxt" placeholder="Max Value" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="txt9d0909">{blankrownumber}</span>
                                                </div>
                                            </div>
                                        </div> */}
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={cancelBtnClick}>Cancel</span>
                                                    <button type="submit" className="loginBtn" onClick={UpdateSaveDetails}>
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
                :
                <></>
            }
        </>

    )
}
export default OrderProperties;