import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { useState, useEffect,useRef } from 'react';
import React from "react";
import $ from 'jquery';
import ReactStars from "react-rating-stars-component";
import { CDNURL } from "../js/constants";
import { ApiGetCall } from "../js/connector";
import Pagination from '@material-ui/lab/Pagination';
export default function TestimonialsReviews() {
    const [pagecount, setPageCount] = useState(0);
    const [Reviews, setReviews] = useState([]);
    const [overallstate, setoverallstate] = useState({});
    const myRef = useRef(null);
    useEffect(() => {
        window.scroll(0, 0);
        GetCustomerReview(null,1);
        return () => {
            setoverallstate({});
        };
    }, []);
    const GetCustomerReview = async (e, value) => {
        window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop });
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/CustomerReview/GetCustomerReview/2?pageNum=" + value + "&pageSize=10").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.reviewsDetails;
                if (responseRs.status == "success") {
                    if (responseRs.reviewsDetails != "") {
                        setReviews(sugData);
                        setPageCount(responseRs.pagecount);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const submitTestimonial = () => {
        window.location.href = "/testimonials";
    }
    return (
        <>
            <NavigationBar />
            <div className="container py-5" ref={myRef}>
                <div className="col-12 row text-center pb-4" >
                    <div className="col-12 ">
                        <h1>Customer Reviews and Testimonials</h1>
                    </div>
                    <div className="col-md-4 offset-md-4 mt-3 mb-3">
                        <button type="submit" className="loginBtn" onClick={submitTestimonial}>Submit Testimonial</button>
                    </div>
                </div>
                {Reviews.map((item, i) => {
                    var returData;
                    var profileImage = CDNURL + "Gallery/" + item.imageUrl;
                    returData = <div className="col-12 p-3 py-3" key={i}>
                        <div className='row py-4 reviewCard'>
                            <div className='col-12 row pe-0'>
                                <div className="col-md-2 text-center">
                                    {item.imageUrl != null ?
                                        <img src={profileImage} className="smallReviewImg" alt="Profile Image"/>
                                        :
                                        <img src="/images/newReview.png" className="" alt="Profile Image"/>
                                    }
                                    <br />
                                    {item.nickName != "" ?
                                        <label className="pt-2 font-bold" style={{ color: "#8C4D36" }}>{item.nickName}</label>
                                        :
                                        <label className="pt-2 font-bold" style={{ color: "#8C4D36" }}>{item.name}</label>
                                    }
                                    <div className="d-flex justify-content-center">
                                        <ReactStars size={25} isHalf={true} value={item.rating} activeColor="#8C4D36" edit={false} />
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="col-12 fs-18 py-1 mt-1" style={{ color: "#8C4D36", fontWeight: "600" }}>
                                        {item.serviceProvided}
                                    </div>
                                    <div className="col-12 pt-2" style={{ fontWeight: "600", fontSize: "14px" }}>
                                        {item.comment}
                                    </div>
                                    <div className="col-12 fs-18 py-1 mt-3" style={{ color: "#8C4D36", fontWeight: "600" }}>
                                        {item.state}, {item.city} - {item.zipCode}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    return returData;
                }
                )}
                <div className="d-flex justify-content-center pt-3">
                    <Pagination count={pagecount} onChange={GetCustomerReview} id="paginationId" />
                </div>
            </div>
            <Footer />
        </>
    )
}