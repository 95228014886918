import React from "react";
import { NavigationBar } from "../Navigationbar";
import { Footer } from "../Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CDNURL } from "../../js/constants";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import $ from "jquery";
import { useEffect } from "react";

export default function TablesPad() {
  useEffect(() => {
    $('#yourElementId').text('Tables Pad');
  });
  return (
    <>
      <NavigationBar />
      <div className="container-fluid px-0 colorpageimage">
        <LazyLoadImage
          src={CDNURL + "Localimages/ProductsHeadImg/TablesPadHead.jpg"}
          className="img-fluid w-100"
        />
        <span className="carousel-caption1">
          <h1 className="firstHead">Tables Pad</h1>
          <div className="col-2 offset-5">
            <hr className="hrClass" />
          </div>
        </span>
      </div>

      <div className="container py-5 colorPara ps-0">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Tables Pad</h1>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <ul>
          <li className="liBullet ps-2 pt-3">
            Purchasing a new, modern set of furniture is just the first step in
            refurbishing your home. The next one, equally important, is getting
            the right accessories where required, and by accessories, we don’t
            mean the purely decorative ones, but those that provide utility
            above everything else. At Berger’s Table Pad Company, we leave
            nothing to chance.
          </li>
          <li className="pt-4 liBullet ps-2">
            A{" "}
            <a className="paraLink" href="/products/table-pads">
              tables pad
            </a>{" "}
            needs to meet specific requirements, both regarding the
            functionality and material quality and the design and innovation at
            the same time. Although it may not seem like it at first glance, a
            table can fulfill more than one role. Tables are probably the most
            used pieces of furniture, which is why they are usually the first to
            wear down.
          </li>
          <li className="pt-4 liBullet ps-2">
            In general, you use them to eat, to drink your coffee in the
            morning, when using your laptop or when you serve your guests with a
            cold drink from time to time. And, with so much activity going on,
            accidents are inevitable. Spilling your drink on a wooden table
            might not be the most fortunate occurrence, but neither is
            scratching it by mistake, or having your pet do the job for you.
          </li>
        </ul>
      </div>

      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>
              For this reason, a table pad will come with specific benefits,
              mainly:
            </h2>
            <p className="prodhr mt-1" />
          </div>
          <div className="px-3">
            <ul className="colorPara">
              <li className="pt-3 ps-2">
                <span className="font-weight-bold">
                  Protecting against liquids, high - temperature materials, and
                  scratching –
                </span>{" "}
                Your goal is to protect your table, especially when we are
                talking about an expensive one that’s part of a living room set,
                for instance. A good tables pad will provide you with just the
                right extra layer of protection required, keeping the furniture
                away from any of the risks mentioned above.
              </li>
              <li className="pt-4  ps-2">
                <span className="font-weight-bold">
                  Flexibility and safety –
                </span>{" "}
                Top tier table pads need to be easy to install and equally easy
                to remove. It is also of crucial importance for them to remain
                stable during use, which is why our MagnaLoc magnetic system is
                one of the best features you could wish for.
              </li>
              <li className="pt-4  ps-2">
                <span className="font-weight-bold">
                  Matching the style of your furniture –
                </span>{" "}
                Table pads come in many shapes, styles, and colors, so pay very
                close attention to all these little details when deciding for
                one. This way, you will not only benefit from a much-needed
                addition regarding utility but also regarding aesthetics.
              </li>
              <li className="pt-4  ps-2">
                <span className="font-weight-bold">Enriching your room –</span>{" "}
                Good, proficient table pads need to become pieces of furniture
                themselves. That means you have to consider aspects of the
                construction material, the manufacturing details, as well as the
                design finishes - all these suggestions a plus in quality and
                style.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Should you get a table pad?</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              Yes, you should. No other answer could be considered as valid. A
              tables pad is essential for protecting your table, something not
              many people feel is as important, unfortunately. But it will begin
              to seem relevant when considering the case where the table makes
              for an expensive piece you wouldn’t want to be ruined too soon.
            </li>
            <li className="pt-4 liBullet ps-2">
              At Berger’s Table Pad Company, we only deal with high-quality
              products, and we have a wide variety of pads to choose from, in
              case our tastes might differ from the majority. It is not whether
              you should get one, but one of when to get it and which type to
              settle for.
            </li>
          </ul>
        </div>
      </div>
      <WhatCustomerThink />
      <Footer />
    </>
  );
}
