import React from "react";
import $ from 'jquery';
export function Errorfunc() {
    var url = window.location.href;
    url = url.toLowerCase();
    if (url.includes('.html')) {
        url = url.replace('.html', '');
        window.location.href = url;
    }else{
        $("#ErrorDiv").removeClass('d-none');
    }

    return (
        <>
            <div className=" container-fluid px-0 d-none" id="ErrorDiv">
                <div className="row page-hero d-flex align-items-center justify-content-center">
                    <div className="col-md-5 text-center px-5 offset-md-1">
                        <h1 className="text404">404</h1>
                        <h3 className="pagenotfound">Page Not Found</h3>
                        <p>The Page you are looking for doesn't exist or another error occurred. <a href="/home" className="error404">Go back</a>, or head over to <a href="/home" className="error404">Berger's Table Pad Company</a> to choose a new direction.</p>
                    </div>
                    <div className="col-md-6 text-center">
                        <img src="/images/404Image.png" className="img-fluid" />
                    </div>
                </div>
            </div>

        </>
    )
}