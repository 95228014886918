import $ from "jquery";
import React, { useEffect, useState } from "react";
import { LiveChat } from "./livechat";
import { CDNURL } from "../js/constants";
import { ApiGetCall } from "../js/connector";
import { useSearchParams } from "react-router-dom";


export const NavigationBar = () => {
  const [state, setState] = useState({});
  const [menuitems, setMenuItems] = useState([]);
  const [submenuitems, setSubMenuItems] = useState([]);
  const [sectionContact, setsectionContact] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  var dealersemail = localStorage.getItem("DealersEmail");
  useEffect(() => {
    if (dealersemail != null) {
      var intials = dealersemail.charAt(0);
      $('.profileImage').text(intials.toUpperCase());
    }
    var utmSource = searchParams.get("utm_source");
    localStorage.setItem('shownotification', '1');
    var sessionutmsource = sessionStorage.getItem('utm_source');
    if (sessionutmsource == null && utmSource != null) {
      sessionStorage.setItem('utm_source', utmSource);
    }
    window.scrollTo(0, 0);
    $("#searchproducts").val("");
    window.addEventListener("scroll", isSticky);
    var totalitem = JSON.parse(localStorage.getItem("addtocart"));
    var vlen;
    if (totalitem != null) {
      vlen = totalitem.Products.length;
    } else {
      vlen = 0;
    }
    $(".totalItemCart").text(vlen);
    myFunction();
    return () => {
      window.removeEventListener("scroll", isSticky);
      setState({});
    };
  }, []);
  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };
  const isSticky = (e) => {
    const header = document.querySelector(".navbarfixedPos");
    const scrollTop = window.scrollY;
    const width = window.innerWidth;
    const topbar = $("#topbarId").height();
    if (scrollTop >= topbar) {
      header.classList.add("is-sticky");
      if (width <= 991) {
        $("#hdnsmallCartImage").removeClass('d-none');
        $("#hdnsmallsearchImage").removeClass('d-none');
        $("#hdnCartImage").addClass('d-none');
        $("#hdnSearchImage").addClass('d-none');
        $("#smallScreenLogo").removeClass('d-none');
      } else {
        $("#hdnCartImage").removeClass('d-none');
        $("#hdnSearchImage").removeClass('d-none');
        $("#hdnsmallCartImage").addClass('d-none');
        $("#hdnsmallsearchImage").addClass('d-none');
      }
    } else {
      header.classList.remove("is-sticky");
      $("#smallScreenLogo").addClass('d-none');
      $("#hdnsmallCartImage").addClass('d-none');
      $("#hdnsmallsearchImage").addClass('d-none');
      $("#hdnCartImage").addClass('d-none');
      $("#hdnSearchImage").addClass('d-none');
    }
  };
  const myFunction = async () => {
    // $("#Overlay").show();
    // $("#LoderId").show();
    await ApiGetCall("/NavigationMenu/GetMenuItems").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        // $("#Overlay").hide();
        // $("#LoderId").hide();
        var sugData = responseRs.menuItems;
        var submenu = responseRs.subMenuItems;
        if (responseRs.menuItems != "") {
          setMenuItems(sugData);
        }
        if (responseRs.subMenuItems != "") {
          setSubMenuItems(submenu);
        }
        ContactFunction();
        setTimeout(function () {
          actvieName();
        }, 500);
      }
    });
  };
  const ContactFunction = async () => {
    // $("#Overlay").show();
    // $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        // $("#Overlay").hide();
        // $("#LoderId").hide();
        setsectionContact(propDetails[26].value);
      }
    });
  }
  const actvieName = () => {
    var PathName = window.location.pathname;
    var pathsplit = PathName.split('/');
    var prodActive = pathsplit[1];
    $(".linkClass").removeClass("activeMenu");
    if (PathName == "/colors") {
      $("#ActiveId_colors").addClass("activeMenu");
    } else if (PathName == "/home") {
      $("#ActiveId_home").addClass("activeMenu");
    } else if (PathName == "/table-cloth") {
      $("#ActiveId_tablecloth").addClass("activeMenu");
    } else if (PathName == "/how-to-measure-for-a-table-pad") {
      $("#ActiveId_measuringtables").addClass("activeMenu");
    } else if (PathName == "/magnetic-locking-system") {
      $("#ActiveId_patentedmagnetic").addClass("activeMenu");
    } else if (PathName == "/our-manufacturing-process") {
      $("#ActiveId_manufacturing").addClass("activeMenu");
    } else if (PathName == "/about-us") {
      $("#ActiveId_about").addClass("activeMenu");
    } else if (PathName == "/contact") {
      $("#ActiveId_contact").addClass("activeMenu");
    } else if (prodActive == "store" || prodActive == "products") {
      $("#navbarDropdownMenuLink").addClass("activeMenu");
    }
  }
  const shoppingcartclick = () => {
    window.location = "/cart";
  }
  const SearchProducts = () => {
    $("#blacksearchloader").removeClass('d-none');
    var typingTimer;
    var doneTypingInterval = 3000;
    clearTimeout(typingTimer);
    typingTimer = setTimeout(doneTyping, doneTypingInterval);
    $("#blacksearchicon").addClass('d-none');
    $("#closesearchicon").addClass('d-none');
  }
  const doneTyping = async () => {
    var vVal = $("#searchproducts").val();
    if (vVal == "") {
      vVal = null;
    }
    await ApiGetCall("/NavigationMenu/SearchString/" + vVal).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#blacksearchloader").addClass('d-none');
        $("#closesearchicon").removeClass('d-none');
        if (responseRs.status == "success") {
          $(".searchdiv").html("");
          $(".searchdiv").css('visibility', 'visible');
          $(".searchdiv").css('opacity', '1');
          var productarray = responseRs.productlist;
          if (productarray.length == 0) {
            $("<label>No Record Found</label>").appendTo(".searchdiv")
          } else {
            for (var i = 0; i < productarray.length; i++) {
              $("<label>" + productarray[i].name + "</label><br/>")
                .attr({
                  staticpath: productarray[i].staticPath,
                  itemid: productarray[i].id,
                  url: productarray[i].productUrl,
                  prodname: productarray[i].name,
                  urlpath: productarray[i].urlPath,
                  class: "crsr-pointer searchLabel"
                }).appendTo(".searchdiv");
              $(".searchLabel").click(function () {
                var staticpath = $(this).attr('staticpath');
                var itemid = $(this).attr('itemid');
                var url = $(this).attr('url');
                var finalname = $(this).attr('urlpath');
                if (url == "") {
                  window.localStorage.setItem("useridordernow", itemid);
                  window.localStorage.setItem("staticpath", staticpath);
                  if (itemid == "26") {
                    window.location = "/products/ultra-elite-dining-table-pad";
                  } else {
                    window.location = "/store/" + finalname;
                  }
                } else if (url == null) {
                  window.localStorage.setItem("useridordernow", itemid);
                  window.localStorage.setItem("staticpath", staticpath);
                  window.location = "/store/" + finalname;
                }
                else {
                  window.location = url;
                }
              });
            }
          }
        }
      }
    });

  }
  const hiddensearchbox = () => {
    $(".searchdiv").html("");
    $("#blacksearchicon").removeClass('d-none');
    $(".searchdiv").css('visibility', 'hidden');
    $(".searchdiv").css('opacity', '0');
    $("#searchproducts").val("");
    $("#blacksearchloader").addClass('d-none');
    $("#closesearchicon").addClass('d-none');
  }
  const showsearchbox = () => {
    window.scroll(0, 0);
    $("#hdnSearchImage").addClass('d-none');
    $("#searchproducts").focus();
  }
  const producturlopen = (url, itemname, itemid, staticpath) => {
    window.localStorage.setItem("useridordernow", itemid);
    window.localStorage.setItem("staticpath", staticpath);
    window.location = url;
    window.localStorage.removeItem("flag");
  }
  return (
    <>
      {/* navigation bar */}
      <div className="container-fluid px-0 ">
        <div className="container-fluid" >
          <div className="row" id="topbarId">
            <div className="col-12 px-0">
              <div className="row mx-0">
                <div className="col-lg-2 text-center p-1">
                  <a href="/home">
                    <img src={CDNURL + "Localimages/Final_Logo_2.svg"} className="img-fluid" alt="Logo" />
                    {/* <img src="images/Final_Logo_2.svg" className="img-fluid" /> */}
                  </a>
                </div>
                <div className="col-lg-3 align-items-center d-flex p-2">
                  <div className="col-12" >
                    <div className="form-outline">
                      <div className="posRel ">
                        <input type="text" className="form-control searchbox" placeholder="Search.." title="Search"
                          id="searchproducts" onKeyUp={SearchProducts} onPaste={SearchProducts} />
                        <img src={CDNURL + "Localimages/blackSearch.svg"} className="img-fluid searchicon" id="blacksearchicon" alt="Search" />
                        <img src={CDNURL + "Localimages/Spinner.svg"} className="d-none img-fluid searchicon LoaderIcon" id="blacksearchloader" alt="Loader" />
                        <img src={CDNURL + "LocalImages/closeicon.png"} className="crsr-Pointer d-none img-fluid searchicon iconClass" id="closesearchicon" onClick={hiddensearchbox} alt="Close Search" />
                        <div className="searchdiv"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 align-items-center d-flex justify-content-center p-2">
                  <span className="callUsTxt">Call Us Toll Free: </span>
                  <a className="orangeTxt ps-2" href="tel:+18007372165">1-800-737-2165</a>
                </div>
                <div className="col-lg-2 col-sm-6 col-12 p-2 align-items-center d-flex justify-content-center  crsr-pointer notifyClass" onClick={shoppingcartclick}>
                  <div className="row shopCartImg">
                    <div className="col-2 posRel px-0">
                      <img src={CDNURL + "LocalImages/shoppingcart.png"} alt="Shopping Cart" />
                      <span className="notificationCountCircle totalItemCart">0</span>
                    </div>
                    <div className="col-10 text-start pt-1">
                      <a className="cartTxt" href="/cart" >SHOPPING CART</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 align-items-center d-flex">
                  <ul className="mb-0">
                    <li className="nav-item dropdown dropdown-user-setting list-inline">
                      <a className="nav-link dropdown-toggle-nocaret ps-4 pe-0" href="#" data-bs-toggle="dropdown">
                        <div className="profileImage"></div>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <a className="dropdown-item" href="#">
                            <div className="d-flex align-items-center">
                              <div className="profileImage"></div>
                              <div className="ms-3">
                                <h6 className="mb-0 dropdown-user-name"><b>{dealersemail}</b></h6>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li><hr className="dropdown-divider" /></li>
                        <li>
                          <a className="dropdown-item" href="/previous-orders">
                            <div className="d-flex align-items-center">
                              <div className=""><i className="bi bi-gear-fill"></i></div>
                              <div className="ms-3"><span>Previous Orders</span></div>
                            </div>
                          </a>
                        </li>
                        <li><hr className="dropdown-divider" /></li>
                        <li>
                          <a className="dropdown-item" href="/dealers-logout">
                            <div className="d-flex align-items-center">
                              <div className=""><i className="bi bi-lock-fill"></i></div>
                              <div className="ms-3"><span>Logout</span></div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbarfixedPos">
          <div className="container-fluid" >
            <div className="p-2 d-none" id="smallScreenLogo">
              <a href="/home">
                <img src={CDNURL + "Localimages/Final_Logo_2_.svg"} className="img-fluid" alt="Logo" />
              </a>
            </div>
            <a className="navbar-brand"></a>
            <a className="d-none mx-1 crsr-pointer" id="hdnsmallsearchImage" onClick={showsearchbox}>
              <img src={CDNURL + "Localimages/whiteSearch.svg"} className="img-fluid mt-1" alt="Search" />
            </a>
            <a className="d-none" id="hdnsmallCartImage" onClick={shoppingcartclick}>
              <div className="pt-1 align-items-left d-flex justify-content-left  crsr-pointer notifyClass" >
                <div className="row shopCartImg">
                  <div className="posRel px-0">
                    <img src={CDNURL + "Localimages/shoppingcart_white.svg"} alt="Shopping Cart" />
                    <span className="notificationCountCircleWhite totalItemCart">0</span>
                  </div>
                </div>
              </div>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse  justify-content-center " id="navbarNavDropdown">
              <ul className="navbar-nav">
                {menuitems.map((item, i) => {
                  var returData;
                  item.status != 0 ? (
                    item.id != "2" ? (
                      (returData = (
                        <li className="nav-item mx-2" key={i}>
                          {item.name == "Home" ?
                            <a className="nav-link linkClass navLink" id={`ActiveId_${item.combineName}`} aria-current="page" href="/home">
                              {item.name}
                            </a>
                            :
                            <a className="nav-link linkClass navLink" id={`ActiveId_${item.combineName}`} aria-current="page" href={item.menuUrl}>
                              {item.name}
                            </a>
                          }

                        </li>
                      ))
                    ) : (
                      (returData = (
                        <li className="nav-item dropdown mx-2" key={i} >
                          <a className="nav-link dropdown-toggle navLink ActiveId_Product" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {item.name}
                          </a>
                          <ul className="dropdown-menu dropdown-menuMain crsr-pointer" aria-labelledby="navbarDropdownMenuLink">
                            <div className="row w-100">
                              {/* <div className="col-md-4"> */}
                                {submenuitems.map(item => {
                                  var returData = (
                                    <div className={`col-md-4  ${item.status == 1 ? "" : "d-none"} `} key={item.id}>
                                      <li>
                                        <a className={`dropdown-item productsFont py-2 ${window.location.pathname == item.menuUrl ? "isProductActive" : ""}`}
                                          onClick={() => producturlopen(item.menuUrl, item.name, item.subMenuId, item.combineName)}>
                                          {item.name}
                                        </a>
                                      </li>
                                    </div>
                                  );
                                  return returData;
                                })}
                              {/* </div> */}
                              {/* <div className="col-md-4">
                                {submenuitems.slice(6, 15).map(item => {
                                  var returData = (
                                    <div className={`col-12  ${item.status == 1 ? "" : "d-none"} `} key={item.id}>
                                      <li>
                                        <a className={`dropdown-item productsFont py-2 ${window.location.pathname == item.menuUrl ? "isProductActive" : ""}`}
                                          onClick={() => producturlopen(item.menuUrl, item.name, item.subMenuId, item.combineName)}>
                                          {item.name}
                                        </a>
                                      </li>
                                    </div>
                                  );
                                  return returData;
                                })}
                              </div>
                              <div className="col-md-4">
                                {submenuitems.slice(15, 22).map(item => {
                                  var returData = (
                                    <div className={`col-12  ${item.status == 1 ? "" : "d-none"} `} key={item.id}>
                                      <li>
                                        <a className={`dropdown-item productsFont py-2 ${window.location.pathname == item.menuUrl ? "isProductActive" : ""}`}
                                          onClick={() => producturlopen(item.menuUrl, item.name, item.subMenuId, item.combineName)}>
                                          {item.name}
                                        </a>
                                      </li>
                                    </div>
                                  );
                                  return returData;
                                })}
                              </div> */}
                            </div>
                          </ul>
                        </li>
                      ))
                    )
                  ) : (
                    <></>
                  );

                  return returData;
                })}
                <li className="d-none mx-1 crsr-pointer mt-2" id="hdnSearchImage" onClick={showsearchbox}>
                  <a>
                    <img src={CDNURL + "Localimages/whiteSearch.svg"} className="img-fluid" alt="Search" />
                  </a>
                </li>
                <li className="d-none mx-1 mt-2" id="hdnCartImage" onClick={shoppingcartclick}>
                  <div className="posRel crsr-pointer shopCartImg">
                    <img src={CDNURL + "Localimages/shoppingcart_white.svg"} alt="Shopping Cart" />
                    <span className="notificationCountCircleWhite totalItemCart">0</span>
                  </div>
                </li>

              </ul>
            </div>

          </div>
        </nav>
      </div>
      <LiveChat />
    </>
  );
};
