import React from "react";
import { LeftMenu } from "./LeftMenuAdmin";
import { useState, useEffect } from "react";
import $ from "jquery";
import Pagination from '@material-ui/lab/Pagination';
import { ApiGetCall } from "../js/connector";
import { CDNURL } from "../js/constants";
import { VerifyLoginToken } from "../js/connector";
export function Customerpage() {
  const [ContactDetails, setContactDetails] = useState([]);
  const [overallstate, setoverallstate] = useState({});
  const [pagecount, setPageCount] = useState(0);
  const [norecord, setnorecord] = useState("");
  const [TempCheck, setTempCheck] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    VerifyLoginToken(GetContactDetails,null, 1);
    return () => {
      setoverallstate({});
    };
  }, []);
  const GetContactDetails = async (e, value) => {
    setTempCheck(true);
    var searchstr = $("#searchproducts").val();
    if (searchstr == "") {
      searchstr = null;
    }
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Banners/ContactDeatils/" + searchstr + "?pageNum=" + value + "&pageSize=100").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        var sugArray = [];
        var sugData = responseRs.contactDetails;
        if (responseRs.status == "success") {
          if (sugData.length != 0) {
            $("#paginationId").removeClass('d-none');
            setContactDetails(sugData);
            setPageCount(responseRs.pagecount);
            setnorecord("");
          } else {
            setContactDetails(sugData);
            setPageCount(responseRs.pagecount);
            sugArray.push(
              <div className="col-12 text-center mt-5">
                <label>No Record Found</label>
              </div>
            );
            $("#paginationId").addClass('d-none');
            setnorecord(sugArray);
          }
          if (searchstr != null) {
            $("#blacksearchloader").addClass('d-none');
            $("#closesearchicon").removeClass('d-none');

          } else {
            $("#blacksearchloader").addClass('d-none');
            $("#closesearchicon").addClass('d-none');
            $("#blacksearchicon").removeClass('d-none');
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  }
  const hiddensearchbox = () => {
    $("#blacksearchicon").removeClass('d-none');
    $("#searchproducts").val("");
    $("#blacksearchloader").addClass('d-none');
    $("#closesearchicon").addClass('d-none');
    GetContactDetails(null, 1);
  }
  const SearchProducts = () => {
    $("#blacksearchloader").removeClass('d-none');
    var typingTimer;
    var doneTypingInterval = 3000;
    clearTimeout(typingTimer);
    typingTimer = setTimeout(doneTyping, doneTypingInterval);
    $("#blacksearchicon").addClass('d-none');
    $("#closesearchicon").addClass('d-none');
  }
  const doneTyping = () => {
    GetContactDetails(null, 1);
  }
  return (
    <>
      {TempCheck == true ?
        <>
          <LeftMenu />
          <div fixed="true" className='container-fluid mainContainer'>
            <div className="content-wrapper" id="GridDivShow">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="cardDiv">
                      <div className="cardDiv-header">
                        <div className="row AlignItems">
                          <div className="col-md-8">
                            <h4>Customers</h4>
                          </div>
                          <div className="col-md-4 align-items-center d-flex ">
                            <div className="col-12" >
                              <div className="form-outline">
                                <div className="posRel ">
                                  <input type="text" className="form-control searchbox" placeholder="Search.."
                                    id="searchproducts" onKeyUp={SearchProducts} onPasteCapture={SearchProducts} onPaste={SearchProducts} />
                                  <img src={CDNURL + "Localimages/blackSearch.svg"} className="img-fluid searchicon" id="blacksearchicon" alt="Search" />
                                  <img src="/images/searchloader.gif" className="d-none img-fluid searchicon iconClass" id="blacksearchloader" alt="Loader" />
                                  <img src={CDNURL + "LocalImages/closeicon.png"} className="crsr-Pointer d-none img-fluid searchicon iconClass" id="closesearchicon" onClick={hiddensearchbox} alt="Close" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pl-3 pr-3">
                        <div className="col-12 gridHeader">
                          <div className="row">
                            <div className="col-3 text-center pe-0">
                              <b>Name</b>
                            </div>
                            <div className="col-3 text-center pe-0">
                              <b>Email</b>
                            </div>
                            <div className="col-2 text-center pe-0">
                              <b>Day Phone</b>
                            </div>
                            <div className="col-2 text-center">
                              <b>Evening Phone</b>
                            </div>
                            <div className="col-2 text-center ps-0"></div>
                          </div>
                        </div>
                        <div>
                          {ContactDetails.map((item, i) => {
                            var returData;
                            returData = (
                              <div key={i} className="col-12 newBrdrBtm p-2 fs-14">
                                <div className="row">
                                  <div className="col-3 text-center pe-0">
                                    {item.firstName} {item.lastName}
                                  </div>
                                  <div className="col-3 text-center pe-0">
                                    {item.email == "" ? (
                                      <label>-</label>
                                    ) : (
                                      <label>{item.email}</label>
                                    )}
                                  </div>
                                  <div className="col-2 text-center pe-0">
                                    {item.dayPhone == "" ? (
                                      <label>-</label>
                                    ) : (
                                      <label>{item.dayPhone}</label>
                                    )}
                                  </div>
                                  <div className="col-2 text-center pe-0">
                                    {item.nightPhone == "" ? (
                                      <label>-</label>
                                    ) : (
                                      <label>{item.nightPhone}</label>
                                    )}
                                  </div>
                                  <div className="col-2  pe-0 popover__wrapper">
                                    <div className="col-12 text-center">
                                      <a href="# ">
                                        <img
                                          src="./images/exclamation.svg"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                    <div className="popover__content">
                                      <span className="popover__message">
                                        <h5>Address:</h5> {item.address1}{" "}
                                        {item.address2}, {item.city} {item.state},{" "}
                                        {item.countryCode} - {item.zip}
                                        {(item.attention != null || item.attention != undefined) ?

                                          <>
                                            {(item.attention != "") ?
                                              <>
                                                <h5 className="pt-3">Comment: </h5>
                                                <span>{item.attention}</span>
                                              </>
                                              :
                                              <></>
                                            }
                                          </> :
                                          <></>
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                            return returData;
                          })}
                          {norecord}
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          <Pagination count={pagecount} onChange={GetContactDetails} id="paginationId" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <></>
      }
    </>
  );
}
