import React, { useState, useEffect } from "react";
import { CDNURL } from "../js/constants";
import { Footer } from "./Footer";
import { NavigationBar } from "./Navigationbar";
import Collapse from "react-bootstrap/Collapse";
import $ from 'jquery';
import { ApiGetCall } from "../js/connector";

export default function SiteMap() {
  const [state, setState] = useState({});
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [submenuitems, setSubMenuItems] = useState([]);
  useEffect(() => {
    myFunction();
    return () => {
      setState({});
    };
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/NavigationMenu/GetMenuItems").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        var submenu = responseRs.subMenuItems;
        if (responseRs.subMenuItems != "") {
          setSubMenuItems(submenu);
          console.log(submenu)
        }
      }
    });
  };
  const producturlopen = (url, itemname, itemid, staticpath) => {
    window.localStorage.setItem("useridordernow", itemid);
    window.localStorage.setItem("staticpath", staticpath);
    window.location = url;
    window.localStorage.removeItem("flag");
  }
  return (
    <div>
      <NavigationBar />
      <div className="container py-5 colorPara ps-0 liMar">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Site map</h1>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <div className="row mt-6 px-5">
          <div className="col-lg-6 m-auto">
            <div className="row">
              <div
                className="col-lg-6 m-auto p-4"
                style={{ border: "5px solid #8C4D36" }}
              >
                <img
                  src={CDNURL + "Localimages/Logo.svg"}
                  className="img-fluid"
                  alt="Logo"
                />
              </div>
              <div className="row ">
                <div className="col-lg-6 m-auto desktop">
                  <div class="vertical"></div>
                </div>
              </div>
            </div>

            <hr className="HR desktop" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 ">
            <div className="col-lg-6 col-10 Mmargin">
              <button
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="SitemapBtn"
              >
                Main Menu
                <img
                  className="ms-3"
                  src={`./images/${open ? "mapMinus" : "mapPlus"}.svg`}
                  alt="Main Menu"
                />
              </button>
              <Collapse in={open}>
                <div id="example-collapse-text">
                  <ul className="siteMapUL p-0">
                    <li>
                      <a href="/home">Home</a>
                    </li>
                    <button
                      onClick={() => setOpen2(!open2)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open2}
                      className="siteMapProBtn"
                    >
                      Products
                      <img
                        className="ms-3"
                        src={`./images/${open2 ? "mapminusSmall" : "mapPlusSmall"
                          }.svg`}
                        alt="Products"
                      />
                    </button>
                    <Collapse in={open2}>
                      <div id="example-collapse-text">
                        <ul className="siteMapLI">
                          {submenuitems.map((item, i) => {
                            var returData = (
                              <li className={`${item.status == 1 ? "" : "d-none"} `} key={item.id}>
                                <a onClick={() => producturlopen(item.menuUrl, item.name, item.subMenuId, item.combineName)}>{item.name}</a>
                              </li>
                            );
                            return returData;
                          })}
                          {/* <li>
                            <a href="/products/tables-pad">Table Pads</a>
                          </li>
                          <li>
                            <a href="/products/table-paddies">Table Paddies</a>
                          </li>
                          <li>
                            <a href="/products/table-extension-pad">
                              Table Extension Pad
                            </a>
                          </li>
                          <li>
                            <a href="/products/other-furniture-pads">
                              Other Furniture Pads
                            </a>
                          </li>
                          <li>
                            <a href="/products/storage-bags">Storage Bags</a>
                          </li>
                          <li>
                            <a href="/products/desk-pads">Desk Pads</a>
                          </li>
                          <li>
                            <a href="/products/custom-table-pad">
                              {" "}
                              Custom Table Pad
                            </a>
                          </li>
                          <li>
                            <a href="/products/custom-table-pads">
                              Custom Table Pads
                            </a>
                          </li>
                          <li>
                            <a href="/products/tables-pad">Tables Pad</a>
                          </li>
                          <li>
                            <a href="/products/tables-pads">Tables Pads</a>
                          </li>
                          <li>
                            <a href="/products/dining-room-table-pad">
                              {" "}
                              Dining Room Table Pad
                            </a>
                          </li>
                          <li>
                            <a href="/products/dining-table-pads">
                              {" "}
                              Dining Table Pads
                            </a>
                          </li>
                          <li>
                            <a href="/products/pads-for-tables">
                              Pads for Tables
                            </a>
                          </li>
                          <li>
                            <a href="/products/table-pad">Table Pad</a>
                          </li>
                          <li>
                            <a href="/products/best-table-pad">
                              Best Table Pad
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </Collapse>
                    <li>
                      {" "}
                      <a href="/colors">Colors</a>
                    </li>
                    <li>
                      {" "}
                      <a href="/table-cloth"> Table cloth</a>
                    </li>
                    <li>
                      {" "}
                      <a href="/how-to-measure-for-a-table-pad">
                        Measuring Your Table
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="/magnetic-locking-system">
                        Patented Magnetic Locks
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="/our-manufacturing-process">
                        Manufacturing Process
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      {" "}
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="col-lg-6 Dmarginl">
              <div className="desktop">
                <button
                  onClick={() => setOpen1(!open1)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open1}
                  className="SitemapBtn"
                >
                  <img
                    className="me-3"
                    src={`./images/${open1 ? "mapMinus" : "mapPlus"}.svg`}
                    alt="Additional Items"
                  />
                  Additional Items
                </button>
              </div>
              <div className="mobile CollapseBtn">
                <button
                  onClick={() => setOpen1(!open1)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open1}
                  className="SitemapBtn"
                >
                  Additional Items
                  <img
                    className="ms-3"
                    src={`./images/${open1 ? "mapMinus" : "mapPlus"}.svg`}
                    alt="Additional Items"
                  />
                </button>
              </div>
              <Collapse in={open1}>
                <div id="example-collapse-text">
                  <ul className="SecDivlist siteMapUL">
                    <li>
                      {" "}
                      <a href="/customized-monograms">Monograms</a>
                    </li>
                    <li>
                      {" "}
                      <a href="/become-a-dealer">Become a Dealer</a>
                    </li>
                    <li>
                      {" "}
                      <a href="/sitemap">Sitemap</a>
                    </li>
                    <li>
                      {" "}
                      <a href="/privacy">Terms of Service and Privacy Policy</a>
                    </li>
                    <li>
                      {" "}
                      <a href="/free-kit-request">Free Kit Request</a>
                    </li>
                    <li>
                      {" "}
                      <a href="/testimonials">Testimonials</a>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
