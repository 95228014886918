import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { CDNURL } from "../js/constants";
import { useEffect, useState } from "react";
import { ApiGetCall } from "../js/connector";
import $ from "jquery";
export default function PhotoModalPopup(props) {
  const [imageDetailsVar, setimageDetailsVar] = useState([]);
  const [overallstate, setoverallstate] = useState({});
  const [validExtension, setvalidExtension] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
    GetUploadImage();
    return () => {
      setoverallstate({});
    };
  }, []);
  const GetUploadImage = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/UploadImages/GetUploadedImages").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        var sugData = responseRs.uploadImagesDetails;
        var i = 1;
        if (responseRs.status == "success") {
          if (responseRs.uploadImagesDetails != "") {
            setimageDetailsVar(sugData);
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  };
  const MAX_WIDTH = 900;
  const MAX_HEIGHT = 1000;
  const MIME_TYPE = "image/jpeg";
  const QUALITY = 0.7;
  const imagesUpload = (e) => {
    const file = e.target.files;
    const extension = file[0].name;
    const extSplit = extension.split('.');
    var extensionName = extSplit[1];
    if (extensionName == "png" || extensionName == "jpg" || extensionName == "jpeg" || extensionName == "svg") {
      $.each(file, function (i, v) {
        var n = i + 1;
        const blobURL = URL.createObjectURL(file[i]);
        const img = new Image();
        img.src = blobURL;
        img.onload = function (event) {
          URL.revokeObjectURL(this.src);
          const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          canvas.toBlob(
            (blob) => {
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function (e) {
                var base64data = reader.result;
                $("<img/>")
                  .attr({
                    src: CDNURL + "LocalImages/closeicon.png",
                    class: "closeBtnImg ",
                    dataid: "imgId_" + n,
                    alt: "dummy"
                  })
                  .appendTo("#imgbothappend");
                $("<img/>")
                  .attr({
                    src: URL.createObjectURL(blob),
                    class: "computerimgs photomodalimg posRel m-2 imgId_" + n,
                    id: "imgId_" + n,
                    base64: base64data,
                    alt: "dummy"
                  })
                  .appendTo("#imgbothappend");
                $(".closeBtnImg").click(function () {
                  var getid = $(this).attr("dataid");
                  $(this).remove();
                  $(`#${getid}`).remove();
                });
              };
            },
            MIME_TYPE,
            QUALITY
          );
        };
      });
      $("#customFile").val("");
      setvalidExtension("");
    }
    else {
      setvalidExtension("File Format Not Supported: ." + extensionName);
    }
  };

  const calculateSize = (img, maxWidth, maxHeight) => {
    let width = img.width;
    let height = img.height;
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }
    return [width, height];
  };
  const getBase64Image = (index) => {
    if ($("#img_" + index).hasClass("myimgredborder")) {
      $("#img_" + index).css("border", "none");
      $("#img_" + index).css("padding", "0px");
      $("#img_" + index).removeClass("myimgredborder");
    } else {
      $("#img_" + index).css("border", "2px solid red");
      $("#img_" + index).css("padding", "5px");
      $("#img_" + index).addClass("myimgredborder");
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        size="lg"
        onHide={() => props.onHide({ msg: "Cross Icon Clicked!" })}
        id="MyModal"
      >
        <Modal.Header closeButton className={`modalHead ${props.ImgCloseBtn}`}>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Upload Images</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="px-5 text-center">
              <label className="dragFileContainer text-center" htmlFor="customFile">
                <input type="file" multiple className="form-control d-none"
                  onChange={imagesUpload} id="customFile" accept=".png, .jpg, .jpeg, .svg" />
                <img id="spaceImageUpload" src="" class="spaceImageRadius w-100 d-none" alt="your image" width="100" />
                <p id="TempText" className="pt-4">
                  <svg className="mb-3" xmlns="http://www.w3.org/2000/svg" width="51" height="41" viewBox="0 0 51 41" fill="none">
                    <path d="M25.8587 17.019C25.8161 16.9645 25.7617 16.9205 25.6996 16.8902C25.6374 16.86 25.5692 16.8442 25.5001 16.8442C25.431 16.8442 25.3628 16.86 25.3007 16.8902C25.2385 16.9205 25.1841 16.9645 25.1415 17.019L18.7665 25.0845C18.714 25.1516 18.6814 25.2321 18.6724 25.3169C18.6635 25.4017 18.6786 25.4872 18.716 25.5638C18.7534 25.6404 18.8116 25.705 18.884 25.75C18.9563 25.7951 19.0399 25.8189 19.1251 25.8187H23.3315V39.6161C23.3315 39.8665 23.5364 40.0714 23.7868 40.0714H27.202C27.4525 40.0714 27.6574 39.8665 27.6574 39.6161V25.8244H31.8751C32.2565 25.8244 32.4671 25.3861 32.2337 25.0902L25.8587 17.019Z" fill="black" fill-opacity="0.5"></path>
                    <path d="M42.5417 11.7653C39.9348 4.8894 33.2923 0 25.5114 0C17.7305 0 11.0879 4.88371 8.48103 11.7596C3.60301 13.0403 0 17.4857 0 22.7679C0 29.0575 5.09431 34.1518 11.3782 34.1518H13.6607C13.9112 34.1518 14.1161 33.9469 14.1161 33.6964V30.2812C14.1161 30.0308 13.9112 29.8259 13.6607 29.8259H11.3782C9.46004 29.8259 7.65569 29.0632 6.31239 27.68C4.97478 26.3026 4.26328 24.447 4.32589 22.5231C4.37712 21.0204 4.8894 19.6088 5.81719 18.4192C6.76775 17.2068 8.09967 16.3246 9.57958 15.9318L11.7368 15.3683L12.528 13.285C13.0175 11.9873 13.7006 10.7749 14.56 9.67634C15.4086 8.58751 16.4137 7.63036 17.5426 6.83605C19.882 5.19107 22.6369 4.3202 25.5114 4.3202C28.3858 4.3202 31.1407 5.19107 33.4801 6.83605C34.6128 7.63292 35.6146 8.58917 36.4627 9.67634C37.3222 10.7749 38.0052 11.993 38.4948 13.285L39.2802 15.3626L41.4318 15.9318C44.5169 16.7628 46.6741 19.569 46.6741 22.7679C46.6741 24.6519 45.9398 26.4278 44.6079 27.7597C43.9547 28.4167 43.1777 28.9376 42.3218 29.2923C41.4659 29.647 40.5482 29.8283 39.6218 29.8259H37.3393C37.0888 29.8259 36.8839 30.0308 36.8839 30.2812V33.6964C36.8839 33.9469 37.0888 34.1518 37.3393 34.1518H39.6218C45.9057 34.1518 51 29.0575 51 22.7679C51 17.4914 47.4084 13.0517 42.5417 11.7653Z" fill="black" fill-opacity="0.5"></path>
                  </svg><br />
                  Drop your image here or click to upload.</p>
              </label>
            </div>
            <h6 className="txtGreen pt-3 ps-5">Supported image formats : .jpg, .jpeg, .png, .svg</h6>
          </div>
          <div id="imgbothappend" className="text-center"></div>
          <h5 className="txtRed ps-5 pt-3">{validExtension}</h5>
          <hr />
          <div className="scrolly-50">
            <div className="text-center">
              <h4>Choose from Gallery</h4>
              {imageDetailsVar.map((item, i) => {
                var returData;
                var profileImage = CDNURL + "Gallery/" + item.imageUrl;
                returData = (
                  <img
                    src={profileImage}
                    id={`img_${i}`}
                    alt="dummy"
                    className="photomodalimg mx-3 my-2"
                    key={i}
                    onClick={(e) => getBase64Image(i)}
                  />
                );
                return returData;
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <span className="me-3 crsr-Pointer" onClick={() => props.onHide()}>close</span>
          <button type="submit" className="loginBtn" onClick={() => props.onClick()}>Save Images</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
