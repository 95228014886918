import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import React, { useEffect, useState, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import { ApiPostCall } from "../js/connector";
import { CDNURL } from "../js/constants";
import { ApiGetCall } from "../js/connector";

export default function Contact(props) {
  $("#yourElementId").text("Contact Berger's Table Pad Factory");
  const myRef = useRef(null);
  const [overallstate, setoverallstate] = useState({});
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [dayphone, setDayphone] = useState("");
  const [nightphone, setNightphone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [comments, setComments] = useState("");
  const sitekeyVal = process.env.REACT_APP_Google_ReCaptcha_SiteKey;
  const [blankCaptcha, setblankCaptcha] = useState("");
  const [sectionContact, setsectionContact] = useState("");

  const [blankFname, setBlankFname] = useState("");
  const [blankLname, setBlankLname] = useState("");
  const [blankEmail, setBlankEmail] = useState("");
  const [blankAddress, setBlankAddress] = useState("");
  const [blankCity, setBlankCity] = useState("");
  const [blankZip, setBlankZip] = useState("");
  const [blankOverall, setBlankOverall] = useState("");
  useEffect(() => {
    myFunction();
    return () => {
      setoverallstate({});
    };
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[19].value);
        setsectionDesciption(propDetails[20].value);
        setsectionCTAtext(propDetails[21].value);
        setsectionContact(propDetails[26].value);
      }
    });
  };
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }

  const SaveContact = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    var temp = 0;
    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    var humanKey = $("#hdnHumanKey").val();
    {
      if (fname == "" || fname.length >= 100) {
        setBlankFname("Invalid Input");
      } else {
        setBlankFname("");
        temp++;
      }

      if (lname == "" || lname.length >= 100) {
        setBlankLname("Invalid Input");
      } else {
        setBlankLname("");
        temp++;
      }

      if (email == "" || email.length >= 100) {
        setBlankEmail("Invalid Input");
      } else {
        if (!pattern.test(email)) {
          setBlankEmail("Enter Valid Email");
        } else {
          setBlankEmail("");
          temp++;
        }

      }

      if (address == "" || address.length >= 250) {
        setBlankAddress("Invalid Input");
      } else {
        setBlankAddress("");
        temp++;
      }

      if (city == "" || city.length >= 50) {
        setBlankCity("Invalid Input");
      } else {
        setBlankCity("");
        temp++;
      }

      if (zipcode == "") {
        setBlankZip("Invalid Input");
      } else {
        setBlankZip("");
        temp++;
      }

      if (humanKey == "") {
        setblankCaptcha("Before you proceed, please complete the captcha");
      } else {
        setblankCaptcha("");
        temp++;
      }

    }
    if (temp >= 7) {
      setBlankOverall("");
      var splitAdd = address.split(",");
      var address1 = splitAdd[0];
      var address2 = splitAdd[1];
      var Stateid = $("#StateId option:selected").val();
      var countrycode = "United States";
      var utmSourcesession = sessionStorage.getItem('utm_source');
      if (utmSourcesession == null) {
        utmSourcesession = null;
      }
      var raw = JSON.stringify({
        UTM_Source: utmSourcesession,
        FirstName: fname,
        LastName: lname,
        Email: email,
        DayPhone: dayphone,
        NightPhone: nightphone,
        Address1: address1,
        Address2: address2,
        City: city,
        State: Stateid,
        Zip: zipcode,
        CountryCode: countrycode,
        Attention: comments,
        GoogleCaptcha: humanKey
      });
      await ApiPostCall("/Contactus/SaveContactus", raw).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          $("#Overlay").hide();
          $("#LoderId").hide();
          const responseRs = JSON.parse(result);
          if (responseRs.status == "Success") {
            window.location = "/thank-you";
          } else {
            $(".alert-danger").show();
            $("#AlertDangerMsg").text(responseRs.message);
            setTimeout(function () {
              $(".alert-danger").hide();
              $("#AlertDangerMsg").text();
            }, 1500);
          }
        }
      });
    } else {
      $("#Overlay").hide();
      $("#LoderId").hide();
      window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop });
      setBlankOverall("Please complete all required fields!");
    }
  };

  const something = () => {
    if (fname != "") {
      setBlankFname("");
    }

    if (lname != "") {
      setBlankLname("");
    }

    if (email != "") {
      setBlankEmail("");
    }

    if (address != "") {
      setBlankAddress("");
    }

    if (city != "") {
      setBlankCity("");
    }

    if (zipcode != "") {
      setBlankZip("");
    }
  };
  function onChange(value) {
    const humanKey = value;
    $("#hdnHumanKey").val(humanKey);
  }

  return (
    <>
      <NavigationBar />
      <input type="hidden" id="hdnHumanKey" />
      <div className="container-fluid px-0  colorHeroSection">
        <div className="row">
          <div className="col-md-6 pt-4 pb-3 text-center">
            <img src={CDNURL + "Localimages/ContactUsGroup.png"} className="img-fluid text-end" alt="Hero Image"/>
          </div>
          <div className="col-md-6 m-auto pt-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More"/>
            </button>
          </div>

        </div>
      </div>
      <div className="container py-5" ref={myRef} >
        <ul className="fs-18 font-weight-bold">
          <li className="pt-2 liBullet ps-2">
            If you have any questions regarding our custom table pads or
            custom table cloth, please fill out the form below or contact
            our office at <a className="paraLink" href="tel:+18007372165">1-800-737-2165</a>. If you
            are interested in becoming a dealer for our products follow this
            link{" "}
            <a className="paraLink" href="/become-a-dealer">
              for more information
            </a>
            .
          </li>
        </ul>
      </div>
      <div className="container px-5 pb-5" >
        <h6 className="pb-3 txtRed">{blankOverall}</h6>
        <div className="col-12">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="row">
                <div className="col-md-6 col-12 mb-4">
                  <label for="firstname">
                    First Name
                    <span className="txtRed">*</span>
                  </label>
                  <input
                    name="firstname"
                    type="text"
                    className="form-control formError"
                    value={fname}
                    onKeyDown={(e) => something(e)}
                    onChange={(e) => setFname(e.target.value)}
                  />
                  <span className="txt9d0909">{blankFname}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <label for="lastname">
                    Last Name
                    <span className="txtRed">*</span>
                  </label>
                  <input
                    name="lastname"
                    type="text"
                    className="form-control formError"
                    value={lname}
                    onKeyDown={(e) => something(e)}
                    onChange={(e) => setLname(e.target.value)}
                  />
                  <span className="txt9d0909">{blankLname}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <label for="email">
                    Email Address
                    <span className="txtRed">*</span>
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control formError"
                    value={email}
                    onKeyDown={(e) => something(e)}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="txt9d0909">{blankEmail}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <label for="dayphone">Day Phone</label>
                  <input
                    name="dayphone"
                    type="text"
                    className="form-control formError"
                    value={dayphone}
                    onChange={(e) => setDayphone(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <label for="eveningphone">Evening Phone</label>
                  <input
                    name="eveningphone"
                    type="text"
                    className="form-control formError"
                    value={nightphone}
                    onChange={(e) => setNightphone(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <label for="address">
                    Address
                    <span className="txtRed">*</span>
                  </label>
                  <textarea
                    name="address"
                    rows="1"
                    className="w-100 form-control formError"
                    value={address}
                    onKeyDown={(e) => something(e)}
                    onChange={(e) => setAddress(e.target.value)}
                  ></textarea>
                  <span className="txt9d0909">{blankAddress}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <label for="city">
                    City
                    <span className="txtRed">*</span>
                  </label>
                  <input
                    name="city"
                    type="text"
                    className="form-control formError"
                    value={city}
                    onKeyDown={(e) => something(e)}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <span className="txt9d0909">{blankCity}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <label for="state">
                    State
                    <span className="txtRed">*</span>
                  </label>
                  <select id="StateId" className="form-select formError" name="state">
                    <option value="AL">AL</option>
                    <option value="AK">AK</option>
                    <option value="AZ">AZ</option>
                    <option value="AR">AR</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="IA">IA</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="ME">ME</option>
                    <option value="MD">MD</option>
                    <option value="MA">MA</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MS">MS</option>
                    <option value="MO">MO</option>
                    <option value="MT">MT</option>
                    <option value="NE">NE</option>
                    <option value="NV">NV</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NY">NY</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VT">VT</option>
                    <option value="VA">VA</option>
                    <option value="WA">WA</option>
                    <option value="WV">WV</option>
                    <option value="WI">WI</option>
                    <option value="WY">WY</option>
                  </select>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <label for="zipcode">
                    Zip Code
                    <span className="txtRed">*</span>
                  </label>
                  <input
                    name="zipcode"
                    type="text"
                    className="form-control formError"
                    value={zipcode}
                    onKeyDown={(e) => something(e)}
                    onChange={(e) => setZipcode(e.target.value)}
                  />
                  <span className="txt9d0909">{blankZip}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <label for="country">
                    Country
                    <span className="txtRed">*</span>
                  </label>
                  <input
                    name="country"
                    type="text"
                    className="form-control"
                    value="United States"
                    disabled
                  />
                </div>
                <div className="col-12 mb-4">
                  <label for="comments">Comments</label>
                  <textarea
                    name="comments"
                    rows="3"
                    className="w-100 form-control formError"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
                <div className="col-12 mb-4">
                  <ReCAPTCHA sitekey={sitekeyVal} onChange={onChange} />
                  <span className="txt9d0909">{blankCaptcha}</span>
                </div>
                <div className="col-12 text-center pb-5">
                  <div className="col-md-2 col-12 offset-md-5">
                    <button
                      type="submit"
                      className="loginBtn"
                      onClick={SaveContact}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="pt-3 text-center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50542570.68410126!2d-163.68518085293567!3d39.35909668542353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886b5745b52f52dd%3A0x983a5b915f1917c4!2sBerger&#39;s%20Table%20Pad%20Factory!5e0!3m2!1sen!2sin!4v1649237929506!5m2!1sen!2sin"
                  width="100%"
                  height="500"
                  allowFullScreen=""
                  loading="lazy"
                  title="Google Map"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
