import React from "react";
import { NavigationBar } from "../Navigationbar";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import { Footer } from "../Footer";
import { ApiGetCall } from "../../js/connector";
import ImgData from "../components/ImgData";
import { CDNURL } from "../../js/constants";
import $ from "jquery";
import { useEffect, useState } from "react";
import HelmetMetaTag from "../components/HelmetMetaTag";

export default function TablePaddies() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  useEffect(() => {
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[37].value);
        setsectionDesciption(propDetails[38].value);
        setsectionCTAtext(propDetails[39].value);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  return (
    <>
      <HelmetMetaTag
        title="Table Paddies | Mini Table Pads from Berger's"
        desc="Our Table Paddies™ are designed to be a high quality miniature table pad to protect your tables as well as a placemat or portable tv tray. Contact us !"
      />
      <NavigationBar />
      <div className="container-fluid px-0  tablepaddiesSection">
        <div className="row">
          <div className="col-md-6 m-auto pt-3 pb-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More" />
            </button>
          </div>
          <div className="col-md-6 pb-3 text-center">
            <img src={CDNURL + "Localimages/TablePaddiesNewGroup.png"} className="img-fluid text-end" alt="Hero Image" />
          </div>
        </div>
      </div>
      <div className="container py-5 colorPara ps-0" id="GotoNavbar">
        <ul>
          <li className=" liBullet ps-2">
            Our Table Paddies™ are ideal for usage as a small <a className="paraLink" href="/products/table-pads">table pad</a>, a
            placemat, or a party or tv tray...all in one! Table Paddies™ make a
            terrific gift.
          </li>
          <li className="pt-4 liBullet ps-2">
            Table Paddies™ are rectangular, oval, and round shaped, available in our Chestnut top  <a className="paraLink" href="/colors">color</a> and velvet brown bottom <a className="paraLink" href="/colors">color.</a> They are constructed of 1/2" <a className="paraLink" href="/products/table-pads">table pad</a> material.
          </li>
          <li className="pt-4 liBullet ps-2">
            If you've got any questions about the Paddies, please do not
            hesitate to{" "}
            <a className="paraLink" href="/contact">
              contact us
            </a>{" "}
            for more information.
          </li>
        </ul>
      </div>

      <div className="container pb-5">
        <div className="row">
          <div className="col-lg-6 ">
            <ImgData
              img1="TablePaddiesImg1"
              shadowImg="TablePaddies-shadow"
              id="tpdd-img"
              className="img-fluid"
              // mt=""
              listData=""
              dataDesign2="true"
              popupImg="TablePaddiesImg-big"
            />
          </div>
          <div className="col-lg-6 pt-5">
            <ul>
              <li className=" liBullet ps-2 h5">
                Protects your table (same as <a className="paraLink" href="/products/table-pads">table pad</a>) while still showing its
                beauty!
              </li>
              <li className=" pt-2 liBullet ps-2 h5">
                Great for parties (what a conversation piece)!
              </li>
              <li className=" pt-2 liBullet ps-2 h5">
                Create elegance in your formal place settings!
              </li>
              <li className=" pt-2 liBullet ps-2 h5">
                Ideal for informal meals as well (when the occasion doesn't
                warrant full use of <a className="paraLink" href="/products/table-pads">table pad</a>)!
              </li>
              <li className=" pt-2 liBullet ps-2 h5">
                Keep your table cloth clean!
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* what our clients think start*/}
      <WhatCustomerThink isH1Tag="no" />
      {/* what our clients think end*/}
      <Footer />
    </>
  );
}
