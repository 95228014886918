import { useState, useEffect } from 'react';
import $ from 'jquery';
import React from "react";
import { LeftMenu } from './LeftMenuAdmin';
import { ApiGetCall } from '../js/connector';
import { VerifyLoginToken } from '../js/connector';
export function CustomizeFaq() {
    const [overallstate, setoverallstate] = useState({});
    const [faqData, setFaqData] = useState([]);
    const [TempCheck, setTempCheck] = useState(false);
    useEffect(() => {
        window.scroll(0, 0);
        VerifyLoginToken(GetFaq);
        return () => {
            setoverallstate({});
        };
    }, []);
    const GetFaq = async () => {
        setTempCheck(true);
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/faq/getfaqs").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.data;
                if (responseRs.data != "") {
                    setFaqData(sugData);
                }
            }
        });
    }
    return (
        <>
            {TempCheck == true ?
                <>
                    <LeftMenu />
                    <div fixed="true" className='container-fluid mainContainer'>
                        <div className='content-wrapper' id="GridDivShow">
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='cardDiv'>
                                            <div className='cardDiv-header'>
                                                <div className='row AlignItems'>
                                                    <div className='col-md-4'>
                                                        <h4>Edit FAQs</h4>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <div className="card-action " id="changeOrderHdnBtn">
                                                            <a href='/FaqAddEdit/0'>
                                                                <button type="submit" className="loginBtn btnhover" >Add New</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-body pl-3 pr-3'>
                                                <div className='col-12 gridHeader'>
                                                    <div className='row'>
                                                        <div className='col-1'>
                                                        </div>
                                                        <div className='col-9 pe-0'>
                                                            <b>Questions</b>
                                                        </div>
                                                        <div className='col-2 text-center'>
                                                            <b>Actions</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='scrolly-63'>
                                                    {faqData.map((item, i) => {
                                                        var returData;
                                                        returData = <div key={i} className="col-12 grid draggableGrid" rowcount={i + 1} id={`MyClass_${item.id}`}>
                                                            <div className='row'>
                                                                <div className='col-1'>
                                                                    <img src="./images/dragdrop.png" alt='Drag Icon' title="Drag to reorder" className='w-25 d-none dragdropdotsimg' />
                                                                </div>
                                                                <div className='col-9 pe-0'>
                                                                    {item.question}
                                                                </div>
                                                                <div class="col-2 text-center">
                                                                    <span>
                                                                        <a href={`FaqAddEdit/${item.id}`}>
                                                                            <img src="/images/editIcon.svg" alt='Edit Icon' title="Edit FAQ" className="iconClass me-1 m-2" data-id={`${item.id}`} />
                                                                        </a>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        return returData;
                                                    }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <></>
            }
        </>
    )
}