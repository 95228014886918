import { CDNURL } from "../js/constants";
import { useState, useEffect } from "react";
import React from "react";
import $ from 'jquery';
export function LiveChat() {
    const [overallstate, setoverallstate] = useState({});
    var isFreeKitVisible = "";
    var currentUrl = window.location.pathname;
    var width = $(window).width();
    if (currentUrl == '/free-kit-request') {
        isFreeKitVisible = "d-none";
    }
    useEffect(() => {
        window.scroll(0, 0);
        return () => {
            setoverallstate({});
        };
    }, []);
    const livechatclick = () => {
        window.open("https://chatserver.comm100.com/chatWindow.aspx?siteId=118577&planId=a8010000-0000-0000-0000-008e0001cf31#", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=10,left=900,width=400,height=600");
    }
    const freekitclick = () => {
        window.open("/free-kit-request");
    }
    return (
        <>
            <div id="comm100-float-button-2">
                <div className={`FreeKitimg crsr-pointer ${isFreeKitVisible}`} onClick={freekitclick}>
                    {width <= 991 ?
                        <span><span className="freeColor">FREE</span> samples</span>
                        :
                        <span><span className="freeColor">FREE</span> samples click here</span>
                    }
                </div>
            </div>
            {/* <div id="comm100-float-button-2">
                <div className="livechatimg crsr-pointer" onClick={livechatclick}>
                    <img src={CDNURL + "Localimages/livechat.png"} alt="Chat button, agent offline" title="Live Chat" />
                </div>
            </div> */}
        </>
    )
}