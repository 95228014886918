import { useState, useEffect } from "react";
import $ from "jquery";
import React from "react";
import Example from "./ckeditor";
import { LeftMenu } from "./LeftMenuAdmin";
import { ApiGetCall, ApiPostCall } from "../js/connector";
import { CDNURL } from "../js/constants";
import { VerifyLoginToken } from "../js/connector";
export function AddEditFaq() {
  const [overallstate, setoverallstate] = useState({});
  const [faqData, setFaqData] = useState([]);
  const [title, addTitle] = useState("");
  const [neweditorState, setTopDescription] = useState("");
  const [faqIdVal, setFaqId] = useState(0);
  const [blankquestion, setblankquestion] = useState("");
  const [blankanswer, setblankanswer] = useState("");
  const [TempCheck, setTempCheck] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    VerifyLoginToken(GetFaq);
    return () => {
      setoverallstate({});
    };
  }, []);
  const GetFaq = async () => {
    setTempCheck(true);
    var faqId = window.location.pathname.toLowerCase();
    faqId = faqId.replace("/faqaddedit/", "");
    setFaqId(faqId);
    $("#Overlay").show();
    $("#LoderId").show();

    if (faqId == "0") {
      addTitle("Add FAQ");
      var newOb = new Object();
      newOb.question = "";
      setFaqData(newOb);
      setTopDescription(<Example content={``} />);
      $("#Overlay").hide();
      $("#LoderId").hide();
    } else {
      addTitle("Update FAQ");
      await ApiGetCall(`/faq/getfaq/${faqId}`).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          var responseRs = JSON.parse(result);
          $("#Overlay").hide();
          $("#LoderId").hide();
          var sugData = responseRs.data;
          if (responseRs.data != "") {
            setFaqData(sugData);
          }
          setTopDescription(<Example content={sugData.answer} />);
          $("#hdnEditorField").val(sugData.answer);
        }
      });
    }
  };

  const saveFaq = async () => {
    var temp = 0;

    $("#Overlay").show();
    $("#LoderId").show();
    var faqOb = new Object();
    faqOb.id = faqIdVal;
    faqOb.question = $('.faq-question-element').val();
    faqOb.answer = $('#hdnEditorField').val();
    if (faqOb.question == "") {
      setblankquestion("Required!");
    } else {
      setblankquestion("");
      temp++;
    }

    if (faqOb.answer == "") {
      setblankanswer("Required!");
    } else {
      setblankanswer("");
      temp++;
    }
    var raw = JSON.stringify(faqOb);
    if (temp >= 2) {
      await ApiPostCall("/faq/AddFaq", raw).then((result) => {
        window.location.href = "/CustomizeFaq";
        $("#Overlay").hide();
        $("#LoderId").hide();
      });
    } else {
      $("#Overlay").hide();
      $("#LoderId").hide();
    }
  }
  const CancelCustom = () => {
    window.location = "/CustomizeFaq";
  }
  return (
    <>
      {TempCheck == true ?
        <>
          <LeftMenu />
          <div fixed="true" className="container-fluid mainContainer">
            <div className="content-wrapper" id="GridDivShow">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="cardDiv">
                      <div className="cardDiv-header">
                        <div className="row AlignItems">
                          <div className="col-1and5 text-center">
                            <a href="/CustomizeFaq">
                              <img src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" alt="Back" />
                            </a>
                          </div>
                          <div className="col-11 ps-0 mt-2">
                            <h4>
                              {title}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pl-3 pr-3">
                        <div className="row">
                          <div className="col-12 pt-3 ps-4">
                            <div className="row">
                              <div className="col-12">
                                <input
                                  type="text"
                                  className="form-control faq-question-element"
                                  placeholder="Enter Question"
                                  defaultValue={faqData.question}
                                />
                              </div>
                              <span style={{ color: "red" }}>
                                {blankquestion}
                              </span>
                              <div className="col-12 mt-4">
                                <h4>Answer</h4>
                              </div>
                              <div className="col-12">
                                {neweditorState}
                              </div>
                              <span style={{ color: "red" }}>
                                {blankanswer}
                              </span>
                              <div className="col-12 py-5 text-end">
                                <span className="me-3 crsr-Pointer" onClick={CancelCustom}>Cancel</span>
                                <button type="submit" className="loginBtn" onClick={saveFaq}>
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <></>
      }
    </>
  );
}
