import { LeftMenu } from "./LeftMenuAdmin"
import $ from "jquery";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ApiGetCall } from "../js/connector";
import { ApiPutCall } from "../js/connector";
import { CDNURL } from "../js/constants";
import Example from "./ckeditor";
import { VerifyLoginToken } from "../js/connector";
const Customization = () => {
    const [overallstate, setoverallstate] = useState({});
    const [PropertyItems, setPropertyItems] = useState([]);
    const [propname, setpropname] = useState("");
    const [proptype, setproptype] = useState("");
    const [propvalue, setpropvalue] = useState("");
    const [neweditorState, setneweditorState] = useState("");
    const [blankpropvalue, setblankpropvalue] = useState("");
    const [TempCheck, setTempCheck] = useState(false);
    useEffect(() => {
        window.scroll(0, 0);
        VerifyLoginToken(showhidedivfunc);
        return () => {
            setoverallstate({});
        };
    }, []);
    const showhidedivfunc = () => {
        setTempCheck(true);
        var PathName = window.location.pathname;
        var pathsplit = PathName.split('/');
        var prodActive = pathsplit[2];
        if (prodActive == "UpdateCustomProperty") {
            EditCustomProperty();
        }
        else {
            myFunction();
        }
    }
    const myFunction = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                setPropertyItems(responseRs.propertyDetails);
            }
        });
    };
    const EditOptionsDiv = (e) => {
        var itemid = parseInt(e.currentTarget.attributes[1].value);
        window.location.href = "/Customization/UpdateCustomProperty/?id=" + itemid;
    };
    const EditCustomProperty = async () => {
        var vId = parseInt(getUrlParameter("id"));
        $("#HdnUserId").val(vId);
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Customization/GetPropertyUpdateDetails/" + vId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                var updateProp = responseRs.customDetails;
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "Success") {
                    $("#GridDivShow").addClass("d-none");
                    $("#UpdatePropertyDivShow").removeClass("d-none");
                    setpropname(updateProp.name);
                    setproptype(updateProp.type);
                    if (updateProp.type == 3) {
                        setneweditorState(<Example content={updateProp.value} />);
                        $("#hdnEditorField").val(updateProp.value);
                        setpropvalue(updateProp.value);
                    } else {
                        setpropvalue(updateProp.value);
                    }
                }
            }
        });
    }
    const MAX_WIDTH = 300;
    const MAX_HEIGHT = 400;
    const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.7;
    const imagesUpload = (e) => {
        const file = e.target.files;
        $.each(file, function (i, v) {
            var n = i + 1;
            const blobURL = URL.createObjectURL(file[i]);
            const img = new Image();
            img.src = blobURL;
            img.onload = function (event) {
                URL.revokeObjectURL(this.src);
                const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(
                    (blob) => {
                        var reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = function (e) {
                            var base64data = reader.result;
                            $("#imgbothappend").html("");
                            $("<img/>")
                                .attr({
                                    src: URL.createObjectURL(blob),
                                    id: "imgId_" + n,
                                    base64: base64data,
                                })
                                .appendTo("#imgbothappend");
                            setpropvalue(base64data);
                        };
                    },
                    MIME_TYPE,
                    QUALITY
                );
            };
        });
    };

    const calculateSize = (img, maxWidth, maxHeight) => {
        let width = img.width;
        let height = img.height;
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
        }
        return [width, height];
    };
    const CancelCustom = () => {
        window.location = "/Customization";
    }
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };
    const UpdateCustomProperty = async () => {
        var temp = 0;
        var itemid = parseInt($("#HdnUserId").val());
        if (propvalue == "") {
            setblankpropvalue("Required!");
        } else {
            setblankpropvalue("");
            temp++;
        }

        if (temp >= 1) {
            $("#Overlay").show();
            $("#LoderId").show();
            var propertyvalue = "";
            if (proptype == 3) {
                propertyvalue = $("#hdnEditorField").val();
            } else if (proptype == 4) {
                var parseimg = propvalue.split(",");
                var profileimg = parseimg[1];
                propertyvalue = profileimg;
            } else {
                propertyvalue = propvalue;
            }
            var raw = JSON.stringify({
                Name: propname,
                Type: proptype,
                Value: propertyvalue
            });
            await ApiPutCall("/Customization/UpdateCustomProperty/" + itemid, raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                    $("#Overlay").show();
                    $("#LoderId").show();
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Property Updated Succesfully.");
                        setTimeout(function () {
                            window.location = "/Customization";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }


    }
    return (
        <>
            {TempCheck == true ?
                <>
                    <input type="hidden" id="HdnUserId" />
                    <LeftMenu />
                    <div fixed="true" className='container-fluid mainContainer'>
                        <div className="content-wrapper" id="GridDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv">
                                            <div className="cardDiv-header">
                                                <div className="row AlignItems">
                                                    <div className="col-12">
                                                        <h4>Customize Property</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 gridHeader">
                                                    <div className="row">
                                                        <div className="col-5 pe-0">
                                                            <b>Property Name</b>
                                                        </div>
                                                        <div className="col-6 pe-0">
                                                            <b>Property Value</b>
                                                        </div>
                                                        <div className="col-1 text-center ps-0">
                                                            <b>Action</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="scrolly-63">
                                                    {PropertyItems.map((item, i) => {
                                                        var returData;
                                                        returData = (<div className="col-12 grid" key={i}>
                                                            <div className="row" key={i}>
                                                                <div className="col-5 mb-2 textWrap">
                                                                    {item.name}
                                                                </div>
                                                                {item.type == 4 ?
                                                                    <div className="col-6 textWrap">
                                                                        {CDNURL + "Gallery/" + item.value}
                                                                    </div> :
                                                                    <div className="col-6 textWrap d-flex myClass" dangerouslySetInnerHTML={{ __html: item.value }}>
                                                                    </div>
                                                                }
                                                                <div className="col-1 text-center">
                                                                    <span>
                                                                        <img src="/images/editIcon.svg" itemid={item.id} title="Edit Option" alt="Edit Icon" className="iconClass me-1" onClick={EditOptionsDiv} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        );
                                                        return returData;
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="UpdatePropertyDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/Orderproperties">
                                                            <img src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" alt="Back" />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Customize Property
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="row">
                                                    <div className="col-md-6 col-12 mb-4">
                                                        <input type="text" className="form-control" defaultValue={propname} disabled />
                                                        <span className="grayTxt">Property Name</span><br />
                                                    </div>
                                                    {proptype == 1 || proptype == 2 ?
                                                        <div className="col-md-6 col-12 mb-4">
                                                            <input type="text" className="form-control" defaultValue={propvalue} placeholder="Property Value" onChange={(e) => setpropvalue(e.target.value)} />
                                                            <span className="grayTxt">Property Value</span>
                                                        </div>
                                                        : (proptype == 3) ?
                                                            <>
                                                                {neweditorState}
                                                                <span className="grayTxt">Property Value</span>
                                                            </>
                                                            :
                                                            <div className="col-11 offset-1">
                                                                <div id="imgbothappend">
                                                                    <label className="mt-4 text-center" htmlFor="customFile">
                                                                        <input type="file" multiple className="form-control d-none"
                                                                            onChange={imagesUpload} id="customFile" accept=".png, .jpg, .jpeg, .svg" />
                                                                        <img src={CDNURL + "Gallery/" + propvalue} className="img-fluid" alt="Image" /><br />
                                                                    </label><br />
                                                                </div>
                                                                <span className="grayTxt">Property Value</span>
                                                            </div>
                                                    }
                                                    <span style={{ color: "red" }}>
                                                        {blankpropvalue}
                                                    </span>

                                                </div>
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={CancelCustom}>Cancel</span>
                                                    <button type="submit" className="loginBtn" onClick={UpdateCustomProperty}>
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <></>
            }
        </>
    )
}
export default Customization;