import $ from "jquery";
import { useState, useEffect } from "react";
import { ApiGetCall, ApiPostCall } from "../js/connector";
export function Faq() {
  const [overallstate, setoverallstate] = useState({});
  const [faqData, setFaqData] = useState([]);
  useEffect(() => {
    window.scroll(0, 0);
    GetFaq();
    return () => {
      setoverallstate({});
    };
  }, []);
  const GetFaq = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/faq/getfaqs").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        var sugData = responseRs.data;
        if (responseRs.data != "") {
          setFaqData(sugData);
        }
      }
    });
  };
  const plusbtnclick = (e) => {
    if ($("#ans" + e).hasClass("tempclass")) {
      $("#question" + e).prop("checked", false);
      $("#question" + e).removeAttr("checked");
      $("#ans" + e).removeClass("tempclass");
      $("#plus" + e).css("transform", "rotate(0deg)");
    } else {
      $(".questions").prop("checked", false);
      $(".answers").removeClass("tempclass");
      $(".plus").css("transform", "rotate(0deg)");
      $("#question" + e).prop("checked", true);
      $("#ans" + e).addClass("tempclass");
      $("#plus" + e).css("transform", "rotate(45deg)");
    }
  };
  return (
    <>
      <div className="container py-5 px-3">
        <div className="row pe-0 justify-content-center">
          <div className="col-12 text-center">
            <h2>Frequently Asked Questions</h2>
            <p className="prodhr" />
          </div>
          {faqData.map((item, i) => {
            var returData;
            returData = (
              <div className="mainquesansdiv" key={i}>
                {i == 0 ?
                  <input type="checkbox" id={`question${item.id}`} className="questions" checked onChange={() => plusbtnclick(item.id)} />
                  :
                  <input type="checkbox" id={`question${item.id}`} className="questions" onChange={() => plusbtnclick(item.id)} />

                }
                <div className="plus" id={`plus${item.id}`}  onClick={() => plusbtnclick(item.id)}>
                  +
                </div>
                <label htmlFor={`question${item.id}`} className="question">
                  <span className="fs-18 py-2 brownTxt font-bold" >
                    {item.question}
                  </span>
                </label>
                <div dangerouslySetInnerHTML={{ __html: item.answer }} className="answers tempclass custom-list-marker" id={`ans${item.id}`}></div>
              </div>
            );
            return returData;
          })}
        </div>
      </div>
    </>
  );
}
