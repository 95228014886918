import React, { useLayoutEffect, useState } from 'react'
import { useLocation,useNavigate } from "react-router-dom";
import "../../css/products.css";
import $ from "jquery";
export default function TablePadsMulipleUrlSelection() {
    const location = useLocation();
    const navigate = useNavigate();
    const [MulipleUrl, setMulipleUrl] = useState(location.pathname?.split("/")[2]);
    const MulipleUrlSelection = (e) => {
        window.localStorage.setItem("useridordernow", $(`#${e.target.id}`).attr("data-urlId"));
        navigate(`/products/${e.target.id}`)
        setMulipleUrl(e.target.id)
    }
    return (
        <React.Fragment>
            <div className="row mb-2" onChange={(e) => MulipleUrlSelection(e)}>
                <div className="col-md-4 col-6"><label className="form-check-label"><input type="radio" data-urlId="34" className="form-check-input" id="economy-table-pad" name="urlradio" value={MulipleUrl} defaultChecked={
                    MulipleUrl === "economy-table-pad" || ""
                } /><label className='ms-2'>Good</label></label></div>
                <div className="col-md-4 col-6"><label className="form-check-label"><input type="radio" data-urlId="35" className="form-check-input" id="standard-economy-table-pad" name="urlradio" value={MulipleUrl} defaultChecked={
                    MulipleUrl === "standard-economy-table-pad" || ""
                } /><label className='ms-2'>Better</label></label></div>
                <div className="col-md-4 col-12"><label className="form-check-label"><input type="radio" data-urlId="26" className="form-check-input" id="ultra-elite-dining-table-pad" name="urlradio" value={MulipleUrl} defaultChecked={
                    MulipleUrl === "ultra-elite-dining-table-pad" || ""
                } /><label className='ms-2'>Best</label></label></div>
            </div>
        </React.Fragment>
    )
}
