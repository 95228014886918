import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CDNURL } from "../js/constants";
import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { ApiGetCall } from "../js/connector";
export function Dealerinquiries(){
    const [state, setState] = useState({});
    const [sectionContact, setsectionContact] = useState("");
    useEffect(() => {
        myFunction();
        return () => {
            setState({});
        };
    }, []);
    const myFunction = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
          if (result == undefined || result == "") {
            alert("Something went wrong");
          } else {
            var responseRs = JSON.parse(result);
            var propDetails = responseRs.propertyDetails;
            $("#Overlay").hide();
            $("#LoderId").hide();
            setsectionContact(propDetails[26].value);
          }
        });
      };
    return(
        <>
            <NavigationBar />
            <div className="contanier-fluid px-0 colorpageimage">
              <LazyLoadImage src={CDNURL + "Localimages/contactHead.png"} className="img-fluid w-100" alt="Hero Image"/>
                <span className="carousel-caption1">
                    <span className="firstHead">Dealer Inquiries</span><br/>
                    <div className="col-2 offset-5">
                        <hr className="hrClass"/>
                    </div>
                </span>
            </div>

            <div className="container colorPara pt-5 px-5">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <p>Superior Quality</p>
                            <p>Expanded Color Choices</p>
                            <p>Marketing Support</p>
                            <p>Speedier Delivery</p>
                            <p>E-Commerce Capabilities</p>
                        </div>
                        <div className="col-md-6 col-12">
                            <p>Fabulous New Display Booklet</p>
                            <p>Customer "Do-It-Yourself-Kit"</p>
                            <p>Better Pricing</p>
                            <p>Guaranteed Customer Satisfaction</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container colorPara pt-5 px-5">
                <p>New Marketing Strategy For Retailers/Decorators/Refinishers...We can do the selling for you through our new "Table-Tent-Program"</p>
                <ul>
                    <li className="pt-3 liBullet ps-2">Reduces pad separation and sliding</li>
                    <li className="pt-3 liBullet ps-2">Unlike other fastening methods, our powerful, neodymium magnets are completely concealed</li>
                    <li className="pt-3 liBullet ps-2">Eliminates damage frequently caused by other locking devices</li>
                    <li className="pt-3 liBullet ps-2">Will not come loose, break or fall off</li>
                    <li className="pt-3 liBullet ps-2">Saves time and energy (nothing to think about-nothing to do)</li>
                    <li className="pt-3 liBullet ps-2">Fully functional up to 100 years</li>
                </ul>
            </div>
            <div className="container colorPara pt-5 px-5">
                <div className="col-12 text-center">
                    <h1>Fiberboard Core</h1>
                    <p className="prodhr mt-1"/>
                </div>
                <ul>
                    <li className="pt-3 liBullet ps-2">Lighter weight than conventional pads made of layered material</li>
                    <li className="pt-3 liBullet ps-2">Less susceptible to humidity and mildew than layered sheets</li>
                    <li className="pt-3 liBullet ps-2">Saves space by storing upright without bowing</li>
                </ul>
                <p className="pt-4">Our fiberboard manufacturing process costs us less, and therefore costs you less. Depending upon how you market and your annual volume, choose from several tailored-made programs that nearly guarantee you the best price.</p>
            </div>
            <div className="container colorPara pt-5 px-5">
                <div className="col-12 text-center">
                    <h1>Fabulous New Display/Demo Booklet</h1>
                    <p className="prodhr mt-1"/>
                </div>
                <ul>
                    <li className="pt-3 liBullet ps-2">Booklet acts as a selling tool as well as an informational piece for a service or retail establishment</li>
                    <li className="pt-3 liBullet ps-2">Fully functional, mini-magnetic pad that's built into sample, demonstrating how invisible magnets work</li>
                    <li className="pt-3 liBullet ps-2">Expanded and larger color chart now displaying 24 color choices (including 6 of the most beautiful leather-tone colors in the industry)</li>
                </ul>
            </div>
            <div className="container colorPara pt-5 px-5">
                <div className="col-12 text-center">
                    <h1>Ongoing Marketing Support</h1>
                    <p className="prodhr mt-1"/>
                </div>
                <ul>
                    <li className="pt-3 liBullet ps-2">Ideas on advertising and in-house promotions</li>
                    <li className="pt-3 liBullet ps-2">E-mail photos, point-of-purchase displays, etc.</li>
                    <li className="pt-3 liBullet ps-2">Suggestions on add-on products</li>
                </ul>
                <p className="colorPara py-5">Call <a className="paraLink" href="tel:+18007372165">1-800-737-2165</a> for a confidential get-to-the-point discussion on how we can work together, or e-mail.</p>
            </div>
            <Footer />
        </>
    )
}