import React, { useEffect, useState, useRef } from "react";

const CountSetInterval = (props) => {
    const intervalRef = useRef();
    const countRef = useRef();
    const { number, duration } = props;
    const [count, setCount] = useState("0");
    const [timeTaken, setTimeTaken] = useState(Date.now());
    useEffect(() => {
        let start = 0;
        const end = parseInt(number);
        if (start === end) return;
        let totalMilSecDur = parseInt(duration);
        let incrementTime = (totalMilSecDur / end) * 2500;
        let timer = setInterval(() => {
            start += 1;
            setCount(String(start));
            if (start === end) {
                clearInterval(timer);
                const diff = Date.now() - timeTaken;
                setTimeTaken(diff / 1000);
            }
        }, incrementTime);
    }, [number, duration]);
    return (
        <>
            <span ref={countRef} className="Count">
                {count}
            </span>
        </>
    );
};
export default CountSetInterval;