import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useEffect, useState } from "react";
import $ from 'jquery';
import { CDNURL } from "../js/constants";
import React from "react";
import { ApiGetCall } from "../js/connector";
export function PatentedLocks() {
    const [isVisible, setIsVisible] = useState(true);
    const [sectionTitle, setsectionTitle] = useState("");
    const [sectionDesciption, setsectionDesciption] = useState("");
    const [sectionCTAtext, setsectionCTAtext] = useState("");
    useEffect(() => {
        $('#yourElementId').text('MagnaLoc Patented Magnetic Locking System');
        myFunction();
        const timer = setTimeout(() => {
            setIsVisible(false);
        });
        return () => {
            clearTimeout(timer);
        }
    }, []);
    const myFunction = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                var propDetails = responseRs.propertyDetails;
                $("#Overlay").hide();
                $("#LoderId").hide();
                setsectionTitle(propDetails[13].value);
                setsectionDesciption(propDetails[14].value);
                setsectionCTAtext(propDetails[15].value);
            }
        });
    };
    const gotonavbar = () => {
        $('html, body').animate({
            scrollTop: $("#GotoNavbar").offset().top
        }, 100);
    }
    return (
        <>
            <NavigationBar />
            <div className="container-fluid px-0  colorHeroSection">
                <div className="row" >
                    <div className="col-md-6 m-auto pt-3" style={{ paddingLeft: "4rem", paddingRight: "6rem" }}>
                        <h1 className="availbleColorTxt">{sectionTitle}</h1>
                        <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
                        <button className="ColorBtn mb-3" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
                            <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More"/>
                        </button>
                    </div>
                    <div className="col-md-6 pb-2 row">
                        <div className="col-6 text-end">
                            <img src={CDNURL + "Localimages/PatentedGroup1.png"} className="img-fluid" alt="Hero Image"/>
                        </div>
                        <div className="col-6 text-end">
                            <img src={CDNURL + "Localimages/PatentedGroup2.gif"} className="img-fluid patentedVideo" alt="Hero Image"/>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container py-5"  >
                <div className="col-12 pt-5">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <ul className="fs-18">
                                <li className="pt-2 liBullet ps-2">Keeping your <a className="paraLink" href="/products/table-pads">table pads</a> sections connected and in place can prove to be a difficult task without some assistance. Our Ultra Elite Table Pad come equipped with our very own patented MagnaLoc™ (Invisible Magnetic Locking System). All table pad locking devices are NOT created equal...not even close!</li>
                                <li className="pt-2 liBullet ps-2">Compare our quality and magnetic locking system to any other you have ever seen in person or online. We think you'll agree - there's no comparison.</li>
                                <li className="pt-2 liBullet ps-2">Our Exclusive Patented Hidden Magnetic Locking System:</li>
                            </ul>
                            <div className="ps-5 pt-4 fs-18 px-0">
                                <ul className=" ps-5">
                                    <li>Reduces pad separation and sliding</li>
                                    <li>100% user-friendly (nothing to think about - nothing to do - they just work)</li>
                                    <li>Unlike other exposed locking systems, MagnaLocs™ are completely concealed</li>
                                    <li>Will not come loose, fall off or break (as other exposed locking systems may)</li>
                                    <li>Eliminates damage that can be caused by other exposed locking devices</li>
                                    <li>Can be fully functional up to 100 years</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 text-center pt-2">
                            <LazyLoadImage src={CDNURL + "Localimages/magneticlocking.png"} className="img-fluid" alt="magnetic locking system"/>
                            <p>Patent# 6,165,577</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}