import { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { CDNURL } from "../js/constants";
import Example from "./ckeditor";
import React from "react";
import Sortable from "sortablejs";
import PhotoModalPopup from "./photomodal";
import { LeftMenu } from "./LeftMenuAdmin";
import { ApiGetCall } from "../js/connector";
import { ApiPostCall } from "../js/connector";
import { ApiPutCall } from "../js/connector";
import { VerifyLoginToken } from "../js/connector";
export function ProductCategory() {
  const [show, setShow] = useState(false);
  const [checkvalue, setCheckvalue] = useState(true);
  const [checkpropvalue, setcheckpropvalue] = useState(true);
  const [droppableRef, seteRef] = useState("");
  const [catname, setCatName] = useState("");
  const [blankimg, setBlankImg] = useState("");
  const [blankCat, setBlankCat] = useState("");
  const [producturl, setProductUrl] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [norecord, setNorecord] = useState("");
  const [parentId, setparentId] = useState("");
  const [subProductVal, setsubProductVal] = useState("");
  const [optionDetailsVar, setoptionDetailsVar] = useState([]);
  const [optionNullDetailsVar, setoptionNullDetailsVar] = useState([]);
  const [SubProductsVar, setSubProductsVar] = useState([]);
  const [neweditorState, setTopDescription] = useState("");
  const [blankURL, setBlankURL] = useState("");
  const [bottomDescription, setBottomDescription] = useState("");
  const [prodImage, setprodImage] = useState([]);
  const [overallstate, setoverallstate] = useState({});
  const [pricetype, setPriceType] = useState(null);
  const [onsale, setonsale] = useState(null);
  const [regularprice, setregularprice] = useState("");
  const [saleprice, setsaleprice] = useState(0);
  const [shippingprice, setshippingprice] = useState("");
  const [blankregularprice, setblankregularprice] = useState("");
  const myRef = useRef(null);
  const [TempCheck, setTempCheck] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    VerifyLoginToken(showhidedivfunc);
    return () => {
      setoverallstate({});
    };
  }, []);
  const showhidedivfunc = () => {
    setTempCheck(true);
    var PathName = window.location.pathname;
    var pathsplit = PathName.split('/');
    var prodActive = pathsplit[2];
    if (prodActive == "AddProduct") {
      showadddiv();
    } else if (prodActive == "UpdateProduct") {
      showupdatediv();
    }
    else {
      GetProdCat();
    }
  }
  const GetProdCat = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Products/GetProductCategory").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        var sugData = responseRs.categoryDetails;
        if (responseRs.status == "success") {
          if (responseRs.categoryDetails != "") {
            setNorecord("");
            setCategoryDetails(sugData);
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  }
  const CancelBannerItems = () => {
    window.location = "/ProductCategory";
  };
  const isShowPopup = (e) => {
    setShow(true);
  };
  const ClearValidation = () => {
    if (catname != "") {
      setBlankCat("");
    }
    if (producturl != "") {
      setBlankURL("");
    }
  };
  const AddProduct = () => {
    window.location.href = "/ProductCategory/AddProduct";
  }
  const showadddiv = () => {
    $("#GridDivShow").addClass("d-none");
    $("#AddCategoryDivShow").removeClass("d-none");
  }
  const EditBannerDiv = (e) => {
    var vId = parseInt(e.currentTarget.attributes[3].value);
    window.location.href = "/ProductCategory/UpdateProduct/?id=" + vId;
  };
  const showupdatediv = async () => {
    var vId = parseInt(getUrlParameter("id"));
    $("#hdnFlag").val(2);
    $("#HdnUserId").val(vId);
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Products/GetCategoryUpdateDetails/" + vId).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        if (responseRs.status == "Success") {
          var sugData = responseRs.productDetails;
          setparentId(sugData.parentId);
          $("#GridDivShow").addClass("d-none");
          $("#UpdateCategoryDivShow").removeClass("d-none");
          setregularprice(sugData.regularPrice);
          setsaleprice(sugData.salesPrice);
          setshippingprice(sugData.shippingPrice);
          if (sugData.onSale == 1) {
            $("#isonsaleid").attr('checked', true);
            setonsale(1);
            $("#hdnSalePriceInput").removeClass('d-none');
          } else {
            setonsale(0);
            $("#hdnSalePriceInput").addClass('d-none');
          }
          if (sugData.priceType == 1) {
            $("#persquareinchId").attr('checked', true);
            setPriceType(1);
          } else if (sugData.priceType == 2) {
            $("#fixedId").attr('checked', true);
            setPriceType(2);
          } else {
            $("#persquareinchId").attr('checked', true);
            setPriceType(1);
          }
          setCatName(sugData.name);
          setProductUrl(sugData.productUrl);
          setTopDescription(<Example content={sugData.topDescription} />);
          $("#hdnEditorField").val(sugData.topDescription);
          setBottomDescription(sugData.bottomDescription);
          $("#bottomUpdateDescription").val(sugData.bottomDescription);
          setsubProductVal(sugData.subProduct);
          if (sugData.subProduct == 1) {
            $("#yesRadioBtn").prop('checked', true);
            $("#hdnPropertiesDiv").addClass('d-none');
            $(".hdnAvailableurl").removeClass('d-none');
            showSubProductDiv();
          } else {
            $("#NoRadioBtn").prop('checked', true);
            $(".hdnAvailableurl").addClass('d-none');
            $("#hdnPropertiesDiv").removeClass('d-none');
            var resultoption = responseRs.options;
            var vArray = [];
            var vNullArray = [];
            for (var i = 0; i < resultoption.length; i++) {
              if (resultoption[i].property_count == 1) {
                vArray.push(resultoption[i]);
              } else {
                vNullArray.push(resultoption[i]);
              }
            }
            setoptionDetailsVar(vArray);
            setoptionNullDetailsVar(vNullArray);
          }
          if (sugData.productsImages != null) {
            setprodImage(sugData.productsImages);
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  };
  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };
  const homepageimgcheckbox = () => {
    var vlen = $(".imgchkboxclass:checked").length;
    if (vlen >= 4) {
      setBlankImg("Maximum 3 images are allowed.")
    } else {
      setBlankImg("");
    }
    $(".imgchkboxclass").each(function () {
      var thisid = $(this).attr("id");
      var vsplit = thisid.split("_");
      var finalVal = parseInt(vsplit[1]);
      var visibleflagval = $("#SelectHomeImage_" + finalVal).attr('visibleflag');
      if ($("#SelectHomeImage_" + finalVal).is(":checked")) {
        $("#SelectHomeImage_" + finalVal).attr('visibleflag', 1);
        if (visibleflagval != $("#SelectHomeImage_" + finalVal).attr('visibleflag')) {
          $("#imgId_" + finalVal).attr('status', 1);
        }
      } else {
        $("#SelectHomeImage_" + finalVal).attr('visibleflag', 0);
        if (visibleflagval != $("#SelectHomeImage_" + finalVal).attr('visibleflag')) {
          $("#imgId_" + finalVal).attr('status', 1);
        }
      }
    });
  }
  const ChangeStatus = async (e) => {
    var vId = parseInt(e.currentTarget.attributes[3].value);
    var vStatus;
    if ($("#VisibleId_" + vId).is(":checked")) {
      $("#VisibleId_" + vId).prop("checked", true);
      vStatus = 1;
    } else {
      $("#VisibleId_" + vId).removeAttr("checked");
      vStatus = 0;
    }
    $("#Overlay").show();
    $("#LoderId").show();
    var raw = JSON.stringify({
      Status: vStatus,
    });
    await ApiPutCall("/Products/VisibleCategory/" + vId, raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        if (responseRs.status == "Success") {
          $(".alert-success").show();
          $("#AlertMsg").text("Updated Successfully.");
          setTimeout(function () {
            $(".alert-success").hide();
            $("#AlertMsg").text("");
          }, 1500);
          if (responseRs.categoryData.prodstatus == 1) {
            setCheckvalue(true);
          } else {
            setCheckvalue(false);
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });

  };
  const showAddCurrentUrl = () => {
    setProductUrl("");
    $("#hdnNewPropertiesDiv").addClass('d-none');
    $("#hdnSubProductDiv").removeClass('d-none');
    $("#topbottmdescription").addClass('d-none');
    $(".subprodcheckbox").prop('checked', false);
    showAddSubProductDiv();
  }
  const showAddPropertyDiv = async () => {
    $("#hdnStatusFlag").val(1);
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Products/GetOptionValues").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        var sugData = responseRs.optionDetails;
        var sugArray = [];
        var i = 1;
        if (responseRs.status == "success") {
          $("#hdnSubProductDiv").addClass('d-none');
          $("#hdnNewPropertiesDiv").removeClass('d-none');
          $("#topbottmdescription").removeClass('d-none');
          if (responseRs.optionDetails != "") {
            setNorecord("");
            setoptionDetailsVar(sugData);
          } else {
            sugArray.push(
              <div className="col-12 gridHeader text-center" key={i}>
                <label>No Record Found</label>
              </div>
            );
            setNorecord(sugArray);
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  }
  const showAvailableUrl = () => {
    window.open("/NavigationMenu");
  };
  const showSubProductDiv = async () => {
    var vId = parseInt($("#HdnUserId").val());
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Products/GetAllSubProducts/" + vId).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        var sugData = responseRs.products;
        var sugArray = [];
        var i = 1;
        if (responseRs.status == "success") {
          if (responseRs.products != "") {
            setNorecord("");
            setSubProductsVar(sugData);
          } else {
            sugArray.push(
              <div key={i}>
                <label>No Record Found</label>
              </div>
            );
            setNorecord(sugArray);
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });

  }
  const showAddSubProductDiv = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Products/GetSubProducts").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        var sugData = responseRs.products;
        var sugArray = [];
        var i = 1;
        if (responseRs.status == "success") {
          if (responseRs.products != "") {
            setNorecord("");
            setSubProductsVar(sugData);
          } else {
            sugArray.push(
              <div key={i}>
                <label>No Record Found</label>
              </div>
            );
            setNorecord(sugArray);
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  }
  const SubProductStatus = (e) => {
    var flag = $("#hdnStatusFlag").val();
    if (flag == 1) {
      if ($("#SubproductCheck_" + e).is(":checked")) {
        $("#SubproductCheck_" + e).attr('status', 1);
      } else {
        $("#SubproductCheck_" + e).attr('status', 3);
      }
    } else {
      if ($("#SubproductUpdateCheck_" + e).is(":checked")) {
        $("#SubproductUpdateCheck_" + e).attr('status', 1);
      } else {
        $("#SubproductUpdateCheck_" + e).attr('status', 3);
      }
    }

  }
  const AddSaveCategoryItems = async () => {
    var temp = 0;
    if (catname == "") {
      setBlankCat("Required!");
      $(".categoryNameClass").focus();
    } else {
      setBlankCat("");
      temp++;
    }

    if (regularprice == "") {
      setblankregularprice("Required!");
      $(".regularpriceClass").focus();
    } else {
      setblankregularprice("");
      temp++;
    }
    if ($("#ImgAddAppend").html() == "") {
      setBlankImg("Required!");
      window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop });
    } else {
      setblankregularprice("");
      temp++;
    }
    if ($(".imgchkboxclass").attr('visibleflag') == 1) {
      setBlankImg("");
      temp++;
    } else {
      setBlankImg("One Image is Required for Home Slider!");
      window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop });
    }
    if ($("#yesRadioBtn").is(":checked") == false) {
      if ($("#NoRadioBtn").is(":checked") == true) {
        $("#BlankSubproductProp").text("");
        temp++;
      } else {
        $("#BlankSubproductProp").text("Required!");
        $(".blanksubprodClass").focus();
      }
    } else {
      if ($("#yesRadioBtn").is(":checked") == false) {
        $("#BlankSubproductProp").text("Required!");
        $(".blanksubprodClass").focus();
      } else {
        $("#BlankSubproductProp").text("");
        temp++;
      }
    }

    var subprodFlag;
    var PropertiesValueArray = [];
    var SubProductsValueArray = [];
    var homepageimgarray = [];
    var ImageArray = [];
    var topdesc = $("#hdnEditorField").val();
    var bottomdesc = $("#bottomDescription").val();
    $(".slideprodimg:visible").each(function () {
      var thisid = $(this).attr("id");
      var vsplit = thisid.split("_");
      var finalVal = parseInt(vsplit[1]);
      var imgsplit = $("#imgId_" + finalVal).attr("base64");
      var imgflag = $("#imgId_" + finalVal).attr('imgflag');
      var sliderimg;
      var rowstatus = $("#imgId_" + finalVal).attr("status");
      if (imgsplit == null) {
        var vsrc = $("#imgId_" + finalVal).attr("src");
        var vsrcsplit = vsrc.split('/');
        var last_part = vsrcsplit[vsrcsplit.length - 1];
        var finalpath = last_part;
        var visibleflag = parseInt($("#SelectHomeImage_" + finalVal).attr('visibleflag'));
        var NewJson = { id: finalVal, imgurl: finalpath, status: rowstatus, Flag: imgflag, ishomeimg: visibleflag };
        ImageArray.push(NewJson);
      } else {
        imgsplit = imgsplit.split(",");
        sliderimg = imgsplit[1];
        var visibleflag = parseInt($("#SelectHomeImage_" + finalVal).attr('visibleflag'));
        var NewJson = { id: finalVal, imgurl: sliderimg, status: rowstatus, Flag: imgflag, ishomeimg: visibleflag };
        ImageArray.push(NewJson);
      }
    });
    if ($("#yesRadioBtn").is(":checked")) {
      subprodFlag = 1;
      $(".subprodrow:visible").each(function () {
        var thisid = $(this).attr("id");
        var vsplit = thisid.split("_");
        var finalVal = parseInt(vsplit[1]);
        var status = $("#SubproductCheck_" + finalVal).attr('status');
        if (status != undefined) {
          var optiontxt = parseInt($("#SubproductCheck_" + finalVal).attr('itemid'));
          if (optiontxt != "") {
            var NewJson = { rowid: optiontxt, roworder: parseInt(status) };
            SubProductsValueArray.push(NewJson);
          }
        }
      });
      if (producturl == "") {
        setBlankURL("Required!")
      } else {
        setBlankURL("");
        temp++;
      }
    } else {
      subprodFlag = 0;
      $(".radioNewgrid:visible").each(function () {
        var thisid = $(this).attr("id");
        var vsplit = thisid.split("_");
        var finalVal = parseInt(vsplit[1]);
        if ($("#VisibleNewRadioId_" + finalVal).is(":checked")) {
          var optiontxt = parseInt($("#VisibleNewRadioId_" + finalVal).attr('itemid'));
          if (optiontxt != "") {
            var NewJson = { rowid: optiontxt };
            PropertiesValueArray.push(NewJson);
          }
        }
      });
      temp++;
    }
    $(".imgchkboxclass").each(function () {
      var thisid = $(this).attr("id");
      var vsplit = thisid.split("_");
      var finalVal = parseInt(vsplit[1]);
      var visibleflag = parseInt($("#SelectHomeImage_" + finalVal).attr('visibleflag'));
      var NewJson = { rowid: finalVal, roworder: visibleflag };
      homepageimgarray.push(NewJson);
    });
    if (temp >= 6) {
      $("#Overlay").show();
      $("#LoderId").show();
      var raw = JSON.stringify({
        Name: catname,
        ProductURL: producturl,
        SubProduct: subprodFlag,
        PropertiesValue: PropertiesValueArray,
        SubProductsArray: SubProductsValueArray,
        topdescription: topdesc,
        bottomdescription: bottomdesc,
        HomePageImages: homepageimgarray,
        price_type: pricetype,
        on_sale: onsale,
        regular_price: regularprice,
        sales_price: saleprice,
        shipping_price: shippingprice,
        ProductImageArray: ImageArray
      });
      await ApiPostCall("/Products/AddProductCategory", raw).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          $("#Overlay").hide();
          $("#LoderId").hide();
          if (responseRs.status == "Success") {
            $(".alert-success").show();
            $("#AlertMsg").text("Category Added Successfully.");
            setTimeout(function () {
              window.location = "/ProductCategory";
            }, 1500);
          } else {
            $(".alert-danger").show();
            $("#AlertDangerMsg").text(responseRs.message);
            setTimeout(function () {
              $(".alert-danger").hide();
              $("#AlertDangerMsg").text();
            }, 1500);
          }
        }
      });
    }
  }
  const UpdateCategoryItems = async () => {
    var userid = parseInt($("#HdnUserId").val());
    var temp = 0;
    var subprodFlag;
    var PropertiesValueArray = [];
    var SubProductsValueArray = [];
    var homepageimgarray = [];
    var ImageArray = [];
    var topdesc = $("#hdnEditorField").val();
    var bottomdesc = $("#bottomUpdateDescription").val();
    $(".imgchkboxclass").each(function () {
      var thisid = $(this).attr("id");
      var vsplit = thisid.split("_");
      var finalVal = parseInt(vsplit[1]);
      var visibleflag = parseInt($("#SelectHomeImage_" + finalVal).attr('visibleflag'));
      var NewJson = { rowid: finalVal, roworder: visibleflag };
      homepageimgarray.push(NewJson);
    });
    if (catname == "") {
      setBlankCat("Required!");
    } else {
      setBlankCat("");
      temp++;
    }

    if (regularprice == "") {
      setblankregularprice("Required!");
    } else {
      setblankregularprice("");
      temp++;
    }
    var tempImgVar = 0;
    var imglength = 0;
    $(".imgchkboxclass:visible").each(function () {
      if ($(this).attr('visibleflag') == 1) {
        tempImgVar = 1;
        imglength++;
      }
    })
    if (tempImgVar == 0) {
      window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop });
      setBlankImg("One Image is Required for Home Slider!")
    } else {
      if (imglength > 3) {
        window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop });
        setBlankImg("You can pick only 3 images")
      } else {
        setBlankImg("");
        temp++;

      }
    }


    $(".slideprodimg").each(function () {
      var thisid = $(this).attr("id");
      var vsplit = thisid.split("_");
      var finalVal = parseInt(vsplit[1]);
      var imgsplit = $("#imgId_" + finalVal).attr("base64");
      var imgflag = $("#imgId_" + finalVal).attr('imgflag');
      var sliderimg;
      var rowstatus = $("#imgId_" + finalVal).attr("status");
      if (imgsplit == null) {
        var vsrc = $("#imgId_" + finalVal).attr("src");
        var vsrcsplit = vsrc.split('/');
        var last_part = vsrcsplit[vsrcsplit.length - 1];
        var finalpath = last_part;
        var visibleflag = parseInt($("#SelectHomeImage_" + finalVal).attr('visibleflag'));
        var NewJson = { id: finalVal, imgurl: finalpath, status: rowstatus, Flag: imgflag, ishomeimg: visibleflag };
        if (rowstatus != 2) {
          ImageArray.push(NewJson);
        }
      } else {
        imgsplit = imgsplit.split(",");
        sliderimg = imgsplit[1];
        var visibleflag = parseInt($("#SelectHomeImage_" + finalVal).attr('visibleflag'));
        var NewJson = { id: finalVal, imgurl: sliderimg, status: rowstatus, Flag: imgflag, ishomeimg: visibleflag };
        if (rowstatus != 2) {
          ImageArray.push(NewJson);
        }
      }
    });
    if ($("#yesRadioBtn").is(":checked")) {
      subprodFlag = 1;
      $(".subprodrow:visible").each(function () {
        var thisid = $(this).attr("id");
        var vsplit = thisid.split("_");
        var finalVal = parseInt(vsplit[1]);
        var status = $("#SubproductUpdateCheck_" + finalVal).attr('status');
        if (status != undefined) {
          var optiontxt = parseInt($("#SubproductUpdateCheck_" + finalVal).attr('itemid'));
          if (optiontxt != "") {
            var NewJson = { rowid: optiontxt, roworder: parseInt(status) };
            SubProductsValueArray.push(NewJson);
          }
        }
      });
      if (producturl == "") {
        setBlankURL("Required!")
      } else {
        setBlankURL("");
        temp++;
      }
    } else {
      subprodFlag = 0;
      $(".radiogrid:visible").each(function () {
        var thisid = $(this).attr("id");
        var vsplit = thisid.split("_");
        var finalVal = parseInt(vsplit[1]);
        var status = parseInt($("#VisibleRadioId_" + finalVal).attr('status'));
        if ($("#VisibleRadioId_" + finalVal).is(":checked")) {
          var optiontxt = parseInt($("#VisibleRadioId_" + finalVal).attr('itemid'));
          var roworder = parseInt($("#VisibleRadioGridId_" + finalVal).attr('propid'));
          var proptype = parseInt($("#VisibleRadioGridId_" + finalVal).attr('proptype'));
          var minimum = parseInt($("#MinUpdateValueTxt_" + finalVal).val());
          var maximum = parseInt($("#MaxUpdateValueTxt_" + finalVal).val());
          var isrequired = 0;
          if ($("#isRequiredId_" + finalVal).is(":checked")) {
            isrequired = 1;
          } else {
            isrequired = 0;
          }
          if (optiontxt != "") {
            var NewJson = { rowid: optiontxt, roworder: roworder, status: status, isRequired: isrequired, PropertyType: proptype, min: minimum, max: maximum };
            PropertiesValueArray.push(NewJson);
          }
        } else if (status == 3) {
          var optiontxt = parseInt($("#VisibleRadioId_" + finalVal).attr('itemid'));
          var roworder = parseInt($("#VisibleRadioGridId_" + finalVal).attr('propid'));
          var proptype = parseInt($("#VisibleRadioGridId_" + finalVal).attr('proptype'));
          var minimum = parseInt($("#MinUpdateValueTxt_" + finalVal).val());
          var maximum = parseInt($("#MaxUpdateValueTxt_" + finalVal).val());
          var isrequired = 0;
          if ($("#isRequiredId_" + finalVal).is(":checked")) {
            isrequired = 1;
          } else {
            isrequired = 0;
          }
          if (optiontxt != "") {
            var NewJson = { rowid: optiontxt, roworder: roworder, status: status, isRequired: isrequired, PropertyType: proptype, min: minimum, max: maximum };
            PropertiesValueArray.push(NewJson);
          }
        }
      });
      temp++;
    }


    if (temp >= 4) {
      $("#Overlay").show();
      $("#LoderId").show();
      var raw = JSON.stringify({
        Name: catname,
        ProductURL: producturl,
        SubProduct: subprodFlag,
        PropertiesValue: PropertiesValueArray,
        SubProductsArray: SubProductsValueArray,
        topdescription: topdesc,
        bottomdescription: bottomdesc,
        HomePageImages: homepageimgarray,
        price_type: pricetype,
        on_sale: onsale,
        regular_price: regularprice,
        sales_price: saleprice,
        shipping_price: shippingprice,
        ProductImageArray: ImageArray
      });
      await ApiPutCall("/Products/UpdateCategory/" + userid, raw).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          $("#Overlay").hide();
          $("#LoderId").hide();
          if (responseRs.status == "Success") {
            $(".alert-success").show();
            $("#AlertMsg").text("Category Updated Successfully.");
            setTimeout(function () {
              window.location = "/ProductCategory";
            }, 1500);
          } else {
            $(".alert-danger").show();
            $("#AlertDangerMsg").text(responseRs.message);
            setTimeout(function () {
              $(".alert-danger").hide();
              $("#AlertDangerMsg").text();
            }, 1500);
          }
        }
      });
    }
  };
  function deleteUploadImage(e) {
    var deleteid = e.target.attributes[1].value;
    if ($("#imgId_" + deleteid).attr('status') == 2) {
      $("#imgId_" + deleteid).attr('status', 3);
      $("#ProductRow_" + deleteid).hide();
    } else {
      $("#ProductRow_" + deleteid).remove();
    }

  }
  var sortable;
  var tempVar = 1;
  const EnableDragDrop = () => {
    tempVar = 1;
    if (droppableRef) {
      sortable = Sortable.create(droppableRef, {
        easing: "cubic-bezier(0.2, 0, 0, 1)",
        animation: 150,
        className: "sortable-list",
        ghostClass: "dragging",
        scrollFn: function () {
          return false;
        },
        scroll: true,
        disabled: false,
        scrollSensitivity: 100,
        bubbleScroll: true,
        onEnd: function () {
          $(".radiogrid:visible").each(function () {
            var thisid = $(this).attr("id");
            $("#" + thisid).attr("rowcount", tempVar);
            tempVar++;
          });
        },
      });
      $(".uncheckclass:visible").each(function () {
        var vid = $(this).attr('id');
        var vsplit = vid.split('_');
        var finalid = vsplit[1];
        if ($(this).is(":checked") == false) {
          $("#rowId_" + finalid).hide();
        }
      });
      $(".dragdropdotsimg").removeClass("d-none");
      $(".grid").css("cursor", "grabbing");
      $("#hdnCancelLink").removeClass("d-none");
      $("#SaveOrderHdnBtn").removeClass("d-none");
      $("#changeOrderHdnBtn").addClass("d-none");
    }
  };
  const CancelDragDrop = () => {
    sortable.option("disabled", true);
    $(".dragdropdotsimg").addClass("d-none");
    $(".grid").css("cursor", "pointer");
    $("#changeOrderHdnBtn").removeClass("d-none");
    $(".mydragdropgrid").show();
    $("#hdnCancelLink").addClass("d-none");
    $("#SaveOrderHdnBtn").addClass("d-none");
  };
  const SaveOrderOfProperties = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    var PropertiesValueArray = [];
    var hdnstatus = $("#hdnStatusFlag").val();
    if (hdnstatus == 1) {
      $(".radioNewgrid:visible").each(function () {
        var thisid = $(this).attr("id");
        var vsplit = thisid.split("_");
        var finalVal = parseInt(vsplit[1]);
        if ($("#VisibleNewRadioId_" + finalVal).is(":checked")) {
          var rowid = parseInt($("#VisibleNewRadioGridId_" + finalVal).attr('propid'));
          var rowcnt = parseInt($("#VisibleNewRadioGridId_" + finalVal).attr('rowcount'));
          if (rowid != null) {
            var NewJson = { rowid: rowid, roworder: rowcnt };
            PropertiesValueArray.push(NewJson);
          }
        }
      })
    } else {
      $(".radiogrid:visible").each(function () {
        var thisid = $(this).attr("id");
        var vsplit = thisid.split("_");
        var finalVal = parseInt(vsplit[1]);
        if ($("#VisibleRadioId_" + finalVal).is(":checked")) {
          var rowid = parseInt($("#VisibleRadioGridId_" + finalVal).attr('propid'));
          var rowcnt = parseInt($("#VisibleRadioGridId_" + finalVal).attr('rowcount'));
          if (rowid != null) {
            var NewJson = { rowid: rowid, roworder: rowcnt };
            PropertiesValueArray.push(NewJson);
          }
        }
      })
    }

    var raw = JSON.stringify({
      SliderOrder: PropertiesValueArray,
    });
    await ApiPostCall("/Products/PropertiesOrder", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        if (responseRs.status == "Success") {
          $(".alert-success").show();
          $("#AlertMsg").text("Order Change Succesfully.");
          setTimeout(function () {
            $(".alert-success").hide();
            $("#AlertMsg").text("");
          }, 1500);
          $(".dragdropdotsimg").addClass("d-none");
          $(".grid").css("cursor", "pointer");
          $("#changeOrderHdnBtn").removeClass("d-none");
          $(".grid").show();
          $("#hdnCancelLink").addClass("d-none");
          $("#SaveOrderHdnBtn").addClass("d-none");
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  }
  const changecheckboxstatus = (itemid) => {
    if ($("#VisibleRadioId_" + itemid).is(":checked")) {
      $("#VisibleRadioId_" + itemid).attr('status', 1);
    } else {
      $("#VisibleRadioId_" + itemid).attr('status', 3);
      $("#MinUpdateValueTxt_" + itemid).val("");
      $("#MaxUpdateValueTxt_" + itemid).val("");
      $("#isRequiredId_" + itemid).prop('checked', false);
    }
  }
  const PriceTypeClick = (vval) => {
    setPriceType(vval);
  }
  const isonsaleclick = () => {
    setsaleprice(0);
    var hdnflag = $("#hdnFlag").val();
    if (hdnflag == 2) {
      if ($("#isonsaleid").is(":checked")) {
        setonsale(1);
        $("#hdnSalePriceInput").removeClass('d-none');
      } else {
        setonsale(0);
        $("#hdnSalePriceInput").addClass('d-none');
      }
    } else {
      if ($("#addisonsaleid").is(":checked")) {
        setonsale(1);
        $("#hdnAddSalePriceInput").removeClass('d-none');

      } else {
        setonsale(0);
        $("#hdnAddSalePriceInput").addClass('d-none');
      }
    }
  }
  const closePopupHandler = () => {
    setShow(false);
  };
  const savemodalphotos = () => {
    var imgboxlen = $(".mainimgbox:visible").length;
    var count = 0;
    var finalhtml = "";
    $(".computerimgs").each(function (i) {
      i += imgboxlen;
      var base64 = $(this).attr('base64');
      var html = '<div class="col-3 py-2" id="ProductRow_' + i + '">' +
        '<div class="imagebox">' +
        '<div class="col-12">' +
        '<div class="mainimgbox text-center">' +
        '<img alt="dummy" src=' + base64 + ' class="slideprodimg" id="imgId_' + i + '" imgflag="1" base64=' + base64 + ' status="1"  />' +
        '</div>' +
        '</div>' +
        '<div class="row">' +
        '<div class="col-10 pt-3 ps-4">' +
        '<h6>Is Homeslider?</h6>' +
        '</div>' +
        '<div class="col-2 text-end pt-3 pe-4">' +
        '<span>' +
        '<img alt="dummy" src="' + CDNURL + 'Localimages/newDeleteIcon.svg" deleteid=' + i + ' class="dltImgClass" title="Delete Banner"/>' +
        '</span>' +
        '</div>' +
        '</div>' +
        '<div class="col-12 px-3">' +
        '<h6 class="py-2">' +
        '<span class="pe-2">Yes</span>' +
        '<label class="switch">' +
        '<input type="checkbox" class="imgchkboxclass checkishomeimg" itemid=' + i + ' id="SelectHomeImage_' + i + '" visibleflag="0" />' +
        '<span class="slider round"></span>' +
        '</label>' +
        '<span class="ps-2">No</span>' +
        '</h6>' +
        '</div>' +
        '</div>' +
        '</div>';
      finalhtml += html;
      count = i;
    });
    $(".myimgredborder").each(function (i) {
      i += count + 1;
      var imggallery = $(this).attr('src');
      var html = '<div class="col-3 py-2" id="ProductRow_' + i + '">' +
        '<div class="imagebox">' +
        '<div class="col-12">' +
        '<div class="mainimgbox text-center">' +
        '<img alt="dummy" src=' + imggallery + ' class="slideprodimg" id="imgId_' + i + '" imgflag="2" status="1"  />' +
        '</div>' +
        '</div>' +
        '<div class="row">' +
        '<div class="col-10 pt-3 ps-4">' +
        '<h6>Is Homeslider?</h6>' +
        '</div>' +
        '<div class="col-2 text-end pt-3 pe-4">' +
        '<span>' +
        '<img alt="dummy" src="' + CDNURL + 'Localimages/newDeleteIcon.svg" deleteid=' + i + ' class="dltImgClass" title="Delete Banner"/>' +
        '</span>' +
        '</div>' +
        '</div>' +
        '<div class="col-12 px-3">' +
        '<h6 class="py-2">' +
        '<span class="pe-2">Yes</span>' +
        '<label class="switch">' +
        '<input type="checkbox" class="imgchkboxclass checkishomeimg" itemid=' + i + ' id="SelectHomeImage_' + i + '" visibleflag="0" />' +
        '<span class="slider round"></span>' +
        '</label>' +
        '<span class="ps-2">No</span>' +
        '</h6>' +
        '</div>' +
        '</div>' +
        '</div>';
      finalhtml += html;
    });
    if ($("#hdnFlag").val() == 2) {
      $("<div>" + finalhtml + "</div><br/>")
        .attr({
          class: "crsr-pointer row"
        }).appendTo("#ImgUpdateAppend");
    } else {
      $("<div>" + finalhtml + "</div><br/>")
        .attr({
          class: "crsr-pointer row"
        }).appendTo("#ImgAddAppend");
    }
    var imglen = $(".dltImgClass:visible").length;
    if (imglen != 0) {
      $("#hdnNewAddedLabel").removeClass('d-none');
    } else {
      $("#hdnNewAddedLabel").addClass('d-none');
    }
    setShow(false);
    $(".checkishomeimg").change(function () {
      var vlen = $(".imgchkboxclass:checked").length;
      if (vlen >= 4) {
        setBlankImg("Maximum 3 images are allowed.")
      } else {
        setBlankImg("");
      }
      $(".imgchkboxclass").each(function () {
        var thisid = $(this).attr("id");
        var vsplit = thisid.split("_");
        var finalVal = parseInt(vsplit[1]);
        if ($("#SelectHomeImage_" + finalVal).is(":checked")) {
          $("#SelectHomeImage_" + finalVal).attr('visibleflag', 1);
        } else {
          $("#SelectHomeImage_" + finalVal).attr('visibleflag', 0);
        }
      });
    });
    $(".dltImgClass").click(function () {
      var deleteid = $(this).attr('deleteid');
      if ($("#imgId_" + deleteid).attr('status') == 2) {
        $("#imgId_" + deleteid).attr('status', 3);
        $("#ProductRow_" + deleteid).hide();
      } else {
        $("#ProductRow_" + deleteid).remove();
      }
      var imglen = $(".dltImgClass:visible").length;
      if (imglen == 0) {
        $("#hdnNewAddedLabel").addClass('d-none');
      }

    });
  }
  return (
    <>
      {TempCheck == true ?
        <>
          <input type="hidden" id="HdnUserId" />
          <input type="hidden" id="hdnFlag" />
          <input type="hidden" id="hdnStatusFlag" />
          <input type="hidden" id="hdnModalImages" />
          <LeftMenu />
          <div fixed="true" className='container-fluid mainContainer'>
            <div className="content-wrapper" id="GridDivShow">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="cardDiv">
                      <div className="cardDiv-header">
                        <div className="row AlignItems">
                          <div className="col-4">
                            <h4>Products</h4>
                          </div>
                          <div className="col-8">
                            <div className="card-action">
                              <button
                                type="submit"
                                className="loginBtn btnhover"
                                onClick={AddProduct}
                              >
                                Add Product
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pl-3 pr-3">
                        <div className="col-12 gridHeader">
                          <div className="row">
                            <div className="col-4 ps-5">
                              <b>Product</b>
                            </div>
                            <div className="col-4 pe-0">
                              <b>Parent</b>
                            </div>
                            <div className="col-2 text-center">
                              <b>Is Active?</b>
                            </div>
                            <div className="col-2 text-center">
                              <b>Action</b>
                            </div>
                          </div>
                        </div>
                        <div className="scrolly-63">
                          {categoryDetails.map((item, i) => {
                            var returData;
                            returData = (
                              <div
                                className="col-12 grid"
                                key={i}
                                rowcount={i + 1}
                                id={`MyClass_${item.id}`}
                              >
                                <div className="row" key={i}>
                                  <div className="col-4 mb-2 ps-5">
                                    {item.name}
                                  </div>
                                  <div className="col-4 mb-2">
                                    {item.parent_name != null ?
                                      item.parent_name
                                      :
                                      <>
                                        -
                                      </>
                                    }
                                  </div>
                                  <div className="col-2 text-center">
                                    {item.active == 1 ? (
                                      <input
                                        className="form-check-input me-2"
                                        type="checkbox"
                                        onClick={ChangeStatus}
                                        defaultChecked={checkvalue}
                                        id={`VisibleId_${item.id}`}
                                        userid={item.id}
                                      />
                                    ) : (
                                      <input
                                        className="form-check-input me-2"
                                        type="checkbox"
                                        onClick={ChangeStatus}
                                        id={`VisibleId_${item.id}`}
                                        userid={item.id}
                                      />
                                    )}
                                  </div>
                                  <div className="col-2 text-center">
                                    <span>
                                      <img
                                        src="/images/editIcon.svg"
                                        title="Edit Category"
                                        className="iconClass me-1"
                                        userid={item.id}
                                        onClick={EditBannerDiv}
                                        alt="Edit"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                            return returData;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-wrapper d-none" id="AddCategoryDivShow">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="cardDiv1">
                      <div className="cardDiv-header pb-0 pt-1 pe-0">
                        <div className="row AlignItems">
                          <div className="col-1and5 text-center">
                            <a href="/ProductCategory">
                              <img src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px mt-1" title="Back" alt="Back" />
                            </a>
                          </div>
                          <div className="col-7 ps-0 mt-2">
                            <h4>
                              Add Product
                            </h4>
                          </div>
                          <div className="col-4 mt-1 text-end pe-0">
                            <span className="me-3 pt-2 crsr-pointer" onClick={CancelBannerItems}>Cancel</span>
                            <button type="submit" className="loginBtn" onClick={AddSaveCategoryItems}>Save Changes</button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pl-3 pr-3">
                        <div className="row">
                          <div className="col-12 pt-3 ps-4">
                            <div className="row">
                              <div className="col-6">
                                <input type="text" className="form-control categoryNameClass" onMouseOut={ClearValidation} placeholder="Product Name"
                                  value={catname} onChange={(e) => setCatName(e.target.value)} />
                                <span style={{ color: "red" }}>{blankCat}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 pt-3 ps-4">
                            <div className="row">
                              <div className="col-4 mb-3">
                                <div className="row">
                                  <div className="col-4 pe-0">
                                    <label className="font-bold">
                                      Price Type:
                                    </label>
                                  </div>
                                  <div className="col-8">
                                    <label className="form-check-label mx-4 my-1">
                                      <input type="radio" defaultChecked={checkvalue} onClick={(e) => PriceTypeClick(1)} className=" form-check-input me-3" name="ordertype" />
                                      Per Square Inch
                                    </label><br />
                                    <label className="form-check-label mx-4 my-1" >
                                      <input type="radio" className="form-check-input me-3" onClick={(e) => PriceTypeClick(2)} name="ordertype" />
                                      Fixed
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 mb-3">
                                <label className="sr-only" htmlFor="inlineFormInputGroup">Regular Price</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">$</div>
                                  </div>
                                  <input type="text" className="form-control regularpriceClass" id="inlineFormInputGroup" placeholder="Regular Price"
                                    value={regularprice} onChange={(e) => setregularprice(e.target.value)} />
                                </div>
                                <label>Regular Price</label>
                                <span style={{ color: "red" }}>{blankregularprice}</span>
                              </div>
                              <div className="col-2 mb-3">
                                <label className="sr-only" htmlFor="ShippingpriceFormInputGroup">Shipping Price</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">$</div>
                                  </div>
                                  <input type="text" className="form-control" id="ShippingpriceFormInputGroup" placeholder="Shipping Price"
                                    value={shippingprice} onChange={(e) => setshippingprice(e.target.value)} />
                                </div>
                                <label>Shipping Price</label>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4  mb-3 text-center">
                                <div className="form-check d-flex justify-content-center mb-3 ms-3">
                                  <input className="form-check-input me-3 ms-4" type="checkbox" value="" id="addisonsaleid" onClick={isonsaleclick} />
                                  <label className="form-check-label"> Is On Sale? </label>
                                </div>
                              </div>
                              <div className="col-2 mb-3 d-none" id="hdnAddSalePriceInput">
                                <label className="sr-only" htmlFor="inlineFormInputGroup">Sale Price</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">$</div>
                                  </div>
                                  <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Sale Price"
                                    value={saleprice} onChange={(e) => setsaleprice(e.target.value)} />
                                </div>
                                <label>Sale Price</label>
                              </div>

                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-12 pt-3 ps-4">
                            <label className="selectImgBtn" onClick={isShowPopup}>
                              Choose Images
                            </label>
                            <span className="ms-3" id="hdnNoteImage">You can pick any 3 images to be displayed on the home page slider</span>
                            <br />
                            <div className="txtRed py-3">Note: Ideal size for home page images should be 400px tall and 300px wide.</div>
                            <div className="col-12">
                              <div className="row">
                                <div className="py-2 d-none" id="hdnNewAddedLabel">
                                  <h2>New Added</h2>
                                  <hr />
                                </div>
                                <div id="ImgAddAppend" ref={myRef}>
                                </div>
                              </div>
                            </div>
                            <br />
                            <h5 style={{ color: "red" }} className="BlankImgClass">{blankimg}</h5>
                          </div>
                        </div>
                        <hr />
                        <div className="row ps-4">
                          <div className="col-12 row my-3">
                            <div className="col-12">
                              <label className="fs-600 fs-18">
                                Does this Product has Sub Products?
                              </label>
                              <label className="form-check-label mx-4">
                                <input type="radio" id="yesRadioBtn" className="form-check-input me-1 blanksubprodClass" name="optradio" onClick={showAddCurrentUrl} />
                                Yes
                              </label>
                              <label className="form-check-label mx-4">
                                <input type="radio" id="NoRadioBtn" className="form-check-input me-1" name="optradio" onClick={showAddPropertyDiv} />
                                No
                              </label>
                            </div>
                            <h5 style={{ color: "red" }} id="BlankSubproductProp"></h5>
                          </div>
                        </div>
                        <hr />
                        <div className="px-3 pb-3">
                          <div className="d-none" id="hdnSubProductDiv">
                            <div className="row align-items-center">
                              <div className="col-4">
                                <label className="fs-20 fs-600 py-3">Sub Products</label>
                              </div>
                              <div className="col-6">
                                <div className="col-12">
                                  <input type="text" id="actionURLId" className="form-control actionurlClass"
                                    placeholder="Redirect URL" value={producturl} onChange={(e) => setProductUrl(e.target.value)} onMouseOut={ClearValidation} />

                                </div>
                                <span style={{ color: "red" }}>{blankURL}</span>
                              </div>
                              <div className="col-2">
                                <a className="crsr-Pointer" onClick={showAvailableUrl}>
                                  Show Available URL
                                </a>
                              </div>
                              <hr />
                            </div>
                            <div className="ps-3 row">
                              {SubProductsVar.map((item, i) => {
                                var returData;
                                returData = (<div className="col-4 py-2 subprodrow" key={i} id={`SubProdGridId_${item.id}`}>
                                  <>
                                    <input
                                      className="form-check-input me-2 subprodcheckbox"
                                      type="checkbox"
                                      itemid={item.id}
                                      id={`SubproductCheck_${item.id}`}
                                      onChange={(e) => SubProductStatus(item.id)}
                                    />
                                    <label className="UED-font2 ms-2">
                                      {item.name}
                                    </label>
                                  </>
                                </div>
                                );
                                return returData;
                              })}
                            </div>
                            {norecord}
                          </div>
                        </div>
                        <div className="row">
                          <div className="px-3 pb-3 pt-2 d-none" id="hdnNewPropertiesDiv">
                            <div className="row px-3">
                              <div className="col-12 pt-3">
                                <div className="row">
                                  <div className="col-12">
                                    <h6>Top Description</h6>
                                    <Example />
                                  </div>
                                  <div className="col-12 py-5">
                                    <h6>Bottom Description</h6>
                                    <textarea rows="3" className="form-control" id="bottomDescription" defaultValue={bottomDescription}></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <label className="fs-20 fs-600 py-3">Select Properties</label>
                              <hr />
                            </div>
                            <div className="ps-3 row">
                              {optionDetailsVar.map((item, i) => {
                                var returData;
                                returData = (<div className="row col-6 py-2 mydragdropgrid" id={`rowId_${item.id}`} key={i} proptype={item.propertyType}>
                                  <div className="col-1 text-center">
                                    <img
                                      src="/images/dragdrop.png"
                                      title="Drag to reorder"
                                      className="w-25 d-none dragdropdotsimg"
                                      alt="Drag"
                                    />
                                  </div>
                                  <div className="col-10 ps-0 radioNewgrid" propid={item.propertyid} rowcount={i + 1} key={i} id={`VisibleNewRadioGridId_${item.id}`}>
                                    <input
                                      className="form-check-input me-2 uncheckclass"
                                      id={`VisibleNewRadioId_${item.id}`}
                                      type="checkbox"
                                      itemid={item.id}
                                    />
                                    <label className="UED-font2 ms-2">
                                      {item.propertyName}
                                    </label>
                                  </div>
                                </div>
                                );
                                return returData;
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 py-5">
                          <div className="card-action me-2">
                            <button type="submit" className="loginBtn" onClick={AddSaveCategoryItems}>
                              Save Changes
                            </button>
                          </div>
                          <div className="card-action mt-1">
                            <span className="me-3 pt-2 crsr-pointer" onClick={CancelBannerItems}>Cancel</span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-wrapper d-none" id="UpdateCategoryDivShow">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="cardDiv1">
                      <div className="cardDiv-header pb-0 pt-1 pe-0">
                        <div className="row AlignItems">
                          <div className="col-1and5 text-center">
                            <a href="/ProductCategory">
                              <img src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px mt-1" title="Back" alt="Back" />
                            </a>
                          </div>
                          <div className="col-7 ps-0 mt-2">
                            <h4>
                              Update Product
                            </h4>
                          </div>
                          <div className="col-4 mt-1 text-end pe-0">
                            <span className="me-3 pt-2 crsr-pointer" onClick={CancelBannerItems}>Cancel</span>
                            <button type="submit" className="loginBtn" onClick={UpdateCategoryItems}>Save Changes</button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pl-3 pr-3">
                        <div className="row">
                          <div className="col-12 pt-3 ps-4">
                            <div className="row">
                              <div className="col-6">
                                <input type="text" className="form-control" onMouseOut={ClearValidation} placeholder="Category Name"
                                  value={catname} onChange={(e) => setCatName(e.target.value)} />
                                <span style={{ color: "red" }}>{blankCat}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 pt-3 ps-4">
                            <div className="row">
                              <div className="col-4 mb-3">
                                <div className="row">
                                  <div className="col-4 pe-0">
                                    <label className="font-bold">
                                      Price Type:
                                    </label>
                                  </div>
                                  <div className="col-8">
                                    <label className="form-check-label mx-4 my-1">
                                      <input type="radio" id="persquareinchId" onClick={(e) => PriceTypeClick(1)} className=" form-check-input me-3" name="ordertype" />
                                      Per Square Inch
                                    </label><br />
                                    <label className="form-check-label mx-4 my-1" >
                                      <input type="radio" className="form-check-input me-3" onClick={(e) => PriceTypeClick(2)} name="ordertype" id="fixedId" />
                                      Fixed
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 mb-3">
                                <label className="sr-only" htmlFor="inlineFormInputGroup">Regular Price</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">$</div>
                                  </div>
                                  <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Regular Price"
                                    value={regularprice} onChange={(e) => setregularprice(e.target.value)} />
                                </div>
                                <label>Regular Price</label>
                                <span style={{ color: "red" }}>{blankregularprice}</span>
                              </div>
                              <div className="col-2 mb-3">
                                <label className="sr-only" htmlFor="ShippingpriceFormInputGroup">Shipping Price</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">$</div>
                                  </div>
                                  <input type="text" className="form-control" id="ShippingpriceFormInputGroup" placeholder="Shipping Price"
                                    value={shippingprice} onChange={(e) => setshippingprice(e.target.value)} />
                                </div>
                                <label>Shipping Price</label>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4  mb-3 text-center">
                                <div className="form-check d-flex justify-content-center mb-3 ms-3">
                                  <input className="form-check-input me-3 ms-4" type="checkbox" value="" id="isonsaleid" onClick={isonsaleclick} />
                                  <label className="form-check-label"> Is On Sale? </label>
                                </div>
                              </div>
                              <div className="col-2 mb-3 d-none" id="hdnSalePriceInput">
                                <label className="sr-only" htmlFor="inlineFormInputGroup">Sale Price</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">$</div>
                                  </div>
                                  <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Sale Price"
                                    value={saleprice} onChange={(e) => setsaleprice(e.target.value)} />
                                </div>
                                <label>Sale Price</label>
                              </div>

                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-12 py-3 ps-4">
                            <label className="selectImgBtn" onClick={isShowPopup}>
                              Choose Images
                            </label>
                            <span className="ms-3" id="hdnNoteImage">You can pick any 3 images to be displayed on the home page slider</span>
                            <br />
                            <div className="txtRed py-3">Note: Ideal size for home page images should be 400px tall and 300px wide.</div>
                            <div className="col-12">
                              <div className="row">
                                {prodImage.map((item, i) => {
                                  var returData;
                                  returData = <div className="col-3 py-2" id={"ProductRow_" + item.id}>
                                    <div className="imagebox">
                                      <div className="col-12">
                                        <div className="mainimgbox text-center">
                                          <img alt="dummy" src={CDNURL + "Gallery/" + item.imageUrl} status="2" className="slideprodimg" id={`imgId_${item.id}`} />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-10 pt-3 ps-4">
                                          <h6>Is Homeslider?</h6>
                                        </div>
                                        <div className="col-2 text-end pt-3 pe-4">
                                          <span>
                                            <img alt="Delete" src={CDNURL + "/Localimages/newDeleteIcon.svg"} deleteid={item.id} className="" title="Delete Banner"
                                              onClick={deleteUploadImage} />
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-12 px-3">
                                        {(item.isHomeImage == 1) ?
                                          <>
                                            <h6 className="py-2">
                                              <span className="pe-2">Yes</span>
                                              <label className="switch">
                                                <input type="checkbox" defaultChecked={checkpropvalue} className="imgchkboxclass"
                                                  itemid={item.id} id={"SelectHomeImage_" + item.id} visibleflag="1" onChange={homepageimgcheckbox} />
                                                <span className="slider round"></span>
                                              </label>
                                              <span className="ps-2">No</span>
                                            </h6>
                                          </>
                                          :
                                          <>
                                            <h6 className="py-2">
                                              <span className="pe-2">Yes</span>
                                              <label class="switch">
                                                <input type="checkbox" className="imgchkboxclass"
                                                  itemid={item.id} id={"SelectHomeImage_" + item.id} visibleflag="0" onChange={homepageimgcheckbox} />
                                                <span class="slider round"></span>
                                              </label>
                                              <span className="ps-2">No</span>
                                            </h6>
                                          </>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  return returData;
                                })}
                                <div className="py-2 d-none" id="hdnNewAddedLabel">
                                  <h2>New Added</h2>
                                  <hr />
                                </div>
                                <div id="ImgUpdateAppend">
                                </div>
                              </div>
                            </div>
                            <br />
                            <h5 style={{ color: "red" }} ref={myRef}>{blankimg}</h5>
                          </div>
                        </div>
                        <hr />
                        {$("#HdnUserId").val() != 26 ?
                          <>
                            <div className="row px-3">
                              {(parentId != null || subProductVal == 0) ?
                                <div className="col-12 pt-3" id="topbottmdescription">
                                  <div className="row">
                                    <div className="col-12">
                                      <h6>Top Description</h6>
                                      {neweditorState}
                                    </div>
                                    <div className="col-12 py-5">
                                      <h6>Bottom Description</h6>
                                      <textarea rows="3" className="form-control" id="bottomUpdateDescription" defaultValue={bottomDescription}></textarea>
                                    </div>
                                  </div>
                                </div>
                                :
                                <></>
                              }
                            </div>
                            {(parentId != null || subProductVal == 0) ?
                              <hr />
                              :
                              <></>
                            }
                            <div className="row ps-4">
                              <div className="col-12 row my-3">
                                <div className="col-6 d-none hdnAvailableurl">
                                  <div className="col-12">
                                    <input type="text" id="actionURLId" className="form-control"
                                      placeholder="Call To Action URL" value={producturl} onChange={(e) => setProductUrl(e.target.value)} onMouseOut={ClearValidation} />
                                  </div>
                                  <span style={{ color: "red" }}>{blankURL}</span>
                                  <div className="col-12 pt-2 text-end">
                                    <a className="crsr-Pointer" onClick={showAvailableUrl}>
                                      Show Available URL
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="px-3 pb-3">
                              <div className="d-none hdnAvailableurl">
                                <div className="row">
                                  <label className="fs-20 fs-600 py-3">Sub Products</label>
                                  <hr />
                                </div>
                                <div className="ps-3 row">
                                  {SubProductsVar.map((item, i) => {
                                    var returData;
                                    returData = (<div className="col-4 py-2 subprodrow" key={i} id={`SubProdGridId_${item.id}`}>
                                      {(item.activeSubProduct == 0) ?
                                        <>
                                          <input
                                            className="form-check-input me-2 subprodcheckbox"
                                            type="checkbox"
                                            itemid={item.id}
                                            id={`SubproductUpdateCheck_${item.id}`}
                                            onChange={(e) => SubProductStatus(item.id)}
                                          />
                                          <label className="UED-font2 ms-2">
                                            {item.name}
                                          </label>
                                        </>
                                        :
                                        <>
                                          <input
                                            className="form-check-input me-2 subprodcheckbox"
                                            type="checkbox"
                                            itemid={item.id}
                                            defaultChecked={checkpropvalue}
                                            id={`SubproductUpdateCheck_${item.id}`}
                                            onChange={(e) => SubProductStatus(item.id)}
                                            status="1"
                                          />
                                          <label className="UED-font2 ms-2">
                                            {item.name}
                                          </label>
                                        </>
                                      }
                                    </div>
                                    );
                                    return returData;
                                  })}
                                </div>
                                {norecord}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 py-5 d-none" id="hdnPropertiesDiv">
                                <div className="row">
                                  <div className="col-12  ps-4">
                                    <div className="row AlignItems">
                                      <div className="col-12  ps-4">
                                        <h3>Select Properties</h3>
                                      </div>
                                    </div>
                                    <div className="cardDiv-header pe-0">
                                      <div className="row AlignItems">
                                        <div className="col-1"></div>
                                        <div className="col-5">Properties</div>
                                        <div className="col-2 text-center ps-0">Is Required?</div>
                                        <div className="col-2 row ">
                                          <div className="col-6 text-center ps-0">
                                            Min
                                          </div>
                                          <div className="col-6 text-center ps-0">
                                            Max
                                          </div>
                                        </div>
                                        <div className="col-2 pe-0">
                                          <div className="card-action d-none" id="SaveOrderHdnBtn" onClick={SaveOrderOfProperties}>
                                            <button type="button" className="btn btn-outline-success" onClick={EnableDragDrop}>Save</button>
                                          </div>
                                          <div className="card-action me-2 mt-2 d-none" id="hdnCancelLink" onClick={CancelDragDrop}>
                                            <label className="crsr-Pointer">
                                              Cancel
                                            </label>
                                          </div>
                                          <div className="card-action" id="changeOrderHdnBtn">
                                            <button type="button" className="btn btn-outline-success" onClick={EnableDragDrop}>Change Order</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" sortable-list"
                                      ref={(el) => seteRef(el)}
                                      id="sortableDivId">
                                      {optionDetailsVar.map((item, i) => {
                                        var returData;
                                        returData = (<div className="row py-2 mydragdropgrid  align-items-center grid" id={`rowId_${item.id}`} key={i} proptype={item.propertyType}>
                                          <div className="col-1 text-center">
                                            <img
                                              src="/images/dragdrop.png"
                                              title="Drag to reorder"
                                              className="w-25 d-none dragdropdotsimg"
                                              alt="Drag"
                                            />
                                          </div>
                                          <div className="col-5 ps-0 radiogrid" propid={item.propertyid} rowcount={i + 1} key={i} id={`VisibleRadioGridId_${item.id}`} proptype={item.propertyType}>
                                            <>
                                              <input
                                                className="form-check-input me-2 uncheckclass"
                                                id={`VisibleRadioId_${item.id}`}
                                                type="checkbox"
                                                defaultChecked={checkpropvalue}
                                                itemid={item.id}
                                                onClick={(e) => changecheckboxstatus(item.id)}
                                                proptype={item.propertyType}
                                              />
                                              <label>
                                                {item.propertyName}
                                              </label>
                                            </>
                                          </div>
                                          <div className="col-2 text-center">
                                            {item.isrequired == 1 ?
                                              <>
                                                <input className="form-check-input me-2" defaultChecked={checkpropvalue} type="checkbox" id={`isRequiredId_${item.id}`} />
                                              </>
                                              :
                                              <>
                                                <input className="form-check-input me-2" type="checkbox" id={`isRequiredId_${item.id}`} />
                                              </>
                                            }
                                          </div>
                                          <div className="col-2">
                                            {item.propertyType == 3 ?

                                              <div className="col-12">
                                                <div className='row px-0'>
                                                  <div className='col-5 text-center pe-0'>
                                                    <input type="text" className="form-control text-center" id={`MinUpdateValueTxt_${item.id}`} placeholder="Min" defaultValue={item.min} />
                                                  </div>
                                                  <div className='col-5 text-center pe-0'>
                                                    <input type="text" className="form-control text-center" id={`MaxUpdateValueTxt_${item.id}`} placeholder="Max" defaultValue={item.max} />
                                                  </div>
                                                </div>
                                              </div>
                                              :
                                              <></>
                                            }
                                          </div>
                                        </div>
                                        );
                                        return returData;
                                      })}
                                      {optionNullDetailsVar.map((item, i) => {
                                        var returData;
                                        returData = (<div className="row py-2 mydragdropgrid  align-items-center grid" id={`rowId_${item.id}`} key={i} proptype={item.propertyType}>
                                          <div className="col-1 text-center">
                                            <img
                                              src="/images/dragdrop.png"
                                              title="Drag to reorder"
                                              className="w-25 d-none dragdropdotsimg"
                                              alt="Drag"
                                            />
                                          </div>
                                          <div className="col-5 ps-0 radiogrid" rowcount={i + 1} key={i} id={`VisibleRadioGridId_${item.id}`} proptype={item.propertyType}>
                                            <>
                                              <input
                                                className="form-check-input me-2 uncheckclass"
                                                id={`VisibleRadioId_${item.id}`}
                                                type="checkbox"
                                                itemid={item.id}
                                              />
                                              <label>
                                                {item.propertyName}
                                              </label>
                                            </>
                                          </div>
                                          <div className="col-2 text-center">
                                            <input className="form-check-input me-2" type="checkbox" id={`isRequiredId_${item.id}`} />
                                          </div>
                                          <div className="col-2">
                                            {item.propertyType == 3 ?

                                              <div className="col-12">
                                                <div className='row'>
                                                  <div className='col-5 text-center pe-0'>
                                                    <input type="text" className="form-control text-center" id={`MinUpdateValueTxt_${item.id}`} placeholder="Min" value={item.min} />
                                                  </div>
                                                  <div className='col-5 text-center pe-0'>
                                                    <input type="text" className="form-control text-center" id={`MaxUpdateValueTxt_${item.id}`} placeholder="Max" value={item.max} />
                                                  </div>
                                                </div>
                                              </div>
                                              :
                                              <></>
                                            }
                                          </div>
                                        </div>
                                        );
                                        return returData;
                                      })}
                                    </div>
                                    {norecord}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                          :
                          <></>
                        }
                        <div className="col-12 py-5">
                          <div className="card-action me-2">
                            <button type="submit" className="loginBtn" onClick={UpdateCategoryItems}>
                              Save Changes
                            </button>
                          </div>
                          <div className="card-action mt-1">
                            <span className="me-3 pt-2 crsr-pointer" onClick={CancelBannerItems}>Cancel</span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PhotoModalPopup className="MainModal" show={show} onHide={closePopupHandler} onClick={savemodalphotos}></PhotoModalPopup>
        </>
        :
        <></>
      }
    </>
  );
}
