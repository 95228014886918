import React from "react";
import { NavigationBar } from "../Navigationbar";
import { Footer } from "../Footer";
import { CDNURL } from "../../js/constants";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { ApiGetCall } from "../../js/connector";
import HelmetMetaTag from "../components/HelmetMetaTag";

export default function DiningTablePads() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  const [discountprice, setdiscountprice] = useState("");
  useEffect(() => {
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[43].value);
        setsectionDesciption(propDetails[44].value);
        setsectionCTAtext(propDetails[45].value);
        GetPrice();
      }
    });
  }
  const GetPrice = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/OrderNow/ProductOrderById/" + 26 + "?path=null").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        console.log(responseRs)
        var salesprice = parseFloat(responseRs.allProducts.salesPrice * 100).toFixed(2);
        setdiscountprice(salesprice);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  return (
    <>
      <HelmetMetaTag
        title="Custom Dining Table Pads | Berger's Table Pads"
        desc="Berger's custom dining table pads are manufactured from high-quality materials to protect and preserve your dining room table. Contact us today!"
      />
      <NavigationBar />
      <div className="container-fluid px-0  extensionSection">
        <div className="row">
          <div className="col-md-7 m-auto pt-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More" />
            </button>
          </div>
          <div className="col-md-5 text-center">
            <img src={CDNURL + "Localimages/DiningTablePadNewGroup.png"} className="img-fluid text-end" alt="Hero Image" />
          </div>
        </div>
      </div>

      <div className="container py-5 colorPara ps-0">
        <ul>
          <li className="liBullet ps-2">
            Shop online at Berger's for top quality{" "}
            <a className="paraLink" href="/products/table-pads">
              dining table pads
            </a>
            , coffee table pads, and protection for all of your home
            furnishings. We make the world’s best custom table pads, designed
            and manufactured to exacting standards, guaranteed at the lowest
            factory-direct price available.
          </li>
        </ul>
      </div>

      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Order Your Measuring Kit</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              Per your request we’ll send you a free product brochure along with
              tracing paper and straightforward instructions on how to get an
              accurate measurement.
            </li>
            <li className="pt-4 liBullet ps-2">
              Many of our customers need only look on the bottom of their table
              for a model number and manufacturer’s name to provide us with
              everything necessary. Our database possesses thousands of model
              numbers on file to ensure the perfect fit. If you don’t see a
              manufacturer or model number on your table, we’ll send you
              everything you need for a correct measurement.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Why is Berger’s a Better Product?</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              If you’re interested in preserving a beautiful table or furnishing
              for a future generation, our dining table pads and furnishing pads
              protect better than other products. Our Ultra Elite Pads are 1/2”
              thick and will protect against everyday spills, accidental heat
              sources up to 550 F, nicks, scratches, and other damage that can
              significantly reduce the value of an expensive piece of furniture.
            </li>
            <li className="pt-4 liBullet ps-2">
              Feel free to read through our website testimonials, or simply type
              Berger’s Table Pads into the search bar of your preferred
              directory to read reviews and ratings from customers. We stand
              behind our table pads with an industry-leading warranty to ensure
              your satisfaction.
            </li>
          </ul>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>{discountprice} Cents Per Square Inch</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              Protect your investment for the unbeatable price of just {discountprice} cents
              per sq inch for our{" "}
              <a data-url="/products/ultra-elite-dining-table-pad" data-id="26" data-staticpath="UltraEliteDiningTablePad" className="subProductOrderNow paraLink">
                Ultra Elite dining table pads
              </a>
              . We’re certain you won’t find a better price for a comparable
              product anywhere. Choose from a palette of leather tone colors,
              including Cranberry, Natural, Mocha, Palomino, Saddle, Chestnut,
              Opal, and Slate. If you prefer a wood grain, we have many to
              choose from, along with solids and Dura-Velvet.
            </li>
            <li className="pt-4 liBullet ps-2">
              Mix and match your favorite colors for the top and bottom of your
              table pad. For additional information or assistance placing your
              order, we have Live Chat and phone assistance available 7 days a
              week.
            </li>
          </ul>
        </div>
      </div>
      <div className="container ps-0">
        <div className="">
          <div className="text-center ps-3">
            <h2>Invisible MagnaLoc System Connects Sections</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              Our patented MagnaLoc system keeps your dining table pads in place
              where they belong. Our exclusive design reduces separation and pad
              sliding- and there’s nothing to do on your part since the MagnaLoc
              system does all the work.
            </li>
            <li className="pt-4 liBullet ps-2">
              Our table pads are designed to last a lifetime- even with everyday
              use. With our quality table pads, your beautiful furniture will
              last for generations. The reason is that we use a solid fiberboard
              core, not layered material most of our competition is using, which
              can trap moisture and lead to mold and mildew forming inside of
              the pad. When stored upright, our pads will not bend or bow.
            </li>
          </ul>
        </div>
      </div>
      <div className="text-center py-5">
        <button type="submit" className="loginBtn pt-3 pb-2 px-3 btnhover subProductOrderNow" data-url="/products/ultra-elite-dining-table-pad" data-id="26" data-staticpath="UltraEliteDiningTablePad">
          <h5>Order Dining Table Pads Now</h5>
        </button>
      </div>
      <WhatCustomerThink isH1Tag="no" />
      <Footer />
    </>
  );
}
