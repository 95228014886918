import { LeftMenu } from "./LeftMenuAdmin";
import { useState, useEffect } from "react";
import $ from 'jquery';
import ModalPopup from "./ModalPopup";
import { ApiGetCall } from "../js/connector";
import { ApiPutCall } from "../js/connector";
import { ApiPostCall } from "../js/connector";
import { ApiDeleteCall } from "../js/connector";
import { CDNURL } from "../js/constants";
import { VerifyLoginToken } from "../js/connector";
export default function ZoneTax() {
    const [overallstate, setoverallstate] = useState({});
    const [zonename, setzonename] = useState("");
    const [zoneTax, setzoneTax] = useState("");
    const [blankzonename, setblankzonename] = useState("");
    const [blankzoneTax, setblankzoneTax] = useState("");
    const [zonetaxlist, setzonetaxlist] = useState([]);
    const [show, setShow] = useState(false);
    const [btnshow, setBtnshow] = useState(false);
    const [modalHead, setmodalHead] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [TempCheck, setTempCheck] = useState(false);
    useEffect(() => {
        window.scroll(0, 0);
        VerifyLoginToken(showhidedivfunc);
        return () => {
            setoverallstate({});
        };
    }, []);
    const showhidedivfunc = () => {
        setTempCheck(true);
        var PathName = window.location.pathname;
        var pathsplit = PathName.split('/');
        var prodActive = pathsplit[2];
        if (prodActive == "AddZone") {
            showadddiv();
        } else if (prodActive == "UpdateZone") {
            showupdatediv();
        }
        else {
            getZoneList();
        }
    }
    const getZoneList = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Checkout/getZoneList").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                setzonetaxlist(responseRs.zoneTax);
            }
        });
    }
    const addzonedivshow = () => {
        window.location.href = "/Zonetax/AddZone";
    }
    const showadddiv = () => {
        $("#Overlay").show();
        $("#LoderId").show();
        setTimeout(function () {
            $("#GridDivShow").addClass("d-none");
            $("#AddZoneDivShow").removeClass("d-none");
            $("#Overlay").hide();
            $("#LoderId").hide();
        }, 500);
    }
    const saveZoneDetails = async () => {
        var temp = 0;
        if (zonename == "") {
            setblankzonename("Required!");
        } else {
            setblankzonename("");
            temp++;
        }

        if (zoneTax == "") {
            setblankzoneTax("Required!");
        } else {
            setblankzoneTax("");
            temp++;
        }

        if (temp >= 2) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                Zone: zonename,
                Tax: zoneTax
            });
            await ApiPostCall("/Checkout/saveZonedetails", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Zone Added Succesfully.");
                        setTimeout(function () {
                            window.location = "/Zonetax";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }
    }
    const UpdateZoneDetails = async () => {
        var vid = parseInt(getUrlParameter("zoneid"));
        var temp = 0;
        if (zonename == "") {
            setblankzonename("Required!");
        } else {
            setblankzonename("");
            temp++;
        }

        if (zoneTax == "") {
            setblankzoneTax("Required!");
        } else {
            setblankzoneTax("");
            temp++;
        }

        if (temp >= 2) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                Zone: zonename,
                Tax: zoneTax
            });
            await ApiPutCall("/Checkout/UpdateZoneDetails/" + vid, raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Zone Updated Succesfully.");
                        setTimeout(function () {
                            window.location = "/Zonetax";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }
    }
    const cancelbutton = () => {
        window.location = "/Zonetax";
    }
    const EditZoneDetails = (vid) => {
        window.location.href = "/Zonetax/UpdateZone/?zoneid=" + vid;
    }
    const showupdatediv = async () => {
        var vid = parseInt(getUrlParameter("zoneid"));
        $("#hdnUserId").val(vid);
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Checkout/EditZoneValues/" + vid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.zoneDetails;
                if (responseRs.status == "Success") {
                    $("#GridDivShow").addClass("d-none");
                    $("#UpdateZoneDivShow").removeClass("d-none");
                    setzonename(sugData.zone);
                    setzoneTax(sugData.tax);
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };
    const isShowPopup = (e) => {
        var vId = e.currentTarget.attributes[3].value;
        $("#hdnUserId").val(vId);
        setShow(true);
        setmodalHead("Delete Zone");
        setModalBody("Are you sure you want to delete this zone?");
        setBtnshow(1);
    };
    const closePopupHandler = () => {
        setShow(false);
    };
    const deleteZone = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        var userid = parseInt($("#hdnUserId").val());
        var raw = "";
        await ApiDeleteCall("/Checkout/DeleteZone/" + userid, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                setShow(false);
                if (responseRs.status == "success") {
                    $(".alert-success").show();
                    $("#AlertMsg").text("Zone Deleted Succesfully.");
                    setTimeout(function () {
                        window.location = "/Zonetax";
                    }, 1500);
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    };
    return (
        <>
            {TempCheck == true ?
                <>
                    <input type="hidden" id="hdnUserId" />
                    <LeftMenu />
                    <div fixed="true" className='container-fluid mainContainer'>
                        <div className="content-wrapper" id="GridDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv">
                                            <div className="cardDiv-header">
                                                <div className="row AlignItems">
                                                    <div className="col-4">
                                                        <h4>Zone/Region</h4>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="card-action">
                                                            <button type="submit" className="loginBtn" onClick={addzonedivshow}>
                                                                Add Zone
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 gridHeader">
                                                    <div className="row">
                                                        <div className="col-5 text-center px-0">
                                                            <b>Zone</b>
                                                        </div>
                                                        <div className="col-4 text-center px-0">
                                                            <b>Tax</b>
                                                        </div>
                                                        <div className="col-3 text-center ps-0">
                                                            <b>Action</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="scrolly-63">
                                                    {zonetaxlist.map((item, i) => {
                                                        var returData;
                                                        returData = (<div className="col-12 grid" key={i}>
                                                            <div className="row" key={i}>
                                                                <div className="col-5 text-center mb-2">
                                                                    <span>{item.zone}</span>
                                                                </div>
                                                                <div className="col-4 text-center">
                                                                    <span>{item.tax}</span>%
                                                                </div>
                                                                <div className="col-3 text-center">
                                                                    <span>
                                                                        <img alt="Edit" src="/images/editIcon.svg" title="Edit Zone"
                                                                            className="iconClass me-1" onClick={() => EditZoneDetails(item.id)} />
                                                                    </span>
                                                                    <span>
                                                                        <img alt="Delete" src="./images/deleteIcon.svg" className="iconClass"
                                                                            title="Delete Zone" userid={item.id} onClick={isShowPopup} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        );
                                                        return returData;
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="AddZoneDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/Zonetax">
                                                            <img alt="Back" src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Add New Zone With Tax
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 pt-3 pb-5">
                                                    <div className="row pb-5">
                                                        <div className="col-6">
                                                            <input type="text" className="form-control" value={zonename} onChange={(e) => setzonename(e.target.value)} />
                                                            <span className="grayTxt">Zone Name</span>
                                                            <br />
                                                            <span style={{ color: "red" }}>{blankzonename}</span>
                                                        </div>
                                                        <div className="col-6">
                                                            <input type="text" className="form-control" value={zoneTax} onChange={(e) => setzoneTax(e.target.value)} />
                                                            <span className="grayTxt">Zone Tax(%)</span>
                                                            <br />
                                                            <span style={{ color: "red" }}>{blankzoneTax}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={cancelbutton}>Cancel</span>
                                                    <button type="submit" className="loginBtn" onClick={saveZoneDetails}>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="UpdateZoneDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/Zonetax">
                                                            <img alt="Back" src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Update Zone With Tax
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 pt-3 pb-5">
                                                    <div className="row pb-5">
                                                        <div className="col-6">
                                                            <input type="text" className="form-control" value={zonename} onChange={(e) => setzonename(e.target.value)} />
                                                            <span className="grayTxt">Zone Name</span>
                                                            <br />
                                                            <span style={{ color: "red" }}>{blankzonename}</span>
                                                        </div>
                                                        <div className="col-6">
                                                            <input type="text" className="form-control" value={zoneTax} onChange={(e) => setzoneTax(e.target.value)} />
                                                            <span className="grayTxt">Zone Tax(%)</span>
                                                            <br />
                                                            <span style={{ color: "red" }}>{blankzoneTax}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={cancelbutton}>Cancel</span>
                                                    <button type="submit" className="loginBtn" onClick={UpdateZoneDetails}>
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalPopup
                        class="MainModal"
                        dataShowhide={btnshow}
                        show={show}
                        data={modalHead}
                        modalBody={modalBody}
                        onHide={closePopupHandler}
                        onClick={deleteZone}
                    ></ModalPopup>
                </>
                :
                <></>
            }
        </>
    )
}