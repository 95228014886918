import { useState, useEffect } from 'react';
import $ from 'jquery';
import React from "react";
import { LeftMenu } from './LeftMenuAdmin';
import { ApiGetCall } from '../js/connector';
import { CDNURL } from '../js/constants';
import { ApiPostCall } from '../js/connector';
import { ApiPutCall } from '../js/connector';
import PhotoModalPopup from './photomodal';
import { VerifyLoginToken } from '../js/connector';
export function TableClothAdmin() {
    const [overallstate, setoverallstate] = useState({});
    const [tableclothitems, settableclothitems] = useState([]);
    const [norecord, setnorecord] = useState("");
    const [blankname, setblankname] = useState("");
    const [blankImageurl, setblankImageurl] = useState("");
    const [finalImage, setFinalImage] = useState("");
    const [tableclothname, settableclothname] = useState("");
    const [checkvalue, setCheckvalue] = useState(true);
    const [show, setShow] = useState(false);
    const [TempCheck, setTempCheck] = useState(false);
    useEffect(() => {
        VerifyLoginToken(showhidedivfunc);
        return () => {
            setoverallstate({});
        };
    }, []);
    const showhidedivfunc = () => {
        setTempCheck(true);
        var PathName = window.location.pathname;
        var pathsplit = PathName.split('/');
        var prodActive = pathsplit[2];
        if (prodActive == "AddTableCloth") {
            showadddiv();
        } else if (prodActive == "UpdateTableCloth") {
            showupdatediv();
        }
        else {
            GetTableClothMenu();
        }
    }
    const GetTableClothMenu = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Category/GetTableClothItems").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.tableClothDetails;
                var sugArray = [];
                if (sugData.length == 0) {
                    sugArray.push(
                        <div className="col-12 text-center mt-5">
                            <label>No Record Found</label>
                        </div>
                    );
                    setnorecord(sugArray);
                } else {
                    settableclothitems(sugData);
                }
            }
        });
    }
    const AddTableCloth = () => {
        window.location.href = "/TableClothesAdmin/AddTableCloth";
    }
    const showadddiv = () => {
        $("#HdnFlag").val(1);
        localStorage.removeItem("base64");
        $("#Overlay").show();
        $("#LoderId").show();
        setTimeout(function () {
            $("#GridDivShow").addClass("d-none");
            $("#AddTableClothDivShow").removeClass("d-none");
            $("#Overlay").hide();
            $("#LoderId").hide();
        }, 500);
    }
    const CancelPage = () => {
        window.location = "/TableClothesAdmin";
    }
    const AddSaveCloth = async () => {
        var temp = 0;
        var finalSendImg = null;
        var imgflag = $(".slideprodimg:visible").attr('imgflag');
        var baseImage = null;
        if ($("#ImgAddAppend").html() != "") {
            if (imgflag == 1) {
                baseImage = $(".slideprodimg:visible").attr("base64");
                var parseimg = baseImage.split(",");
                finalSendImg = parseimg[1];
            } else {
                baseImage = $(".slideprodimg:visible").attr("src");
                var vsrcsplit = baseImage.split('/');
                var last_part = vsrcsplit[vsrcsplit.length - 1];
                finalSendImg = last_part;
            }
        }

        if (tableclothname == "") {
            setblankname("Required!");
        } else {
            setblankname("");
            temp++;
        }

        if (baseImage == null) {
            setblankImageurl("Required!");
        } else {
            setblankImageurl("");
            temp++;
        }

        if (temp >= 2) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                name: tableclothname,
                imageurl: finalSendImg
            });
            await ApiPostCall("/Category/SaveClothItem", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Table Cloth Added Successfully.");
                        setTimeout(function () {
                            window.location = "/TableClothesAdmin";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        } else {
            $("#Overlay").hide();
            $("#LoderId").hide();
        }
    }
    const EditTableCloth = (vId) => {
        localStorage.removeItem("base64");
        // var vId = parseInt(e.currentTarget.attributes[3].value);
        window.location.href = "/TableClothesAdmin/UpdateTableCloth/?id=" + vId;
    }
    const showupdatediv = async () => {
        $("#HdnFlag").val(2);
        var vId = parseInt(getUrlParameter("id"));
        $("#HdnUserId").val(vId);
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Category/GetTableClothUpdateDetails/" + vId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.updateDetails;
                if (responseRs.status == "Success") {
                    $("#GridDivShow").addClass("d-none");
                    $("#UpdateTableClothDivShow").removeClass("d-none");
                    settableclothname(sugData.name);
                    $(".hdnTableClothImageClose").removeClass('d-none');
                    setFinalImage(CDNURL + "Gallery/" + sugData.clothImage);
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const UpdateSaveCloth = async () => {
        var userid = parseInt($("#HdnUserId").val());
        var temp = 0;
        var finalSendImg = null;
        var imgflag = $(".slideprodimg:visible").attr('imgflag');
        var baseImage = null;
        if ($("#ImgUpdateAppend").html() != "") {
            if (imgflag == 1) {
                baseImage = $(".slideprodimg:visible").attr("base64");
                var parseimg = baseImage.split(",");
                finalSendImg = parseimg[1];
            } else if (imgflag == 2) {
                baseImage = $(".slideprodimg:visible").attr("src");
                var vsrcsplit = baseImage.split('/');
                var last_part = vsrcsplit[vsrcsplit.length - 1];
                finalSendImg = last_part;
            }
            else {
                baseImage = $(".myupdatedimg:visible").attr("src");
                var vsrcsplit = baseImage.split('/');
                var last_part = vsrcsplit[vsrcsplit.length - 1];
                finalSendImg = last_part;
            }
        }
        if (tableclothname == "") {
            setblankname("Required!!");
        } else {
            setblankname("");
            temp++;
        }

        if ($("#ImgUpdateAppend").html() == "") {
            setblankImageurl("Required!!");
        } else {
            setblankImageurl("");
            temp++;
        }
        if (temp >= 2) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                name: tableclothname,
                imageurl: finalSendImg
            });
            await ApiPutCall("/Category/UpdatetableCloth/" + userid, raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Table Cloth Updated Succesfully.");
                        setTimeout(function () {
                            window.location = "/TableClothesAdmin";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }
    };
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };
    const changeStatus = async (itemid) => {
        var vStatus;
        if ($("#VisibleId_" + itemid).is(":checked")) {
            $("#VisibleId_" + itemid).prop("checked", true);
            vStatus = 1;
        } else {
            $("#VisibleId_" + itemid).removeAttr("checked");
            vStatus = 0;
        }
        $("#Overlay").show();
        $("#LoderId").show();
        var raw = JSON.stringify({
            Status: vStatus,
        });
        await ApiPutCall("/Category/VisibleTableCloth/" + itemid, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "Success") {
                    $(".alert-success").show();
                    $("#AlertMsg").text("Updated Successfully.");
                    setTimeout(function () {
                        $(".alert-success").hide();
                        $("#AlertMsg").text("");
                    }, 1500);
                    if (responseRs.visibleData.isActive == 1) {
                        setCheckvalue(true);
                    } else {
                        setCheckvalue(false);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const removeTableClothImage = () => {
        if ($("#HdnFlag").val() == 2) {
            $("#ImgUpdateAppend").html("");
        } else {
            $("#ImgAddAppend").html("");
        }
    }
    const isShowPopup = (e) => {
        setShow(true);
    };
    const closePopupHandler = () => {
        setShow(false);
    };
    const savemodalphotos = () => {
        var imgboxlen = $(".mainimgbox:visible").length;
        var count = 0;
        var finalhtml = "";
        $(".hdnTableClothImageClose").removeClass('d-none');
        $(".computerimgs").each(function (i) {
            i += imgboxlen;
            var base64 = $(this).attr('base64');
            var html = '<div class="col-3 py-2" id="ProductRow_' + i + '">' +
                '<div class="imagebox">' +
                '<div class="col-12">' +
                '<div class="mainimgbox text-center">' +
                '<img alt="dummy" src=' + base64 + ' class="slideprodimg" id="imgId_' + i + '" imgflag="1" base64=' + base64 + ' status="1"  />' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>';
            finalhtml += html;
            count = i;
        });
        $(".myimgredborder").each(function (i) {
            i += count + 1;
            var imggallery = $(this).attr('src');
            var html = '<div class="col-3 py-2" id="ProductRow_' + i + '">' +
                '<div class="imagebox">' +
                '<div class="col-12">' +
                '<div class="mainimgbox text-center">' +
                '<img alt="dummy" src=' + imggallery + ' class="slideprodimg" id="imgId_' + i + '" imgflag="2" status="1"  />' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>';
            finalhtml += html;
        });
        if ($("#HdnFlag").val() == 2) {
            $("#ImgUpdateAppend").html("");
            $("<div>" + finalhtml + "</div><br/>")
                .attr({
                    class: "crsr-pointer row"
                }).appendTo("#ImgUpdateAppend");
        } else {
            $("#ImgAddAppend").html("");
            $("<div>" + finalhtml + "</div><br/>")
                .attr({
                    class: "crsr-pointer row"
                }).appendTo("#ImgAddAppend");
        }
        if ($(".mainimgbox:visible").length > 1) {
            setblankImageurl("You can upload only one image");
            if ($("#HdnFlag").val() == 2) {
                $("#ImgUpdateAppend").html("");
            } else {
                $("#ImgAddAppend").html("");
            }
        }
        else {
            setblankImageurl("");
        }

        setShow(false);
    }
    return (
        <>
            {TempCheck == true ?
                <>
                    <input type="hidden" id="HdnUserId" />
                    <input type="hidden" id="HdnFlag" />
                    <LeftMenu />
                    <div fixed="true" className='container-fluid mainContainer'>
                        <div className="content-wrapper" id="GridDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv">
                                            <div className="cardDiv-header">
                                                <div className="row AlignItems">
                                                    <div className="col-md-8">
                                                        <h4>Table Cloth</h4>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="card-action">
                                                            <button type="submit" className="loginBtn btnhover" onClick={AddTableCloth}>
                                                                Add New
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 gridHeader">
                                                    <div className="row">
                                                        <div className="col-2 text-center">
                                                            <b>Image</b>
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            <b>Name</b>
                                                        </div>
                                                        <div className="col-3 text-center pe-0">
                                                            <b>Is Active?</b>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <b>Action</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='scrolly-63'>
                                                    {tableclothitems.map((item, i) => {
                                                        var returData;
                                                        var profileImage =
                                                            CDNURL + "Gallery/" + item.clothImage;
                                                        returData = (
                                                            <div className="col-12 grid" key={i} id={`row_${item.id}`}>
                                                                <div className="row" key={i}>
                                                                    <div className="col-2 text-center mb-2">
                                                                        <img src={profileImage} alt="dummy" className="MiniIcon" />
                                                                    </div>
                                                                    <div className="col-4 text-center mt-3">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="col-3 text-center mt-3">
                                                                        {item.isActive == 1 ? (
                                                                            <input className="form-check-input me-2" defaultChecked={checkvalue} id={`VisibleId_${item.id}`}
                                                                                type="checkbox" value="" userid={item.id} onClick={(e) => changeStatus(item.id)} />
                                                                        ) : (
                                                                            <input className="form-check-input me-2" id={`VisibleId_${item.id}`}
                                                                                type="checkbox" value="" userid={item.id} onClick={(e) => changeStatus(item.id)} />
                                                                        )}
                                                                    </div>
                                                                    <div className="col-3 text-center mt-3">
                                                                        <span>
                                                                            <img alt='Edit' src="./images/editIcon.svg" title="Edit Banner" className="iconClass me-1"
                                                                                userid={item.id} onClick={(e) => EditTableCloth(item.id)} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                        return returData;
                                                    })}
                                                    {norecord}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="AddTableClothDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/TableClothesAdmin">
                                                            <img alt='Back' src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Add New Table Cloth
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3 px-5">
                                                <div className="row">
                                                    <div className="col-12 pt-3">
                                                        <div className="row">
                                                            <div className="col-6 ">
                                                                <input type="text" className="form-control" value={tableclothname}
                                                                    onChange={(e) => settableclothname(e.target.value)} />
                                                                <div className='d-flex justify-content-between'>
                                                                    <span className="grayTxt me-2">Name</span>
                                                                    <span style={{ color: "red" }}>{blankname}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 pt-3">
                                                                    <label className="selectImgBtn" onClick={isShowPopup}>
                                                                        Choose Image
                                                                    </label>
                                                                    <img alt='Close' src={CDNURL + "LocalImages/closeicon.png"} className="ms-3 h-25px w-10 d-none hdnTableClothImageClose" onClick={removeTableClothImage} />
                                                                    <label className="txtRed py-3 ps-3">Note: Ideal size for images should be 250px tall and 550px wide.</label>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div id="ImgAddAppend"></div>
                                                                    </div>
                                                                    <h5 style={{ color: "red" }}>{blankImageurl}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={CancelPage}>Cancel</span>
                                                    <button type="submit"
                                                        className="loginBtn btnhover" onClick={AddSaveCloth}>Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="UpdateTableClothDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/TableClothesAdmin">
                                                            <img alt='Back' src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Update Table Cloth
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3 px-5">
                                                <div className="row">
                                                    <div className="col-12 pt-3">
                                                        <div className="row">
                                                            <div className="col-6 ">
                                                                <input type="text" className="form-control" value={tableclothname}
                                                                    onChange={(e) => settableclothname(e.target.value)} />
                                                                <div className='d-flex justify-content-between'>
                                                                    <span className="grayTxt me-2">Name</span>
                                                                    <span style={{ color: "red" }}>{blankname}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 pt-3">
                                                                    <label className="selectImgBtn" onClick={isShowPopup}>
                                                                        Choose Image
                                                                    </label>
                                                                    <img alt='Close' src={CDNURL + "LocalImages/closeicon.png"} className="ms-3 h-25px w-10 d-none hdnTableClothImageClose" onClick={removeTableClothImage} />
                                                                    <label className="txtRed py-3 ps-3">Note: Ideal size for images should be 250px tall and 550px wide.</label>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div id="ImgUpdateAppend">
                                                                            <img alt='Final Image' src={finalImage} className="img-fluid myupdatedimg" />
                                                                        </div>
                                                                    </div>
                                                                    <h5 style={{ color: "red" }}>{blankImageurl}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={CancelPage}>Cancel</span>
                                                    <button type="submit"
                                                        className="loginBtn btnhover" onClick={UpdateSaveCloth}>Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PhotoModalPopup className="MainModal" show={show} onHide={closePopupHandler} onClick={savemodalphotos}></PhotoModalPopup>
                </>
                :
                <></>
            }
        </>
    )
}