import React from "react";
import { CDNURL } from "../../js/constants";
import { useState, useEffect } from "react";
import $ from "jquery";
import ReactStars from "react-rating-stars-component";
import { ApiGetCall } from "../../js/connector";
export function WhatCustomerThink(props) {
  const [Reviews, setReviews] = useState([]);
  const [overallstate, setoverallstate] = useState({});
  const [reviewstatus, setreviewstatus] = useState([]);
  useEffect(() => {
    window.scroll(0, 0);
    GetCustomerReview();
    return () => {
      setoverallstate({});
    };
  }, []);
  const GetCustomerReview = async () => {
    await ApiGetCall("/CustomerReview/GetCustomerReview/3").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        var sugData = responseRs.reviewsDetails;
        if (responseRs.status == "success") {
          if (responseRs.reviewsDetails != "") {
            if (sugData.length == 0) {
              setreviewstatus(0);
            } else {
              setreviewstatus(1);
            }
            setReviews(sugData);
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  };
  const viewallTestimonial = () => {
    window.location.href = "/testimonial";
  };
  return (
    <>
      {reviewstatus == 1 ? (
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              {props.isH1Tag == "no" ? (
                <h2 className="h1">What Our Customers Think</h2>
              ) : (
                <h1>What Our Customers Think</h1>
              )}

              <p className="prodhr" />
            </div>
            <div className="row pt-3">
              <div className="col-12 ">
                <div className="row ">
                  {Reviews.map((item, i) => {
                    var returData;
                    var profileImage = CDNURL + "Gallery/" + item.imageUrl;
                    returData = (
                      <div className="col-lg-4 col-12 px-5 ptmy-2" key={i}>
                        <div className="fisrtDiv p-4 col-12">
                          {item.imageUrl != null ? (
                            <img
                              src={profileImage}
                              className="clientImg"
                              alt="Customer"
                            />
                          ) : (
                            <img
                              src={CDNURL + "Localimages/homeReview.png"}
                              className="clientImg"
                              alt="Customer"
                            />
                          )}
                          <p className="profileTxt pb-3 pt-2">{item.comment}</p>
                          <span className="fs-14 brownTxt font-bold">
                            {item.nickname != "" ? (
                              <h5>{item.nickName}</h5>
                            ) : (
                              <h5>{item.name}</h5>
                            )}
                          </span>
                          <ReactStars
                            size={25}
                            isHalf={true}
                            value={item.rating}
                            activeColor="#8C4D36"
                            edit={false}
                          />
                        </div>
                      </div>
                    );
                    return returData;
                  })}
                  <div className="col-12 text-center pt-3">
                    <button
                      type="submit"
                      className="loginBtn py-2"
                      onClick={viewallTestimonial}
                    >
                      View All Testimonials
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
