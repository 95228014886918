import { LeftMenu } from "./LeftMenuAdmin";
import $ from 'jquery';
import ModalPopup from "./ModalPopup";
import { useState, useEffect } from "react";
import { CDNURL } from "../js/constants";
import { ApiGetCall } from "../js/connector";
import { ApiPostCall } from "../js/connector";
import { VerifyLoginToken } from "../js/connector";
export default function UploadImages() {
    const [btnshow, setBtnshow] = useState(false);
    const [show, setShow] = useState(false);
    const [modalHead, setmodalHead] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [overallstate, setoverallstate] = useState({});
    const [imageDetailsVar, setimageDetailsVar] = useState([]);
    const [norecord, setNorecord] = useState("");
    const [TempCheck, setTempCheck] = useState(false);
    useEffect(() => {
        window.scroll(0, 0);
        VerifyLoginToken(GetUploadImage);
        return () => {
            setoverallstate({});
        };
    }, []);
    const GetUploadImage = async () => {
        setTempCheck(true);
        $("#Overlay").show();
        $("#LoderId").show();

        await ApiGetCall("/UploadImages/GetUploadedImages").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.uploadImagesDetails;
                var sugArray = [];
                var i = 1;
                if (responseRs.status == "success") {
                    if (responseRs.uploadImagesDetails != "") {
                        setNorecord("");
                        setimageDetailsVar(sugData);
                    } else {
                        sugArray.push(
                            <div className="col-12 gridHeader text-center" key={i}>
                                <label>No Record Found</label>
                            </div>
                        );
                        setNorecord(sugArray);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const closePopupHandler = () => {
        setShow(false);
    };
    const uploadphoto = () => {
        setShow(true);
        setmodalHead("Upload Image");
        setBtnshow(3);
        setModalBody(<div className="row">
            <div className="col-12 mt-2">
                <label htmlFor="customFile" className="selectImgBtn">
                    Choose Image
                </label>
            </div>
            <span style={{ color: "red" }} className="d-none" id="requiredId">Required.</span>
            <div className="col-12 mt-2">
                <input type="file" className="form-control d-none" id="customFile" onChange={imagesUpload} accept=".png, .jpg, .jpeg, .svg" />
                <div id="ImgAppend" className="mt-3"></div>
            </div>
        </div>);
    }
    const MAX_WIDTH = 300;
    const MAX_HEIGHT = 400;
    const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.7;
    const imagesUpload = (e) => {
        $("#ImgAppend").html("");
        const file = e.target.files;
        const len = file.length;
        $.each(file, function (i, v) {
            var n = i + 1;
            const blobURL = URL.createObjectURL(file[i]);
            const img = new Image();
            img.src = blobURL;
            img.onload = function (event) {
                URL.revokeObjectURL(this.src);
                const [newWidth, newHeight] = calculateSize(
                    img,
                    MAX_WIDTH,
                    MAX_HEIGHT
                );
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(
                    (blob) => {
                        var reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = function () {
                            var base64data = reader.result;
                            $("<img/>")
                                .attr({
                                    src: URL.createObjectURL(blob),
                                    class: "imgClass me-3",
                                    id: "imgId",
                                    base64: base64data,
                                    alt: "Image"
                                })
                                .appendTo("#ImgAppend");
                        };
                    },
                    MIME_TYPE,
                    QUALITY
                );
                $("#requiredId").addClass("d-none");

            };
        });
    };
    const calculateSize = (img, maxWidth, maxHeight) => {
        let width = img.width;
        let height = img.height;
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
        }
        return [width, height];
    };
    const SaveUploadedImage = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        var filename = $("#ImgAppend").html();
        if (filename == "") {
            $("#requiredId").removeClass("d-none");
            $("#Overlay").hide();
            $("#LoderId").hide();
        } else {
            $("#requiredId").addClass("d-none");
            var imgsplit1 = $("#imgId").attr("base64");
            imgsplit1 = imgsplit1.split(",");
            var finalimagesend = imgsplit1[1];
            var raw = JSON.stringify({
                ImageUrl: finalimagesend
            });

            await ApiPostCall("/UploadImages/SaveUploadImages", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        setShow(false);
                        $(".alert-success").show();
                        $("#AlertMsg").text("Image Added Successfully.");
                        setTimeout(function () {
                            window.location = "/UploadImages";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }
    }
    const ShowPicture = (e) => {
        var currentSrc = e.currentTarget.currentSrc;
        setShow(true);
        setmodalHead("Uploaded Image");
        setModalBody(<div className="text-center"><img src={currentSrc} alt="dummy" className="img-fluid" /></div>);
        setBtnshow(2);
    };
    const clipboardclick = (e) => {
        var userid = e.target.attributes[3].value;
        var url = e.target.attributes[2].value;
        var temp = $("<input>");
        $("body").append(temp);
        temp.val(url).select();
        document.execCommand("copy");
        temp.remove();
        $(".urlcopyclass").text("");
        $("#UrlId_" + userid).text("URL copied!");
        setTimeout(function () {
            $("#UrlId_" + userid).text("");
        }, 1500);
    }
    return (
        <>
            {TempCheck == true ?
                <>
                    <LeftMenu />
                    <div fixed="true" className='container-fluid mainContainer'>
                        <div className="content-wrapper" id="GridDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv">
                                            <div className="cardDiv-header">
                                                <div className="row AlignItems">
                                                    <div className="col-4">
                                                        <h4>Upload Images</h4>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="card-action">
                                                            <button type="submit" className="loginBtn" onClick={uploadphoto}>
                                                                Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 gridHeader">
                                                    <div className="row">
                                                        <div className="col-2 text-center pe-0">
                                                            <b>Image</b>
                                                        </div>
                                                        <div className="col-9 text-center pe-0">
                                                            <b>Image URL</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="scrolly-63">
                                                    {imageDetailsVar.map((item, i) => {
                                                        var returData;
                                                        var profileImage = CDNURL + "Gallery/" + item.imageUrl;
                                                        returData = (<div className="col-12 grid" key={i}>
                                                            <div className="row" key={i}>
                                                                <div className="col-2 text-center">
                                                                    <img src={profileImage} alt="dummy" className="MiniIcon" onClick={ShowPicture} />
                                                                </div>
                                                                <div className="col-9 text-center mt-3">
                                                                    <span>{profileImage}</span>
                                                                </div>
                                                                <div className="col-1 text-center mt-3">
                                                                    <img alt="Copy" src="./images/copyurl.svg" className="h-20" profileimg={profileImage} userid={item.id} title="click to copy" onClick={(e) => clipboardclick(e)} />
                                                                </div>
                                                                <div className="col-12 text-end">
                                                                    <h6 className="urlcopyclass" id={`UrlId_${item.id}`}></h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        );
                                                        return returData;
                                                    })}
                                                    {norecord}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalPopup class="MainModal" dataShowhide={btnshow} show={show}
                        data={modalHead} modalBody={modalBody} onHide={closePopupHandler} onClick={SaveUploadedImage}>
                    </ModalPopup>
                </>
                :
                <></>
            }
        </>
    )
}