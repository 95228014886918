import React from "react";
import { useState, useEffect } from "react";
import { ApiGetCall } from "../js/connector";
import $ from "jquery";
import { CDNURL } from "../js/constants";
export function Footer() {
  const [overallstate, setoverallstate] = useState({});
  const [sectionContact, setsectionContact] = useState("");
  const [sectionEmail, setsectionEmail] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
    myFunction();
    return () => {
      setoverallstate({});
    };
  }, []);
  const myFunction = async () => {
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        setsectionContact(propDetails[26].value);
        setsectionEmail(propDetails[27].value);
      }
    });
  }
  const contactus = () => {
    window.location.href = "/contact";
  };
  const OpenWebsite = (e) => {
    var website = e.currentTarget.attributes[3].value;
    window.open(website);
  };

  return (
    <>
      {/* footer */}
      <div className="container-fluid px-0 footerClass">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 py-5 px-5 ">
                <div className="row textSmCenter">
                  <div className="col-12">
                    <img src={CDNURL + "Localimages/Final_Logo_2.svg"} className="img-fluid mb-2 ps-1" alt="Logo" />
                  </div>
                </div>
                <span className="pt-2">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-2">
                          <img
                            src={CDNURL + "Localimages/location.svg"}
                            className="img-fluid"
                            alt="Location"
                          />
                        </div>
                        <div className="col-10">
                          Berger’s Table Pad Factory, Indianapolis IN<br />
                          <a href="tel:+18007372165" className="text-decoration-none" style={{ color: "black" }}>
                            Toll Free: 1-800-737-2165
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
                <span className="pt-2">
                  <div className="row">
                    <div className="col-12 pt-2">
                      <div className="row">
                        <div className="col-2 pe-0">
                          <img
                            src={CDNURL + "Localimages/email.svg"}
                            className="img-fluid"
                            alt="Email"
                          />
                        </div>
                        <div className="col-10">
                          <a href="mailto: wholesale@tablepads.com" className="text-decoration-none" style={{ color: "black", fontSize: "17px" }}>
                            wholesale@tablepads.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div className="col-lg-2 col-md-6 col-12 py-5 px-3 textSmCenter">
                <h5 className="fs-600">POPULAR LINKS</h5>
                <p className="footerLinks pt-2">
                  <a href="/products/table-pads" className="py-2">Products</a>
                  <br />
                  <a href="/colors" className="py-2">Colors</a>
                  <br />
                  <a href="/table-cloth" className="py-2">Table Cloths</a>
                  <br />
                  <a href="/how-to-measure-for-a-table-pad" className="py-2">Measuring Your Table</a>
                  <br />
                  <a href="/about-us" className="py-2">About Us</a>
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-12 py-5 px-3 text-center">
                <h5 className="fs-600 pb-2">REQUEST MORE INFORMATION</h5>
                <button
                  className=" ContactBtn btnhover"
                  type="submit"
                  onClick={contactus}
                >
                  Contact Us
                </button>
              </div>
              <div className="col-lg-3 col-md-6 col-12 py-5 px-3 text-center">
                <h5 className="fs-600 pb-2">WRITE REVIEW & COMMENTS</h5>
                <p>
                  <img
                    src={CDNURL + "Localimages/websitelogo.png"}
                    // src="/images/websitelogo.png"
                    className="img-fluid me-3 crsr-pointer"
                    title="Website"
                    onClick={OpenWebsite}
                    web="/testimonials"
                    alt="Website"
                  />
                  <img
                    src={CDNURL + "Localimages/googlelogo.png"}
                    // src="/images/googlelogo.png"
                    className="img-fluid me-3 crsr-pointer"
                    title="Google"
                    onClick={OpenWebsite}
                    web="https://www.google.com/search?hl=en-IN&gl=in&q=Berger%27s+Table+Pad+Factory&ludocid=10969180522338129860&lsig=AB86z5Wp-93H62Mr7v1gZFqNt9pD#lrd=0x886b5745b52f52dd:0x983a5b915f1917c4,3"
                    alt="Google"
                  />
                  <img
                    src={CDNURL + "Localimages/facebook_logo.png"}
                    // src="/images/facebook_logo.png"
                    className="img-fluid me-3 crsr-pointer"
                    title="Facebook"
                    onClick={OpenWebsite}
                    web="https://www.facebook.com/bergerstablepads/"
                    alt="Facebook"
                  />
                  {/* <img
                    src="/images/redlogo.png"
                    className="img-fluid me-3 crsr-pointer"
                    title="YELP"
                    onClick={OpenWebsite}
                    web="https://www.yelp.com/writeareview/biz/NZ3-S0F26OS_rQv3lNTUIw?return_url=%2Fbiz%2FNZ3-S0F26OS_rQv3lNTUIw"
                  /> */}
                  <img
                    src={CDNURL + "Localimages/yplogo.png"}
                    // src="/images/yplogo.png"
                    className="img-fluid me-3 crsr-pointer"
                    title="YP"
                    onClick={OpenWebsite}
                    web="https://www.yellowpages.com/indianapolis-in/mip/bergers-table-pad-factory-3979859#reviews"
                    alt="YP"
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center pb-5">
              <span>
                © 2020 Berger's Table Pad Factory | All Rights Reserved |{" "}
                <a href="/sitemap" className="txtRed text-decoration-none">
                  Sitemap
                </a>{" "}
                |{" "}
                <a href="/privacy" className="txtRed text-decoration-none">
                  Privacy Policy
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
