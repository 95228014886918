import { ApiGetCall } from "../../js/connector";
import React, { useEffect, useState } from "react";
import $ from "jquery";
const DeliverQuality = (props) => {
  const [overallstate, setoverallstate] = useState({});
  const [MainTitle, setMainTitle] = useState("");
  const [VideoTitle, setVideoTitle] = useState("");
  const [VideoDescription, setVideoDescription] = useState("");
  const [Video, setVideo] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
    myFunction();
    return () => {
      setoverallstate({});
    };
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setMainTitle(propDetails[0].value);
        setVideoTitle(propDetails[1].value);
        setVideoDescription(propDetails[2].value);
        setVideo(propDetails[3].value);
      }
    });
  };
  return (
    <>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            {props.isH1Tag == "no" ? (
              <h2 className="shopAdTitle">{MainTitle}</h2>
            ) : (
              <h1 className="shopAdTitle">{MainTitle}</h1>
            )}

            <p className="prodhr" />
          </div>
          <div className="row pt-3">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-6 col-12 deliverTxt p-2">
                  <span className="TxtHead">{VideoTitle}</span>
                  <div
                    className="TxtPara pt-2"
                    dangerouslySetInnerHTML={{ __html: VideoDescription }}
                  ></div>
                </div>
                <div className="col-lg-6 col-12 px-0">
                  <iframe
                    title="We Deliver Quality Table Pads!"
                    width="100%"
                    height="100%"
                    src={Video}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeliverQuality;
