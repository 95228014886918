import { useState, useEffect } from "react"
import $ from 'jquery';
import React from "react";
import { ApiPostCall } from "../js/connector";
export function DealersLogin() {
    const [state, setState] = useState({});
    const [UserCode, setUserCode] = useState("");
    const [pwd, setPwd] = useState("");
    const [blankUserCode, setBlankUserCode] = useState("");
    const [blankPassword, setBlankPassword] = useState("");
    var staticpath = window.localStorage.getItem("staticpath");
    useEffect(() => {
        window.scroll(0, 0);
        return () => {
            setState({});
        };
    }, []);
    const loginUser = async () => {
        var temp = 0;
        if (UserCode == "") {
            setBlankUserCode("Required!");
        } else {
            setBlankUserCode("");
            temp++;
        }
        if (pwd == "") {
            setBlankPassword("Required!");
        } else {
            setBlankPassword("");
            temp++;
        }

        if (temp >= 2) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                "UserCode": UserCode,
                "Password": pwd
            });
            await ApiPostCall("/DealersLogin/Login", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        localStorage.setItem('DealersUserCode', responseRs.data.userCode);
                        localStorage.setItem('DealersEmail', responseRs.data.email);
                        window.location = "/products/table-pads";
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }
    }
    const LoginWithEnter = (e) => {
        if (e.keyCode == 13) {
            loginUser();
        }
    }
    return (
        <div className="DealersLoginDiv" >
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-2-strong mx-auto">
                            <div className="card-body p-5 ">
                                <div className="mb-4">
                                    <img src="./images/Logo.svg" className="img-fluid" alt="Logo" />
                                </div>
                                <div className="col-12 text-center">
                                    <h5 className="fs-600 checked">Distributor Portal</h5>
                                </div>
                                <div className="my-4">
                                    <input type="email" className="form-control" value={UserCode} onChange={e => setUserCode(e.target.value)} placeholder="User Code" onKeyDown={LoginWithEnter} />
                                    <span style={{ color: "red" }}>{blankUserCode}</span>
                                </div>
                                <div className="mb-5">
                                    <input type="password" className="form-control" value={pwd} onChange={e => setPwd(e.target.value)} placeholder="Password" onKeyDown={LoginWithEnter} />
                                    <span style={{ color: "red" }}>{blankPassword}</span>
                                </div>
                                <div className="col-12 text-center">
                                    <button type="submit" className="loginBtn btnhover" onClick={loginUser}>LOGIN</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}