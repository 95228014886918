import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { useState, useEffect } from "react";
import $ from "jquery";
import { ApiGetCall } from "../js/connector";
import { ApiPostCall } from "../js/connector";
export default function Checkout() {
  const [checkvalue, setCheckvalue] = useState(true);
  const [overallstate, setoverallstate] = useState({});
  const [shippingprice, setshippingprice] = useState("");
  const [subtotalprice, setsubtotal] = useState("");
  const [indianTax, setindianTax] = useState("");
  const [grandTotal, setgrandTotal] = useState("");
  const [zonetaxlist, setzonetaxlist] = useState([]);
  const [zonetaxName, setzonetaxName] = useState("");
  const [indianTaxTotal, setindianTaxTotal] = useState("");

  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [mobile, setmobile] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [city, setcity] = useState("");
  const [company, setcompany] = useState("");
  const [taxnumber, settaxnumber] = useState("");
  const [year, setyear] = useState([]);

  const [blankfname, setblankfname] = useState("");
  const [blanklname, setblanklname] = useState("");
  const [blankemail, setblankemail] = useState("");
  const [blankmobile, setblankmobile] = useState("");
  const [blankaddress1, setblankaddress1] = useState("");
  const [blankzipcode, setblankzipcode] = useState("");
  const [blankcity, setblankcity] = useState("");
  const [blankzone, setblankzone] = useState("");
  const [validphone, setvalidphone] = useState("");

  const [shipfname, setshipfname] = useState("");
  const [shiplname, setshiplname] = useState("");
  const [shipemail, setshipemail] = useState("");
  const [shipphone, setshipphone] = useState("");
  const [shipmobile, setshipmobile] = useState("");
  const [shipaddress1, setshipaddress1] = useState("");
  const [shipaddress2, setshipaddress2] = useState("");
  const [shipzipcode, setshipzipcode] = useState("");
  const [shipcity, setshipcity] = useState("");
  const [shipcompany, setshipcompany] = useState("");
  const [shiptaxnumber, setshiptaxnumber] = useState("");

  const [blankshipfname, setblankshipfname] = useState("");
  const [blankshiplname, setblankshiplname] = useState("");
  const [blankshipemail, setblankshipemail] = useState("");
  const [blankshipmobile, setblankshipmobile] = useState("");
  const [blankshipaddress1, setblankshipaddress1] = useState("");
  const [blankshipzipcode, setblankshipzipcode] = useState("");
  const [blankshipcity, setblankshipcity] = useState("");
  const [blankshipzone, setblankshipzone] = useState("");
  const [cardtype, setcardtype] = useState("Master Card");
  const [cardNumber, setcardNumber] = useState("");
  const [cardcvv, setcardcvv] = useState("");
  const [customerNote, setcustomerNote] = useState("");

  const [blankcardNumber, setblankcardNumber] = useState("");
  const [blankcardcvv, setblankcardcvv] = useState("");
  const [blankcardtype, setblankcardtype] = useState("");
  const [blankexpirationdate, setblankexpirationdate] = useState("");
  const [ItemGrid, setItemGrid] = useState([]);
  const [BillingAddresses, setBillingAddresses] = useState([]);
  var storedNames = JSON.parse(localStorage.getItem("addtocart"));
  if (storedNames != null) {
    var rushprice = storedNames.RushPrice;
  }
  var Discount = localStorage.getItem("Discount");
  var DiscountType = localStorage.getItem("DiscountType");
  var DiscountAmount = localStorage.getItem("DiscountAmount");
  var BaseTotalAMount = localStorage.getItem("BaseTotalAMount");
  var couponCode = localStorage.getItem("couponCode");
  var UserCode = localStorage.getItem("DealersUserCode");
  var discountTypeText = "";
  if (DiscountType == "percent") {
    discountTypeText = "(" + Discount + "%)";
  }
  useEffect(() => {
    window.scroll(0, 0);
    getZoneList();
    // getAllAddresses();
    setItemGrid(storedNames.Products);
    return () => {
      setoverallstate({});
    };
  }, []);
  const getAllAddresses = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/DealersLogin/GetAddresses/" + UserCode).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        console.log(responseRs);
      }
    });
  }
  const getZoneList = async () => {
    $("#BillingAddressDiv").removeClass("d-none");
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Checkout/getZoneList").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        setzonetaxlist(responseRs.zoneTax);
        calculation();
      }
    });
  };
  const calculation = () => {
    var taxval = parseInt($("#zoneId option:selected").attr("taxvalue"));
    var taxname = $("#zoneId option:selected").text();
    var shipval = localStorage.getItem("shippingprice");
    var subtotal = localStorage.getItem("totalprice");
    var taxpercentage = parseFloat(taxval / 100);
    var taxtotal = parseFloat(parseFloat(subtotal) * taxpercentage).toFixed(2);
    if (taxname == "Select") {
      $("#zonetaxDiv").addClass("d-none");
      taxtotal = 0;
    } else {
      $("#zonetaxDiv").removeClass("d-none");
    }
    setzonetaxName(taxname);
    setindianTax(taxval);
    setindianTaxTotal(taxtotal);
    setshippingprice(shipval);
    setsubtotal(subtotal);
    var maintotal = parseFloat(
      parseFloat(shipval) + parseFloat(subtotal) + parseFloat(taxtotal)
    ).toFixed(2);

    if (couponCode != undefined && couponCode != null && couponCode != "") {
      maintotal = maintotal - DiscountAmount;
      maintotal = parseFloat(maintotal).toFixed(2);
    }
    // for(var i =0;i<storedNames.Products.length;i++){
    //   if(storedNames.Products[i].Rush == 1){
    //     maintotal = parseFloat(maintotal + 30).toFixed(2);
    //   }
    // }
    setgrandTotal(maintotal);
    var currentYear = new Date().getFullYear();
    var yeararray = [];
    var CartArray = [];
    for (var i = 0; i < 10; i++) {
      yeararray.push(currentYear);
      currentYear++;
    }
    setyear(yeararray);
    var vnewjson = {};
    vnewjson["zonename"] = taxname;
    vnewjson["zonevalue"] = taxval;
    vnewjson["TaxTotal"] = taxtotal;
    CartArray.push(vnewjson);
    localStorage.setItem("taxDetails", JSON.stringify(CartArray));
  };
  const BillingAddressClick = () => {
    var zoneval = parseInt($("#zoneId option:selected").val());
    var zonetext = $("#zoneId option:selected").text();
    var temp = 0;
    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    var phoneNumPattern = /^[0-9+()-]+$/gm;
    {
      if (fname == "") {
        setblankfname("Required!");
      } else {
        setblankfname("");
        temp++;
      }

      if (lname == "") {
        setblanklname("Required!");
      } else {
        setblanklname("");
        temp++;
      }

      if (email == "") {
        setblankemail("Required!");
      } else {
        if (!pattern.test(email)) {
          setblankemail("Enter Valid Email");
        } else {
          setblankemail("");
          temp++;
        }
      }

      if (mobile == "") {
        setblankmobile("Required!");
      } else {
        if (!phoneNumPattern.test(mobile)) {
          setblankmobile("Enter Valid Contact Number");
        } else {
          setblankmobile("");
          temp++;
        }
      }


      if (phone == "") {
        setvalidphone("");
        temp++;
      } else {
        if (!phoneNumPattern.test(setvalidphone)) {
          setvalidphone("Enter Valid Contact Number");
        } else {
          setvalidphone("");
          temp++;
        }
      }

      if (address1 == "") {
        setblankaddress1("Required!");
      } else {
        setblankaddress1("");
        temp++;
      }

      if (zipcode == "") {
        setblankzipcode("Required!");
      } else {
        setblankzipcode("");
        temp++;
      }

      if (city == "") {
        setblankcity("Required!");
      } else {
        setblankcity("");
        temp++;
      }

      if (zoneval == 0) {
        setblankzone("Required!");
      } else {
        setblankzone("");
        temp++;
      }
    }
    var mobilesplit = mobile.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+', '');
    var phonesplit = phone.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+', '');
    if (temp >= 9) {
      $("#BillingAddressDiv").addClass("d-none");
      $("#billingadressimg").attr("src", "");
      $("#billingadressimg").attr("src", "/images/leftBlackArrow.png");
      $("#billingChange").removeClass("d-none");
      if ($("#addressSameBilling").is(":checked")) {
        $("#hdnFlag").val(1);
        $("#PaymentProcessDiv").removeClass("d-none");
        $(".changeclass").removeClass("d-none");
        $("#paymentdivimg").attr("src", "");
        $("#paymentdivimg").attr("src", "/images/downArrowShow.png");
        setshipfname(fname);
        setshiplname(lname);
        setshipemail(email);
        setshipphone(phone);
        setshipmobile(mobile);
        setshipaddress1(address1);
        setshipaddress2(address2);
        setzipcode(zipcode);
        setshipcity(city);
        setshipcompany(company);
        setshiptaxnumber(taxnumber);


        var finalObj = new Object();
        finalObj.firstname = fname;
        finalObj.lastname = lname;
        finalObj.email = email;
        finalObj.phone = phonesplit;
        finalObj.mobile = mobilesplit;
        finalObj.address1 = address1;
        finalObj.address2 = address2;
        finalObj.zipcode = zipcode;
        finalObj.city = city;
        finalObj.company = company;
        finalObj.taxnumber = taxnumber;
        finalObj.zoneval = zoneval;
        finalObj.zonename = zonetext;
        var data = localStorage.getItem("addtocart");
        var parsedData = JSON.parse(data);
        parsedData.BillingAddress = finalObj;
        data = JSON.stringify(parsedData);
        localStorage.setItem("addtocart", data);

        var finalshipObj = new Object();
        finalshipObj.firstname = fname;
        finalshipObj.lastname = lname;
        finalshipObj.email = email;
        finalshipObj.phone = phonesplit;
        finalshipObj.mobile = mobilesplit;
        finalshipObj.address1 = address1;
        finalshipObj.address2 = address2;
        finalshipObj.zipcode = zipcode;
        finalshipObj.city = city;
        finalshipObj.zonename = zonetext;
        finalshipObj.zoneval = zoneval;
        finalshipObj.company = company;
        finalshipObj.taxnumber = taxnumber;
        var shipdata = localStorage.getItem("addtocart");
        var parsedShipData = JSON.parse(shipdata);
        parsedShipData.ShippingAddress = finalshipObj;
        shipdata = JSON.stringify(parsedShipData);
        localStorage.setItem("addtocart", shipdata);
        setTimeout(function () {
          $("#zoneShipId").val(zoneval);
        }, 1500);
      } else {
        $("#ShippingAddressDiv").removeClass("d-none");
        $("#billingChange").removeClass("d-none");
        $("#shippingDivImg").attr("src", "");
        $("#shippingDivImg").attr("src", "/images/downArrowShow.png");
        var finalObj = new Object();
        finalObj.firstname = fname;
        finalObj.lastname = lname;
        finalObj.email = email;
        finalObj.phone = phonesplit;
        finalObj.mobile = mobilesplit;
        finalObj.address1 = address1;
        finalObj.address2 = address2;
        finalObj.zipcode = zipcode;
        finalObj.city = city;
        finalObj.zonename = zonetext;
        finalObj.zoneval = zoneval;
        finalObj.company = company;
        finalObj.taxnumber = taxnumber;
        var data = localStorage.getItem("addtocart");
        var parsedData = JSON.parse(data);
        parsedData.BillingAddress = finalObj;
        data = JSON.stringify(parsedData);
        localStorage.setItem("addtocart", data);
      }
    }
  };
  const ShippingAddressClick = () => {
    $("#hdnFlag").val(1);
    var zoneval = parseInt($("#zoneShipId option:selected").val());
    var zonetext = $("#zoneShipId option:selected").text();
    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    var phoneNumPattern = /^[0-9+()-]+$/gm;
    var mobilesplit = mobile.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+', '');
    var phonesplit = phone.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+', '');
    var temp = 0;
    {
      if (shipfname == "") {
        setblankshipfname("Required!");
      } else {
        setblankshipfname("");
        temp++;
      }

      if (shiplname == "") {
        setblankshiplname("Required!");
      } else {
        setblankshiplname("");
        temp++;
      }

      if (shipemail == "") {
        setblankshipemail("Required!");
      } else {
        if (shipemail == "") {
          setblankshipemail("Required!");
        } else {
          if (!pattern.test(shipemail)) {
            setblankshipemail("Enter Valid Email");
          } else {
            setblankshipemail("");
            temp++;
          }
        }
      }

      if (shipmobile == "") {
        setblankshipmobile("Required!");
      } else {
        if (!phoneNumPattern.test(shipmobile)) {
          setblankshipmobile("Enter Valid Contact Number");
        } else {
          setblankshipmobile("");
          temp++;
        }
      }


      if (shipphone == "") {
        setvalidphone("");
        temp++;
      } else {
        if (!phoneNumPattern.test(shipphone)) {
          setvalidphone("Enter Valid Contact Number");
        } else {
          setvalidphone("");
          temp++;
        }
      }


      if (shipaddress1 == "") {
        setblankshipaddress1("Required!");
      } else {
        setblankshipaddress1("");
        temp++;
      }

      if (shipzipcode == "") {
        setblankshipzipcode("Required!");
      } else {
        setblankshipzipcode("");
        temp++;
      }

      if (shipcity == "") {
        setblankshipcity("Required!");
      } else {
        setblankshipcity("");
        temp++;
      }

      if (zoneval == 0) {
        setblankshipzone("Required!");
      } else {
        setblankshipzone("");
        temp++;
      }
    }
    if (temp >= 8) {
      var finalshipObj = new Object();
      finalshipObj.firstname = shipfname;
      finalshipObj.lastname = shiplname;
      finalshipObj.email = shipemail;
      finalshipObj.phone = phonesplit;
      finalshipObj.mobile = mobilesplit;
      finalshipObj.address1 = shipaddress1;
      finalshipObj.address2 = shipaddress2;
      finalshipObj.zipcode = shipzipcode;
      finalshipObj.city = shipcity;
      finalshipObj.zonename = zonetext;
      finalshipObj.zoneval = zoneval;
      finalshipObj.company = shipcompany;
      finalshipObj.taxnumber = shiptaxnumber;
      var shipdata = localStorage.getItem("addtocart");
      var parsedShipData = JSON.parse(shipdata);
      parsedShipData.ShippingAddress = finalshipObj;
      shipdata = JSON.stringify(parsedShipData);
      localStorage.setItem("addtocart", shipdata);
      $("#ShippingAddressDiv").addClass("d-none");
      $("#PaymentProcessDiv").removeClass("d-none");
      $("#shippingDivImg").attr("src", "");
      $("#shippingDivImg").attr("src", "/images/leftBlackArrow.png");
      $("#paymentdivimg").attr("src", "");
      $("#paymentdivimg").attr("src", "/images/downArrowShow.png");
      $(".changeclass").removeClass("d-none");
      paymentBtnClick();
    }
  };
  const showbiliingdiv = () => {
    $("#ShippingAddressDiv").addClass("d-none");
    if ($("#BillingAddressDiv").hasClass("d-none")) {
      $("#BillingAddressDiv").removeClass("d-none");
      $("#billingadressimg").attr("src", "");
      $("#billingadressimg").attr("src", "/images/downArrowShow.png");
    } else {
      $("#BillingAddressDiv").addClass("d-none");
      $("#billingadressimg").attr("src", "");
      $("#billingadressimg").attr("src", "/images/leftBlackArrow.png");
    }
  };
  const labelshowbiliingdiv = () => {
    var hdnflag = $("#hdnFlag").val();
    if (hdnflag == 1) {
      $("#ShippingAddressDiv").addClass("d-none");
      if ($("#BillingAddressDiv").hasClass("d-none")) {
        $("#BillingAddressDiv").removeClass("d-none");
        $("#billingadressimg").attr("src", "");
        $("#billingadressimg").attr("src", "/images/downArrowShow.png");
      } else {
        $("#BillingAddressDiv").addClass("d-none");
        $("#billingadressimg").attr("src", "");
        $("#billingadressimg").attr("src", "/images/leftBlackArrow.png");
      }
    }
  };
  const labelshowshippingdiv = () => {
    var hdnflag = $("#hdnFlag").val();
    if (hdnflag == 1) {
      $("#BillingAddressDiv").addClass("d-none");
      if ($("#ShippingAddressDiv").hasClass("d-none")) {
        $("#ShippingAddressDiv").removeClass("d-none");
        $("#shippingDivImg").attr("src", "");
        $("#shippingDivImg").attr("src", "/images/downArrowShow.png");
      } else {
        $("#ShippingAddressDiv").addClass("d-none");
        $("#shippingDivImg").attr("src", "");
        $("#shippingDivImg").attr("src", "/images/leftBlackArrow.png");
      }
    }
  };
  const showshippingdiv = () => {
    $("#BillingAddressDiv").addClass("d-none");
    if ($("#ShippingAddressDiv").hasClass("d-none")) {
      $("#ShippingAddressDiv").removeClass("d-none");
      $("#shippingDivImg").attr("src", "");
      $("#shippingDivImg").attr("src", "/images/downArrowShow.png");
    } else {
      $("#ShippingAddressDiv").addClass("d-none");
      $("#shippingDivImg").attr("src", "");
      $("#shippingDivImg").attr("src", "/images/leftBlackArrow.png");
    }
  };
  const paymentBtnClick = async () => {
    var radiotype = 0;
    var tempcard = cardNumber.replace(/ /g, "");
    // var temp = 0;

    // {
    //   $(".cardtyperadio").each(function () {
    //     if ($(this).is(":checked")) {
    //       radiotype = 1;
    //     }
    //   });
    //   if (radiotype == 0) {
    //     setblankcardtype("Required!");
    //   } else {
    //     setblankcardtype("");
    //     temp++;
    //   }

    //   if (cardNumber == "") {
    //     setblankcardNumber("Required!");
    //   } else if (tempcard.length > 16 || tempcard.length < 15) {
    //     setblankcardNumber("This Card number is not valid!");
    //   } else {
    //     setblankcardNumber("");
    //     temp++;
    //   }

    //   if (cardcvv == "") {
    //     setblankcardcvv("Required!");
    //   } else if (cardcvv.length > 4) {
    //     setblankcardcvv("This Card CVV is not valid!");
    //   } else if (cardcvv.length < 3) {
    //     setblankcardcvv("This Card CVV is not valid!");
    //   } else {
    //     setblankcardcvv("");
    //     temp++;
    //   }

    //   var month = $("#expirationMonthId option:selected").val();
    //   var year = $("#expirationYearId option:selected").val();
    //   if (month == "0") {
    //     setblankexpirationdate("Required!");
    //   } else if (year == "0") {
    //     setblankexpirationdate("Required!");
    //   } else {
    //     setblankexpirationdate("");
    //     temp++;
    //   }
    // }

    // if (temp >= 4) {
    var utmSourcesession = sessionStorage.getItem('utm_source');
    if (utmSourcesession == null) {
      utmSourcesession = null;
    }
    var totalpaymentObj = new Object();
    totalpaymentObj.subtotal = subtotalprice;
    totalpaymentObj.shipping = shippingprice;
    if ($("#AddFactoryRushID option:selected").val() == "277") {
      totalpaymentObj.grandtotal = parseFloat(parseFloat(grandTotal) + 30).toFixed(2);
    } else {
      totalpaymentObj.grandtotal = parseFloat(grandTotal).toFixed(2);
    }
    totalpaymentObj.taxtotal = indianTaxTotal;
    var expirationyear = parseInt(
      $("#expirationYearId option:selected").val()
    );
    var expirationmonth = parseInt(
      $("#expirationMonthId option:selected").val()
    );
    var expirationdate = expirationyear + "-" + expirationmonth;
    var paymentObj = new Object();
    if ($("#AddFactoryRushID option:selected").val() == "277") {
      paymentObj.amount = parseFloat(parseFloat(grandTotal) + 30).toFixed(2);
    } else {
      paymentObj.amount = parseFloat(grandTotal).toFixed(2);
    }
    paymentObj.cardNumber = cardNumber.replace(/ /g, "");
    paymentObj.expirationDate = expirationdate;
    paymentObj.cardCode = cardcvv;
    paymentObj.customerNote = customerNote;
    paymentObj.cardType = cardtype;
    var paymentdata = localStorage.getItem("addtocart");
    var parsedpaymentData = JSON.parse(paymentdata);
    parsedpaymentData.paymentDetails = paymentObj;
    parsedpaymentData.UTM_Source = utmSourcesession;
    parsedpaymentData.Totalpayment = totalpaymentObj;
    parsedpaymentData.CouponCode = localStorage.getItem("couponCode");
    if ($("#AddFactoryRushID option:selected").val() == "277") {
      parsedpaymentData.Rush = 1;
    } else {
      parsedpaymentData.Rush = 0;
    }
    parsedpaymentData.DistributorUserCode = localStorage.getItem("DealersUserCode");
    parsedpaymentData.BaseTotal = localStorage.getItem("BaseTotalAMount");
    parsedpaymentData.Discount = localStorage.getItem("Discount");
    var discountTypeVal = localStorage.getItem("DiscountType");
    if (discountTypeVal == undefined || discountTypeVal == null || discountTypeVal == "NULL") {
      discountTypeVal = "";
    }
    parsedpaymentData.DiscountType = discountTypeVal;
    parsedpaymentData.DiscountAmount = localStorage.getItem("DiscountAmount");
    paymentdata = JSON.stringify(parsedpaymentData);
    localStorage.setItem("addtocart", paymentdata);
    $("#Overlay").show();
    $("#LoderId").show();
    var data = JSON.parse(localStorage.getItem("addtocart"));
    var raw = JSON.stringify(data);
    await ApiPostCall("/DealersLogin/DealersOrderSave", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        if (responseRs.status == "Success") {
          $("#PaymentErrorId").text("");
          var ordernumber = responseRs.orderNumber;
          localStorage.setItem("orderNumber", ordernumber);
          window.location = "/confirm-order";
        } else {
          $("#PaymentErrorId").text(responseRs.message);
        }
      }
    });
    // }
  };
  const changeCardType = (cardname) => {
    setcardtype(cardname);
  };
  const OnRushChange = () => {
    if ($("#AddFactoryRushID option:selected").val() == "277") {
      $("#RushDiv").removeClass('d-none');
    } else {
      $("#RushDiv").addClass('d-none');
    }
  }
  return (
    <>
      <input type="hidden" id="hdnFlag" />
      <NavigationBar />
      <div className="container py-5 px-3">
        <div className="col-12 text-center">
          <label className="activeColorLink">
            <h1>Checkout</h1>
          </label>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="col-12 d-flex justify-content-between">
              <h5>
                <img
                  src="/images/downArrowShow.png"
                  className="img-fluid me-2"
                />
                Rush
              </h5>
            </div>
            <div className="row py-4 px-4">
              <div className="col-md-6">
                <select className="form-select" id="AddFactoryRushID" onChange={OnRushChange}>
                  <option value="0">Select</option>
                  <option value="277">Yes (${rushprice})</option>
                  <option value="278">No</option>
                </select>
              </div>
            </div>
            {/* Billing Address div */}
            <div className="col-md-12 py-3">
              <div className="col-12 d-flex justify-content-between">
                <h5>
                  <img
                    src="/images/downArrowShow.png"
                    className="img-fluid me-2"
                    id="billingadressimg"
                    onClick={labelshowbiliingdiv}
                    alt="Billing Address"
                  />
                  Billing Address
                </h5>
                <span
                  className="txtRed me-1 changeclass d-none crsr-pointer "
                  id="billingChange"
                  onClick={showbiliingdiv}
                >
                  Change
                </span>
              </div>
              {/* <div className="col-12 px-4 pt-3">
                <div className="AddressBox p-3">
                  <h5 className="grayTxt">Choose Previous Addresses</h5>
                  

                </div>
              </div> */}
              <div className="row py-4 d-none px-4 " id="BillingAddressDiv">
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={fname}
                    onChange={(e) => setfname(e.target.value)}
                  />
                  <label>
                    First Name
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankfname}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={lname}
                    onChange={(e) => setlname(e.target.value)}
                  />
                  <label>
                    Last Name
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blanklname}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                  <label>
                    Email Address
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankemail}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    placeholder="000-000-0000"
                  />
                  <label>Phone</label>
                  <span className="txt9d0909">{validphone}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                    placeholder="000-000-0000"
                  />
                  <label>
                    Mobile
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankmobile}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={address1}
                    onChange={(e) => setaddress1(e.target.value)}
                  />
                  <label>
                    Address Line 1<span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankaddress1}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={address2}
                    onChange={(e) => setaddress2(e.target.value)}
                  />
                  <label>Address Line 2</label>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={zipcode}
                    onChange={(e) => setzipcode(e.target.value)}
                  />
                  <label>
                    ZIP / Postal Code
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankzipcode}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={city}
                    onChange={(e) => setcity(e.target.value)}
                  />
                  <label>
                    City
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankcity}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <select
                    className="form-select"
                    id="zoneId"
                    onChange={calculation}
                  >
                    <option value="0">Select</option>
                    {zonetaxlist.map((item, j) => {
                      var returData;
                      returData = (
                        <option value={item.id} key={j} taxvalue={item.tax}>
                          {item.zone}
                        </option>
                      );
                      return returData;
                    })}
                  </select>
                  <label>
                    Zone/Region
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankzone}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={company}
                    onChange={(e) => setcompany(e.target.value)}
                  />
                  <label>Company</label>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={taxnumber}
                    onChange={(e) => settaxnumber(e.target.value)}
                  />
                  <label>Tax Number</label>
                </div>
                <div className="col-12">
                  <h6>
                    <input
                      className="form-check-input me-2"
                      id="addressSameBilling"
                      defaultChecked={checkvalue}
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label">
                      {" "}
                      My delivery address is same as the billing{" "}
                    </label>
                  </h6>
                </div>
                <div className="col-12 pt-3">
                  <button
                    className="orderNowBtn py-2 px-3 btnhover"
                    onClick={BillingAddressClick}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>

            {/* shipping Address div */}
            <div className="col-md-12 py-3">
              <div className="col-12 d-flex justify-content-between">
                <h5>
                  <img
                    src="/images/leftBlackArrow.png"
                    className="img-fluid me-2"
                    id="shippingDivImg"
                    onClick={labelshowshippingdiv}
                    alt="Shipping Address"
                  />
                  Shipping Address
                </h5>
                <span
                  className="txtRed me-1 changeclass d-none crsr-pointer "
                  id="shippingChange"
                  onClick={showshippingdiv}
                >
                  Change
                </span>
              </div>
              <div className="row py-4 d-none" id="ShippingAddressDiv">
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={shipfname}
                    onChange={(e) => setshipfname(e.target.value)}
                  />
                  <label>
                    First Name
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankshipfname}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={shiplname}
                    onChange={(e) => setshiplname(e.target.value)}
                  />
                  <label>
                    Last Name
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankshiplname}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="email"
                    className="form-control"
                    value={shipemail}
                    onChange={(e) => setshipemail(e.target.value)}
                  />
                  <label>
                    Email Address
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankshipemail}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="number"
                    className="form-control"
                    value={shipphone}
                    onChange={(e) => setshipphone(e.target.value)}
                  />
                  <label>Phone</label>
                  <span className="txt9d0909">{validphone}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="number"
                    className="form-control"
                    value={shipmobile}
                    onChange={(e) => setshipmobile(e.target.value)}
                  />
                  <label>
                    Mobile
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankshipmobile}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={shipaddress1}
                    onChange={(e) => setshipaddress1(e.target.value)}
                  />
                  <label>
                    Address Line 1<span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankshipaddress1}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={shipaddress2}
                    onChange={(e) => setshipaddress2(e.target.value)}
                  />
                  <label>Address Line 2</label>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={shipzipcode}
                    onChange={(e) => setshipzipcode(e.target.value)}
                  />
                  <label>
                    ZIP / Postal Code
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankshipzipcode}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={shipcity}
                    onChange={(e) => setshipcity(e.target.value)}
                  />
                  <label>
                    City
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankshipcity}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <select
                    className="form-select"
                    id="zoneShipId"
                    onChange={calculation}
                  >
                    <option value="0">Select</option>
                    {zonetaxlist.map((item, j) => {
                      var returData;
                      returData = (
                        <option value={item.id} key={j} taxvalue={item.tax}>
                          {item.zone}
                        </option>
                      );
                      return returData;
                    })}
                  </select>
                  <label>
                    Zone/Region
                    <span className="txtRed">*</span>
                  </label>
                  <span className="txt9d0909">{blankshipzone}</span>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={shipcompany}
                    onChange={(e) => setshipcompany(e.target.value)}
                  />
                  <label>Company</label>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    value={shiptaxnumber}
                    onChange={(e) => setshiptaxnumber(e.target.value)}
                  />
                  <label>Tax Number</label>
                </div>
                <div className="col-12 pt-3" id="ConfirmOrderBtn">
                  <button
                    className="orderNowBtn py-2 px-3 btnhover"
                    onClick={ShippingAddressClick}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 pt-3" id="ConfirmOrderBtn">
              <button
                className="orderNowBtn py-2 px-3 btnhover"
                onClick={paymentBtnClick}
              >
                Confirm Order
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-12 pt-4">
              <div className="totalDiv px-3">
                <h3 className="text-center">
                  <label className="activeColorLink">Your Order</label>
                </h3>
                {ItemGrid.map((slide, i) => (
                  <div className="col-12 " key={i}>
                    <div className="row pt-3">
                      <div className="col-12 ps-4 ">
                        <h5 className="txtHover">
                          <span>{i + 1}) </span>
                          {slide.ItemName}
                        </h5>
                      </div>
                      <div className="ps-4 pt-1">
                        <h6 className="ms-1 row">
                          <div className="col-6">Quantity :</div>
                          <div className="col-6">{slide.qty}</div>
                        </h6>
                      </div>

                      {/* <div className="ps-4 pt-1">
                        <h6 className="ms-1 row">
                          <div className="col-6">Factory Rush :</div>
                          <div className="col-6">
                            {slide.Rush == 1 ? (
                              <span>
                                ${" "}
                                {(
                                  slide.RushPrice.toFixed(2) * slide.qty
                                ).toFixed(2)}
                              </span>
                            ) : (
                              <>--</>
                            )}
                          </div>
                        </h6>
                      </div> */}
                      <div className="ps-4 pt-1">
                        <h6 className="ms-1 row">
                          <div className="col-6">Warranty :</div>
                          <div className="col-6">
                            {slide.flag == 1 ? (
                              <span>$ {(30.0 * slide.qty).toFixed(2)}</span>
                            ) : (
                              <>--</>
                            )}
                          </div>
                        </h6>
                      </div>
                      <div className="ps-4 pt-1 d-none">
                        <h6 className="ms-1 row">
                          <div className="col-6">Price :</div>
                          <div className="col-6">
                            <span>
                              $ {(slide.price * slide.qty).toFixed(2)}
                            </span>
                          </div>
                        </h6>
                      </div>
                      <div className="ps-4 pt-1 d-none">
                        <h6 className="ms-1 row">
                          <div className="col-6">Shipping :</div>
                          <div className="col-6">
                            $ {(slide.shippingprice * slide.qty).toFixed(2)}
                          </div>
                        </h6>
                      </div>
                      <div className="ps-4 pt-1 d-none">
                        <h5 className="ms-1 row checked">
                          <div className="col-6">Total :</div>
                          <div className="col-6">
                            ${" "}
                            {(
                              parseFloat(slide.item_total) +
                              parseFloat(slide.shippingprice * slide.qty)
                            ).toFixed(2)}
                          </div>
                        </h5>
                      </div>
                    </div>
                    <hr />
                  </div>
                ))}
                <div className="ps-1 d-none">
                  <h6 className="ms-1 row">
                    <div className="col-7">
                      {zonetaxName} Taxes ({indianTax}%) :
                    </div>
                    <div className="col-5">$ {indianTaxTotal}</div>
                  </h6>
                </div>

                {couponCode != undefined &&
                  couponCode != null &&
                  couponCode != "" ? (
                  <>
                    <div className="ps-1 pb-2 d-none">
                      <h5 className="ms-1 row checked">
                        <div className="col-6">Base Total :</div>
                        <div className="col-6 ps-3">$ {BaseTotalAMount}</div>
                        <span className="sub-description">Base Prices of Products</span>
                      </h5>
                    </div>
                    <div className="ps-1 pb-2 d-none">
                      <h5 className="ms-1 row checked">
                        <div className="col-6">Discount :</div>
                        <div className="col-6 ps-3 ">
                          ${DiscountAmount} {discountTypeText}
                        </div>
                      </h5>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="ps-1 d-none">
                  <h6 className="ms-1 row checked d-none" id="RushDiv">
                    <div className="col-6">Rush :</div>
                    <div className="col-6 ps-3">$ {rushprice}</div>
                  </h6>
                  <h5 className="ms-1 row checked">
                    <div className="col-6">Grand Total :</div>
                    <div className="col-6 ps-3">$ {grandTotal}</div>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
