import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { useState, useEffect } from "react";
import $ from 'jquery';
export default function Confirmorder() {

    var taxDetails = JSON.parse(localStorage.getItem("taxDetails"));
    var ordernumber = localStorage.getItem('orderNumber');
    const [overallstate, setoverallstate] = useState({});
    const [ItemGrid, setItemGrid] = useState([]);
    const [BillingAddress, setBillingAddress] = useState([]);
    const [ShippingAddress, setShippingAddress] = useState([]);
    const [shippingprice, setshippingprice] = useState("");
    const [subtotalprice, setsubtotal] = useState("");
    const [indianTaxTotal, setindianTaxTotal] = useState("");
    const [indianTax, setindianTax] = useState("");
    const [grandTotal, setgrandTotal] = useState("");
    const [zonetaxName, setzonetaxName] = useState("");
    const [cardType, setcardType] = useState("");
    var storedNames = JSON.parse(localStorage.getItem('addtocart'));
    useEffect(() => {
        window.scroll(0, 0);
        var localvar = JSON.parse(localStorage.getItem("addtocart"));
        if (localvar != null) {
            localStorage.setItem('addtocart', JSON.stringify(localvar));
        } else {
            continueshopping();
        }
        localStorage.removeItem("addtocart");
        if (storedNames != null) {
            setItemGrid(storedNames.Products);
            setBillingAddress(storedNames.BillingAddress);
            setShippingAddress(storedNames.ShippingAddress);
            var subtotal = localStorage.getItem("totalprice");
            var shipval = localStorage.getItem("shippingprice");
            setcardType(storedNames.paymentDetails.cardType);
            setshippingprice(shipval);
            setsubtotal(subtotal);
            var taxtotal = taxDetails[0].TaxTotal;
            var taxval = taxDetails[0].zonevalue;
            var zonename = taxDetails[0].zonename;
            var maintotal = 0;
            setindianTaxTotal(taxtotal);
            setgrandTotal(maintotal);
            setzonetaxName(zonename);
            setindianTax(taxval);
            if (storedNames.Rush == 1) {
                var maintotal = parseFloat(parseFloat(shipval) + parseFloat(subtotal) + parseFloat(taxtotal) + parseFloat(storedNames.RushPrice)).toFixed(2);
            } else {
                var maintotal = parseFloat(parseFloat(shipval) + parseFloat(subtotal) + parseFloat(taxtotal)).toFixed(2);
            }
            setgrandTotal(maintotal);
        }
        return () => {
            setoverallstate({});
        };
    }, []);
    const continueshopping = () => {
        window.location = "/home";
        localStorage.removeItem('addtocart');
        localStorage.removeItem("totalprice");
        localStorage.removeItem("shippingprice");
        localStorage.removeItem("taxDetails");
        localStorage.removeItem("staticpath");
        localStorage.removeItem("orderNumber");
        localStorage.removeItem("useridordernow");
        $(".totalItemCart").text(0);
        $(".totalItemCart").text(0);
    }
    return (
        <>
            <NavigationBar />
            <div className="container-fluid px-0 py-5 text-center">
                <h1>Thank You For Your Order</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="pb-2 checked">
                            <label>Order Details</label>
                        </h3>
                        <div className="col-12">
                            <h4>Name: <span className="text-uppercase">{BillingAddress.firstname} {BillingAddress.lastname}</span></h4>
                        </div>
                        <div className="row">
                            <div className="col-md-6 py-3">
                                <h6>Order Number</h6>
                                <span>#{ordernumber}</span>
                            </div>
                            <div className="col-md-6 py-3">
                                <h6>Payment</h6>
                                <span>{cardType}</span>
                            </div>
                            <div className="col-md-6 py-3">
                                <h6>Billing Address</h6>
                                {BillingAddress.address2 != "" ?
                                    <span>{BillingAddress.address1},{BillingAddress.address2}, {BillingAddress.city}, {BillingAddress.zonename}, {BillingAddress.zipcode} </span>
                                    :
                                    <span>{BillingAddress.address1},{BillingAddress.city}, {BillingAddress.zonename}, {BillingAddress.zipcode} </span>
                                }

                            </div>
                            <div className="col-md-6 py-3">
                                <h6>Shipping Address</h6>
                                {ShippingAddress.address2 != "" ?
                                    <span>{ShippingAddress.address1},{ShippingAddress.address2}, {ShippingAddress.city}, {ShippingAddress.zonename}, {ShippingAddress.zipcode} </span>
                                    :
                                    <span>{ShippingAddress.address1},{ShippingAddress.city}, {ShippingAddress.zonename}, {ShippingAddress.zipcode} </span>
                                }

                            </div>
                            <div className="col-md-6 py-3">
                                <h6>Email Address</h6>
                                <span>{BillingAddress.email}</span>
                            </div>
                            <div className="col-md-6 py-3">
                                <h6>Mobile Number</h6>
                                <span>{BillingAddress.mobile}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="pb-2 checked">
                            <label>Order Summary</label>
                        </h3>
                        {ItemGrid.map((slide, i) => (
                            <div className='col-12' key={i}>
                                <div className='row py-3'>
                                    <div className="col-lg-2 col-12 text-center pe-0">
                                        <div className="h-10vh">
                                            <img src={slide.itemImg} alt="dummy" className="newslideprodimg" />
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-12 textSmCenter pt-1 row pe-0">
                                        <div className="col-lg-9">
                                            <span>{slide.ItemName}</span>
                                        </div>
                                        <div className="col-lg-3 d-none">
                                            <span>$ {slide.item_total}</span>
                                        </div>
                                        <div className="col-12 pt-2">
                                            <span className="ps-2">- Qty: {slide.qty}</span><br />
                                            <span className="fs-14  ps-2">
                                                {slide.propertyname.map((item, i) => {
                                                    var returData;
                                                    returData = <p key={i}>- {item.propname}: {item.propvalue}</p>
                                                    return returData;
                                                })}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                <hr />
                <div className="col-md-6  offset-md-3 text-center  pt-2 d-none">
                    <div className="px-5">
                        {/* <h6 className="pb-2 pt-3 d-flex justify-content-between">
                            <label>Sub Total</label>
                            <label className="text-end">:  &nbsp;&nbsp;${subtotalprice}</label>
                        </h6>
                        <h6 className="pb-2 d-flex justify-content-between">
                            <label>Flat Rate Shipping</label>
                            <label className="text-end">:  &nbsp;&nbsp;${shippingprice}</label>
                        </h6> */}
                        {(indianTax != 0) ?
                            <>
                                <h6 className="pb-2 d-flex justify-content-between" id="zonetaxDiv">
                                    <label>{zonetaxName} Taxes ({indianTax}%)</label>
                                    <label className="text-end">:  &nbsp;&nbsp;${indianTaxTotal}</label>
                                </h6>
                                <hr />
                            </>
                            :
                            <></>
                        }

                        <h4 className="pb-2 checked d-flex justify-content-between">
                            <label>Total &nbsp;&nbsp;</label>
                            <label className="text-end">:  &nbsp;&nbsp;${grandTotal}</label>
                        </h4>
                    </div>
                </div>
                <div className="col-12 py-3 text-center">
                    <button className="orderNowBtn py-2 px-3 btnhover" onClick={continueshopping}>Continue Shopping</button>
                </div>
            </div>
            <Footer />
        </>
    )
}