import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import ReactStars from "react-rating-stars-component";
import React, { useState } from "react";
import $ from 'jquery';
import { WhatCustomerThink } from "./components/WhatCustomerThink";
import { ApiPostCall } from "../js/connector";
import ReCAPTCHA from "react-google-recaptcha"
export default function Testimonials() {
    const sitekeyVal = process.env.REACT_APP_Google_ReCaptcha_SiteKey;
    const [blankCaptcha, setblankCaptcha] = useState("");
    const [yourname, setYourName] = useState("");
    const [nickname, setNickName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [serviceprovide, setServiceProvide] = useState("");
    const [zipcode, setZipCode] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [rating, setRating] = useState(5);
    const [comment, setComment] = useState("");

    const [blankyourname, setblankyourname] = useState("");
    const [blankemail, setblankemail] = useState("");
    const [blankphone, setblankphone] = useState("");
    const [blankserviceprovide, setblankserviceprovide] = useState("");
    const [blankzipcode, setblankzipcode] = useState("");
    const [blankstate, setblankstate] = useState("");
    const [blankcity, setblankcity] = useState("");
    const [blankcomment, setblankcomment] = useState("");
    const ratingChanged = (newRating) => {
        setRating(newRating);
    };
    const cancelclick = () => {
        window.location.href = "/testimonials";
    }
    const SubmitTestimonial = async () => {
        var temp = 0;
        var profileimg = null;
        var humanKey = $("#hdnHumanKey").val();
        var base64data = $("#reviewprofileId").attr('base64');
        if (base64data != null) {
            base64data = base64data.split(",");
            profileimg = base64data[1];
        }
        {
            if (yourname == "") {
                setblankyourname("Required!");
            } else {
                setblankyourname("");
                temp++;
            }

            if (humanKey == "") {
                setblankCaptcha("Before you proceed, please complete the captcha");
              } else {
                setblankCaptcha("");
                temp++;
              }

            if (email == "") {
                setblankemail("Required!");
            } else {
                setblankemail("");
                temp++;
            }

            if (phone == "") {
                setblankphone("Required!");
            } else {
                setblankphone("");
                temp++;
            }

            if (serviceprovide == "") {
                setblankserviceprovide("Required!");
            } else {
                setblankserviceprovide("");
                temp++;
            }

            if (zipcode == "") {
                setblankzipcode("Required!");
            } else {
                setblankzipcode("");
                temp++;
            }

            if (state == "") {
                setblankstate("Required!");
            } else {
                setblankstate("");
                temp++;
            }

            if (city == "") {
                setblankcity("Required!");
            } else {
                setblankcity("");
                temp++;
            }

            if (comment == "") {
                setblankcomment("Required!");
            } else {
                setblankcomment("");
                temp++;
            }
        }
        if (temp >= 9) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                Name: yourname,
                Nickname: nickname,
                Email: email,
                Phone: phone,
                ServiceProvided: serviceprovide,
                Zipcode: zipcode,
                State: state,
                City: city,
                Rating: rating,
                Comment: comment,
                AdminApprove: 0,
                ImageUrl: profileimg,
                GoogleCaptcha: humanKey
            });
            await ApiPostCall("/CustomerReview/SaveTestimonial", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Save Succesfully.");
                        setTimeout(function () {
                            $(".alert-success").hide();
                            $("#AlertMsg").text("");
                            window.location.href = "/testimonial";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }
    }
    const MAX_WIDTH = 300;
    const MAX_HEIGHT = 400;
    const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.7;
    const imagesUpload = (e) => {
        const file = e.target.files;
        const len = file.length;
        $.each(file, function (i, v) {
            var n = i + 1;
            const blobURL = URL.createObjectURL(file[i]);
            const img = new Image();
            img.src = blobURL;
            img.onload = function (event) {
                URL.revokeObjectURL(this.src);
                const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(
                    (blob) => {
                        var reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = function (e) {
                            var base64data = reader.result;
                            $("#reviewprofileId").attr('src', URL.createObjectURL(blob));
                            $("#reviewprofileId").addClass('reviewProfile');
                            $("#reviewprofileId").attr('base64', base64data);
                        };
                    },
                    MIME_TYPE,
                    QUALITY
                );
            };
        });
    };
    const calculateSize = (img, maxWidth, maxHeight) => {
        let width = img.width;
        let height = img.height;
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
        }
        return [width, height];
    };
    function onChange(value) {
        const humanKey = value;
        $("#hdnHumanKey").val(humanKey);
    }
    return (
        <>
            <input type="hidden" id="hdnHumanKey" />
            <NavigationBar />
            <div className="container py-5 text-center">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-12 pt-3">
                        <h1>Submit a testimonial</h1>
                        <label className="pb-4 pt-1 contentClr">* ALL fields marked with * are required</label>
                        <div className="row">
                            <div className="col-12 py-3 text-center">
                                <label htmlFor="customFile">
                                    <img alt="Profile Image" src="/images/review_profile.png" className="" id="reviewprofileId" /><br />
                                    <span className="contentClr fs-13"> Upload your Image</span><br />
                                    <span className="contentClr fs-13">Maximum size: 1MB</span>
                                    <input type="file" className="form-control d-none"
                                        onChange={imagesUpload} id="customFile" accept=".png, .jpg, .jpeg, .svg" />
                                </label>
                            </div>
                            <div className="col-md-6 col-12 mb-2 pt-2">
                                <input type="text" placeholder="Your Name*" className="form-control" value={yourname} onChange={(e) => setYourName(e.target.value)} />
                                <span style={{ color: "red" }}>{blankyourname}</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3 pt-2">
                                <input type="text" placeholder="Nickname" className="form-control" value={nickname} onChange={(e) => setNickName(e.target.value)} />
                                <span className="contentClr fs-13">Please put name to be shown with review. If empty your name will be used.</span>
                            </div>
                            <div className="col-md-6 col-12 mb-4">
                                <input type="email" placeholder="Email*" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <span style={{ color: "red" }}>{blankemail}</span>
                            </div>
                            <div className="col-md-6 col-12 mb-4">
                                <input type="text" placeholder="Phone*" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                <span style={{ color: "red" }}>{blankphone}</span>
                            </div>
                            <div className="col-12 mb-4">
                                <input type="text" placeholder="Service(s) Provided*" className="form-control" value={serviceprovide} onChange={(e) => setServiceProvide(e.target.value)} />
                                <span style={{ color: "red" }}>{blankserviceprovide}</span>
                            </div>
                            <div className="col-md-6 col-12">
                                <input type="text" placeholder="Zip Code*" className="form-control" value={zipcode} onChange={(e) => setZipCode(e.target.value)} />
                                <span style={{ color: "red" }}>{blankzipcode}</span>
                            </div>
                            <div className="col-md-3 col-12">
                                <input type="text" placeholder="State*" className="form-control" value={state} onChange={(e) => setState(e.target.value)} />
                                <span style={{ color: "red" }}>{blankstate}</span>
                            </div>
                            <div className="col-md-3 col-12">
                                <input type="text" placeholder="City*" className="form-control" value={city} onChange={(e) => setCity(e.target.value)} />
                                <span style={{ color: "red" }}>{blankcity}</span>
                            </div>
                            <div className="col-12 d-flex">
                                <label className="me-3 mt-4 contentClr">Rating*</label>
                                <ReactStars count={5} onChange={ratingChanged} size={40} isHalf={true} value={rating}
                                    emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>}
                                    fullIcon={<i className="fa fa-star"></i>} activeColor="#8C4D36" />
                            </div>
                            <div className="col-12">
                                <textarea className="form-control" placeholder="Comment*" rows="3" value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                <span style={{ color: "red" }}>{blankcomment}</span>
                            </div>
                        </div>
                        <div className="col-12 mt-4 d-flex justify-content-center">
                            <ReCAPTCHA sitekey={sitekeyVal} onChange={onChange} />
                        </div>
                        <div className="txt9d0909">{blankCaptcha}</div>
                        <div className="col-12 pt-3 pb-5 text-center">
                            <button type="submit" className="loginBtn" onClick={SubmitTestimonial}>
                                Submit
                            </button>
                            <span className="ms-3 pt-2 crsr-pointer" onClick={cancelclick}>Cancel</span>
                        </div>
                        <div className="col-12 pb-5 text-center">
                            <label className="fs-14">† Note: By providing your contact information, you give us permission to contact you regarding service provided. We do not share or abuse your contact info.</label>
                        </div>
                    </div>
                </div>
            </div>
            <WhatCustomerThink />
            <Footer />
        </>
    )
}