import CKEditor from "react-ckeditor-component";
import React, {Component} from 'react';
import $ from 'jquery';

class Example extends Component {
    constructor(props) {
        super(props);
        this.updateContent = this.updateContent.bind(this);
        this.state = {
            content: props.content,
        }
    }
    updateContent(newContent) {
        this.setState({
            content: newContent
        })
    }
    
    onChange(evt){
      var newContent = evt.editor.getData();
      $("#hdnEditorField").val(newContent);
    }
    
    onBlur(evt){
      var newContent = evt.editor.getData();
      $("#hdnEditorField").val(newContent);
    }
    
    afterPaste(evt){
      var newContent = evt.editor.getData();
      $("#hdnEditorField").val(newContent);
    }

    render() {
        return (
          <>
          <input type="hidden" id="hdnEditorField" />
            <CKEditor 
              activeClass="p10" 
              content={this.state.content} 
              events={{
                "blur": this.onBlur,
                "afterPaste": this.afterPaste,
                "change": this.onChange
              }}
             />
             </>
        )
    }
}
export default Example;