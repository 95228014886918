import React from "react";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { NavigationBar } from "../Navigationbar";
import { Footer } from "../Footer";
import { ApiGetCall } from "../../js/connector";
import { CDNURL } from "../../js/constants";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import HelmetMetaTag from "../components/HelmetMetaTag";

export default function DeskPads() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  useEffect(() => {
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[31].value);
        setsectionDesciption(propDetails[32].value);
        setsectionCTAtext(propDetails[33].value);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  return (
    <>
      <HelmetMetaTag
        title="Custom Desk Pads & Protectors | Berger's Table Pads"
        desc="Berger's Table Pads manufactures custom desk pads with the highest quality materials to offer protection for your desk from all kinds of damage."
      />
      <NavigationBar />
      <div className="container-fluid px-0  extensionSection">
        <div className="row">
          <div className="col-md-7 m-auto pt-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More" />
            </button>
          </div>
          <div className="col-md-5 text-center">
            <img src={CDNURL + "Localimages/DeskPadsNewGroup.png"} className="img-fluid text-end" alt="Hero Image" />
          </div>
        </div>
      </div>

      <div className="text-center py-5">
        <button type="submit" className="loginBtn pt-3 pb-2 px-3 btnhover subProductOrderNow" data-url="/store/ultra-elite-desk-pads" data-id="31">
          <h5>Order Desk Pads Now</h5>
        </button>
      </div>

      <div className="container pb-5 colorPara ps-0">
        <ul>
          <li className=" liBullet ps-2">
            Shop online at Berger's Table Pad Factory for a high quality desk
            pad to protect your desk or table while working and prevent unwanted
            damage. A desk pad, also referred to as a desk mat or even a
            blotter, is a durable surface covering used to provide a flat, soft
            surface that allows you to work comfortably without the risk of
            causing damage to your tabletop or office desk.
          </li>
        </ul>
      </div>

      <div className="container ps-0 pb-5">
        <div className="">
          <div className="text-center">
            <h2>Why a Berger's Desk Pad?</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              We already make the world's best <a className="paraLink" href="/products/custom-table-pad">custom table pads</a> which are
              designed and manufactured to the highest quality of standards so
              it only makes sense that we can also provide our customers with
              the highest quality desk pads to both provide protection and
              comfort while working at home. Our desk pads are available at very
              competitive, factory-direct prices.
            </li>
          </ul>
        </div>
      </div>
      <div className="container ps-0 pb-5">
        <div className="">
          <div className="text-center">
            <h2>Why is Berger’s a Better Product?</h2>
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-3 liBullet ps-2">
              If you want to protect a beautiful desk or preserve the family
              table for future generations, a high quality desk pad is what
              you're searching for. Our pads are 1/2" thick and can protect
              against everyday nicks, scratches, and other "normal" damage that
              reduces the value of your furniture.
            </li>
            <li className="pt-4 liBullet ps-2">
              Feel free to read through our website testimonials, or simply type
              Berger’s Table Pads into the search bar of your preferred
              directory to read reviews and ratings from customers. We stand
              behind our pads with an industry-leading warranty to ensure your
              satisfaction.
            </li>
            <li className="pt-4 liBullet ps-2">
              Our desk pads are incredibly durable and are designed to last a
              lifetime of everyday use. When you use one of our desk pads, your
              desk or tabletop will be able to last for years. The reason is
              that we use a solid fiberboard core, not layered material most of
              our competition is using, which can trap moisture and lead to mold
              and mildew forming inside of the pad. When <a className="paraLink" href="/products/storage-bags">stored</a> upright, our
              pads will not bend or bow.
            </li>
          </ul>
        </div>
      </div>
      <WhatCustomerThink isH1Tag="no" />
      <Footer />
    </>
  )
}
