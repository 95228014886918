import { NavigationBar } from "./Navigationbar"
import { Footer } from "./Footer"
import { CDNURL } from "../js/constants"
import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { ApiGetCall } from "../js/connector";
export function Thankyou() {
    const [state, setState] = useState({});
    const [sectionContact, setsectionContact] = useState("");
    useEffect(() => {
        myFunction();
        return () => {
            setState({});
        };
    }, []);
    const myFunction = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
          if (result == undefined || result == "") {
            alert("Something went wrong");
          } else {
            var responseRs = JSON.parse(result);
            var propDetails = responseRs.propertyDetails;
            $("#Overlay").hide();
            $("#LoderId").hide();
            setsectionContact(propDetails[26].value);
          }
        });
      };
    return (
        <>
            <NavigationBar />
            <div className="container px-5 text-center">
                <div className="row">
                    <div className="col-12 mx-auto p-5">
                        <h1 className="ourProdTitle">Thank You</h1>
                        <p className="cartTxt py-3 px-5">
                            Thank you for contacting our team. A member of our team will be in
                            touch with you shortly. If you have any additional questions,
                            please contact our office at{" "}
                            <a className="paraLink" href="tel:+18007372165">1-800-737-2165</a>.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}