import React, { useState, useRef, useEffect } from "react";
import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import $ from "jquery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CDNURL } from "../js/constants";
import * as NumericInput from "react-numeric-input";
import 'bootstrap/dist/css/bootstrap.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { v4 as uuid } from 'uuid';
import { ApiGetCall } from "../js/connector";
import HelmetMetaTag from "./components/HelmetMetaTag";

export default function OrderNow() {
  const [ShowData, setShowData] = useState({ productValue: [], images: [], custompropertytables: [] });
  const [overallstate, setoverallstate] = useState({});
  const [exzoomcomp, setexzoomcomp] = useState([]);
  const [qtyMinval, setqtyMinval] = useState(null);
  const [qtyVal, setqtyVal] = useState("");
  const [nav1, setNav1] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [img, setImg] = useState("");
  const [prodtemparray, setprodarray] = useState([]);
  const [regularprice, setregularprice] = useState("");
  const [discountprice, setdiscountprice] = useState("");
  const [rushprice, setrushprice] = useState("");
  const [warranty, setwarranty] = useState(0);
  var PathName = window.localStorage.getItem("staticpath");
  var userid = parseInt(window.localStorage.getItem("useridordernow"));
  const images = [];
  const myRef = useRef(null);
  useEffect(() => {
    setqtyMinval(1);
    setqtyVal(1);
    GetUploadImage();
    setNav1(slider1);
    return () => {
      setoverallstate({});
    };
  }, []);
  const GetUploadImage = async () => {
    setoverallstate({ test: true });
    $("#Overlay").show();
    $("#LoderId").show();

    var flag = parseInt(window.localStorage.getItem("flag"));
    await ApiGetCall("/OrderNow/ProductOrderById/" + userid + "?path=null").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        setrushprice(parseInt(responseRs.rushPrice));
        $("#hdnregularprice").val(responseRs.allProducts.regularPrice);
        $("#hdnsalesprice").val(responseRs.allProducts.salesPrice);
        $("#hdnshippingprice").val(responseRs.allProducts.shippingPrice);
        $("#hdnonsaleprice").val(responseRs.allProducts.onSale);
        $("#hdnProductType").val(responseRs.allProducts.productType);
        setregularprice(responseRs.allProducts.regularPrice);
        setdiscountprice(responseRs.allProducts.salesPrice);
        var slidesData = [];
        var vimgs = responseRs.allProducts.productsImages;
        for (var i = 0; i < vimgs.length; i++) {
          var vimg = { img: CDNURL + "Gallery/" + vimgs[i].imageUrl };
          slidesData.push(vimg);
          images.push(CDNURL + "Gallery/" + vimgs[i].imageUrl);
        }
        setImg(images[0]);
        setexzoomcomp(slidesData);
        setShowData({
          ...ShowData, productValue: responseRs.allProducts,
          custompropertytables: responseRs.allProducts.productsProperites
        })
        $('#yourElementId').text(responseRs.allProducts.name);
        var url = window.location.href;
        var parts = url.split("/");
        var uid = parts[parts.length - 1];
        var addtocart = JSON.parse(localStorage.getItem("addtocart"));
        var ProdArray = [];
        if (addtocart != null) {
          var cartproducts = addtocart.Products;
          if (uid != null) {
            for (var i = 0; i < cartproducts.length; i++) {
              if (cartproducts[i].UID == uid) {
                ProdArray.push(cartproducts[i]);
              }
            }
            setprodarray(ProdArray);
            if (ProdArray.length != 0) {
              $("#txtQtyId").val(ProdArray[0].qty);
              $("#CalculatedPrice").val("$" + parseFloat(ProdArray[0].price).toFixed(2));
              var prodproperty = ProdArray[0].propertyname;
              for (var j = 0; j < prodproperty.length; j++) {
                $(".txtPropName").each(function () {
                  var vid = $(this).attr('id');
                  var vsplit = vid.split('_');
                  var vpropname = vsplit[1];
                  var tempvar = $("#propvalueid_" + vpropname).attr('tempvar');
                  var proname = $(this).text();
                  if (proname == prodproperty[j].propname) {
                    if (tempvar == 1) {
                      $("#propvalueid_" + vpropname + " option:selected").text(prodproperty[j].propvalue);
                      $("#propvalueid_" + vpropname + " option:selected").val(prodproperty[j].optionvalue);
                    } else {
                      $("#propvalueid_" + vpropname).val(prodproperty[j].propvalue);
                    }
                  }
                });
                $("#addtocartbtnid").text('Update Cart');
              }
            }
            if (flag == 1) {
              $("#warrantyId").attr('checked', true);
            }
          }
        }
        calculatePrice();
      }
    });
  }
  const calculatePrice = () => {
    var regularPrice = parseFloat($("#hdnregularprice").val());
    var length = $("#propvalueid_lengthInches").val();
    var width = $("#propvalueid_widthInches").val();
    var fractionval = $("#propvalueid_lengthFraction option:selected").text();
    var fractionwidth = $("#propvalueid_widthFraction option:selected").text();
    var factoryval = $("#propvalueid_factoryRush option:selected").val();
    var onsaleprod = $("#hdnonsaleprice").val();
    var hdnsalesprice = parseFloat($("#hdnsalesprice").val());
    var fractionsplit = fractionval.split('/');
    var value1 = fractionsplit[0];
    var value2 = fractionsplit[1];
    var division = 0;
    if (value2 == undefined) {
      division = 0;
    } else {
      division = parseInt(value1) / parseInt(value2);
    }
    if (length != null) {
      var finallength = parseFloat(division) + parseFloat(length);
    } else {
      var finallength = 0;
    }
    var fractionwidthsplit = fractionwidth.split('/');
    var widthvalue1 = fractionwidthsplit[0];
    var widthvalue2 = fractionwidthsplit[1];
    var widthdivision = 0;
    if (widthvalue2 == undefined) {
      widthdivision = 0;
    } else {
      widthdivision = parseInt(widthvalue1) / parseInt(widthvalue2);
    }
    if (width != null) {
      var finalwidth = parseFloat(widthdivision) + parseFloat(width);
    } else {
      var finalwidth = 0;
    }
    var lengthextension = $("#propvalueid_lengthextensionInches").val();
    var widthhextension = $("#propvalueid_widthhextensionInches").val();
    if (PathName == "tablepaddies") {
      setqtyMinval(4);
      setqtyVal(4);
      if (onsaleprod == 1) {
        hdnsalesprice = parseFloat(parseFloat(hdnsalesprice)).toFixed(2);
        $("#CalculatedPrice").val("$" + hdnsalesprice);
      } else {
        regularPrice = parseFloat(regularPrice).toFixed(2);
        $("#CalculatedPrice").val("$" + regularPrice);
      }

    } else if (PathName == "tableextender") {
      var widthextender = parseInt(widthhextension) * 2;
      var lengthextender = parseInt(lengthextension) * 2;
      finalwidth = parseFloat(widthdivision) + parseFloat(width) + parseInt(widthextender);
      finallength = parseFloat(division) + parseFloat(length) + parseInt(lengthextender);
      if (width != "" && length != "") {
        if (onsaleprod == 1) {
          var finalprice = (hdnsalesprice * parseFloat(finalwidth) * parseFloat(finallength)).toFixed(2);
        } else {
          var finalprice = (regularPrice * parseFloat(finalwidth) * parseFloat(finallength)).toFixed(2);
        }
        $("#CalculatedPrice").val("$" + finalprice);
      }
    } else if (PathName == "TABLEPADSTORAGEBAG") {
      var finalprice = 35;
      $("#CalculatedPrice").val("$" + finalprice);
    } else if (PathName == "TABLELEAFSTORAGEBAG") {
      var finalprice = 35;
      $("#CalculatedPrice").val("$" + finalprice);
    } else if (PathName == "CUSTOMTABLECLOTH") {
      var dropwidth = parseFloat($("#propvalueid_dropwidth").val());
      var droplength = parseFloat($("#propvalueid_droplength").val());
      if (dropwidth > 0) {
        dropwidth = dropwidth * 2;
      }
      if (droplength > 0) {
        droplength = droplength * 2;
      }
      finalwidth = finalwidth + dropwidth;
      finallength = finallength + droplength;
      if (onsaleprod == 1) {
        var finalprice = (parseFloat(hdnsalesprice) * parseFloat(finalwidth) * parseFloat(finallength)).toFixed(2);
      } else {
        var finalprice = (regularPrice * parseFloat(finalwidth) * parseFloat(finallength)).toFixed(2);
      }
      $("#CalculatedPrice").val("$" + finalprice);
    }
    else {
      if (onsaleprod == 1) {
        var finalprice = (parseFloat(hdnsalesprice) * parseFloat(finalwidth) * parseFloat(finallength)).toFixed(2);
      } else {
        var finalprice = (regularPrice * parseFloat(finalwidth) * parseFloat(finallength)).toFixed(2);
      }
      $("#CalculatedPrice").val("$" + finalprice);
    }
    if (PathName != "tablepaddies" && (finalwidth == 0 || finallength == 0)) {
      $("#CalculatedPrice").val("$" + regularPrice.toFixed(2));
    }

  }
  const opencartpage = () => {
    $(".txtPropName").each(function () {
      var isrequired = $(this).attr('requiredval');
      if (isrequired == 1) {
        var vid = $(this).attr('id');
        var vsplit = vid.split('_');
        var vpropname = vsplit[1];
        var tempvar = $("#propvalueid_" + vpropname).attr('tempvar');
        if (tempvar == 1) {
          var optionval = $("#propvalueid_" + vpropname + " option:selected").val();
          if (optionval == 0) {
            $("#validationId_" + vpropname).text("Required!");
          } else {
            $("#validationId_" + vpropname).text("");
          }
        } else {
          var vVal = $("#propvalueid_" + vpropname).val();
          if (vVal == "") {
            $("#validationId_" + vpropname).text("Required!");
          } else {
            $("#validationId_" + vpropname).text("");
          }
        }
      }
    });
    var tempvar = $(".validationclas").text();
    var PathName = window.location.pathname;
    var pathsplit = PathName.split('/');
    var prodActive = pathsplit[2];
    if (tempvar == "") {
      const unique_id = uuid();
      var itemname = $("#productname").text();
      var vimg = $(".exzoomimgstxt:first-child");
      if (vimg.length == 0) {
        var finalimg = $(".newslideprodimg").attr('src');
      } else {
        var finalimg = vimg[0].attributes[1].value;
      }
      var price = $("#CalculatedPrice").val();
      var pricesplit = price.split('$');
      var finalprice = parseFloat(pricesplit[1]);
      var qty = $("#txtQtyId").val();
      var shippingPrice = parseInt($("#hdnshippingprice").val());
      var prodid = parseInt(localStorage.getItem('useridordernow'));
      var PathName = window.localStorage.getItem("staticpath");

      var producttype = $("#hdnProductType").val();
      var proptableshape = $("#propvalueid_tableshape option:selected").val();
      if (producttype == "Paddies") {
        if (proptableshape == "348") {
          producttype = "Rectangular Paddie";
        } else if (proptableshape == "349") {
          producttype = "Oval Paddie";
        } else {
          producttype = producttype;
        }
      }
      var ob = new Object();
      var productArray = [];
      var FinalArray = [];
      var lengthextension = $("#propvalueid_lengthextensionInches").val();
      var widthhextension = $("#propvalueid_widthhextensionInches").val();
      var fractionheight = $("#propvalueid_lengthFraction option:selected").text();
      var fractionwidth = $("#propvalueid_widthFraction option:selected").text();
      var finalObj = new Object();
      $(".txtPropName").each(function () {
        var product = new Object();
        var vid = $(this).attr('id');
        var vsplit = vid.split('_');
        var vpropname = vsplit[1];
        var proname = $(this).attr('propname');
        var tempvar = $("#propvalueid_" + vpropname).attr('tempvar');
        var provalue;
        if (tempvar == 1) {
          var pval = $("#propvalueid_" + vpropname + " option:selected").val();
          if (pval != 0) {
            provalue = $("#propvalueid_" + vpropname + " option:selected").text();
            product.propname = proname;
            product.propvalue = provalue;
            product.optionvalue = pval;
            productArray.push(product);
          }
        } else {
          provalue = $("#propvalueid_" + vpropname).val();
          product.propname = proname;
          product.propvalue = provalue;
          productArray.push(product);
        }
      });
      var warrantyobj = new Object();
      if (warranty == 1) {
        warrantyobj.propname = "Unconditional Warranty";
        warrantyobj.propvalue = "$30 extra for each item";
        productArray.push(warrantyobj);
        finalObj.flag = 1;
      } else {
        finalObj.flag = 0;
      }
      if ($("#propvalueid_magnaloc option:selected").val() == "275") {
        finalObj.Magnets = 1;
      } else {
        finalObj.Magnets = 0;
      }
      finalObj.price = finalprice;
      finalObj.product_id = prodid;
      finalObj.qty = qty;
      finalObj.shippingprice = shippingPrice;
      finalObj.ItemName = itemname;
      finalObj.UrlPath = prodActive;
      finalObj.itemImg = finalimg;
      finalObj.product_type = producttype;
      finalObj.LengthExtension = lengthextension;
      finalObj.WidthExtension = widthhextension;
      finalObj.FractionHeight = fractionheight;
      finalObj.FractionWidth = fractionwidth;
      finalObj.item_description = "";
      finalObj.top_color = $("#propvalueid_topcolor option:selected").text();
      finalObj.bottom_color = $("#propvalueid_bottomcolor option:selected").text();
      finalObj.shape = $("#propvalueid_tableshape option:selected").text();
      // var rushID = $("#propvalueid_factoryRush").val();
      // if (rushID == "277") {
      //   finalObj.Rush = 1;
      // } else {
      //   finalObj.Rush = 0;
      // }
      var length = $("#propvalueid_lengthInches").val();
      var finalfractionlength = 0;
      if (length == null) {
        length = 0;
      } else {
        var lenFraction = $("#propvalueid_lengthFraction option:selected").text();
        if (lenFraction != "Select") {
          var lenfractionsplit = lenFraction.split('/');
          var len1 = lenfractionsplit[0];
          var len2 = lenfractionsplit[1];
          var divisionlen = 0;
          if (len2 == undefined) {
            divisionlen = 0;
          } else {
            divisionlen = parseInt(len1) / parseInt(len2);
          }
          length = parseInt($("#propvalueid_lengthInches").val());
          finalfractionlength = (parseFloat(divisionlen) + parseFloat(length)).toFixed(2);
        } else {
          finalfractionlength = parseInt($("#propvalueid_lengthInches").val());
        }
      }
      finalObj.length = finalfractionlength;
      var width = $("#propvalueid_widthInches").val();
      var finalfractionwidth = 0;
      if (width == null) {
        width = 0;
      } else {
        var widFraction = $("#propvalueid_widthFraction option:selected").text();
        if (widFraction != "Select") {
          var widfractionsplit = widFraction.split('/');
          var wid1 = widfractionsplit[0];
          var wid2 = widfractionsplit[1];
          var divisionwid = 0;
          if (wid2 == undefined) {
            divisionwid = 0;
          } else {
            divisionwid = parseInt(wid1) / parseInt(wid2);
          }
          width = parseInt($("#propvalueid_widthInches").val());
          finalfractionwidth = (parseFloat(divisionwid) + parseFloat(width)).toFixed(2);
        } else {
          finalfractionwidth = parseInt($("#propvalueid_widthInches").val());
        }
      }
      finalObj.width = finalfractionwidth;
      finalObj.propertyname = productArray;
      finalObj.RushPrice = rushprice;
      finalObj.UID = unique_id;
      finalObj.pathname = PathName;
      FinalArray.push(finalObj);
      var data = localStorage.getItem('addtocart');
      if (data == null) {
        ob.Products = FinalArray;
        ob.RushPrice = rushprice;
        data = JSON.stringify(ob);
      } else {
        var parsedData = JSON.parse(data);
        if (prodtemparray.length != 0) {
          var vid;
          for (var v = 0; v < parsedData.Products.length; v++) {
            if (parsedData.Products[v].ItemName == prodtemparray[0].ItemName) {
              vid = v;
            }
          }
          var index = parsedData.Products.indexOf(parsedData.Products[vid]);
          parsedData.Products.splice(index, 1);
        }
        parsedData.Products.push(finalObj);
        data = JSON.stringify(parsedData);
      }
      localStorage.setItem('addtocart', data);
      window.location = "/cart";
    }
    else {
      window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop });
    }
  }
  const settingsThumbs = {
    slidesToShow: 2,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    width: 600
  };
  const hoverHandler = (image, i) => {
    setImg(image);
  };
  const clickTigger = () => {
    var imgsrc = $(".slick-current .slick-slide")
      .find("img")
      .attr("src");
    setImg(imgsrc);
  };
  $(".slick-prev").click(function () {
    clickTigger();
  });
  $(".slick-next").click(function () {
    clickTigger();
  });
  $(".slick-dots").click(function () {
    clickTigger();
  });
  const ChangeWarranty = () => {
    if ($("#warrantyId").is(":checked")) {
      setwarranty(1);
    } else {
      setwarranty(0);
    }
  }
  return (
    <div>
      {PathName == "conferencepads" ?
        <HelmetMetaTag
          title="Conference Table Pads & Protection | Berger's"
          desc="Berger's Table Pads manufactures custom conference table pads in a variety of shapes and sizes to protect your conference room table from damage. "
        />
        :
        <HelmetMetaTag
          title="Custom High-Quality Table Pads | Berger's Table Pads"
          desc="Berger's Table Pads offers high-quality, custom-made table pads to protect a variety of furniture, including dining tables & desks. Contact us today!"
        />
      }
      <NavigationBar />
      <input type="hidden" id="hdnregularprice" />
      <input type="hidden" id="hdnsalesprice" />
      <input type="hidden" id="hdnshippingprice" />
      <input type="hidden" id="hdnonsaleprice" />
      <input type="hidden" id="hdnProductType" />
      <div className="container pb-3">
        <div className="row">
          <div className="col-12 text-center mt-4">
            <label className="activeColorLink"><h2 className="h1">Order Now</h2></label>
          </div>
          <div className="col-lg-5 text-center py-5">
            <div className="slider-wrapper pt-3">
              <div className="cat">
                <img src={img} className="w-100 newslideprodimg" alt="dummy" />
              </div>
              {exzoomcomp.length > 1 ?
                <div className="thumbnail-slider-wrap">
                  <Slider {...settingsThumbs} asNavFor={nav1} ref={(slider) => setSlider1(slider)}>
                    {exzoomcomp.map((slide, i) => (
                      <div className="slick-slide exzoomimgstxt" key={i} imgsrc={slide.img} onMouseEnter={(e) => hoverHandler(slide.img)}>
                        <img className="slick-slide-image" src={slide.img} alt="dummy" />
                      </div>
                    ))}
                  </Slider>
                </div>
                :
                <></>
              }

            </div>
          </div>
          <div className="col-lg-7 py-5 ps-3">
            <h1 className="row UED-font1 ps-3 pt-1" id="productname">{ShowData.productValue.name}</h1>
            <div className="row ps-3" ref={myRef}>
              <div className="row" dangerouslySetInnerHTML={{ __html: ShowData.productValue.topDescription }}></div>
            </div>
            {/* properties div start */}
            <div className="row pt-3" >
              {ShowData.custompropertytables.map((data, i) => {
                var returnData;
                returnData = <div className="col-md-6 my-3" key={i}>
                  {(data.property.propertyType == 1) ?
                    <div className="col-12" key={i}>
                      <div className="col-12">
                        <select className="form-select txtInputValues" tempvar="1" id={`propvalueid_${(data.property.idName)}`} onChange={calculatePrice}>
                          <option value="0">Select</option>
                          {data.property.propertyValues.map((item, j) => {
                            var returData;
                            returData = (
                              <option value={item.id} key={j}>
                                {item.propertyText}
                              </option>
                            );
                            return returData;
                          })}
                        </select>
                      </div>
                    </div>

                    :
                    <div className="col-12">
                      <NumericInput id={`propvalueid_${(data.property.idName)}`} min={parseInt(data.min)} max={parseInt(data.max)} defaultValue={parseInt(data.min)} className="w-100 form-control txtInputValues" onChange={calculatePrice} />
                    </div>
                  }
                  <div className="col-12" >
                    <span className="mb-3 font-weight-bold">
                      {data.isRequired == 1 ?
                        <span className="txtRed me-1">*</span>
                        :
                        <></>
                      }
                      <span className="txtPropName" requiredval={data.isRequired} id={`propnameid_${(data.property.idName)}`} propname={data.property.propertyName}>
                        {(data.property.propertyType == 3) ?
                          `${data.property.propertyName} (Min:${data.min}in, Max:${data.max}in)`
                          :
                          data.property.propertyName
                        }
                      </span>
                      {data.property.description != '' ?
                        <OverlayTrigger delay={{ show: 1000 }}
                          overlay={(props) => (
                            <Tooltip {...props} className="mytooltip">
                              <div dangerouslySetInnerHTML={{ __html: data.property.description }} className="img-fluid"></div>
                            </Tooltip>
                          )} placement="auto">
                          <img src="/images/products_Cate/question.svg" className="ms-2 img-fluid crsr-Pointer" alt="dummy" />
                        </OverlayTrigger>
                        :
                        <></>
                      }<br />
                      <span style={{ color: "red" }} className="validationclas" id={`validationId_${(data.property.idName)}`}></span>
                    </span>
                  </div>
                </div>
                return returnData;
              })}
              {/* {userid != 28 && userid != 29 ?
                <div className="col-md-6 my-3">
                  <div className="col-12">
                    <select className="form-select txtInputValues" id="propvalueid_factoryRush" tempvar="1" onChange={calculatePrice}>
                      <option value="0">Select</option>
                      <option value="277">Yes (${rushprice})</option>
                      <option value="278">No</option>
                    </select>
                    <h6 className="mb-3 font-weight-bold">
                      <span className="txtRed me-1">*</span>
                      <span className="txtPropName" requiredval="1" id="propnameid_factoryRush" propname="Factory Rush">
                        Factory Rush
                      </span>
                      <br />
                      <span style={{ color: "red" }} className="validationclas" id="validationId_factoryRush"></span>
                    </h6>
                  </div>
                </div>
                :
                <></>
              } */}

            </div>

            {/* price and qty div start */}
            <div className="row ps-0">
              <div className="form-check d-flex justify-content-start mb-3 ms-3">
                <input className="form-check-input" id="warrantyId" type="checkbox" value="" onChange={ChangeWarranty} />
                <span className="form-check-label ms-2"> Unconditional Warranty - $30 extra for each item </span>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-md-4"></div>
              <div className="col-md-2 py-2 ps-1 d-none">
                <NumericInput min={qtyMinval} value={qtyVal} className="w-100 form-control" id="txtQtyId" />
              </div>
              <div className="col-md-2 py-2 ps-1 d-none">
                <input
                  disabled
                  type="text"
                  className="form-control text-center font-bold"
                  id="CalculatedPrice"
                />
              </div>
              {/* {ShowData.productValue.onSale == 1 ?
                <div className="col-md-12 text-center">
                  <h6>Discount Price: <b className="brownTxt fs-18">${discountprice}/{ShowData.productValue.priceType == 2 ? "item" : "sq inch"}</b></h6>
                  <h6 className="contentClr fs-14">Actual Price: <strike>${regularprice}/{ShowData.productValue.priceType == 2 ? "item" : "sq inch"}</strike></h6>
                </div>
                :
                <></>
              } */}
            </div>
            {/* add to cart btn start */}
            <div className="row">
              <div className="col-md-12 text-center">
                <button className="orderNowBtn py-2 px-3 btnhover" onClick={opencartpage} id="addtocartbtnid">ADD TO CART</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <u className="brownTxt">
          <h5>DESCRIPTION</h5>
        </u>
        <p>{ShowData.productValue.bottomDescription}</p>
      </div>
      <Footer />
    </div>
  );
}
