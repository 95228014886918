import React, { useState, useEffect, useRef } from "react";
import Carousel from 'react-bootstrap/Carousel';
import $ from 'jquery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CDNURL } from "../js/constants";
import { WhatCustomerThink } from "./components/WhatCustomerThink";
import { Faq } from "./FAQ";
import { ApiGetCall } from "../js/connector";
import DeliverQuality from "./components/DeliverQuality";
export function Home() {
    const [state, setState] = useState({});
    const [bannerDetailsVar, setBannerDetails] = useState([]);
    const [intervalCar, setIntervalCar] = useState(null);
    const [carouselStatus, setCarouselStatus] = useState("");
    const [categoryDetailsVar, setcategoryDetailsVar] = useState([]);
    const [activeSlider, setActiveSlider] = useState("");
    const [sectionContact, setsectionContact] = useState("");
    const myRef = useRef(null);
    var width = $(window).width();

    const css = `
    .myimage:after {
        background: rgb(0 0 0 / ${process.env.REACT_APP_OPACITY}%);
    }
   `

    useEffect(() => {
        myRef.current.scrollTo(0, 0);
        GetBanners();
        return () => {
            setState({});
        };
    }, []);
    function trigger(currentSlider) {
        if (intervalCar == null) {
            setIntervalCar(1000);
        }
        setActiveSlider(currentSlider);
    }
    const triggerStop = () => {
        setIntervalCar(null);
        setActiveSlider("");
    }
    const GetBanners = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Banners/GetBannerImages").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                $("#Overlay").hide();
                $("#LoderId").hide();
                const responseRs = JSON.parse(result);
                var sugData = responseRs.bannerDetails;
                if (responseRs.status == "success") {

                    if (responseRs.bannerDetails != "") {
                        setBannerDetails(sugData);
                    }
                    var tempvar = 0;
                    for (var i = 0; i <= sugData.length; i++) {
                        if (sugData[i].status == 1) {
                            tempvar = 1;
                            break;
                        }
                    }
                    if (tempvar == 1) {
                        setCarouselStatus(1);
                    }
                    else {
                        setCarouselStatus(0);
                    }
                    myFunction();
                    GetProductCat();
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const myFunction = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                var propDetails = responseRs.propertyDetails;
                $("#Overlay").hide();
                $("#LoderId").hide();
                setsectionContact(propDetails[26].value);
            }
        });
    };
    const GetProductCat = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Products/GetAllProducts").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                $("#Overlay").hide();
                $("#LoderId").hide();
                const responseRs = JSON.parse(result);
                var sugData = responseRs.allProducts;
                if (responseRs.status == "Success") {
                    if (responseRs.allProducts != "") {
                        setcategoryDetailsVar(sugData);
                    }
                    const width = window.innerWidth;
                    if (width <= 991) {
                        $(".gallery1").css('visibility', 'visible');
                        $(".gallery1").css('opacity', '1');
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const producturlopen = (url, itemname, itemid, staticpath, urlPath) => {
        var finalname = itemname.replace(/\s/g, '');
        if (url == "") {
            window.localStorage.setItem("useridordernow", itemid);
            window.localStorage.setItem("staticpath", staticpath);
            window.location = "/store/" + urlPath;

        } else if (url == null) {
            window.localStorage.setItem("useridordernow", itemid);
            window.localStorage.setItem("staticpath", staticpath);
            window.location = "/store/" + urlPath;

        } else {
            window.localStorage.setItem("useridordernow", itemid);
            window.localStorage.setItem("staticpath", staticpath);
            window.location = url;
        }
        window.localStorage.removeItem("flag");
    }
    return (
        <div ref={myRef}>
            <style>{css}</style>
            {/* slider */}
            {(carouselStatus == 1) ?
                <div className="container-fluid px-0 myimage">
                    <Carousel controls={false}>
                        {bannerDetailsVar.map((item, i) => {
                            var returData;
                            var profileImage = CDNURL + "Gallery/" + item.imageUrl;
                            var PathName = window.location.origin;
                            (item.status == 1) ?
                                returData = <Carousel.Item data-bs-interval={500} key={i}>
                                    <React.Suspense>
                                        <LazyLoadImage delayTime="10" className="sliderImg" src={profileImage} alt="slider" />
                                    </React.Suspense>
                                    <span className="carousel-caption1">
                                        <h2 className="firstHead">{item.imageCaption}</h2>
                                        <h2 className="firstPara pt-2">{item.description}</h2>
                                        {(item.actionUrl != "") ?
                                            <div className="col-12 text-center pt-3 pe-2">
                                                <a type="submit" className="bannerBtn" href={PathName + "/" + item.actionUrl}>{item.actionBtn}<img src={CDNURL + "Localimages/BannerIcon.png"} className="ps-2 img-fluid" alt="Banner Icon" /></a>
                                            </div> :
                                            <></>
                                        }
                                    </span>
                                </Carousel.Item>
                                :
                                <></>
                            return returData;
                        }
                        )}
                    </Carousel>
                </div>
                :
                <></>
            }

            {/* easy way to shop div */}
            <div className="container pb-5 pt-3">
                <div className="row pt-5">
                    <div className="col-12 text-center">
                        {/* <h2 className="shopAdTitle">Easy Ways to Shop</h2> */}
                        {/* <p className="prodhr" /> */}
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-12">
                        <div className="row justify-content-center">
                            {/* <div className="col-lg-6 col-12 text-center mt-2">
                                <div className="posRel">
                                    <img src={CDNURL + "Localimages/EasyWayShop1.png"} className="img-fluid w-100" alt="Online 24 hours/Day" />
                                    <div className="shopNowClass1 btnhover">
                                        <a href="/products/table-pads" className="shopNowTxt txtHover">
                                            SHOP NOW
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-6 text-center mt-2">
                                <div className="posRel">
                                    <img src="images/EasyWaysToShop.png" className="img-fluid  w-100" alt="By Phone 7 Days a Week" />
                                    {/* <img src={CDNURL + "Localimages/EasyWayShop2.png"} className="img-fluid  w-100" alt="By Phone 7 Days a Week" /> */}
                                    <div className="shopNowClass2 btnhover">
                                        <a href="tel:+18007372165" className="shopNowTxt txtHover">
                                            CALL: 1-800-737-2165
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* our products div */}
            <div className="container pt-5 pb-3">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <h2 className="shopAdTitle">Our Products</h2>
                        <p className="prodhr" />
                    </div>

                    <div className="row pt-3">
                        <div className="col-12">
                            <div className="row">
                                {categoryDetailsVar.map((item, i) => {
                                    var returData;
                                    {
                                        (item.active == 1) ?
                                            returData = <div className="col-lg-3 col-12 text-center" key={i}>
                                                <div className="posRel myImageHover">
                                                    {width <= 991 ?
                                                        <Carousel className="sampleSlide" interval={1000} controls={false} onMouseEnter={() => trigger(`slider${item.id}`)} onMouseLeave={triggerStop}>
                                                            {item.productsImages.map((slide, i) => (
                                                                <Carousel.Item key={i} interval={1000} className="CarouselClass crsr-pointer" onClick={() => producturlopen(item.productUrl, item.name, item.id, item.staticPath, item.urlPath)}>
                                                                    {item.onSale == 1 ?
                                                                        <div className="corner-ribbon top-right blue">
                                                                            On Sale
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    <div className="w-100  h-100">
                                                                        <img src={CDNURL + "Gallery/" + slide.imageUrl} className="w-100 h-100" alt="Product Image" />
                                                                    </div>
                                                                </Carousel.Item>
                                                            ))}
                                                        </Carousel>
                                                        :
                                                        <Carousel className="sampleSlide" pause={(activeSlider == `slider${item.id}`) ? false : true} controls={false} interval={(activeSlider == `slider${item.id}`) ? 1000 : null} onMouseEnter={() => trigger(`slider${item.id}`)} onMouseLeave={triggerStop}>
                                                            {item.productsImages.map((slide, i) => (
                                                                <Carousel.Item key={i} className="CarouselClass crsr-pointer" onClick={() => producturlopen(item.productUrl, item.name, item.id, item.staticPath, item.urlPath)}>
                                                                    {item.onSale == 1 ?
                                                                        <div className="corner-ribbon top-right blue">
                                                                            On Sale
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    <div className="w-100 h-100">
                                                                        <img src={CDNURL + "Gallery/" + slide.imageUrl} className="w-100 h-100" alt="Product Image" />
                                                                    </div>
                                                                </Carousel.Item>
                                                            ))}
                                                        </Carousel>
                                                    }

                                                    {/* <div className="gallery1" onClick={() => producturlopen(item.productUrl, item.name, item.id, item.staticPath, item.urlPath)}>
                                                        SHOP NOW
                                                    </div> */}
                                                </div>
                                                <p className="font-bold pb-5 pt-3 ">{item.name}</p>
                                            </div>
                                            :
                                            <></>
                                    }
                                    return returData;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-6 col-12 px-0">
                    <iframe
                        title="We Deliver Quality Table Pads!"
                        width="100%"
                        style={{height:"400px"}}
                        src="https://youtube.com/embed/H7S4ylOXaLk"
                    ></iframe>
                </div>
            </div>
            {/* <DeliverQuality /> */}
            <WhatCustomerThink isH1Tag="no" />
            <Faq />
        </div>
    )
}
