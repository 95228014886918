import React from "react";
import { WhatCustomerThink } from "./components/WhatCustomerThink";
import { Footer } from "./Footer";
import { NavigationBar } from "./Navigationbar";

export default function PrivacyPolicy() {
  return (
    <div>
      <NavigationBar />
      <div className="container py-5 colorPara ps-0">
        <div className="container-fluid">
          <div className="text-center">
            <h2 className="h1">Privacy Policy</h2>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <div className="ps-2 pt-3">
          Your privacy is very important to us. Accordingly, we have developed
          this Policy in order for you to understand how we collect, use,
          communicate and disclose and make use of personal information. The
          following outlines our privacy policy.
        </div>
        <ul>
          <li className="ps-2 pt-3">
            Before or at the time of collecting personal information, we will
            identify the purposes for which information is being collected.
          </li>
          <li className="ps-2 pt-3">
            We will collect and use of personal information solely with the
            objective of fulfilling those purposes specified by us and for other
            compatible purposes, unless we obtain the consent of the individual
            concerned or as required by law.
          </li>
          <li className="ps-2 pt-3">
            We will only retain personal information as long as necessary for
            the fulfillment of those purposes.
          </li>
          <li className="ps-2 pt-3">
            We will collect personal information by lawful and fair means and,
            where appropriate, with the knowledge or consent of the individual
            concerned.
          </li>
          <li className="ps-2 pt-3">
            Personal data should be relevant to the purposes for which it is to
            be used, and, to the extent necessary for those purposes, should be
            accurate, complete, and up-to-date.
          </li>
          <li className="ps-2 pt-3">
            We will protect personal information by reasonable security
            safeguards against loss or theft, as well as unauthorized access,
            disclosure, copying, use or modification.
          </li>
          <li className="ps-2 pt-3">
            We will make readily available to customers information about our
            policies and practices relating to the management of personal
            information.
          </li>
        </ul>
        <div className="ps-2 pt-3">
          We are committed to conducting our business in accordance with these
          principles in order to ensure that the confidentiality of personal
          information is protected and maintained.
        </div>
      </div>
      <div className="container py-5 colorPara ps-0">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Web Site Terms and Conditions of Use</h1>
            <p className="prodhr mt-1" />
          </div>
        </div>

        <ol>
          <li style={{ fontSize: "2rem" }}>
            <h2>Terms</h2>
          </li>
          <div className=" ps-2 pt-3">
            By accessing this web site, you are agreeing to be bound by these
            web site Terms and Conditions of Use, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this site. The
            materials contained in this web site are protected by applicable
            copyright and trade mark law.
          </div>
          <li className="mt-4" style={{ fontSize: "2rem" }}>
            <h2>Use License</h2>
          </li>
          <ol className="my-4" type="a">
            <li>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on Bergers Table Pad Factory's
              web site for personal, non-commercial transitory viewing only.
              This is the grant of a license, not a transfer of title, and under
              this license you may not:
            </li>
            <ol className="my-4">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on Bergers Table Pad Factory's web site;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or "mirror" the
                materials on any other server.
              </li>
            </ol>
            <li>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by Bergers Table Pad
              Factory at any time. Upon terminating your viewing of these
              materials or upon the termination of this license, you must
              destroy any downloaded materials in your possession whether in
              electronic or printed format.
            </li>
          </ol>
          <li className="mt-4" style={{ fontSize: "2rem" }}>
            <h2>Disclaimer</h2>
          </li>
          <div className="ps-2 pt-3">
            The materials on Bergers Table Pad Factory's web site are provided
            "as is". Bergers Table Pad Factory makes no warranties, expressed
            or implied, and hereby disclaims and negates all other warranties,
            including without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or
            non-infringement of intellectual property or other violation of
            rights. Further, Bergers Table Pad Factory does not warrant or
            make any representations concerning the accuracy, likely results,
            or reliability of the use of the materials on its Internet web
            site or otherwise relating to such materials or on any sites
            linked to this site.
          </div>
          <li className="mt-4" style={{ fontSize: "2rem" }}>
            <h2>Limitations</h2>
          </li>
          <div className="ps-2 pt-3">
            In no event shall Bergers Table Pad Factory or its suppliers be
            liable for any damages (including, without limitation, damages for
            loss of data or profit, or due to business interruption,) arising
            out of the use or inability to use the materials on Bergers Table
            Pad Factory's Internet site, even if Bergers Table Pad Factory or a
            Bergers Table Pad Factory authorized representative has been
            notified orally or in writing of the possibility of such damage.
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.
          </div>
          <li className="mt-4" style={{ fontSize: "2rem" }}>
            <h2>Revisions and Errata</h2>
          </li>
          <div className="ps-2 pt-3">
            The materials appearing on Bergers Table Pad Factory's web site
            could include technical, typographical, or photographic errors.
            Bergers Table Pad Factory does not warrant that any of the materials
            on its web site are accurate, complete, or current. Bergers Table
            Pad Factory may make changes to the materials contained on its web
            site at any time without notice. Bergers Table Pad Factory does not,
            however, make any commitment to update the materials.
          </div>
          <li className="mt-4" style={{ fontSize: "2rem" }}>
            <h2>Links</h2>
          </li>
          <div className="ps-2 pt-3">
            Bergers Table Pad Factory has not reviewed all of the sites linked
            to its Internet web site and is not responsible for the contents of
            any such linked site. The inclusion of any link does not imply
            endorsement by Bergers Table Pad Factory of the site. Use of any
            such linked web site is at the user's own risk.
          </div>
          <li className="mt-4" style={{ fontSize: "2rem" }}>
            <h2>Site Terms of Use Modifications</h2>
          </li>
          <div className="ps-2 pt-3">
            Bergers Table Pad Factory may revise these terms of use for its web
            site at any time without notice. By using this web site you are
            agreeing to be bound by the then current version of these Terms and
            Conditions of Use.
          </div>
          <li className="mt-4" style={{ fontSize: "2rem" }}>
            <h2>Governing Law</h2>
          </li>
          <div className="ps-2 pt-3">
            Any claim relating to Bergers Table Pad Factory's web site shall be
            governed by the laws of the State of Indiana without regard to its
            conflict of law provisions.
          </div>
          <div className="ps-2 pt-3">
            General Terms and Conditions applicable to Use of a Web Site.
          </div>
        </ol>
      </div>

      <WhatCustomerThink isH1Tag="no" />
      <Footer />
    </div>
  );
}
