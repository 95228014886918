import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useEffect, useState } from "react";
import { CDNURL } from "../js/constants";
import $ from 'jquery';
import React from "react";
import { ApiGetCall } from "../js/connector";
export function MeasuringTables() {
    const [isVisible, setIsVisible] = useState(true);
    const [sectionTitle, setsectionTitle] = useState("");
    const [sectionDesciption, setsectionDesciption] = useState("");
    const [sectionCTAtext, setsectionCTAtext] = useState("");
    useEffect(() => {
        $('#yourElementId').text('How to Measure your table for a table pads');
        myFunction();
        const timer = setTimeout(() => {
            setIsVisible(false);
        });
        return () => {
            clearTimeout(timer);
        }
    }, []);
    const myFunction = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                var propDetails = responseRs.propertyDetails;
                $("#Overlay").hide();
                $("#LoderId").hide();
                setsectionTitle(propDetails[10].value);
                setsectionDesciption(propDetails[11].value);
                setsectionCTAtext(propDetails[12].value);
            }
        });
    };
    const gotonavbar = () => {
        $('html, body').animate({
            scrollTop: $("#GotoNavbar").offset().top
        }, 100);
    }
    return (
        <>
            <NavigationBar />
            <div className="container-fluid px-0  colorHeroSection">
                <div className="row">
                <div className="col-md-6 pb-2 text-center">
                        <img src={CDNURL + "Localimages/MeasuringGroup.png"} className="img-fluid text-end" alt="Hero Image"/>
                    </div>
                    <div className="col-md-6 m-auto pt-3" style={{ paddingLeft: "4rem", paddingRight: "6rem" }}>
                        <h2 className="availbleColorTxt">{sectionTitle}</h2>
                        <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
                        <button className="ColorBtn mb-3" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
                            <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More"/>
                        </button>
                    </div>
                    
                </div>
            </div>

            {/* No Pattern Necessary start */}
            <div className="pb-5 px-0  "  >
                <div className="container p-5 ">
                    <div className="text-center ">
                        <h2>No Pattern Necessary</h2>
                        <p className="prodhr mt-1" />
                    </div>
                    <ul className="colorPara py-3">
                        <li className="pt-2 liBullet ps-2">At least 50 percent of table pads do NOT require a table pattern. If your table is square, round, or octagon, we do not need a pattern of your table.</li>
                        <li className="pt-2 liBullet ps-2">WE HAVE THOUSANDS OF TABLE PATTERNS ON FILE!</li>
                        <li className="pt-2 liBullet ps-2">Just check underneath your table or your table’s receipt for a model number (usually written in crayon). We probably have it on file.</li>
                        <li className="pt-2 liBullet ps-2">If we do require a pattern of your table, we can walk you through the simple process in about two minutes!</li>
                    </ul>
                </div>
            </div>
            {/* No Pattern Necessary end */}

            {/* Measuring Dining Tables para start */}
            <div className="container-fluid py-5 bgF6F6F6">
                <div className="col-12 text-center px-5">
                    <h1>How to Measure for a Table Pad</h1>
                    <p className="prodhr mt-1" />
                    <span className="contentClr">If you're looking for instructions on how to measure for a table pad, this is the correct place.</span>
                </div>
            </div>


            <div className="container-fluid py-5 px-5 bgF6F6F6">
                <div className="col-12 text-center">
                    <h2>Measuring Dining Tables</h2>
                    <p className="prodhr mt-1" />
                </div>
                <div className="col-12 pt-3">
                    <div className="row">
                        <div className="col-md-6 col-12 px-0">
                            <LazyLoadImage src={CDNURL + "Localimages/measuringimg1.png"} className="img-fluid py-5 card shadow-2-strong mx-auto" alt="Measuring Dining Tables"/>
                        </div>
                        <div className="col-md-6 col-12 px-0">
                            <LazyLoadImage src={CDNURL + "Localimages/measuringimg2.png"} className="img-fluid py-5 card shadow-2-strong mx-auto" alt="Measuring Dining Tables"/>
                        </div>
                    </div>
                </div>
                <div className="col-12 pt-3">
                    <div className="row">
                        <div className="col-12">
                            <ul className="fs-18">
                                <li className="pt-2 liBullet ps-2">Measure the short side of the table first - this will be the width.</li>
                                <li className="pt-2 liBullet ps-2">Measure the long side of the table second, with leaves removed - this will be the length.</li>
                                <li className="pt-2 liBullet ps-2">Measure the width of your extension leaves (see image for width).</li>
                                <li className="pt-2 liBullet ps-2">If you have a square table with square corners (90°), these are the measurements you will use. If your table is shaped, refer to the next sections for measuring or patterning.</li>
                                <li className="pt-2 liBullet ps-2">Measure the flat table surface to the nearest 1/16" (excluding beveled edge). Give us the number of leaves and each leaf length.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Measuring Dining Tables para end */}


            {/* Measuring Tips start */}
            <div className="pb-5 px-0 ">
                <div className="container-fluid">
                    <div className="col-12 py-5">
                        <div className="col-12 text-center">
                            <h2>Measuring Tips</h2>
                            <p className="prodhr mt-1" />
                        </div>
                        <div className="container">
                            <div className="row pt-5">
                                <div className="col-md-6 col-12 text-center">
                                    <h4 className="pb-3">Measuring with a Tape Measure</h4>
                                    <LazyLoadImage src={CDNURL + "Localimages/measuringimg3.png"} className="img-fluid card shadow-2-strong mx-auto" alt="Tape Measure Image"/>
                                </div>
                                <div className="col-md-6 col-12 ">
                                    <ul className="cartTxt">
                                        <li className="liBullet ps-2">Measure width of table at center split to nearest 1/16" (flat surface only).</li>
                                        <li className="pt-2 liBullet ps-2">Important: Do not include beveled or sloped edges.</li>
                                        <li className="pt-2 liBullet ps-2">Best to use metal tape measure.</li>
                                        <li className="pt-2 liBullet ps-2">Measure full length of table from end to end directly down center (flat surface only).</li>
                                        <li className="pt-2 liBullet ps-2">Important: Make sure extension leaves are removed.</li>
                                        <li className="pt-2 liBullet ps-2">Never use measurements provided by manufacturer (they usually include molding or sloped edges).</li>
                                        <li className="pt-2 liBullet ps-2">Pattern is necessary unless table has square corners (90 degrees) or is perfectly round.</li>
                                        <li className="pt-2 liBullet ps-2">Do not include molding around perimeter or sloped edges.</li>
                                        <li className="pt-2 liBullet ps-2">Do not include extension leaves.</li>
                                    </ul>
                                </div>

                                <div className="col-12 me-2 pt-3 text-center">
                                    <div className="row">
                                        <div className="col-md-4 offset-md-4 col-12">
                                            <button type="submit" className="loginBtn btnhover">
                                                <a href="/products/table-pads">VIEW OUR TABLE PADS</a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Measuring Tips end */}

            {/* Measuring Conference Tables start */}
            <div className="container-fluid pb-5 px-5 bgF6F6F6">
                <div className="col-12 text-center pt-4">
                    <h2>Measuring Conference Tables</h2>
                    <p className="prodhr mt-1" />
                    <h6 className="pt-2">If your conference table is a conventional shape, use the instructions above for measuring. If it is an unconventional shape, please refer to the instructions below.</h6>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-4 col-12 py-2">
                            <div className="card shadow-2-strong mx-auto">
                                <div className="px-3">
                                    <LazyLoadImage src={CDNURL + "Localimages/Conference1.png"} className="img-fluid" alt="Channel Table"/>
                                </div>
                                <div className="cardText">
                                    <h3>Channel Table</h3>
                                    <p className="pt-2">Measure at the outside widest and longest points.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 py-2">
                            <div className="card shadow-2-strong mx-auto">
                                <div className="px-3">
                                    <LazyLoadImage src={CDNURL + "Localimages/Conference2.png"} className="img-fluid" alt="Horseshoe Table"/>
                                </div>
                                <div className="cardText">
                                    <h3>Horseshoe Table</h3>
                                    <p className="pt-2">Measure at the outside widest and longest points, to the farthest open edge of the horseshoe.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 py-2">
                            <div className="card shadow-2-strong mx-auto">
                                <div className="px-3">
                                    <LazyLoadImage src={CDNURL + "Localimages/Conference3.png"} className="img-fluid" alt="Racetrack Table"/>
                                </div>
                                <div className="cardText">
                                    <h3>Racetrack Table</h3>
                                    <p className="pt-1">Measure at the outside widest and longest points, these are different than tables with simple port holes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 py-5 text-center">
                        <div className="row">
                            <div className="col-md-4 offset-md-4 col-12 me-2">
                                <button type="submit" className="loginBtn btnhover">
                                    <a href="/products/other-furniture-pads">View Our Conference Table Pads</a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Measuring Conference Tables end */}

            {/* Measuring Drop Leaf Tables start */}
            <div className="pb-5 px-0">
                <div className="container-fluid ">
                    <div className="col-12 py-5 text-center">
                        <h2>Measuring Drop Leaf Tables</h2>
                        <p className="prodhr mt-1" />
                    </div>
                    <div className="container">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <ul className="cartTxt">
                                        <li className="pt-2 liBullet ps-2">Raise the drop leaves and remove any extension leaves.</li>
                                        <li className="pt-2 liBullet ps-2">Measure the short side of the table first - this will be the width.</li>
                                        <li className="pt-2 liBullet ps-2">Measure the long side of the table with the drop leaves raised - this will be the total length.</li>
                                        <li className="pt-2 liBullet ps-2">Measure the width of any leaves.</li>
                                        <li className="pt-2 liBullet ps-2">If you have a square table with square corners (90°), these are the measurements you will use. If your table is shaped, refer to the next sections for measuring or patterning.</li>
                                    </ul>
                                </div>
                                <div className="col-md-6 col-12">
                                    <LazyLoadImage src={CDNURL + "Localimages/DropLeaf.png"} className="img-fluid card shadow-2-strong mx-auto" alt="Drop Leaf Tables"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Measuring Drop Leaf Tables end */}


            {/* Measuring a Buffet Table start */}
            <div className="px-0 bgF6F6F6">
                <div className="container  p-5">
                    <div className="text-center">
                        <h2>Measuring a Buffet Table</h2>
                        <p className="prodhr mt-1" />
                    </div>
                    <div className="col-12 pb-5">
                        <div className="row pt-3">
                            <ul className="cartTxt ">
                                <li className="pt-2 liBullet ps-2">Measure the short side of the table first - this will be the width.</li>
                                <li className="pt-2 liBullet ps-2">Measure the long side of the table second - this will be the length.</li>
                                <li className="pt-2 liBullet ps-2">If you have a square table with square corners (90°), these are the measurements you will use. If your table is shaped, refer to the next sections for measuring or patterning.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 py-3 text-center">
                        <div className="row">
                            <div className="col-md-4 offset-md-4 col-12 me-2">
                                <button type="submit" className="loginBtn btnhover">
                                    <a href="/products/other-furniture-pads">View Our Buffet Table Pads</a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Measuring a Buffet Table end */}


            {/* Measuring a Piano start */}
            <div className="pb-5 px-0 ">
                <div className="container-fluid  text-center">
                    <div className="col-12 py-3 text-center">
                        <h2>Measuring a Piano</h2>
                        <p className="prodhr mt-1" />
                        <p className="pt-2">When measuring a piano for a pad, it is important to measure the widest and longest points of the piano.</p>
                        <div className="py-5">
                            <LazyLoadImage src={CDNURL + "Localimages/Piano.png"} className="img-fluid pt-5 card shadow-2-strong mx-auto" alt="Piano"/>
                        </div>
                    </div>
                    <div className="col-12 pb-5 text-center">
                        <div className="row">
                            <div className="col-md-4 offset-md-4 col-12 me-2">
                                <button type="submit" className="loginBtn btnhover">
                                    <a href="/products/other-furniture-pads">View Our Piano Pads</a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Measuring a Piano end */}


            <Footer />
        </>
    )
}