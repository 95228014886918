import React, { useState, useRef, useEffect } from "react";
import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import $ from "jquery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CDNURL } from "../js/constants";
import * as NumericInput from "react-numeric-input";
import 'bootstrap/dist/css/bootstrap.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ApiGetCall } from "../js/connector";
import { v4 as uuid } from 'uuid';
import TablePadsMulipleUrlSelection from "./components/TablePadsMulipleUrlSelection";
export default function GoodEconomyTablePads() {
    const [overallstate, setoverallstate] = useState({});
    const [exzoomcomp, setexzoomcomp] = useState([]);
    const [qtyMinval, setqtyMinval] = useState(null);
    const [qtyVal, setqtyVal] = useState("");
    const [nav1, setNav1] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [img, setImg] = useState("");
    const [warranty, setwarranty] = useState(0);
    const [leaveyesno, setleaveyesno] = useState(1);
    const [howmanyleaves, sethowmanyleaves] = useState(1);
    const [prodtemparray, setprodarray] = useState([]);
    var flag = parseInt(window.localStorage.getItem("flag"));
    const [rushprice, setrushprice] = useState("");
    const [showdata, setShowData] = useState("");
    const [regularprice, setregularprice] = useState("");
    const [discountprice, setdiscountprice] = useState("");
    const [cartlength, setcartlength] = useState("");
    const [cartwidth, setcartwidth] = useState("");
    const images = [];
    const myRef = useRef(null);
    const tableshapedata = "<div class='col-12'><p>This is the shape of your table, Please use the image for more information about how to measure your table.</p></div><div class='col-12'><img src='https://tpindy.azureedge.net/Gallery/5a81b0bb-3aca-4f7d-9807-ef3fbedcdff7.jpg' /></div>";
    const widthInches = "<p>This is the Short side of your leaf, Please use the image for more information about how to measure your table.</p><img src='https://tpindy.azureedge.net/Gallery/744b3b6b-d5cd-41be-b0ad-3e90f8f1ae32.jpg' />";
    const lengthInches = "<p>This is the long side of your table, measure with all leaves removed, Please use the image for more information about how to measure your table.</p><img src='https://tpindy.azureedge.net/Gallery/744b3b6b-d5cd-41be-b0ad-3e90f8f1ae32.jpg' />";
    const leaflength = "<div class='col-12'><p>This is the Short side of your leaf, Please use the image for more information about how to measure your table.</p></div><div class='col-12'><img src='https://tpindy.azureedge.net/Gallery/764f15f0-d460-451a-a468-aace68029740.jpg' /></div>";
    useEffect(() => {
        setqtyMinval(1);
        setqtyVal(1);
        GetUploadImage();
        setNav1(slider1);
        return () => {
            setoverallstate({});
        };
    }, []);
    const GetUploadImage = async () => {
        setoverallstate({ test: true });
        $("#Overlay").show();
        $("#LoderId").show();
        var userid = window.localStorage.getItem("useridordernow");
        var flag = parseInt(window.localStorage.getItem("flag"));
        if (userid != null) {
            userid = parseInt(window.localStorage.getItem("useridordernow"));
            await ApiGetCall("/OrderNow/ProductOrderById/" + userid + "?path=null").then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    var slidesData = [];
                    setrushprice(parseInt(responseRs.rushPrice));
                    var vimgs = responseRs.allProducts.productsImages;
                    $("#hdnregularprice").val(responseRs.allProducts.regularPrice);
                    $("#hdnsalesprice").val(responseRs.allProducts.salesPrice);
                    $("#hdnshippingprice").val(responseRs.allProducts.shippingPrice);
                    $("#hdnonsaleprice").val(responseRs.allProducts.onSale);
                    $("#hdnProductType").val(responseRs.allProducts.productType);
                    setShowData(responseRs.allProducts);
                    setregularprice(responseRs.allProducts.regularPrice);
                    setdiscountprice(responseRs.allProducts.salesPrice);
                    for (var i = 0; i < vimgs.length; i++) {
                        var vimg = { img: CDNURL + "Gallery/" + vimgs[i].imageUrl };
                        slidesData.push(vimg);
                        images.push(CDNURL + "Gallery/" + vimgs[i].imageUrl);
                    }
                    setImg(images[0]);
                    setexzoomcomp(slidesData);
                    $('#yourElementId').text(responseRs.allProducts.name);
                    var url = window.location.href;
                    var parts = url.split("/");
                    var uid = parts[parts.length - 1];
                    var addtocart = JSON.parse(localStorage.getItem("addtocart"));
                    var ProdArray = [];
                    if (addtocart != null) {
                        var cartproducts = addtocart.Products;
                        if (uid != null) {
                            for (var i = 0; i < cartproducts.length; i++) {
                                if (cartproducts[i].UID == uid) {
                                    ProdArray.push(cartproducts[i]);
                                }
                            }
                            setprodarray(ProdArray);
                            if (ProdArray.length != 0) {
                                $("#txtQtyId").val(ProdArray[0].qty);
                                $("#CalculatedPrice").val("$" + parseFloat(ProdArray[0].price).toFixed(2));
                                var prodproperty = ProdArray[0].propertyname;
                                for (var j = 0; j < prodproperty.length; j++) {
                                    $(".txtPropName").each(function () {
                                        var vid = $(this).attr('id');
                                        var vsplit = vid.split('_');
                                        var vpropname = vsplit[1];
                                        var tempvar = $("#propvalueid_" + vpropname).attr('tempvar');
                                        var proname = $(this).text();
                                        if (proname == prodproperty[j].propname) {
                                            if (tempvar == 1) {
                                                $("#propvalueid_" + vpropname + " option:selected").text(prodproperty[j].propvalue);
                                                $("#propvalueid_" + vpropname + " option:selected").val(prodproperty[j].optionvalue);
                                            } else {
                                                $("#propvalueid_" + vpropname).val(prodproperty[j].propvalue);
                                            }
                                        }
                                    });
                                    $("#addtocartbtnid").text('Update Cart');
                                }
                            }
                            if (flag == 1) {
                                $("#warrantyId").attr('checked', true);
                            }
                            console.log('test');
                            console.log(ProdArray);
                            if (ProdArray.length != 0) {
                                if (ProdArray[0].leaves == 0) {
                                    $("#extensionleaveNo").prop('checked', true);
                                } else {
                                    $("#extensionleaveyes").prop('checked', true);
                                    $("#differentsizeDiv").removeClass('d-none');
                                    if (ProdArray[0].LeaveDifferentSize == 2) {
                                        $("#DifferentSizeDivNo").prop('checked', true);
                                        $(".LeaveshdnDiv").removeClass('d-none');
                                        $("#propvalueid_lengthofleaves").val(ProdArray[0].LeavesArray[0].lengthInches);
                                        $("#propvalueid_numberofleaves").val(ProdArray[0].leaves);
                                        $("#propvalueid_leaflength").val(ProdArray[0].LeavesArray[0].lengthFraction);
                                        $("#howmanyleavesdiv").addClass('d-none');
                                        $(".commonleafdiv").addClass('d-none');
                                    } else {
                                        $("#DifferentSizeDivYes").prop('checked', true);
                                        $("#propvalueid_howmanyleaves").val(ProdArray[0].leaves);
                                        $("#propnameid_lengthofleaves").val("");
                                        $("#propvalueid_leaflength").val(0);
                                        $("#howmanyleavesdiv").removeClass('d-none');
                                        $(".LeaveshdnDiv").addClass('d-none');
                                        var leaveid = ProdArray[0].leaves;
                                        if (leaveid == 1) {
                                            $(".commonleafdiv").addClass('d-none');
                                            $(".leafonediv").removeClass('d-none');
                                            $("#propvalueid_leafonelength").val(ProdArray[0].LeavesArray[0].lengthInches);
                                        } else if (leaveid == 2) {
                                            $(".commonleafdiv").addClass('d-none');
                                            $(".leafonediv").removeClass('d-none');
                                            $(".leafseconddiv").removeClass('d-none');
                                            $("#propvalueid_leafonelength").val(ProdArray[0].LeavesArray[0].lengthInches);
                                            $("#propvalueid_leafsecondlength").val(ProdArray[0].LeavesArray[1].lengthInches);
                                        } else if (leaveid == 3) {
                                            $(".commonleafdiv").addClass('d-none');
                                            $(".leafonediv").removeClass('d-none');
                                            $(".leafseconddiv").removeClass('d-none');
                                            $(".leafthirddiv").removeClass('d-none');
                                            $("#propvalueid_leafonelength").val(ProdArray[0].LeavesArray[0].lengthInches);
                                            $("#propvalueid_leafsecondlength").val(ProdArray[0].LeavesArray[1].lengthInches);
                                            $("#propvalueid_leafthirdlength").val(ProdArray[0].LeavesArray[2].lengthInches);
                                        } else if (leaveid == 4) {
                                            $(".commonleafdiv").addClass('d-none');
                                            $(".leafonediv").removeClass('d-none');
                                            $(".leafseconddiv").removeClass('d-none');
                                            $(".leafthirddiv").removeClass('d-none');
                                            $(".leaffourthdiv").removeClass('d-none');
                                            $("#propvalueid_leafonelength").val(ProdArray[0].LeavesArray[0].lengthInches);
                                            $("#propvalueid_leafsecondlength").val(ProdArray[0].LeavesArray[1].lengthInches);
                                            $("#propvalueid_leafthirdlength").val(ProdArray[0].LeavesArray[2].lengthInches);
                                            $("#propvalueid_leaffourthlength").val(ProdArray[0].LeavesArray[3].lengthInches);
                                        } else if (leaveid == 5) {
                                            $(".commonleafdiv").addClass('d-none');
                                            $(".leafonediv").removeClass('d-none');
                                            $(".leafseconddiv").removeClass('d-none');
                                            $(".leafthirddiv").removeClass('d-none');
                                            $(".leaffourthdiv").removeClass('d-none');
                                            $(".leaffifthdiv").removeClass('d-none');
                                            $("#propvalueid_leafonelength").val(ProdArray[0].LeavesArray[0].lengthInches);
                                            $("#propvalueid_leafsecondlength").val(ProdArray[0].LeavesArray[1].lengthInches);
                                            $("#propvalueid_leafthirdlength").val(ProdArray[0].LeavesArray[2].lengthInches);
                                            $("#propvalueid_leaffourthlength").val(ProdArray[0].LeavesArray[3].lengthInches);
                                            $("#propvalueid_leaffifthlength").val(ProdArray[0].LeavesArray[4].lengthInches);
                                        } else {
                                            $(".commonleafdiv").addClass('d-none');
                                        }

                                    }
                                    calculatePrice();
                                }
                            }
                        }
                    }
                    calculatePrice();
                }
            });
        } else {
            window.location = "/";
        }
    }
    const calculatePrice = () => {
        var regularPrice = parseFloat($("#hdnregularprice").val());
        var length = $("#propvalueid_LengthInches").val();
        var width = $("#propvalueid_widthInches").val();
        var fractionlength = $("#propvalueid_lengthFraction option:selected").text();
        var fractionwidth = $("#propvalueid_widthFraction option:selected").text();
        var factoryval = $("#propvalueid_factoryRush option:selected").val();
        var onsaleprod = $("#hdnonsaleprice").val();
        var hdnsalesprice = parseFloat($("#hdnsalesprice").val());
        var fractionsplit = fractionlength.split('/');
        var value1 = fractionsplit[0];
        var value2 = fractionsplit[1];
        var division = 0;
        if (value2 == undefined) {
            division = 0;
        } else {
            division = parseInt(value1) / parseInt(value2);
        }
        var finallength = parseFloat(division) + parseFloat(length);

        var fractionwidthsplit = fractionwidth.split('/');
        var widthvalue1 = fractionwidthsplit[0];
        var widthvalue2 = fractionwidthsplit[1];
        var widthdivision = 0;
        if (widthvalue2 == undefined) {
            widthdivision = 0;
        } else {
            widthdivision = parseInt(widthvalue1) / parseInt(widthvalue2);
        }
        var finalwidth = parseFloat(widthdivision) + parseFloat(width);

        var factoryRush = 0;
        if (factoryval == "277") {
            factoryRush = rushprice;
        }
        //var leavesNoPrice = 0;
        var finalLeafLength = 0;
        if (leaveyesno == 0 && $("#propvalueid_lengthofleaves").val() != "") {
            var numberleaves = $("#propvalueid_numberofleaves option:selected").val();
            var lengthleaves = $("#propvalueid_lengthofleaves").val();
            var leaflengthfraction = $("#propvalueid_leaflength option:selected").text();
            var leaffractionsplit = leaflengthfraction.split('/');
            var leaf1 = leaffractionsplit[0];
            var leaf2 = leaffractionsplit[1];
            var divisionleaf = 0;
            if (leaf2 == undefined) {
                divisionleaf = 0;
            } else {
                divisionleaf = parseInt(leaf1) / parseInt(leaf2);
            }
            var sameSizeLeafLength = parseFloat(divisionleaf) + parseFloat(lengthleaves);

            finalLeafLength = (parseInt(numberleaves) * parseFloat(sameSizeLeafLength)).toFixed(2);
        } else {


            if (howmanyleaves == 1) {
                var totalOfLeaf = 0;
                var finalleaffractionlength = 0;
                $(".leaflengthclass:visible").each(function () {
                    var leaflength = $(this).val();
                    if (leaflength != "") {
                        totalOfLeaf = parseFloat(totalOfLeaf) + parseFloat(leaflength);
                    }
                });
                $(".leaffractionlengthclass:visible").each(function () {
                    var vid = $(this).attr('id');
                    var fractionval = $("#" + vid + " option:selected").text();
                    if (fractionval != "Select") {
                        var leaffractionyessplit = fractionval.split('/');
                        var leaf11 = leaffractionyessplit[0];
                        var leaf12 = leaffractionyessplit[1];
                        var divisionleaffraction = 0;
                        if (leaf12 == undefined) {
                            divisionleaffraction = 0;
                        } else {
                            divisionleaffraction = parseInt(leaf11) / parseInt(leaf12);
                        }
                        finalleaffractionlength = parseFloat(finalleaffractionlength + parseFloat(divisionleaffraction)).toFixed(2);
                    }
                });
                finalLeafLength = parseFloat(totalOfLeaf) + parseFloat(finalleaffractionlength);


            }
        }

        finallength = parseFloat(finallength) + parseFloat(finalLeafLength);
        setcartlength(finallength);
        setcartwidth(finalwidth);
        var finalprice = 0;
        if (onsaleprod == 1) {
            finalprice = (parseFloat(hdnsalesprice) * parseFloat(finalwidth) * parseFloat(finallength)).toFixed(2);
        } else {
            finalprice = (regularPrice * parseFloat(finalwidth) * parseFloat(finallength)).toFixed(2);
        }
        $("#CalculatedPrice").val("$" + finalprice);
    }
    const settingsThumbs = {
        slidesToShow: 2,
        slidesToScroll: 1,
        asNavFor: ".slider-for",
        dots: true,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: "10px",
        width: 600
    };
    const hoverHandler = (image, i) => {
        setImg(image);
    };
    const clickTigger = () => {
        var imgsrc = $(".slick-current .slick-slide")
            .find("img")
            .attr("src");
        setImg(imgsrc);
    };
    $(".slick-prev").click(function () {
        clickTigger();
    });
    $(".slick-next").click(function () {
        clickTigger();
    });
    $(".slick-dots").click(function () {
        clickTigger();
    });
    const ChangeWarranty = () => {
        if ($("#warrantyId").is(":checked")) {
            setwarranty(1);
        } else {
            setwarranty(0);
        }
    }
    const showdifferentdiv = () => {
        $("#differentsizeDiv").removeClass('d-none');
    }
    const hidedifferentsizediv = () => {
        $("#differentsizeDiv").addClass('d-none');
        $("#howmanyleavesdiv").addClass('d-none');
        $(".commonleafdiv").addClass('d-none');
        $(".LeaveshdnDiv").addClass('d-none');
    }
    const showleavesdiv = () => {
        $("#propnameid_lengthofleaves").val("");
        $("#propvalueid_leaflength").val(0);
        $("#howmanyleavesdiv").removeClass('d-none');
        $(".LeaveshdnDiv").addClass('d-none');
        setleaveyesno(1);
        calculatePrice();

    }
    const showNoLeavesdiv = () => {
        $(".LeaveshdnDiv").removeClass('d-none');
        $("#propnameid_lengthofleaves").val("");
        $("#propvalueid_numberofleaves").val(0);
        $("#propvalueid_leaflength").val(0);
        $("#howmanyleavesdiv").addClass('d-none');
        $(".commonleafdiv").addClass('d-none');
        setleaveyesno(0);
        calculatePrice();
    }
    const showTotalLeavesDiv = () => {
        var leaveid = $("#propvalueid_howmanyleaves option:selected").val();
        $(".leaflengthclass").val("");
        $(".leaffractionlengthclass").val("0");
        if (leaveid == 1) {
            $(".commonleafdiv").addClass('d-none');
            $(".leafonediv").removeClass('d-none');
            sethowmanyleaves(1);
        } else if (leaveid == 2) {
            $(".commonleafdiv").addClass('d-none');
            $(".leafonediv").removeClass('d-none');
            $(".leafseconddiv").removeClass('d-none');
            sethowmanyleaves(1);
        } else if (leaveid == 3) {
            $(".commonleafdiv").addClass('d-none');
            $(".leafonediv").removeClass('d-none');
            $(".leafseconddiv").removeClass('d-none');
            $(".leafthirddiv").removeClass('d-none');
            sethowmanyleaves(1);
        } else if (leaveid == 4) {
            $(".commonleafdiv").addClass('d-none');
            $(".leafonediv").removeClass('d-none');
            $(".leafseconddiv").removeClass('d-none');
            $(".leafthirddiv").removeClass('d-none');
            $(".leaffourthdiv").removeClass('d-none');
            sethowmanyleaves(1);
        } else if (leaveid == 5) {
            $(".commonleafdiv").addClass('d-none');
            $(".leafonediv").removeClass('d-none');
            $(".leafseconddiv").removeClass('d-none');
            $(".leafthirddiv").removeClass('d-none');
            $(".leaffourthdiv").removeClass('d-none');
            $(".leaffifthdiv").removeClass('d-none');
            sethowmanyleaves(1);
        } else {
            $(".commonleafdiv").addClass('d-none');
            sethowmanyleaves(0);
        }
        calculatePrice();
    }
    const addtocart = () => {
        $(".txtPropName").each(function () {
            var isrequired = $(this).attr('requiredval');
            if (isrequired == 1) {
                var vid = $(this).attr('id');
                var vsplit = vid.split('_');
                var vpropname = vsplit[1];
                var tempvar = $("#propvalueid_" + vpropname).attr('tempvar');
                if (tempvar == 1) {
                    var optionval = $("#propvalueid_" + vpropname + " option:selected").val();
                    if (optionval == 0) {
                        $("#validationId_" + vpropname).text("Required!");
                    } else {
                        $("#validationId_" + vpropname).text("");
                    }
                } else {
                    var vVal = $("#propvalueid_" + vpropname).val();
                    if (vVal == "") {
                        $("#validationId_" + vpropname).text("Required!");
                    } else {
                        $("#validationId_" + vpropname).text("");
                    }
                }
            }
        });
        var tempvar = $(".validationclas").text();
        if (tempvar == "") {
            const unique_id = uuid();
            var itemname = "Good Economy Table Pad";
            var vimg = $(".exzoomimgstxt:first-child");
            var finalimg = vimg[0].attributes[1].value;
            var price = $("#CalculatedPrice").val();
            var pricesplit = price.split('$');
            var finalprice = parseFloat(pricesplit[1]);
            var qty = $("#txtQtyId").val();
            var shippingPrice = parseInt($("#hdnshippingprice").val());
            var prodid = parseInt(localStorage.getItem('useridordernow'));
            var PathName = window.localStorage.getItem("staticpath");
            var producttype = $("#hdnProductType").val();
            var fractionheight = $("#propvalueid_lengthFraction option:selected").text();
            var fractionwidth = $("#propvalueid_widthFraction option:selected").text();
            var ob = new Object();
            var productArray = [];
            var leafFinalArray = [];
            var FinalArray = [];
            var finalObj = new Object();
            if (!$("#differentsizeDiv").hasClass('d-none')) {
                if (leaveyesno == 1) {
                    finalObj.leaves = parseInt($("#propvalueid_howmanyleaves option:selected").val());
                    $(".commonleafdiv:visible").each(function () {
                        var LeafArray = new Object();
                        var vid = $(this).attr('id');
                        if (vid != null) {
                            var split = vid.split("_");
                            var idname = split[1];
                            LeafArray.lengthFraction = $(".leafFractionClass_" + idname + " option:selected").text();
                            var lengthleaffraciotn = $(".leafFractionClass_" + idname + " option:selected").text();
                            var leaffractionyessplit = lengthleaffraciotn.split('/');
                            var leaf11 = leaffractionyessplit[0];
                            var leaf12 = leaffractionyessplit[1];
                            var divisionleaffraction = 0;
                            if (leaf12 == undefined) {
                                divisionleaffraction = 0;
                            } else {
                                divisionleaffraction = parseInt(leaf11) / parseInt(leaf12);
                            }
                            var lInches = parseFloat($(".leafInchClass_" + idname).val());
                            var finallengthinches = Math.round(parseFloat(parseFloat(lInches) * parseFloat(divisionleaffraction)).toFixed(2));
                            LeafArray.lengthInches = finallengthinches;
                            LeafArray.DescriptionLeaf = parseFloat(divisionleaffraction).toFixed(2);
                            LeafArray.LeafLengthDesc = parseFloat(lInches).toFixed(2);
                            leafFinalArray.push(LeafArray);
                        }
                    });
                } else {
                    var noleaves = parseInt($("#propvalueid_numberofleaves option:selected").val());
                    finalObj.leaves = noleaves;
                    // for (var i = 0; i < noleaves; i++) {
                    var LeafArray = new Object();
                    LeafArray.lengthFraction = $("#propvalueid_leaflength option:selected").text();
                    var lengthleaffraciotn = $("#propvalueid_leaflength option:selected").text();
                    var leaffractionyessplit = lengthleaffraciotn.split('/');
                    var leaf11 = leaffractionyessplit[0];
                    var leaf12 = leaffractionyessplit[1];
                    var divisionleaffraction = 0;
                    if (leaf12 == undefined) {
                        divisionleaffraction = 0;
                    } else {
                        divisionleaffraction = parseInt(leaf11) / parseInt(leaf12);
                    }
                    var lInches = parseFloat($("#propvalueid_lengthofleaves").val());
                    var finallengthinches = Math.round(parseFloat(parseFloat(lInches) * parseFloat(divisionleaffraction)).toFixed(2));
                    LeafArray.lengthInches = finallengthinches;
                    LeafArray.DescriptionLeaf = parseFloat(divisionleaffraction).toFixed(2);
                    LeafArray.LeafLengthDesc = parseFloat(lInches).toFixed(2);
                    leafFinalArray.push(LeafArray);
                    // }
                }
            }
            if (leafFinalArray.length == 0) {
                finalObj.leaves = 0;
            }
            $(".txtPropName:visible").each(function () {
                var product = new Object();
                var vid = $(this).attr('id');
                var vsplit = vid.split('_');
                var vpropname = vsplit[1];
                var proname = $(this).attr('propname');
                var tempvar = $("#propvalueid_" + vpropname).attr('tempvar');
                var provalue;
                if (tempvar == 1) {
                    var pval = $("#propvalueid_" + vpropname + " option:selected").val();
                    if (pval != 0) {
                        if(proname == "Top Color"){
                            product.propname = "Top Color"
                        product.propvalue = "White Solid";
                        product.optionvalue = "359";
                        }else if (proname == "Bottom Color"){
                            product.propname = "Bottom Color"
                            product.propvalue = "Black Dura-Velvet";
                            product.optionvalue = "214";
                        } else if(proname == "MagnaLoc"){
                            product.propname = "MagnaLoc"
                            product.propvalue = "No";
                            product.optionvalue = "276";
                        }else{
                            provalue = $("#propvalueid_" + vpropname + " option:selected").text();
                            product.propname = proname;
                            product.propvalue = provalue;
                            product.optionvalue = pval;
                        }
                        productArray.push(product);
                    }
                } else {
                    provalue = $("#propvalueid_" + vpropname).val();
                    product.propname = proname;
                    product.propvalue = provalue;
                    productArray.push(product);
                }
            });
            var warrantyobj = new Object();
            if (warranty == 1) {
                warrantyobj.propname = "Unconditional Warranty";
                warrantyobj.propvalue = "$30 extra for each item";
                productArray.push(warrantyobj);
                finalObj.flag = 1;
                // var warprice = finalprice + 30;
                // finalObj.warrantyprice = warprice;
            } else {
                finalObj.flag = 0;
                // finalObj.warrantyprice = finalprice;
            }
            finalObj.price = finalprice;
            finalObj.product_id = prodid;
            finalObj.qty = qty;
            finalObj.shippingprice = shippingPrice;
            finalObj.ItemName = itemname;
            finalObj.itemImg = finalimg;
            finalObj.product_type = producttype;
            finalObj.FractionHeight = fractionheight;
            finalObj.FractionWidth = fractionwidth;
            finalObj.LeaveYesNo = leaveyesno;
            finalObj.Magnets = 1;
            if ($("#DifferentSizeDivYes").is(":checked")) {
                finalObj.LeaveDifferentSize = 1;
            } else {
                finalObj.LeaveDifferentSize = 2;
            }
            // finalObj.item_total = price;
            finalObj.item_description = "";
            finalObj.top_color = $("#propvalueid_topcolor option:selected").text();
            finalObj.bottom_color = $("#propvalueid_bottomcolor option:selected").text();
            finalObj.shape = $("#propvalueid_tableshape option:selected").text();
            // var rushID = $("#propvalueid_factoryRush").val();
            // if (rushID == "277") {
            //     finalObj.Rush = 1;
            // } else {
            //     finalObj.Rush = 0;
            // }
            var length = $("#propvalueid_LengthInches").val();
            var finalfractionlength = 0;
            if (length == null) {
                length = 0;
            } else {
                var lenFraction = $("#propvalueid_lengthFraction option:selected").text();
                if (lenFraction != "Select") {
                    var lenfractionsplit = lenFraction.split('/');
                    var len1 = lenfractionsplit[0];
                    var len2 = lenfractionsplit[1];
                    var divisionlen = 0;
                    if (len2 == undefined) {
                        divisionlen = 0;
                    } else {
                        divisionlen = parseInt(len1) / parseInt(len2);
                    }
                    length = parseInt($("#propvalueid_LengthInches").val());
                    finalfractionlength = Math.round((parseFloat(divisionlen) + parseFloat(length)).toFixed(2));
                } else {
                    finalfractionlength = parseInt($("#propvalueid_LengthInches").val());
                }
            }
            finalObj.length = finalfractionlength;
            var width = $("#propvalueid_widthInches").val();
            var finalfractionwidth = 0;
            if (width == null) {
                width = 0;
            } else {
                var widFraction = $("#propvalueid_widthFraction option:selected").text();
                if (widFraction != "Select") {
                    var widfractionsplit = widFraction.split('/');
                    var wid1 = widfractionsplit[0];
                    var wid2 = widfractionsplit[1];
                    var divisionwid = 0;
                    if (wid2 == undefined) {
                        divisionwid = 0;
                    } else {
                        divisionwid = parseInt(wid1) / parseInt(wid2);
                    }
                    width = parseInt($("#propvalueid_widthInches").val());
                    finalfractionwidth = Math.round((parseFloat(divisionwid) + parseFloat(width)).toFixed(2));
                } else {
                    finalfractionwidth = parseInt($("#propvalueid_widthInches").val());
                }
            }
            finalObj.width = finalfractionwidth;
            finalObj.propertyname = productArray;
            finalObj.RushPrice = rushprice;
            finalObj.LeavesArray = leafFinalArray;
            finalObj.UID = unique_id;
            finalObj.pathname = PathName;
            FinalArray.push(finalObj);
            var data = localStorage.getItem('addtocart');
            if (data == null) {
                ob.Products = FinalArray;
                ob.RushPrice = rushprice;
                data = JSON.stringify(ob);
            } else {
                var parsedData = JSON.parse(data);
                if (prodtemparray.length != 0) {
                    var vid;
                    for (var v = 0; v < parsedData.Products.length; v++) {
                        if (parsedData.Products[v].ItemName == prodtemparray[0].ItemName) {
                            vid = v;
                        }
                    }
                    var index = parsedData.Products.indexOf(parsedData.Products[vid]);
                    parsedData.Products.splice(index, 1);
                }
                parsedData.Products.push(finalObj);
                data = JSON.stringify(parsedData);
            }
           
            localStorage.setItem('addtocart', data);
            window.location = "/cart";
        }
        else {
            window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop });
        }
    }
    return (
        <>
            <input type="hidden" id="hdnregularprice" />
            <input type="hidden" id="hdnsalesprice" />
            <input type="hidden" id="hdnshippingprice" />
            <input type="hidden" id="hdnonsaleprice" />
            <input type="hidden" id="hdnProductType" />
            <NavigationBar />
            <div className="col-12 text-center mt-4">
                <label className="activeColorLink"><h2 className="h1">Order Now</h2></label>
            </div>
          
            <div className="container pb-3">
                <div className="row">
                    <div className="col-lg-5 text-center py-5">
                        <div className="slider-wrapper pt-3">
                            <div className="cat">
                                <img src={img} className="w-100 newslideprodimg" alt="dummy" />
                            </div>
                            {exzoomcomp.length > 1 ?
                                <div className="thumbnail-slider-wrap">
                                    <Slider {...settingsThumbs} asNavFor={nav1} ref={(slider) => setSlider1(slider)}>
                                        {exzoomcomp.map((slide, i) => (
                                            <div className={`slick-slide exzoomimgstxt ${i}`} key={i} imgsrc={slide.img} onMouseEnter={(e) => hoverHandler(slide.img)}>
                                                <img className="slick-slide-image" src={slide.img} alt="dummy" />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                :
                                <></>
                            }

                        </div>
                    </div>
                    <div className="col-lg-7 py-5 ">
                    <TablePadsMulipleUrlSelection />
                        <h1 className="row UED-font1 ps-3 pt-1" id="productname">Good Economy Table Pad</h1>
                        <div className="row ps-3" ref={myRef}>
                            <div className="row mt-4">
                                <p>
                                    Our highest quality custom made dining table pad will protect your dining room table from everyday use.
                                </p>
                                <p>
                                    Any table pad greater than 50 in wide will be made with 2 folds instead of 1, making it smaller for ease of handling and storage.
                                </p>
                                <p>
                                    <ul>
                                        <li>Featuring the MagnaLoc System</li>
                                        <li>1/2 in Thick Pad</li>
                                        <li>550° Heat Rating</li>
                                        <li>Wide Selection of Color Options</li>
                                        <li>Velvet Bottom</li>
                                        <li>Limited Lifetime Warranty</li>
                                    </ul>
                                </p>
                            </div>
                            <div className="row pt-3">
                                <div className="col-12 my-3 pe-0">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues" id="propvalueid_tableshape" tempvar="1">
                                            <option value="0">Select</option>
                                            <option value="42">Oval / Egg</option>
                                            <option value="48">Oval with Scallops</option>
                                            <option value="49">Capsule</option>
                                            <option value="50">Totally Rectangular (90° Square Corners)</option>
                                            <option value="51">Rectangular / Square with Rounded or Coin Corners</option>
                                            <option value="52">Rectangular with Indented Corners</option>
                                            <option value="53">Rectangular with Patterned Sides / Ends</option>
                                            <option value="54">Rectangular with Clipped Corners</option>
                                            <option value="55">True Octagon</option>
                                            <option value="56">Arrowhead Corners</option>
                                            <option value="57">True Round</option>
                                            <option value="58">Boat or Ship Shape</option>
                                        </select>
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtRed me-1">*</span>
                                            <span className="txtPropName" requiredval="1" id="propnameid_tableshape" propname="Table Shape">
                                                Table Shape
                                            </span>
                                            <OverlayTrigger delay={{ show: 1000 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className="mytooltip">
                                                        <div dangerouslySetInnerHTML={{ __html: tableshapedata }} className="img-fluid"></div>
                                                    </Tooltip>
                                                )} placement="auto">
                                                <img src="/images/products_Cate/question.svg" className="ms-2 img-fluid crsr-Pointer" alt="dummy" />
                                            </OverlayTrigger>
                                            <br />
                                            <span style={{ color: "red" }} className="validationclas" id="validationId_tableshape"></span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0">
                                    <div className="col-12">
                                        <NumericInput min={10} max={90} defaultValue={10} className="w-100 form-control txtInputValues" id="propvalueid_widthInches" onChange={calculatePrice} />
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtRed me-1">*</span>
                                            <span className="txtPropName" requiredval="1" id="propnameid_widthInches" propname="Width of Table (Inches) (Min:10in, Max:90in)">
                                                Width of Table (Inches) (Min:10in, Max:90in)
                                            </span>
                                            <OverlayTrigger delay={{ show: 1000 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className="mytooltip">
                                                        <div dangerouslySetInnerHTML={{ __html: widthInches }} className="img-fluid"></div>
                                                    </Tooltip>
                                                )} placement="auto">
                                                <img src="/images/products_Cate/question.svg" className="ms-2 img-fluid crsr-Pointer" alt="dummy" />
                                            </OverlayTrigger>
                                            <br />
                                            <span style={{ color: "red" }} className="validationclas" id="validationId_widthInches"></span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues" tempvar="1" id="propvalueid_widthFraction" onChange={calculatePrice}>
                                            <option value="0">Select</option>
                                            <option value="11">0/16</option>
                                            <option value="15">1/16</option>
                                            <option value="23">1/8</option>
                                            <option value="24">3/36</option>
                                            <option value="59">1/4</option>
                                            <option value="60">5/16</option>
                                            <option value="61">3/8</option>
                                            <option value="62">7/16</option>
                                            <option value="63">1/2</option>
                                            <option value="64">9/16</option>
                                            <option value="65">5/8</option>
                                            <option value="66">11/16</option>
                                            <option value="67">3/4</option>
                                            <option value="68">13/16</option>
                                            <option value="69">7/8</option>
                                            <option value="345">15/16</option>
                                        </select>
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" id="propnameid_widthFraction" propname="Width of Table (Fractions)">
                                                Width of Table (Fractions)
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0">
                                    <div className="col-12">
                                        <NumericInput id="propvalueid_LengthInches" min={10} max={210} defaultValue={10} className="w-100 form-control txtInputValues" onChange={calculatePrice} />
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtRed me-1">*</span>
                                            <span className="txtPropName" requiredval="1" id="propnameid_LengthInches" propname="Length of Table,without extension leaves(Inches) (Min:10in, Max:210in)">
                                                Length of Table,without extension leaves(Inches) (Min:10in, Max:210in)
                                            </span>
                                            <OverlayTrigger delay={{ show: 1000 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className="mytooltip">
                                                        <div dangerouslySetInnerHTML={{ __html: lengthInches }} className="img-fluid"></div>
                                                    </Tooltip>
                                                )} placement="auto">
                                                <img src="/images/products_Cate/question.svg" className="ms-2 img-fluid crsr-Pointer" alt="dummy" />
                                            </OverlayTrigger>
                                            <br />
                                            <span style={{ color: "red" }} className="validationclas" id="validationId_LengthInches"></span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues" tempvar="1" id="propvalueid_lengthFraction" onChange={calculatePrice}>
                                            <option value="0">Select</option>
                                            <option value="25">0/16</option>
                                            <option value="26">1/16</option>
                                            <option value="27">1/8</option>
                                            <option value="28">3/16</option>
                                            <option value="70">1/4</option>
                                            <option value="71">5/16</option>
                                            <option value="72">3/8</option>
                                            <option value="73">7/16</option>
                                            <option value="74">1/2</option>
                                            <option value="75">9/16</option>
                                            <option value="76">5/8</option>
                                            <option value="77">11/16</option>
                                            <option value="78">3/4</option>
                                            <option value="79">13/16</option>
                                            <option value="80">7/8</option>
                                            <option value="344">15/16</option>
                                        </select>
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" id="propnameid_lengthFraction" propname="Length of Table (Fractions)">
                                                Length of Table (Fractions)
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-12 mb-3 pe-0">
                                    <div className="col-12">
                                        <div className="form-check mt-1 ps-0">
                                            <b className="me-4">
                                                Do you have extension leaves?
                                            </b>
                                            <label className="form-check-label mx-4">
                                                <input type="radio" id="extensionleaveyes" className="form-check-input" name="optradio" onClick={showdifferentdiv} />
                                                <label>Yes</label>
                                            </label>
                                            <label className="form-check-label mx-4">
                                                <input type="radio" id="extensionleaveNo" className="form-check-input" name="optradio" onClick={hidedifferentsizediv} />
                                                <label>No</label>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 my-3 pe-0 d-none" id="differentsizeDiv">
                                    <div className="col-12">
                                        <div className="form-check ps-0">
                                            <b className="me-4">
                                                Do you have leaves of different size?
                                            </b>
                                            <label className="form-check-label mx-4">
                                                <input type="radio" id="DifferentSizeDivYes" className="form-check-input" name="sizeradio" onClick={showleavesdiv} />
                                                Yes
                                            </label>
                                            <label className="form-check-label mx-4">
                                                <input type="radio" id="DifferentSizeDivNo" className="form-check-input" name="sizeradio" onClick={showNoLeavesdiv} />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 my-3 pe-0 d-none" id="howmanyleavesdiv">
                                    <div className="col-md-6">
                                        <select className="form-select txtInputValues" tempvar="1" id="propvalueid_howmanyleaves" onChange={showTotalLeavesDiv}>
                                            <option value="0">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span id="propnameid_howmanyleaves" propname="How many leaves?">
                                                How many leaves?
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 leafonediv d-none commonleafdiv" id="LeafID_one">
                                    <div className="col-12">
                                        <NumericInput id="propvalueid_leafonelength" min={6} max={50} defaultValue={6} className="w-100 form-control txtInputValues leaflengthclass leafInchClass_one" onChange={calculatePrice} />
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" id="propnameid_leafonelength" propname="Leaf 1 Length">
                                                Leaf 1 Length (Min: 6in, Max: 50in)
                                            </span>
                                            <OverlayTrigger delay={{ show: 1000 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className="mytooltip">
                                                        <div dangerouslySetInnerHTML={{ __html: leaflength }} className="img-fluid"></div>
                                                    </Tooltip>
                                                )} placement="auto">
                                                <img src="/images/products_Cate/question.svg" className="ms-2 img-fluid crsr-Pointer" alt="dummy" />
                                            </OverlayTrigger>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 leafonediv d-none commonleafdiv">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues leaffractionlengthclass leafFractionClass_one" tempvar="1" id="propvalueid_leafoneFraction" onChange={calculatePrice}>
                                            <option value="0">Select</option>
                                            <option value="11">0/16</option>
                                            <option value="15">1/16</option>
                                            <option value="23">1/8</option>
                                            <option value="24">3/36</option>
                                            <option value="59">1/4</option>
                                            <option value="60">5/16</option>
                                            <option value="61">3/8</option>
                                            <option value="62">7/16</option>
                                            <option value="63">1/2</option>
                                            <option value="64">9/16</option>
                                            <option value="65">5/8</option>
                                            <option value="66">11/16</option>
                                            <option value="67">3/4</option>
                                            <option value="68">13/16</option>
                                            <option value="69">7/8</option>
                                            <option value="345">15/16</option>
                                        </select>
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" id="propnameid_leafoneFraction" propname="Leaf 1 Length Fractions">
                                                Leaf 1 Length Fractions
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 leafseconddiv d-none commonleafdiv" id="LeafID_second">
                                    <div className="col-12">
                                        <NumericInput id="propvalueid_leafsecondlength" min={6} max={50} defaultValue={6} className="leafInchClass_second w-100 form-control txtInputValues leaflengthclass" onChange={calculatePrice} />
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Leaf 2 Length" id="propnameid_leafsecondlength">
                                                Leaf 2 Length (Min: 6in, Max: 50in)
                                            </span>
                                            <OverlayTrigger delay={{ show: 1000 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className="mytooltip">
                                                        <div dangerouslySetInnerHTML={{ __html: leaflength }} className="img-fluid"></div>
                                                    </Tooltip>
                                                )} placement="auto">
                                                <img src="/images/products_Cate/question.svg" className="ms-2 img-fluid crsr-Pointer" alt="dummy" />
                                            </OverlayTrigger>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 leafseconddiv d-none commonleafdiv">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues leaffractionlengthclass leafFractionClass_second" tempvar="1" id="propvalueid_leafsecondFraction" onChange={calculatePrice}>
                                            <option value="0">Select</option>
                                            <option value="11">0/16</option>
                                            <option value="15">1/16</option>
                                            <option value="23">1/8</option>
                                            <option value="24">3/36</option>
                                            <option value="59">1/4</option>
                                            <option value="60">5/16</option>
                                            <option value="61">3/8</option>
                                            <option value="62">7/16</option>
                                            <option value="63">1/2</option>
                                            <option value="64">9/16</option>
                                            <option value="65">5/8</option>
                                            <option value="66">11/16</option>
                                            <option value="67">3/4</option>
                                            <option value="68">13/16</option>
                                            <option value="69">7/8</option>
                                            <option value="345">15/16</option>
                                        </select>
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Leaf 2 Length Fractions" id="propnameid_leafsecondFraction">
                                                Leaf 2 Length Fractions
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 leafthirddiv d-none commonleafdiv" id="LeafID_third">
                                    <div className="col-12">
                                        <NumericInput id="propvalueid_leafthirdlength" min={6} max={50} defaultValue={6} className="leafInchClass_third w-100 form-control txtInputValues leaflengthclass" onChange={calculatePrice} />
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Leaf 3 Length" id="propnameid_leafthirdlength">
                                                Leaf 3 Length (Min: 6in, Max: 50in)
                                            </span>
                                            <OverlayTrigger delay={{ show: 1000 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className="mytooltip">
                                                        <div dangerouslySetInnerHTML={{ __html: leaflength }} className="img-fluid"></div>
                                                    </Tooltip>
                                                )} placement="auto">
                                                <img src="/images/products_Cate/question.svg" className="ms-2 img-fluid crsr-Pointer" alt="dummy" />
                                            </OverlayTrigger>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 leafthirddiv d-none commonleafdiv">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues leaffractionlengthclass leafFractionClass_third" tempvar="1" id="propvalueid_leafthirdFraction" onChange={calculatePrice}>
                                            <option value="0">Select</option>
                                            <option value="11">0/16</option>
                                            <option value="15">1/16</option>
                                            <option value="23">1/8</option>
                                            <option value="24">3/36</option>
                                            <option value="59">1/4</option>
                                            <option value="60">5/16</option>
                                            <option value="61">3/8</option>
                                            <option value="62">7/16</option>
                                            <option value="63">1/2</option>
                                            <option value="64">9/16</option>
                                            <option value="65">5/8</option>
                                            <option value="66">11/16</option>
                                            <option value="67">3/4</option>
                                            <option value="68">13/16</option>
                                            <option value="69">7/8</option>
                                            <option value="345">15/16</option>
                                        </select>
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Leaf 3 Length Fractions" id="propnameid_leafthirdFraction">
                                                Leaf 3 Length Fractions
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 leaffourthdiv d-none commonleafdiv" id="LeafID_fourth">
                                    <div className="col-12">
                                        <NumericInput id="propvalueid_leaffourthlength" min={6} max={50} defaultValue={6} className="leafInchClass_fourth w-100 form-control txtInputValues leaflengthclass" onChange={calculatePrice} />
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Leaf 4 Length" id="propnameid_leaffourthlength">
                                                Leaf 4 Length (Min: 6in, Max: 50in)
                                            </span>
                                            <OverlayTrigger delay={{ show: 1000 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className="mytooltip">
                                                        <div dangerouslySetInnerHTML={{ __html: leaflength }} className="img-fluid"></div>
                                                    </Tooltip>
                                                )} placement="auto">
                                                <img src="/images/products_Cate/question.svg" className="ms-2 img-fluid crsr-Pointer" alt="dummy" />
                                            </OverlayTrigger>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 leaffourthdiv d-none commonleafdiv">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues leaffractionlengthclass leafFractionClass_fourth" tempvar="1" id="propvalueid_leaffourthFraction" onChange={calculatePrice}>
                                            <option value="0">Select</option>
                                            <option value="11">0/16</option>
                                            <option value="15">1/16</option>
                                            <option value="23">1/8</option>
                                            <option value="24">3/36</option>
                                            <option value="59">1/4</option>
                                            <option value="60">5/16</option>
                                            <option value="61">3/8</option>
                                            <option value="62">7/16</option>
                                            <option value="63">1/2</option>
                                            <option value="64">9/16</option>
                                            <option value="65">5/8</option>
                                            <option value="66">11/16</option>
                                            <option value="67">3/4</option>
                                            <option value="68">13/16</option>
                                            <option value="69">7/8</option>
                                            <option value="345">15/16</option>
                                        </select>
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Leaf 4 Length Fractions" id="propnameid_leaffourthFraction">
                                                Leaf 4 Length Fractions
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 leaffifthdiv d-none commonleafdiv" id="LeafID_fifth">
                                    <div className="col-12">
                                        <NumericInput id="propvalueid_leaffifthlength" min={6} max={50} defaultValue={6} className="leafInchClass_fifth w-100 form-control txtInputValues leaflengthclass" onChange={calculatePrice} />
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Leaf 5 Length" id="propnameid_leaffifthlength">
                                                Leaf 5 Length (Min: 6in, Max: 50in)
                                            </span>
                                            <OverlayTrigger delay={{ show: 1000 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className="mytooltip">
                                                        <div dangerouslySetInnerHTML={{ __html: leaflength }} className="img-fluid"></div>
                                                    </Tooltip>
                                                )} placement="auto">
                                                <img src="/images/products_Cate/question.svg" className="ms-2 img-fluid crsr-Pointer" alt="dummy" />
                                            </OverlayTrigger>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 leaffifthdiv d-none commonleafdiv">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues leaffractionlengthclass leafFractionClass_fifth" tempvar="1" id="propvalueid_leaffifthFraction" onChange={calculatePrice}>
                                            <option value="0">Select</option>
                                            <option value="11">0/16</option>
                                            <option value="15">1/16</option>
                                            <option value="23">1/8</option>
                                            <option value="24">3/36</option>
                                            <option value="59">1/4</option>
                                            <option value="60">5/16</option>
                                            <option value="61">3/8</option>
                                            <option value="62">7/16</option>
                                            <option value="63">1/2</option>
                                            <option value="64">9/16</option>
                                            <option value="65">5/8</option>
                                            <option value="66">11/16</option>
                                            <option value="67">3/4</option>
                                            <option value="68">13/16</option>
                                            <option value="69">7/8</option>
                                            <option value="345">15/16</option>
                                        </select>
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Leaf 5 Length Fractions" id="propnameid_leaffifthFraction">
                                                Leaf 5 Length Fractions
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 d-none LeaveshdnDiv">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues" tempvar="1" id="propvalueid_numberofleaves" onChange={calculatePrice}>
                                            <option value="0">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Number of Leaves" id="propnameid_numberofleaves">
                                                Number of Leaves
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 d-none LeaveshdnDiv">
                                    <div className="col-12">
                                        <NumericInput id="propvalueid_lengthofleaves" min={6} max={50} className="w-100 form-control txtInputValues" onChange={calculatePrice} />
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Length of Leaves" id="propnameid_lengthofleaves">
                                                Length of Leaves (Min: 6in, Max: 50in)
                                            </span>
                                            <OverlayTrigger delay={{ show: 1000 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className="mytooltip">
                                                        <div dangerouslySetInnerHTML={{ __html: leaflength }} className="img-fluid"></div>
                                                    </Tooltip>
                                                )} placement="auto">
                                                <img src="/images/products_Cate/question.svg" className="ms-2 img-fluid crsr-Pointer" alt="dummy" />
                                            </OverlayTrigger>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3 pe-0 d-none LeaveshdnDiv">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues" tempvar="1" id="propvalueid_leaflength" onChange={calculatePrice}>
                                            <option value="0">Select</option>
                                            <option value="0/16">0/16</option>
                                            <option value="1/16">1/16</option>
                                            <option value="1/8">1/8</option>
                                            <option value="3/16">3/16</option>
                                            <option value="1/4">1/4</option>
                                            <option value="5/16">5/16</option>
                                            <option value="3/8">3/8</option>
                                            <option value="7/16">7/16</option>
                                            <option value="1/2">1/2</option>
                                            <option value="9/16">9/16</option>
                                            <option value="5/8">5/8</option>
                                            <option value="11/16">11/16</option>
                                            <option value="3/4">3/4</option>
                                            <option value="13/16">13/16</option>
                                            <option value="7/8">7/8</option>
                                            <option value="15/16">15/16</option>
                                        </select>
                                    </div>
                                    <div className="col-12" >
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" propname="Leaf Length (Fractions)" id="propnameid_leaflength">
                                                Leaf Length (Fractions)
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-6 my-3 pe-0">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues hide-backgroundImg" tempvar="1" disabled={true} id="propvalueid_topcolor">
                                            {/* <option value="0">Select</option>
                                            <option value="193">Natural Leathertone</option>
                                            <option value="195">Mocha Leathertone</option>
                                            <option value="196">Palomino</option>
                                            <option value="197">Walnut Woodgrain</option>
                                            <option value="198">Cranberry Leathertone</option>
                                            <option value="199">Lt. Cherry WG/Cherry WG</option>
                                            <option value="200">Blonde WG/Hickory WG</option>
                                            <option value="202">Slate</option>
                                            <option value="203">Oak</option>
                                            <option value="204">MAHOGANY</option>
                                            <option value="207">Opal Leathertone</option>
                                            <option value="208">Saddle Leathertone</option>
                                            <option value="209">Cocoa</option>
                                            <option value="210">Pecan Woodgrain</option>
                                            <option value="211">Brandy Woodgrain</option>
                                            <option value="212">CHERRY</option>
                                            <option value="351">Bone White Solid</option>
                                            <option value="352">Mahogany Woodgrain</option>
                                            <option value="353">Saddle</option>
                                            <option value="354">Chestnut Leathertone</option>
                                            <option value="356">Fruitwood WG/Maple WG</option>
                                            <option value="357">Palomino Leathertone</option> */}
                                            {/* <option value="358">Black Solid</option> */}
                                            <option value="359">White Solid</option>
                                            {/* <option value="360">Dk. Oak WG/Oak WG</option> */}
                                        </select>
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" requiredval="1" id="propnameid_topcolor" propname="Top Color">
                                                Top Color
                                            </span>
                                            <br />
                                            <span style={{ color: "red" }} className="validationclas" id="validationId_topcolor"></span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-6 my-3 pe-0">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues hide-backgroundImg" id="propvalueid_bottomcolor" disabled={true} tempvar="1">
                                            {/* <option value="0">Select</option>
                                            <option value="213">Green Dura-Velvet</option> */}
                                            <option value="214">Black Dura-Velvet</option>
                                            {/* <option value="215">Tan Dura-Velvet</option>
                                            <option value="216">Burgundy Dura-Velvet</option>
                                            <option value="217">Brown Dura-Velvet</option> */}
                                        </select>
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtPropName" requiredval="1" id="propnameid_bottomcolor" propname="Bottom Color">
                                                Bottom Color
                                            </span>
                                            <br />
                                            <span style={{ color: "red" }} className="validationclas" id="validationId_bottomcolor"></span>
                                        </h6>
                                    </div>
                                </div>
                                
                                {/* <div className="col-6 my-3 pe-0">
                                    <div className="col-12">
                                        <select className="form-select txtInputValues" id="propvalueid_factoryRush" tempvar="1" onChange={calculatePrice}>
                                            <option value="0">Select</option>
                                            <option value="277">Yes (${rushprice})</option>
                                            <option value="278">No</option>
                                        </select>
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtRed me-1">*</span>
                                            <span className="txtPropName" requiredval="1" id="propnameid_factoryRush" propname="Factory Rush">
                                                Factory Rush
                                            </span>
                                            <br />
                                            <span style={{ color: "red" }} className="validationclas" id="validationId_factoryRush"></span>
                                        </h6>
                                    </div>
                                </div> */}
                                {/* <div className="col-6 my-3 pe-0" style={{visibility:"hidden"}}>
                                    <div className="col-12">
                                        <select className="form-select txtInputValues" id="propvalueid_Magnaloc" tempvar="1">
                                            <option value="276">No</option>
                                        </select>
                                        <h6 className="mb-3 font-weight-bold">
                                            <span className="txtRed me-1">*</span>
                                            <span className="txtPropName" requiredval="1" id="propnameid_Magnaloc" propname="MagnaLoc">
                                                MagnaLoc
                                            </span>
                                            <br />
                                            <span style={{ color: "red" }} className="validationclas" id="validationId_Magnaloc"></span>
                                        </h6>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row ps-0">
                                <div className="form-check d-flex justify-content-start mb-3 ms-3">
                                    <input className="form-check-input" id="warrantyId" type="checkbox" value="" onChange={ChangeWarranty} />
                                    <h6 className="form-check-label ms-2"> Unconditional Warranty - $30 extra for each item </h6>
                                </div>
                            </div>
                            <div className="row my-4 d-none">
                                <div className="col-md-4"></div>
                                <div className="col-md-2 py-2 ps-1">
                                    <NumericInput min={qtyMinval} value={qtyVal} className="w-100 form-control" id="txtQtyId" />
                                </div>
                                <div className="col-md-2 py-2 ps-1">
                                    <input disabled type="text" className="form-control text-center font-bold" id="CalculatedPrice" />
                                </div>
                            </div>
                            {showdata.onSale == 1 ?
                                <div className="col-md-12 text-center d-none">
                                    <h6>Discount Price: <b className="brownTxt fs-18">${discountprice == 0.1? "0.10": discountprice}/sq inch</b></h6>
                                    <h6 className="contentClr fs-14">Actual Price: <strike>${regularprice}/sq inch</strike></h6>
                                </div>
                                :
                                <></>
                            }
                            {/* add to cart btn start */}
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <button className="orderNowBtn py-2 px-3 btnhover" id="addtocartbtnid" onClick={addtocart}>ADD TO CART</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <u className="brownTxt">
                    <h5>DESCRIPTION</h5>
                </u>
                <p>Our Elite dining table pad is our top of the line product. It is ideal for both fine dining and every day usage.</p>
            </div>
            <Footer />
        </>
    )
}
