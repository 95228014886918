import React, { Component } from "react";
import $ from "jquery";
import ModalPopup from "../ModalPopup";
import { CDNURL } from "../../js/constants";

class ImgData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      modalBody: "",
    };
  }

  isShowPopup = () => {
    this.setState({ show: true });
    this.setState({
      modalBody: (
        <>
          <img
            // src={CDNURL + `Localimages/products_Cate/${this.props.popupImg}.jpg`}
            src={`/images/products_Cate/${this.props.popupImg}.jpg`}
            alt="dummy"
            className="img-fluid h-80vh"
          />
        </>
      ),
    });
  };

  closePopupHandler = () => {
    this.setState({ show: false });
    $(`#${this.props.id}`).attr(
      "src",
      CDNURL + `Localimages/products_Cate/${this.props.img1}.jpg`
      // `./images/products_Cate/${this.props.img1}.jpg`
    );
  };

  ChangeImgUrl1 = () => {
    $(`#${this.props.id}`).attr(
      "src",
      CDNURL + `Localimages/products_Cate/${this.props.shadowImg}.jpg`
      // `./images/products_Cate/${this.props.shadowImg}.jpg`
    );
  };

  ShowRealImg1 = () => {
    $(`#${this.props.id}`).attr(
      "src",
      CDNURL + `Localimages/products_Cate/${this.props.img1}.jpg`
      // `./images/products_Cate/${this.props.img1}.jpg`
    );
  };

  render() {
    return (
      <>
        {/* <div className={`col-lg-6 ${this.props.mt}`}> */}
        <div className={`containerShadow ${this.props.pb != "none" ? "pb-3" : ""}`}>
          <div className="row justify-content-center py-2" onMouseEnter={this.ChangeImgUrl1} onMouseLeave={this.ShowRealImg1}>
            <img className="tp-img img-fluid" src={CDNURL + `Localimages/products_Cate/${this.props.img1}.jpg`} id={this.props.id}
              onClick={this.isShowPopup} alt="dummy" />
            <ModalPopup show={this.state.show} modalBody={this.state.modalBody} onHide={this.closePopupHandler}
              class="ImgPopupSize" ImgCloseBtn="imgCloseBtn"></ModalPopup>
          </div>
          {this.props.dataDesign1 == "true" ? (
            //dining table pad
            <>
              <div className="row mx-1">
                <div className="col-lg-6 col-md-6 my-3 tps-font1 px-0">
                  Best Table Pad
                </div>
                <div className="col-lg-6 col-md-6 text-center">
                  <div data-url="/products/ultra-elite-dining-table-pad" data-id="26" data-staticpath="UltraEliteDiningTablePad" className="subProductOrderNow">
                    <button className="p-2 orderNowBtn mt-3 px-4 py-2">
                      <img
                        className="mx-2"
                        src={CDNURL + `Localimages/products_Cate/basket.png`}
                        alt="Basket"
                      />
                      Tell me more
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mx-1">
                <div className="col-lg-6 col-md-6 px-0">
                  {/* <div className="row">
                    <div className="tps-font2">{this.props.regular}¢</div>
                  </div> */}
                  {/* <div className="row"> */}
                  {/* <div className="mt-3 tps-font3">{this.props.regular}¢ per sq inch</div> */}
                  {/* </div> */}
                </div>
                {/* <div className="col-lg-6 col-md-6 text-center">
                  <div data-url="/products/best-table-pad" data-id="26" data-staticpath="UltraEliteDiningTablePad" className="subProductOrderNow">
                    <button className="p-2 orderNowBtn mt-3 px-4 py-2">
                      <img
                        className="mx-2"
                        src={CDNURL + `Localimages/products_Cate/basket.png`}
                        alt="Basket"
                      />
                      Tell me more
                    </button>
                  </div>
                </div> */}
              </div>
            </>
          ) : this.props.dataDesign11 == "true" ? (
            //dining table pad
            <>
              <div className="row mx-1">
                <div className="col-lg-6 col-md-6 my-3 tps-font1 px-0">
                  Good Table Pad
                </div>
                <div className="col-lg-6 col-md-6 text-center">
                  <a href="/contact">
                    <button className="p-2 orderNowBtn mt-3 px-4 py-2">
                      <img
                        className="mx-2"
                        src={CDNURL + `Localimages/products_Cate/basket.png`}
                        alt="Basket"
                      />
                      Tell me more
                    </button>
                  </a>
                </div>
              </div>
              <div className="row mx-1">
                <div className="col-lg-6 col-md-6 px-0">
                  {/* <div className="mt-3 tps-font3">10¢ per sq inch</div> */}
                  {/* <div className=" mt-3 tps-font3">$159.00</div> */}
                </div>
                {/* <div className="col-lg-6 col-md-6 text-center">
                  <a href="/contact">
                    <button className="p-2 orderNowBtn mt-3 px-4 py-2">
                      <img
                        className="mx-2"
                        src={CDNURL + `Localimages/products_Cate/basket.png`}
                        alt="Basket"
                      />
                      Tell me more
                    </button>
                  </a>
                </div> */}
              </div>
            </>
          ) : this.props.dataDesign2 == "true" ? (
            //Table Paddies
            <>
              <div className="row mx-1">
                <div className="col-lg-6 col-md-6 text-center">
                  <div className=" mt-3 tpds-font1">Table Paddies</div>
                </div>
                <div className="col-lg-6 col-md-6 text-center">
                  <div data-url="/store/table-paddies" data-id="19" data-staticpath="tablepaddies" className="subProductOrderNow">
                    <button className="p-2 orderNowBtn mt-3 px-4 py-2">
                      <img
                        className="mx-2"
                        src={CDNURL + `Localimages/products_Cate/basket.png`}
                        alt="Basket"
                      />
                      ORDER NOW
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : this.props.dataDesign3 == "true" ? (
            //Other Furniture Pad
            <>
              <div className="row mt-3 tpds-font1 justify-content-center">
                {this.props.data1}
              </div>
              <div className="row mx-1 justify-content-center text-center">
                <div data-url={this.props.dataurl} data-id={this.props.dataid} data-staticpath={this.props.datastaticpath} className="subProductOrderNow">
                  <button className="p-2 orderNowBtn mt-3 w-50 px-3 py-2">
                    <img className="mx-2" src={CDNURL + `Localimages/products_Cate/basket.png`} alt="Basket" />
                    ORDER NOW
                  </button>
                </div>
              </div>
            </>
          ) : this.props.dataDesign4 == "true" ? (
            //TableExtensionPad
            <>
              <div className="row mx-1 justify-content-center text-center">
                <div data-url="/store/table-extender-pads" data-id="16" data-staticpath="tableextender" className="subProductOrderNow">
                  <button className="p-2 orderNowBtn mt-3 w-50 px-4 py-2">
                    <img
                      className="mx-2"
                      src={CDNURL + `Localimages/products_Cate/basket.png`}
                      alt="Basket"
                    />
                    ORDER NOW
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className={`row mx-4 my-4 ${this.props.listData != "" ? "" : "d-none"}`}>
          <ul className="px-5">
            <li className=" liBullet ps-2 h5 text-align-justify">
              {this.props.listData}
            </li>
          </ul>
        </div>
        {/* </div> */}
      </>
    );
  }
}
export default ImgData;
