import { LeftMenu } from "./LeftMenuAdmin";
import { useState, useEffect } from 'react';
import React from "react";
import $ from 'jquery';
import ReactStars from "react-rating-stars-component";
import { CDNURL } from "../js/constants";
import { ApiGetCall } from "../js/connector";
import { ApiPutCall } from "../js/connector";
import Pagination from '@material-ui/lab/Pagination';
import { VerifyLoginToken } from "../js/connector";
export default function CustomerReviews() {
    const [pagecount, setPageCount] = useState(0);
    const [Reviews, setReviews] = useState([]);
    const [overallstate, setoverallstate] = useState({});
    const [checkvalue, setCheckvalue] = useState(true);
    const [norecord, setnorecord] = useState("");
    const [TempCheck, setTempCheck] = useState(false);
    useEffect(() => {
        window.scroll(0, 0);
        VerifyLoginToken(GetCustomerReview,null,1);
        return () => {
            setoverallstate({});
        };
    }, []);
    const GetCustomerReview = async (e, value) => {
        setTempCheck(true);
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/CustomerReview/GetCustomerReview/1?pageNum=" + value + "&pageSize=10").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var sugArray = [];
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.reviewsDetails;
                if (responseRs.status == "success") {
                    if (sugData.length == 0) {
                        sugArray.push(
                            <div className="col-12 text-center mt-5">
                                <label>No Record Found</label>
                            </div>
                        );
                        setnorecord(sugArray);
                        $("#paginationId").hide();
                    } else {
                        setReviews(sugData);
                        setPageCount(responseRs.pagecount);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }

    const Isapproved = async (reviewid) => {
        $("#Overlay").show();
        $("#LoderId").show();
        var isapprovevar = 0;
        if ($("#IsApprovedId_" + reviewid).is(":checked")) {
            isapprovevar = 1;
        } else {
            isapprovevar = 0;
        }
        var raw = JSON.stringify({
            Status: isapprovevar
        });
        await ApiPutCall("/CustomerReview/ChangeApproveStatus/" + reviewid, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "Success") {
                    $(".alert-success").show();
                    $("#AlertMsg").text("Review Approved Succesfully.");
                    setTimeout(function () {
                        $(".alert-success").hide();
                        $("#AlertMsg").text("");
                    }, 1500);
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    return (
        <>
            {TempCheck == true ?
                <>
                    <LeftMenu />
                    <div fixed="true" className='container-fluid mainContainer'>
                        <div className='content-wrapper' id="GridDivShow">
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='cardDiv'>
                                            <div className='cardDiv-header'>
                                                <div className='row AlignItems'>
                                                    <div className='col-12'>
                                                        <h4>Customer Reviews and Testimonials</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-body pl-3 pr-3'>
                                                <div className='col-12 gridHeader'>
                                                    <div className='row'>
                                                        <div className='col-md-10 pe-0 ps-4'>
                                                            <b>Reviews</b>
                                                        </div>
                                                        <div className='col-md-2 text-center ps-0'>
                                                            <b>Is Approved?</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="scrolly-57">
                                                    {Reviews.map((item, i) => {
                                                        var returData;
                                                        var tempDate = new Date(item.createdDate);
                                                        var formattedDate = [tempDate.getDate(), tempDate.getMonth() + 1, tempDate.getFullYear()].join('/');
                                                        var h = tempDate.getHours();
                                                        h = (h < 10) ? ("0" + h) : h;

                                                        var m = tempDate.getMinutes();
                                                        m = (m < 10) ? ("0" + m) : m;

                                                        var s = tempDate.getSeconds();
                                                        s = (s < 10) ? ("0" + s) : s;
                                                        var formattedtime = [h, m, s].join(':');
                                                        var finaldate = formattedDate + " " + formattedtime;
                                                        var profileImage = CDNURL + "UserImage/" + item.imageUrl;
                                                        returData = <div className="col-12 p-3 newBrdrBtm pe-0" key={i}>
                                                            <div className='row py-2'>
                                                                <div className='col-md-10 row pe-0'>
                                                                    <div className="col-md-1">
                                                                        {item.imageUrl != null ?
                                                                            <img src={profileImage} className="smallReviewImg" alt="Review Image" />
                                                                            :
                                                                            <img src="/images/reviewsmall.png" className="smallReviewImg" alt="Review Image" />
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-11 ps-0">
                                                                        <ReactStars size={25} isHalf={true} value={item.rating} activeColor="#8C4D36" edit={false} />
                                                                    </div>
                                                                    <div className="col-md-12 fs-18 py-1  mt-3">
                                                                        {item.serviceProvided}
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {item.comment}
                                                                    </div>
                                                                    <div className="col-md-12 mt-2">
                                                                        {item.nickName != "" ?
                                                                            <h5>{item.nickName} - {item.state}, {item.city} - {item.zipCode}</h5>
                                                                            :
                                                                            <h5>{item.name} - {item.state}, {item.city} - {item.zipCode}</h5>
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-12 pb-2">
                                                                        {finaldate}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-2 text-center pe-0'>
                                                                    {(item.adminApprove == 1) ?
                                                                        <input className="form-check-input me-2" id={`IsApprovedId_${item.id}`} defaultChecked={checkvalue} type="checkbox" value="" onClick={(e) => Isapproved(item.id)} />
                                                                        :
                                                                        <input className="form-check-input me-2" id={`IsApprovedId_${item.id}`} type="checkbox" value="" onClick={(e) => Isapproved(item.id)} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        return returData;
                                                    }
                                                    )}
                                                </div>
                                                {norecord}
                                                <div className="d-flex justify-content-center pt-3">
                                                    <Pagination count={pagecount} onChange={GetCustomerReview} id="paginationId" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <></>
            }
        </>
    )
}