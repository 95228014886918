import React, { useEffect, useState } from "react";
import { NavigationBar } from "../Navigationbar";
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import { Footer } from "../Footer";
import ImgData from "../components/ImgData";
import { CDNURL } from "../../js/constants";
import $ from "jquery";
import { ApiGetCall } from "../../js/connector";
import HelmetMetaTag from "../components/HelmetMetaTag";

export default function TablePads() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  const [regularprice, setregularprice] = useState("");
  const [discountprice, setdiscountprice] = useState("");
  useEffect(() => {
    $('#yourElementId').text('Custom Dining Table Pads Made to Order');
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[28].value);
        setsectionDesciption(propDetails[29].value);
        setsectionCTAtext(propDetails[30].value);
        GetPrice();
      }
    });
  }
  const GetPrice = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/OrderNow/ProductOrderById/" + 26 + "?path=null").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        console.log(responseRs)
        $("#Overlay").hide();
        $("#LoderId").hide();
        var regularprice = parseFloat(responseRs.allProducts.regularPrice * 100).toFixed(2);
        var salesprice = parseFloat(responseRs.allProducts.salesPrice * 100).toFixed(2);
        setregularprice(regularprice);
        setdiscountprice(salesprice);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  return (
    <>
      <HelmetMetaTag
        title="Custom Table Pads & Protectors | Berger's Table Pads"
        desc="Berger's Table Pads manufactures custom table pads with the highest quality materials to protect your dining table, desk, conference table, piano, & more."
      />
      <NavigationBar />
      <div className="container-fluid px-0  tablepadsSection">
        <div className="row">
          <div className="col-md-7 m-auto pt-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More" />
            </button>
          </div>
          <div className="col-md-5 pb-3 text-center">
            <img src={CDNURL + "Localimages/tablePadsGroup.png"} className="img-fluid text-end" alt="Hero Image" />
          </div>
        </div>
      </div>
      <div className="container py-5 colorPara ps-0" id="GotoNavbar">
        <ul>
          <li className=" liBullet ps-2">
            A <a className="paraLink" href="/products/dining-table-pads">dining room table cover</a> provides the ultimate in protection
            against scratches, gouges, moisture, breakage, and burns. A well
            designed and constructed table cover even provides protection
            against mishaps and damage from children and pets. <a className="paraLink" href="/products/custom-table-pad">Custom table pads</a>
            {" "}are, by a wide margin, the ultimate protection for your table.
          </li>
          <li className="pt-4 liBullet ps-2">
            With our <a className="paraLink" href="/products/custom-table-pad">custom table pads,</a> you will get an elegant product at an
            unheard of price!
          </li>
          <li className="pt-4 liBullet ps-2">
            There are a number of ways that you can ensure you're going to get
            the proper table pad for your specific table. Follow this link to{" "}
            <a className="paraLink" href="/how-to-measure-for-a-table-pad">
              learn how to measure your table.
            </a>{" "}
            You can also{" "}
            <a className="paraLink" href="/contact">
              contact us
            </a>{" "}
            to set up a consultation or order one of our{" "}
            <a className="paraLink" href="/free-kit-request">
              free measuring kits.
            </a>{" "}
            You can get more information about our{" "}
            <a className="paraLink" href="/magnetic-locking-system">
              MagnaLoc™ patented magnetic locking system
            </a>
            , our{" "}
            <a className="paraLink" href="/customized-monograms">
              custom monograms
            </a>
            , or view a full list of our available{" "}
            <a className="paraLink" href="/colors">
              top and bottom colors
            </a>
            .
          </li>
          <li className="pt-4 liBullet ps-2">
            We have two different styles of table pads: the Ultra Elite Table
            Pad and our Economy Table Pad. If you're interested in ordering one
            of our table pads, follow the links below.
          </li>
        </ul>
      </div>

      <div className="container pt-3">
        <div className="row">
          <div className="col-lg-6 ">
            <ImgData
              img1="elite-dining-table-pad"
              shadowImg="elite-dining-table-pad-shadow"
              id="tp-img1"
              // mt=""
              listData=""
              dataDesign1="true"
              popupImg="elite-dining-table-pad-big2"
              regular={regularprice}
              discount={discountprice}
            />
            <div className="container row my-4">
              <ul className="ps-5">
                <li className=" liBullet ps-2 h5">
                  Includes our exclusive, patented MagnaLocs
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Fine dining / Daily use
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Protects delicate finishes and antiques
                </li>
                <li className=" pt-2 liBullet ps-2 h5">1/2" thick</li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Protects against accidental heat sources up to 550° F
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Choice of any woodgrain, leathertone, or solid color vinyl top
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Choice of Exclusive Breathable Dura-Velvet™ bottom color
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Lifetime limited manufacturer warranty!
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  100% Money Back Guarantee
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 ">
            <ImgData
              img1="economy-table-pad"
              shadowImg="economy-table-pad-shadow"
              id="tp-img2"
              // mt=""
              listData=""
              dataDesign11="true"
              popupImg="economy-table-pad-big"
            />
            <div className="container row  my-4">
              <ul className="ps-5 mt-2">
                <li className=" liBullet ps-2 h5">
                  1/2 inch thick vs. 1/4 inch competitor pad
                </li>
                <li className=" pt-2 liBullet ps-2 h5">Occasional use</li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Protects against accidental heat sources up to 550° F
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  Bone White top color and Green bottom color
                </li>
                <li className=" pt-2 liBullet ps-2 h5">
                  5 Year limited manufacturer warranty
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-3 ps-0">
        <div className="">
          <div className="text-center">
            <span className="ColorHead">Table Pads</span>
            <br />
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-4 liBullet ps-2">
              Shopping for a new dining table can be an exciting experience, that is until the sticker shock reminds you that you have a perfectly good table at home that is still functional, if not beautiful. If you’re currently shopping for a replacement for your dining room table, simply because its surface is worn or it lacks the luster of days gone by, table pads are a very affordable alternative to buying new furniture.
            </li>
          </ul>
        </div>
      </div>
      <div className="container py-3 ps-0">
        <div className="">
          <div className="text-center">
            <span className="ColorHead">Berger’s Table Pads Make Sense</span>
            <br />
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-4 liBullet ps-2">
              For a fraction of the cost of buying a new table, you could
              purchase Berger’s table pads that will restore the beauty of your
              table and lengthen its lifespan. Cover up old and worn-out table
              surfaces with our high-quality table pads that are designed to
              last a lifetime.
            </li>
            <li className="pt-4 liBullet ps-2">
              If your table is functional, a Berger’s pad will allow you to
              continue using your table day in and day out, without any
              embarrassment when friends or family come to visit. Our 1/2” table
              pads are made with a solid fiberboard core that holds up
              throughout the years, so your children and grandchildren can enjoy
              your furniture as much as you do.
            </li>
          </ul>
        </div>
      </div>
      <div className="container py-3 ps-0">
        <div className="">
          <div className="text-center">
            <span className="ColorHead">Best Price Guarantee</span>
            <br />
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-4 liBullet ps-2">
              With so many companies to choose from, it may seem difficult
              selecting the best one. There are actually only 3 manufacturers in
              the country. We are by far the largest, providing over 75% of all
              table pads sold in the US. All the others are simply resellers,
              buying from us or 1 of the other 2. They obviously must make a
              markup that you end up paying not to mention they can’t offer a
              money back guarantee or stand behind any warranty. Our Berger’s
              Best Price Guarantee, along with our 100% satisfaction warranty,
              ensures you’ll be thrilled with the product you receive from us.
              We make our table pads in the USA to exacting standards, selling
              them at factory-direct prices to our customers. The result is a
              product that looks great, fits better, and is absolutely
              guaranteed to exceed your expectations.
            </li>
          </ul>
        </div>
      </div>
      <div className="container py-3 ps-0">
        <div className="">
          <div className="text-center">
            <span className="ColorHead">
              When We Say Best Price, We Mean It
            </span>
            <br />
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-4 liBullet ps-2">
              Our <a className="paraLink subProductOrderNow" href="/products/ultra-elite-dining-table-pad" data-id="26" data-staticpath="UltraEliteDiningTablePad">Ultra Elite table pads</a> are just {discountprice} cents per square inch.
              That’s equivalent to what you would pay for a much lower quality
              product from another company. We can offer our table pads at this
              low price because we’ve cut out the middleman and sell directly to
              our customers.
            </li>
            <li className="pt-4 liBullet ps-2">
              Ultra Elite pads are ideal for everyday use and fine dining. Our
              half-inch pads protect delicate finishes, antiques, and expensive
              furniture- and cover up old and worn-out surfaces, so no one will
              ever know that your table is anything less than beautiful.
            </li>
          </ul>
        </div>
      </div>
      <div className="container py-3 ps-0">
        <div className="">
          <div className="text-center">
            <span className="ColorHead">
              Preserve Your Furniture For Years to Come
            </span>
            <br />
            <p className="prodhr mt-1" />
          </div>
          <ul className="colorPara">
            <li className="pt-4 liBullet ps-2">
              If you’re like most of our customers, you have better things to do
              with your money than spend it on replacing furniture that is still
              100% functional. Cover up your furnishings with Berger’s table
              pads and coverings to preserve them for future generations.
            </li>
            <li className="pt-4 liBullet ps-2">
              Our table pads can save you a significant amount of money- and
              since they are manufactured from superior materials, they will
              outlast other products you may be looking at. Reach out to one of
              our product representatives with your questions, for assistance
              measuring your table, or to request a product sample. We’ll do
              everything possible to make the process a stress-free experience
              for you. Use our Live Chat tools or call our office we’re open
              seven days a week.
            </li>
          </ul>
        </div>
      </div>
      {/* what our clients think start*/}
      <WhatCustomerThink isH1Tag="no" />
      {/* what our clients think end*/}
      <Footer />
    </>
  );
}
