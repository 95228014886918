import $ from "jquery";
import React, { useEffect, useState } from "react";
import Sortable from "sortablejs";
import ModalPopup from "./ModalPopup";
import { CDNURL } from "../js/constants";
import { FinalURl } from "../js/constants";
import { LeftMenu } from "./LeftMenuAdmin";
import { ApiGetCall } from "../js/connector";
import { ApiPostCall } from "../js/connector";
import { ApiDeleteCall } from "../js/connector";
import { ApiPutCall } from "../js/connector";
import PhotoModalPopup from "./photomodal";
import { VerifyLoginToken } from "../js/connector";
export function AdminPage() {
  const [checkvalue, setCheckvalue] = useState(true);
  const [show, setShow] = useState(false);
  const [btnshow, setBtnshow] = useState(false);
  const [droppableRef, seteRef] = useState("");
  const [description, setDescription] = useState("");
  const [caption, setCaption] = useState("");
  const [blankCaption, setBlankCaption] = useState("");
  const [blankImageurl, setblankImageurl] = useState("");
  const [finalImage, setFinalImage] = useState("");
  const [bannerDetailsVar, setBannerDetails] = useState([]);
  const [norecord, setNorecord] = useState("");
  const [sliderorder, setsliderorder] = useState("");
  const [imgName, setImgName] = useState("");
  const [modalHead, setmodalHead] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [CurrentUrl, setCurrentUrl] = useState(FinalURl + "/");
  const [actionUrl, setactionUrl] = useState("");
  const [actionBtnTxt, setactionBtnTxt] = useState("");
  const [overallstate, setoverallstate] = useState({});
  const [PhotoShow, setPhotoShow] = useState(false);
  const [TempCheck, setTempCheck] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    VerifyLoginToken(showhidedivfunc);
    return () => {
      setoverallstate({});
    };
  }, []);
  const showhidedivfunc = () => {
    setTempCheck(true);
    var PathName = window.location.pathname;
    var pathsplit = PathName.split('/');
    var prodActive = pathsplit[2];
    if (prodActive == "AddBanners") {
      showadddiv();
    } else if (prodActive == "UpdateBanners") {
      showupdatediv();
    }
    else {
      GetBannerImages();
    }
  }
  const isShowPopup = (e) => {
    var vId = e.currentTarget.attributes[3].value;
    $("#HdnUserId").val(vId);
    setShow(true);
    setmodalHead("Delete Banner");
    setModalBody("Are you sure you want to delete this banner?");
    setBtnshow(1);
  };

  const ShowPicture = (e) => {
    var currentSrc = e.currentTarget.currentSrc;
    setShow(true);
    setmodalHead("Banner Image");
    setModalBody(<img src={currentSrc} alt="dummy" className="img-fluid" />);
    setBtnshow(2);
  };

  const GetBannerImages = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Banners/GetBannerImages").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        var sugData = responseRs.bannerDetails;
        var sugArray = [];
        var i = 1;
        if (responseRs.status == "success") {
          if (responseRs.bannerDetails != "") {
            setNorecord("");
            setBannerDetails(sugData);
          } else {
            sugArray.push(
              <div className="col-12 gridHeader text-center" key={i}>
                <label>No Record Found</label>
              </div>
            );
            setNorecord(sugArray);
          }
          $("#hdnCancelLink").addClass("d-none");
          $("#SaveOrderHdnBtn").addClass("d-none");
          var vCnt = $(".grid:visible").length;
          if (vCnt == 0) {
            $("#changeOrderHdnBtn").addClass("d-none");
          } else {
            $("#changeOrderHdnBtn").removeClass("d-none");
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  }
  const OpenAddBannerDiv = () => {
    window.location.href = "/HomeBanner/AddBanners";
  };
  const showadddiv = () => {
    $("#hdnFlag").val(1);
    var vCnt = $(".grid:visible").length;
    setsliderorder(vCnt + 1);
    $("#Overlay").show();
    $("#LoderId").show();
    setTimeout(function () {
      $("#GridDivShow").addClass("d-none");
      $("#AddBannerDivShow").removeClass("d-none");
      $("#Overlay").hide();
      $("#LoderId").hide();
    }, 500);
  }

  const SaveBannerItems = async () => {
    var parts = actionUrl.split("/");
    var last_part = parts[parts.length - 1];
    var temp = 0;
    var finalSendImg = null;
    var imgflag = $(".slideprodimg:visible").attr('imgflag');
    var baseImage = null;
    if ($("#ImgAddAppend").html() != "") {
      if (imgflag == 1) {
        baseImage = $(".slideprodimg:visible").attr("base64");
        var parseimg = baseImage.split(",");
        finalSendImg = parseimg[1];
      } else {
        baseImage = $(".slideprodimg:visible").attr("src");
        var vsrcsplit = baseImage.split('/');
        var last_part = vsrcsplit[vsrcsplit.length - 1];
        finalSendImg = last_part;
      }
    }
    if (caption == "") {
      setBlankCaption("Required.");
    } else {
      setBlankCaption("");
      temp++;
    }

    if ($("#ImgAddAppend").html() == "") {
      setblankImageurl("Required.");
    } else {
      setblankImageurl("");
      temp++;
    }
    if (temp >= 2) {
      $("#Overlay").show();
      $("#LoderId").show();
      var raw = JSON.stringify({
        ImageCaption: caption,
        Description: description,
        ImageUrl: finalSendImg,
        SliderOrder: sliderorder,
        FileName: null,
        ActionUrl: last_part,
        ActionBtn: actionBtnTxt,
      });
      await ApiPostCall("/Banners/BannerImages", raw).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          $("#Overlay").hide();
          $("#LoderId").hide();
          if (responseRs.status == "Success") {
            $(".alert-success").show();
            $("#AlertMsg").text("Banner Added Successfully.");
            setTimeout(function () {
              window.location = "/HomeBanner";
            }, 1500);
          } else {
            $(".alert-danger").show();
            $("#AlertDangerMsg").text(responseRs.message);
            setTimeout(function () {
              $(".alert-danger").hide();
              $("#AlertDangerMsg").text();
            }, 1500);
          }
        }
      });
    }
  };
  const CancelBannerItems = () => {
    window.location = "/HomeBanner";
  };

  const ClearValidation = () => {
    if (caption != "") {
      setBlankCaption("");
    }
  };
  var sortable;
  var tempVar = 1;
  const EnableDragDrop = () => {
    tempVar = 1;
    if (droppableRef) {
      sortable = Sortable.create(droppableRef, {
        easing: "cubic-bezier(0.2, 0, 0, 1)",
        animation: 150,
        className: "sortable-list",
        ghostClass: "dragging",
        scrollFn: function () {
          return false;
        },
        scroll: true,
        disabled: false,
        scrollSensitivity: 100,
        bubbleScroll: true,
        onEnd: function () {
          $(".grid").each(function () {
            var thisid = $(this).attr("id");
            $("#" + thisid).attr("rowcount", tempVar);
            tempVar++;
          });
        },
      });

      $(".dragdropdotsimg").removeClass("d-none");
      $(".grid").css("cursor", "grabbing");
      $("#hdnCancelLink").removeClass("d-none");
      $("#SaveOrderHdnBtn").removeClass("d-none");
      $("#changeOrderHdnBtn").addClass("d-none");
    }
  };
  const CancelDragDrop = () => {
    sortable.option("disabled", true);
    $(".dragdropdotsimg").addClass("d-none");
    $(".grid").css("cursor", "pointer");
    $("#hdnCancelLink").addClass("d-none");
    $("#changeOrderHdnBtn").removeClass("d-none");
    $("#SaveOrderHdnBtn").addClass("d-none");
  };
  const SaveOrderOfSlider = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    var GridArray = [];
    $(".grid").each(function () {
      var rowcnt = parseInt($(this).attr("rowcount"));
      var thisid = $(this).attr("id");
      var vsplit = thisid.split("_");
      var finalVal = parseInt(vsplit[1]);
      var NewJson = { rowid: finalVal, roworder: rowcnt };
      GridArray.push(NewJson);
    });
    var raw = JSON.stringify({
      SliderOrder: GridArray,
    });
    await ApiPostCall("/Banners/SliderOrder", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        if (responseRs.status == "Success") {
          $(".alert-success").show();
          $("#AlertMsg").text("Order Change Succesfully.");
          setTimeout(function () {
            $(".alert-success").hide();
            $("#AlertMsg").text("");
          }, 1500);
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  };
  const deleteBanner = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    var userid = parseInt($("#HdnUserId").val());
    var raw = "";
    await ApiDeleteCall("/Banners/DeleteBanner/" + userid, raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        setShow(false);
        if (responseRs.status == "success") {
          $(".alert-success").show();
          $("#AlertMsg").text("Banner Deleted Succesfully.");
          setTimeout(function () {
            window.location = "/HomeBanner";
          }, 1500);
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  };

  const EditBannerDiv = (e) => {
    var vId = parseInt(e.currentTarget.attributes[4].value);
    var bannercount = parseInt(e.currentTarget.attributes[3].value);
    window.location.href = "/HomeBanner/UpdateBanners/?id=" + vId + "&Banner=" + bannercount;
  };
  const showupdatediv = async () => {
    $("#hdnFlag").val(2);
    var vId = parseInt(getUrlParameter("id"));
    var bannercount = parseInt(getUrlParameter("Banner"));
    $("#HdnUserId").val(vId);
    $("#HdnBannerCount").val(bannercount);
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Banners/GetBannerUpdateDetails/" + vId).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        var sugData = responseRs.bannerDetails;
        if (responseRs.status == "Success") {
          $("#GridDivShow").addClass("d-none");
          $("#UpdateBannerDivShow").removeClass("d-none");
          setCaption(sugData.imageCaption);
          setDescription(sugData.description);
          setImgName(sugData.imageUrl);
          setactionBtnTxt(sugData.actionBtn);
          setactionUrl(sugData.actionUrl);
          setFinalImage(CDNURL + "Gallery/" + sugData.imageUrl);
          $(".hdnTableClothImageClose").removeClass('d-none');
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  }
  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };
  const UpdateBannerItems = async () => {
    var finalSendImg = null;
    var imgflag = $(".slideprodimg:visible").attr('imgflag');
    var baseImage = null;
    if ($("#ImgUpdateAppend").html() != "") {
      if (imgflag == 1) {
        baseImage = $(".slideprodimg:visible").attr("base64");
        var parseimg = baseImage.split(",");
        finalSendImg = parseimg[1];
      } else if (imgflag == 2) {
        baseImage = $(".slideprodimg:visible").attr("src");
        var vsrcsplit = baseImage.split('/');
        var last_part = vsrcsplit[vsrcsplit.length - 1];
        finalSendImg = last_part;
      }
      else {
        baseImage = $(".myupdatedimg:visible").attr("src");
        var vsrcsplit = baseImage.split('/');
        var last_part = vsrcsplit[vsrcsplit.length - 1];
        finalSendImg = last_part;
      }
    }
    var userid = parseInt($("#HdnUserId").val());
    var bannercnt = parseInt($("#HdnBannerCount").val());
    var temp = 0;
    if (caption == "") {
      setBlankCaption("Required!!");
    } else {
      setBlankCaption("");
      temp++;
    }

    if ($("#ImgUpdateAppend").html() == "") {
      setblankImageurl("Required!!");
    } else {
      setblankImageurl("");
      temp++;
    }
    if (temp >= 2) {
      $("#Overlay").show();
      $("#LoderId").show();
      var raw = JSON.stringify({
        ImageCaption: caption,
        Description: description,
        ImageUrl: finalSendImg,
        SliderOrder: bannercnt,
        ActionUrl: actionUrl,
        ActionBtn: actionBtnTxt,
      });
      await ApiPutCall("/Banners/UpdateBanner/" + userid, raw).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          $("#Overlay").hide();
          $("#LoderId").hide();
          if (responseRs.status == "Success") {
            $(".alert-success").show();
            $("#AlertMsg").text("Banner Updated Succesfully.");
            setTimeout(function () {
              window.location = "/HomeBanner";
            }, 1500);
          } else {
            $(".alert-danger").show();
            $("#AlertDangerMsg").text(responseRs.message);
            setTimeout(function () {
              $(".alert-danger").hide();
              $("#AlertDangerMsg").text();
            }, 1500);
          }
        }
      });
    }
  };
  const closePopupHandler = () => {
    setShow(false);
  };
  const ChangeStatus = async (e) => {
    var vId = parseInt(e.currentTarget.attributes[3].value);
    var vStatus;
    if ($("#VisibleId_" + vId).is(":checked")) {
      $("#VisibleId_" + vId).prop("checked", true);
      vStatus = 1;
    } else {
      $("#VisibleId_" + vId).removeAttr("checked");
      vStatus = 0;
    }
    $("#Overlay").show();
    $("#LoderId").show();
    var raw = JSON.stringify({
      Status: vStatus,
    });
    await ApiPutCall("/Banners/VisibleSlider/" + vId, raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        $("#Overlay").hide();
        $("#LoderId").hide();
        if (responseRs.status == "Success") {
          $(".alert-success").show();
          $("#AlertMsg").text("Updated Successfully.");
          setTimeout(function () {
            $(".alert-success").hide();
            $("#AlertMsg").text("");
          }, 1500);
          if (responseRs.bannerData.status == 1) {
            setCheckvalue(true);
          } else {
            setCheckvalue(false);
          }
        } else {
          $(".alert-danger").show();
          $("#AlertDangerMsg").text(responseRs.message);
          setTimeout(function () {
            $(".alert-danger").hide();
            $("#AlertDangerMsg").text();
          }, 1500);
        }
      }
    });
  };
  const showAvailableUrl = () => {
    window.open("/NavigationMenu");
  };
  const removeTableClothImage = () => {
    if ($("#hdnFlag").val() == 2) {
      $("#ImgUpdateAppend").html("");
    } else {
      $("#ImgAddAppend").html("");
    }
  }
  const isPhotoShowPopup = () => {
    setPhotoShow(true);
  }
  const closePhotoPopupHandler = () => {
    setPhotoShow(false);
  }
  const savemodalphotos = () => {
    var imgboxlen = $(".mainimgbox:visible").length;
    var count = 0;
    var finalhtml = "";
    $(".hdnTableClothImageClose").removeClass('d-none');
    $(".computerimgs").each(function (i) {
      i += imgboxlen;
      var base64 = $(this).attr('base64');
      var html = '<div class="col-3 py-2" id="ProductRow_' + i + '">' +
        '<div class="imagebox">' +
        '<div class="col-12">' +
        '<div class="mainimgbox text-center">' +
        '<img src=' + base64 + ' class="slideprodimg" id="imgId_' + i + '" imgflag="1" base64=' + base64 + ' status="1"  />' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>';
      finalhtml += html;
      count = i;
    });
    $(".myimgredborder").each(function (i) {
      i += count + 1;
      var imggallery = $(this).attr('src');
      var html = '<div class="col-3 py-2" id="ProductRow_' + i + '">' +
        '<div class="imagebox">' +
        '<div class="col-12">' +
        '<div class="mainimgbox text-center">' +
        '<img src=' + imggallery + ' class="slideprodimg" id="imgId_' + i + '" imgflag="2" status="1"  />' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>';
      finalhtml += html;
    });
    if ($("#hdnFlag").val() == 2) {
      $("#ImgUpdateAppend").html("");
      $("<div>" + finalhtml + "</div><br/>")
        .attr({
          class: "crsr-pointer row"
        }).appendTo("#ImgUpdateAppend");
    } else {
      $("#ImgAddAppend").html("");
      $("<div>" + finalhtml + "</div><br/>")
        .attr({
          class: "crsr-pointer row"
        }).appendTo("#ImgAddAppend");
    }
    if ($(".mainimgbox:visible").length > 1) {
      setblankImageurl("You can upload only one image");
      if ($("#hdnFlag").val() == 2) {
        $("#ImgUpdateAppend").html("");
      } else {
        $("#ImgAddAppend").html("");
      }
    }
    else {
      setblankImageurl("");
    }
    setPhotoShow(false);
  }

  return (
    <>
      {TempCheck == true ?
        <>

          <input type="hidden" id="HdnUserId" />
          <input type="hidden" id="hdnFlag" />
          <input type="hidden" id="HdnBannerCount" />
          <input type="hidden" id="HdnFileName" />
          <LeftMenu />
          <div fixed="true" className='container-fluid mainContainer'>
            <div className="content-wrapper" id="GridDivShow">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="cardDiv">
                      <div className="cardDiv-header">
                        <div className="row AlignItems">
                          <div className="col-4">
                            <h4>Home Page Banners</h4>
                          </div>
                          <div className="col-8">
                            <div className="card-action">
                              <button
                                type="submit"
                                className="loginBtn btnhover"
                                onClick={OpenAddBannerDiv}
                              >
                                Add Banner
                              </button>
                            </div>
                            <div
                              className="card-action me-2 d-none"
                              id="SaveOrderHdnBtn"
                            >
                              <button
                                type="submit"
                                className="loginBtn btnhover"
                                onClick={SaveOrderOfSlider}
                              >
                                Save
                              </button>
                            </div>
                            <div
                              className="card-action me-2"
                              id="changeOrderHdnBtn"
                            >
                              <button
                                type="submit"
                                className="loginBtn btnhover"
                                onClick={EnableDragDrop}
                              >
                                Change Order
                              </button>
                            </div>
                            <div
                              className="card-action me-2 mt-1 d-none"
                              id="hdnCancelLink"
                            >
                              <label
                                onClick={CancelDragDrop}
                                className="crsr-Pointer "
                              >
                                Cancel
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pl-3 pr-3">
                        <div className="col-12 gridHeader">
                          <div className="row">
                            <div className="col-1"></div>
                            <div className="col-2 text-center pe-0">
                              <b>Banner Image</b>
                            </div>
                            <div className="col-5 text-center pe-0">
                              <b>Banner Caption</b>
                            </div>
                            <div className="col-2 text-center">
                              <b>Is Active?</b>
                            </div>
                            <div className="col-2 text-center ps-0">
                              <b>Action</b>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sortable-list scrolly-63"
                          ref={(el) => seteRef(el)}
                          id="sortableDivId"
                        >
                          {bannerDetailsVar.map((item, i) => {
                            var returData;
                            var profileImage =
                              CDNURL + "Gallery/" + item.imageUrl;
                            returData = (
                              <div
                                className="col-12 grid"
                                key={i}
                                rowcount={i + 1}
                                id={`MyClass_${item.id}`}
                              >
                                <div className="row" key={i}>
                                  <div className="col-1 text-center mt-2">
                                    <img
                                      src="./images/dragdrop.png"
                                      title="Drag to reorder"
                                      className="w-25 d-none dragdropdotsimg"
                                      alt="Drag Icon"
                                    />
                                  </div>
                                  <div className="col-2 text-center mb-2">
                                    <img
                                      src={profileImage}
                                      alt="dummy"
                                      className="MiniIcon"
                                      onClick={ShowPicture}
                                    />
                                  </div>
                                  <div className="col-5 text-center mt-3">
                                    {item.imageCaption}
                                  </div>
                                  <div className="col-2 text-center mt-3">
                                    {item.status == 1 ? (
                                      <input
                                        className="form-check-input me-2"
                                        defaultChecked={checkvalue}
                                        id={`VisibleId_${item.id}`}
                                        onClick={ChangeStatus}
                                        type="checkbox"
                                        value=""
                                        userid={item.id}
                                      />
                                    ) : (
                                      <input
                                        className="form-check-input me-2"
                                        id={`VisibleId_${item.id}`}
                                        onClick={ChangeStatus}
                                        type="checkbox"
                                        value=""
                                        userid={item.id}
                                      />
                                    )}
                                  </div>
                                  <div className="col-2 text-center mt-3">
                                    <span>
                                      <img
                                        src="./images/editIcon.svg"
                                        title="Edit Banner"
                                        className="iconClass me-1"
                                        bannercount={i + 1}
                                        userid={item.id}
                                        onClick={EditBannerDiv}
                                        alt="Edit Icon"
                                      />
                                    </span>
                                    <span>
                                      <img
                                        src="./images/deleteIcon.svg"
                                        className="iconClass"
                                        title="Delete Banner"
                                        userid={item.id}
                                        onClick={isShowPopup}
                                        alt="Delete Icon"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                            return returData;
                          })}
                          {norecord}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-wrapper d-none" id="AddBannerDivShow">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="cardDiv1">
                      <div className="cardDiv-header pb-0 pt-1">
                        <div className="row AlignItems">
                          <div className="col-1and5 text-center">
                            <a href="/HomeBanner">
                              <img src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" alt="Back" />
                            </a>
                          </div>
                          <div className="col-11 ps-0 mt-2">
                            <h4>
                              Add New Banners
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pl-3 pr-3 px-5">
                        <div className="row">
                          <div className="col-12 pt-3">
                            <div className="row">
                              <div className="col-6 ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Caption"
                                  onMouseLeave={ClearValidation}
                                  value={caption}
                                  onChange={(e) => setCaption(e.target.value)}
                                />
                                <span style={{ color: "red" }}>
                                  {blankCaption}
                                </span>
                              </div>
                              <div className="col-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Call To Action Button"
                                  value={actionBtnTxt}
                                  onChange={(e) =>
                                    setactionBtnTxt(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 pt-3">
                            <div className="row">
                              <div className="col-12 py-2 ps-3">
                                <h6>{CurrentUrl}</h6>
                              </div>
                              <div className="col-5">
                                <input
                                  type="text"
                                  id="actionURLId"
                                  className="form-control"
                                  onMouseLeave={ClearValidation}
                                  placeholder="Call To Action URL"
                                  value={actionUrl}
                                  onChange={(e) => setactionUrl(e.target.value)}
                                />
                              </div>
                              <div className="col-3 pt-1">
                                <a
                                  className="ps-2 crsr-Pointer text-decoration-none"
                                  onClick={showAvailableUrl}
                                >
                                  Show Available URL
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 pt-3">
                            <textarea
                              rows="3"
                              className="w-100 form-control"
                              defaultValue={description}
                              placeholder="Description"
                              onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                          </div>
                          <div className="row">
                            <div className="col-12 pt-3">
                              <label className="selectImgBtn" onClick={isPhotoShowPopup}>
                                Choose Banner
                              </label>
                              <img src={CDNURL + "LocalImages/closeicon.png"} alt="Close" className="ms-3 h-25px w-10 d-none hdnTableClothImageClose" onClick={removeTableClothImage} />
                              <label className="txtRed py-3 ps-3">Note: Ideal size for Banners images should be less than 1200px tall and less than 800px wide</label>
                              <br />
                              <div className="row">
                                <div id="ImgAddAppend"></div>
                              </div>
                              <h5 style={{ color: "red" }}>{blankImageurl}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 py-5 text-end">
                          <span className="me-3 crsr-Pointer" onClick={CancelBannerItems}>Cancel</span>
                          <button type="submit"
                            className="loginBtn btnhover" onClick={SaveBannerItems}>Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-wrapper d-none" id="UpdateBannerDivShow">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="cardDiv1">
                      <div className="cardDiv-header pb-0 pt-1">
                        <div className="row AlignItems">
                          <div className="col-1and5 text-center">
                            <a href="/HomeBanner">
                              <img src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" alt="Back" />
                            </a>
                          </div>
                          <div className="col-11 ps-0 mt-2">
                            <h4>
                              Update Banners
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pl-3 pr-3 px-5">
                        <div className="row">
                          <div className="col-12 pt-3">
                            <div className="row">
                              <div className="col-6 ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Caption"
                                  onMouseLeave={ClearValidation}
                                  value={caption}
                                  onChange={(e) => setCaption(e.target.value)}
                                />
                                <span style={{ color: "red" }}>
                                  {blankCaption}
                                </span>
                              </div>
                              <div className="col-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Call To Action Button"
                                  value={actionBtnTxt}
                                  onChange={(e) =>
                                    setactionBtnTxt(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 pt-3">
                            <div className="row">
                              <div className="col-12 py-2">
                                <h6>{CurrentUrl}</h6>
                              </div>
                              <div className="col-5">
                                <input
                                  type="text"
                                  id="actionURLId"
                                  className="form-control"
                                  onMouseLeave={ClearValidation}
                                  placeholder="Call To Action URL"
                                  value={actionUrl}
                                  onChange={(e) => setactionUrl(e.target.value)}
                                />
                              </div>
                              <div className="col-3 pt-1">
                                <a
                                  className="ps-2 crsr-Pointer text-decoration-none"
                                  onClick={showAvailableUrl}
                                >
                                  Show Available URL
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 pt-3">
                            <textarea
                              rows="3"
                              className="w-100 form-control"
                              defaultValue={description}
                              placeholder="Description"
                              onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                          </div>
                          <div className="row">
                            <div className="col-12 pt-3">
                              <label className="selectImgBtn" onClick={isPhotoShowPopup}>
                                Choose Banner
                              </label>
                              <img src={CDNURL + "LocalImages/closeicon.png"} alt="Close" className="ms-3 h-25px w-10 d-none hdnTableClothImageClose" onClick={removeTableClothImage} />
                              <label className="txtRed py-3 ps-3">Note: Ideal size for Banners images should be less than 1200px tall and less than 800px wide</label>
                              <br />
                              <div className="row">
                                <div id="ImgUpdateAppend">
                                  <img src={finalImage} className="myupdatedimg mainimgbox w-auto" alt="dummy" />
                                </div>
                              </div>
                              <h5 style={{ color: "red" }}>{blankImageurl}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 py-5 text-end">
                          <span className="me-3 crsr-Pointer" onClick={CancelBannerItems}>Cancel</span>
                          <button
                            type="submit"
                            className="loginBtn btnhover"
                            onClick={UpdateBannerItems}
                          >
                            Update Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalPopup
            class="MainModal"
            dataShowhide={btnshow}
            show={show}
            data={modalHead}
            modalBody={modalBody}
            onHide={closePopupHandler}
            onClick={deleteBanner}
          ></ModalPopup>
          <PhotoModalPopup className="MainModal" show={PhotoShow} onHide={closePhotoPopupHandler} onClick={savemodalphotos}></PhotoModalPopup>
        </>
        :
        <></>
      }
    </>
  );
}
