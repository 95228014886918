import React, { useState } from "react";
import { NavigationBar } from "./Navigationbar";
import { Footer } from "./Footer";
import { useEffect } from "react";
import * as NumericInput from "react-numeric-input";
import { ApiGetCall, ApiPostCall } from "../js/connector";
import $ from "jquery";
import ModalPopup from "./ModalPopup";
export default function Cartpage() {
  const [show, setShow] = useState(false);
  const [clearshow, setclearshow] = useState(false);
  const [btnshow, setBtnshow] = useState(false);
  const [modalHead, setmodalHead] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [ItemGrid, setItemGrid] = useState([]);
  const [grandTotal, setgrandTotal] = useState("");
  const [overallstate, setoverallstate] = useState({});
  var storedNames = JSON.parse(localStorage.getItem("addtocart"));
  var userid = parseInt(window.localStorage.getItem("useridordernow"));
  var width = $(window).width();
  if (storedNames != null) {
    var rushprice = storedNames.RushPrice;
  }
  useEffect(() => {
    window.scroll(0, 0);

    if (width <= 991) {
      $("#cartHeader").addClass("d-none");
    } else {
      $("#cartHeader").removeClass("d-none");
    }
    if (storedNames != null) {
      console.log(storedNames.Products)
      setItemGrid(storedNames.Products);
      setTimeout(function () {
        calculateTotal();
      }, 1500);
    } else {
      $("#noitemfounddiv").removeClass("d-none");
      $("#cartdiv").addClass("d-none");
    }
    shoppingcart();
    return () => {
      setoverallstate({});
    };
  }, []);
  const closePopupHandler = () => {
    setShow(false);
  };
  const closeCartPopupHandler = () => {
    setclearshow(false);
  };
  const checkout = () => {
    ApplyCouponCode();
    var totalitem = JSON.parse(localStorage.getItem("addtocart"));
    var vlen = totalitem.Products.length;
    for (var i = 0; i < vlen; i++) {
      var itemname = totalitem.Products[i].ItemName;
      var prodname = $("#ProductName_" + i).text();
      var itemtotal = $("#totalRowPriceId_" + i).text();
      var vQty = parseInt($("#qtyId_" + i).val());
      if (itemname == prodname) {
        totalitem.Products[i].qty = vQty;
        itemtotal = parseFloat(itemtotal).toFixed(2);
        totalitem.Products[i].item_total = itemtotal;

      }
      if (totalitem.Products[i].pathname == "TABLEPADSTORAGEBAG") {
        totalitem.Products[i].item_description = "TablePad Bag";
      } else if (totalitem.Products[i].pathname == "TABLELEAFSTORAGEBAG") {
        totalitem.Products[i].item_description = "Leaf Bag";
      } else {
        totalitem.Products[i].item_description = $("#ProductDescription_" + i).text();
      }

      if ($("#factoryRush_" + i + " option:selected").val() == "277") {
        totalitem.Products[i].Rush = 1;
      } else {
        totalitem.Products[i].Rush = 0;
      }
    }
    localStorage.setItem("addtocart", JSON.stringify(totalitem));
    window.location = "/checkout";
  };
  const ApplyCouponCode = async () => {
    calculateTotal();
    $('.coupon-error').text('');
    $('.coupon-success').text('');
    $('.coupon-success-note').addClass('d-none');
    $('.discountContainer').addClass('d-none');
    var couponCode = $('.couponCodeInput').val();
    couponCode = couponCode.trim();
    if (couponCode == "") {
      localStorage.setItem("couponCode", "");
      $('.coupon-error').text('Please enter a valid coupon code!');
    } else {
      localStorage.setItem("couponCode", couponCode);
      await ApiGetCall(`/CouponCode/getCoupon/${couponCode}`).then((result) => {
        var resultJson = JSON.parse(result);
        var couponData = resultJson.data;
        if (resultJson.status == "invalid") {
          $('.coupon-error').text("This coupon code is not valid, please use a different one.");
        } else if (resultJson.status == "Success") {
          //discount type 1= Percent, 2= flat discount
          if (couponData.discountType == 1) {
            CalculatePercent(couponData);
            $('.coupon-success-note').removeClass('d-none');
          } else if (couponData.discountType == 2) {
            CalculateFlatPrice(couponData);
            $('.coupon-success-note').removeClass('d-none');
          }

        }
      });
    }

  };
  function CalculatePercent(couponData) {
    var storedProducts = JSON.parse(localStorage.getItem("addtocart"));
    var totalBasePrice = 0;
    $(storedProducts.Products).each(function () {
      var product = this;
      totalBasePrice += product.price * product.qty;
      if(product.Rush == 1){
        totalBasePrice = totalBasePrice + 30;
      }
    });
    var currentTotalPrice = grandTotal;
    var discountedPrice = (totalBasePrice / 100) * couponData.discount;



    var discountedTotal = currentTotalPrice - discountedPrice;

    localStorage.setItem("BaseTotalAMount", totalBasePrice.toFixed(2));
    localStorage.setItem("DiscountType", "percent");
    localStorage.setItem("Discount", couponData.discount);
    localStorage.setItem("DiscountAmount", discountedPrice.toFixed(2));

    setgrandTotal(discountedTotal.toFixed(2));
    $('.discountContainer').removeClass('d-none');
    $('.discountContainer .discountGiven').empty().text(discountedPrice.toFixed(2));
    $('.coupon-success').text('Coupon code of ' + couponData.discount + '% applied successfully!');
  }
  function CalculateFlatPrice(couponData) {
    var storedProducts = JSON.parse(localStorage.getItem("addtocart"));
    var totalBasePrice = 0;
    $(storedProducts.Products).each(function () {
      var product = this;
      totalBasePrice += product.price * product.qty;
    });
    var currentTotalPrice = grandTotal;
    var discountedPrice = couponData.discount;


    var discountedTotal = currentTotalPrice - discountedPrice;

    $('.discountContainer').removeClass('d-none');
    $('.discountContainer .discountGiven').empty().text(discountedPrice);
    setgrandTotal(discountedTotal.toFixed(2));

    localStorage.setItem("BaseTotalAMount", totalBasePrice.toFixed(2));
    localStorage.setItem("DiscountType", "flat");
    localStorage.setItem("Discount", couponData.discount);
    localStorage.setItem("DiscountAmount", discountedPrice);

    $('.coupon-success').text('Coupon code of  $' + couponData.discount + ' applied successfully!');
  }
  const shoppingcart = () => {
    if (storedNames != null) {
      var totalitem = JSON.parse(localStorage.getItem("addtocart"));
      var vlen = totalitem.Products.length;
      $(".totalItemCart").text(vlen);
    }
  };
  const calculateTotal = () => {
    var Mainrushprice = storedNames.RushPrice;
    var subtotalmain = 0;
    var shippingprice = 0;
    var total = 0;
    var tax = 0;
    $(".mainrow:visible").each(function () {
      var vid = $(this).attr("id");
      var vsplit = vid.split("_");
      var finalcnt = vsplit[1];
      var ship = $(this).attr("shippingprice");
      var flag = $(this).attr('flag');
      var vPrice = parseFloat($("#priceId_" + finalcnt).attr("price"));
      var vQty = parseInt($("#qtyId_" + finalcnt).val());
      var rush = $(this).attr('rush');
      var rowpricetotal = 0;
      var warrantyprice = 0;
      var rushprice = 0;
      if (flag == 1) {
        warrantyprice = 30;
      }
      if (rush == 1) {
        rushprice = Mainrushprice;
      }
      rowpricetotal = (parseFloat(vPrice) + parseFloat(rushprice) + parseFloat(warrantyprice)).toFixed(2);
      rowpricetotal = (rowpricetotal * vQty).toFixed(2);
      $("#totalRowPriceId_" + finalcnt).text(rowpricetotal);
      subtotalmain += parseFloat(rowpricetotal);
      tax += parseFloat(rowpricetotal * 0.07);
      shippingprice += parseFloat(parseFloat(ship) * vQty);
      total += parseFloat(
        parseFloat(rowpricetotal) + parseFloat(tax) + parseFloat(shippingprice)
      );
    });
    localStorage.setItem("shippingprice", shippingprice.toFixed(2));
    localStorage.setItem("totalprice", subtotalmain.toFixed(2));
    setgrandTotal(subtotalmain.toFixed(2));
    noitemfounddiv();
    var totalitem = JSON.parse(localStorage.getItem("addtocart"));
    var vlen = totalitem.Products.length;
    for (var i = 0; i < vlen; i++) {
      var itemname = totalitem.Products[i].ItemName;
      var prodname = $("#ProductName_" + i).text();
      var vQty = parseInt($("#qtyId_" + i).val());
      if (itemname == prodname) {
        totalitem.Products[i].qty = vQty;
      }
    }
    localStorage.setItem("addtocart", JSON.stringify(totalitem));
  };
  const deletRow = () => {
    setShow(false);
    var vid = $("#HdnUserId").val();
    var index = storedNames.Products.indexOf(storedNames.Products[vid]);
    storedNames.Products.splice(index, 1);
    localStorage.setItem("addtocart", JSON.stringify(storedNames));
    $("#rowId_" + vid).hide();
    noitemfounddiv();
    shoppingcart();
    calculateTotal();
  };
  const noitemfounddiv = () => {
    var vlen = $(".mainrow:visible").length;
    if (vlen > 0) {
      $("#cartdiv").removeClass("d-none");
      $("#noitemfounddiv").addClass("d-none");
    } else {
      $("#noitemfounddiv").removeClass("d-none");
      $("#cartdiv").addClass("d-none");
    }
  };
  const clearcart = () => {
    setclearshow(false);
    localStorage.removeItem("addtocart");
    $("#noitemfounddiv").removeClass("d-none");
    $("#cartdiv").addClass("d-none");
    $(".totalItemCart").text(0);
  };
  const editcartitem = (itemname, uid, prodid, pathname, flag, urlpath) => {
    var finalname = itemname.replace(/\s/g, "");
    if (finalname == "UltraEliteDiningTablePad") {
      window.open("/products/" + finalname + "/" + uid);
    } else {
      window.open("/store/" + urlpath + "/" + uid);
    }
    localStorage.setItem("useridordernow", prodid);
    localStorage.setItem("staticpath", pathname);
    localStorage.setItem("flag", flag);
  };
  const isShowPopup = (vId) => {
    $("#HdnUserId").val(vId);
    setShow(true);
    setmodalHead("Delete Cart Item");
    setModalBody("Are you sure you want to remove this item from your cart?");
    setBtnshow(1);
  };
  const ShowCartPopup = () => {
    setclearshow(true);
    setmodalHead("Delete Cart Item");
    setModalBody("Are you sure you want to clear cart?");
    setBtnshow(1);
  }
  return (
    <>
      <input type="hidden" id="HdnUserId" />
      <NavigationBar />
      <div className="container pt-2 pb-5 d-none" id="noitemfounddiv">
        <div className="col-12 mx-auto px-5  text-center">
          <img className="img-fluid ms-4" src="./images/emptyCart.svg" alt="Empty Cart" />
          <h2 className="mt-4 h1">Your Cart Is Empty</h2>
          <h5 className="mt-4">
            Looks like you haven’t made your choice yet...
          </h5>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-4">
              <a href="/home">
                <button className="p-2 orderNowBtn ">Continue Shopping</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-4" id="cartdiv">
        <div className="col-12 text-center mb-4">
          <label className="activeColorLink"><h1>Cart</h1></label>
        </div>
        <div className="cardDiv-header" id="cartHeader">
          <div className="col-12">
            <div className="row">
              <div className="col-2 text-center ps-0">Item Image</div>
              <div className="col-4">Item Name & Description</div>
              <div className="col-2 text-center d-none">Price</div>
              {/* <div className="col-2 ">Rush</div> */}
              <div className="col-1 text-center">Warranty</div>
              <div className="col-2 text-center">Quantity</div>
              <div className="col-2 text-center d-none">Total</div>
              <div className="col-2 text-center crsr-pointer">
                <h6 onClick={ShowCartPopup}>
                  <img
                    src="/images/removecart.svg"
                    title="Clear Cart"
                    className="h-25px"
                    alt="Clear Cart"
                  />
                  Clear
                </h6>
              </div>
            </div>
          </div>
        </div>
        {ItemGrid.map((slide, i) => (
          <div className="col-12 p-4 newBrdrBtm mainrow" flag={slide.flag}
            id={`rowId_${i}`} key={i} shippingprice={slide.shippingprice} uid={slide.UID} rush={slide.Rush}>
            <div className="row py-3">
              <div className="col-lg-2 text-center py-2 ps-0">
                <div className="h-10vh">
                  <img
                    src={slide.itemImg}
                    alt="dummy"
                    className="newslideprodimg"
                  />
                </div>
              </div>
              <div className="col-lg-4 px-0 py-2 textSmCenter">
                <h5 id={`ProductName_${i}`}
                  className="crsr-pointer txtHover"
                  onClick={(e) =>
                    editcartitem(
                      slide.ItemName,
                      slide.UID,
                      slide.product_id,
                      slide.pathname,
                      slide.flag,
                      slide.UrlPath
                    )
                  }
                >
                  {slide.ItemName}
                </h5>
                <span className="fs-14" id={`ProductDescription_${i}`}>
                  {slide.propertyname.map((item, i) => {
                    var returData;
                    returData = (
                      <p key={i}>
                        - {item.propname}: {item.propvalue}
                      </p>
                    );
                    return returData;
                  })}
                </span>
              </div>
              <div className="col-lg-2 col-12 text-center py-2 d-none" id={`priceId_${i}`} price={slide.price.toFixed(2)}>
                {width <= 991 ?
                  <span>
                    Price : $ {slide.price.toFixed(2)}
                  </span>
                  :
                  <span>
                    $ {slide.price.toFixed(2)}
                  </span>
                }
              </div>
              {/* <div className="col-lg-2 col-12 text-center py-2"> */}
                {/* {slide.Rush == 1 ?
                  width <= 991 ?
                    <span>Factory Rush : $ {slide.RushPrice.toFixed(2)}</span>
                    :
                    <span>$ {slide.RushPrice.toFixed(2)}</span>
                  :
                  <>-</>
                } */}
                {/* {slide.product_id != 28 && slide.product_id != 29 ?
                  slide.Rush == 1 ?
                    <div className="col-md-12">
                      <select className="form-select" id={`factoryRush_${i}`}>
                        <option value="0">Select</option>
                        <option value="277" selected>Yes (${rushprice})</option>
                        <option value="278">No</option>
                      </select>
                    </div>
                    :
                    <div className="col-md-12">
                      <select className="form-select" id={`factoryRush_${i}`}>
                        <option value="0">Select</option>
                        <option value="277" >Yes (${rushprice})</option>
                        <option value="278">No</option>
                      </select>
                    </div>
                  :
                  <>-</>
                } */}
              {/* </div> */}
              <div className="col-lg-1 col-12 text-center py-2">
                {slide.flag == 1 ?
                  width <= 991 ?
                    <span>Warranty : $30.00</span>
                    :
                    <span>$30.00</span>
                  :
                  <>-</>
                }
              </div>
              <div className="col-lg-2 col-12 text-center py-2">
                <div className="col-lg-12 offset-lg-0 col-6 offset-3">
                  <NumericInput
                    min={1}
                    defaultValue={parseInt(slide.qty)}
                    className="w-100 form-control"
                    onChange={calculateTotal}
                    id={`qtyId_${i}`}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-12 text-center py-2 d-none">
                {width <= 991 ?
                  <span>Total : $ <span id={`totalRowPriceId_${i}`}></span></span>
                  :
                  <span>$ <span id={`totalRowPriceId_${i}`}></span></span>
                }

              </div>
              <div className="col-lg-2 mt-1 text-center">
                <img
                  src="/images/editIcon.svg"
                  title="Edit Item"
                  className="iconClass crsr-pointer"
                  alt="Edit Product"
                  onClick={(e) =>
                    editcartitem(
                      slide.ItemName,
                      slide.UID,
                      slide.product_id,
                      slide.pathname,
                      slide.flag,
                      slide.UrlPath
                    )
                  }
                />
                <img
                  src="/images/closeBtn.png"
                  title="Remove Item"
                  className="iconClass ms-2 crsr-pointer"
                  onClick={(e) => isShowPopup(i)}
                  alt="Remove Item"
                // onClick={(e) => deletRow(i)}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="col-12 row pt-5 mx-0">
          <div className="col-lg-6 py-2 px-0">
            <h6>You can enter coupon code below to get discount!</h6>
            <div className="input-group my-3">
              <div>
                <input
                  type="text"
                  className="form-control couponCodeInput"
                  placeholder="Coupon Code"
                />
              </div>
              <div className="input-group-append  ps-3">
                <button className="CouponBtn btnhover" type="button" onClick={ApplyCouponCode}>
                  Apply Code
                </button>
              </div>
              <p class="error-message coupon-error"></p>
              <p class="success-message coupon-success"></p>
              <p class="success-message coupon-success-note d-none">Note: Coupons are applicable on base prices of the products only.</p>
            </div>
          </div>
          <div className="col-lg-6 text-end px-0 py-2">
            <div className="col-lg-4 offset-lg-8 d-none">
              <h4 className="pb-2 discountContainer d-none">
                <label>Discount:</label>
                <label className="text-end">&nbsp;&nbsp; $<span className="discountGiven"></span></label>
              </h4>
              <h4 className="pb-2 checked">
                <label>Total:</label>
                <label className="text-end">&nbsp;&nbsp; ${grandTotal}</label>
              </h4>
            </div>
            <div className="col-12 text-end">
              <button className="orderNowBtn py-2 px-3 btnhover" onClick={checkout}>
                CHECK OUT
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 text-end py-3">
          <h5>
            <a className="checked text-decoration-none" href="/home">Continue Shopping</a>
          </h5>
        </div>
      </div>
      <ModalPopup
        class="MainModal"
        dataShowhide={btnshow}
        show={show}
        data={modalHead}
        modalBody={modalBody}
        onHide={closePopupHandler}
        onClick={deletRow}
      ></ModalPopup>
      <ModalPopup
        class="MainModal"
        dataShowhide={btnshow}
        show={clearshow}
        data={modalHead}
        modalBody={modalBody}
        onHide={closeCartPopupHandler}
        onClick={clearcart}
      ></ModalPopup>
      <Footer />

    </>
  );
}
