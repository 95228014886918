import $ from 'jquery';
import Cookies from 'js-cookie';
const BaseUrl = process.env.REACT_APP_Base_URL;
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Access-Control-Allow-Origin", "*");
myHeaders.append("authToken", process.env.REACT_APP_AuthToken);
export function ApiPostCall(endpoint, payload) {
    var urlencoded = payload;
    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
        .then((response) => response.text())
        .then((result) => { return result })
        .catch((error) => { return error });
}
export function ApiGetCall(endpoint) {
    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
        .then((response) => response.text())
        .then((result) => { return result })
        .catch((error) => { return error });
}
export function ApiPutCall(endpoint, payload) {
    var urlencoded = payload;
    var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
        .then((response) => response.text())
        .then((result) => { return result })
        .catch((error) => { return error });
}
export function ApiDeleteCall(endpoint, payload) {
    var urlencoded = payload;
    var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
        .then((response) => response.text())
        .then((result) => { return result })
        .catch((error) => { return error });
}
export function VerifyLoginToken(EndPointName,Param1,Param2){
    var token = Cookies.get('_uvcf');
        var email = Cookies.get('_opfc');
        var raw = JSON.stringify({
            "Token": token,
            "email": email
        });
        ApiPostCall("/AdminLogin/VerifyToken", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "Success") {
                    EndPointName(Param1,Param2);
                } else {
                    window.location = "/ryan-tp-panel";
                }
            }
        });
}