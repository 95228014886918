import { useState, useEffect } from "react"
import $ from 'jquery';
import React from "react";
import { ApiPostCall } from "../js/connector";
import { useCookies } from 'react-cookie'
import Cookies from 'js-cookie';
export function Loginpage() {
    const [state, setState] = useState({});
    const [cookies, setCookie] = useCookies();
    const [TokenCookie, setTokenCookie] = useCookies();
    const [TokenEmail, setTokenEmail] = useCookies();
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const [blankEmail, setBlankEmail] = useState("");
    const [blankPassword, setBlankPassword] = useState("");
    useEffect(() => {
        window.scroll(0, 0);
        var remember = cookies.remember;
        if (remember == 'true') {
            var cookieemail = cookies.EmailId;
            var cookiepwd = cookies.Password;
            setEmail(cookieemail);
            setPwd(cookiepwd);
            $("#rememberMeId").attr('checked', true);
        }
        return () => {
            setState({});
        };
    }, []);
    const loginUser = async () => {
        var temp = 0;

        if (email == "") {
            setBlankEmail("Required!");
        } else {
            setBlankEmail("");
            temp++;
        }

        if (pwd == "") {
            setBlankPassword("Required!");
        } else {
            setBlankPassword("");
            temp++;
        }

        if (temp >= 2) {

            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                "Email": email,
                "Password": pwd
            });
            await ApiPostCall("/AdminLogin/Login", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        localStorage.setItem('adminlogin', 'true');
                        localStorage.setItem('shownotification', '0');
                        setTokenCookie('_uvcf', responseRs.loginToken.token);
                        setTokenEmail('_opfc', responseRs.loginToken.email);
                        window.location = "/NavigationMenu";
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        }
    }
    const something = async (event) => {
        if (event.keyCode === 13) {
            var temp = 0;
            if (email == "") {
                setBlankEmail("Required!")
            } else {
                setBlankEmail("");
                temp++;
            }

            if (pwd == "") {
                setBlankPassword("Required!")
            } else {
                setBlankPassword("");
                temp++;
            }

            if (temp >= 2) {
                $("#Overlay").show();
                $("#LoderId").show();
                var raw = JSON.stringify({
                    "Email": email,
                    "Password": pwd
                });
                await ApiPostCall("/AdminLogin/Login", raw).then((result) => {
                    if (result == undefined || result == "") {
                        alert("Something went wrong");
                    } else {
                        const responseRs = JSON.parse(result);
                        $("#Overlay").hide();
                        $("#LoderId").hide();
                        if (responseRs.status == "Success") {
                            localStorage.setItem('adminlogin', 'true');
                            localStorage.setItem('shownotification', '0');
                            setTokenCookie('_uvcf', responseRs.loginToken.token);
                            setTokenEmail('_opfc', responseRs.loginToken.email);
                            window.location = "/NavigationMenu";
                        } else {
                            $(".alert-danger").show();
                            $("#AlertDangerMsg").text(responseRs.message);
                            setTimeout(function () {
                                $(".alert-danger").hide();
                                $("#AlertDangerMsg").text();
                            }, 1500);
                        }
                    }
                });
            }
        }
    }

    const rememberme = async () => {
        if ($("#rememberMeId").is(':checked')) {
            var emailid = email;
            var password = pwd;
            //(minutes*hours)*day
            var cookieExpireTime = (3600 * 24) * 30;
            setCookie('EmailId', emailid, cookieExpireTime);
            setCookie('Password', password, cookieExpireTime);
            setCookie('remember', true, cookieExpireTime);
        } else {
            setCookie('EmailId', null);
            setCookie('Password', null);
            setCookie('remember', null);
        }
    }
    return (
        <>
            <div className="bodyDiv" >
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card shadow-2-strong mx-auto">
                                <div className="card-body p-5 ">
                                    <div className="mb-5">
                                        <img src="./images/Logo.svg" className="img-fluid" alt="Logo" />
                                    </div>
                                    <div className="mb-4 text-center">
                                        <h5>SIGN IN</h5>
                                    </div>
                                    <div className="mb-4">
                                        <input type="email" className="form-control" value={email} onKeyDown={(e) => something(e)} onChange={e => setEmail(e.target.value)} placeholder="Email Id" />
                                        <span style={{ color: "red" }}>{blankEmail}</span>
                                    </div>
                                    <div className="mb-4">
                                        <input type="password" className="form-control" value={pwd} onKeyDown={(e) => something(e)} onChange={e => setPwd(e.target.value)} placeholder="Password" />
                                        <span style={{ color: "red" }}>{blankPassword}</span>
                                    </div>
                                    <div className="form-check d-flex justify-content-start mb-3">
                                        <input className="form-check-input me-2" id="rememberMeId" type="checkbox" value="" onClick={rememberme} />
                                        <label className="form-check-label"> Remember Me </label>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button type="submit" className="loginBtn btnhover" onClick={loginUser}>LOGIN</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}