import { useState, useEffect } from 'react';
import $ from 'jquery';
import React from "react";
import { LeftMenu } from './LeftMenuAdmin';
import { ApiGetCall } from '../js/connector';
import { CDNURL } from '../js/constants';
import { ApiPostCall } from '../js/connector';
import { ApiPutCall } from '../js/connector';
import PhotoModalPopup from './photomodal';
import { VerifyLoginToken } from '../js/connector';
export function ColorAdmin() {
    const [overallstate, setoverallstate] = useState({});
    const [colorname, setcolorname] = useState("");
    const [blankname, setblankname] = useState("");
    const [blankImageurl, setblankImageurl] = useState("");
    const [blankColorTableImageurl, setblankColorTableImageurl] = useState("");
    const [finalImage, setFinalImage] = useState("");
    const [colortableImage, setcolortableImage] = useState("");
    const [blankCategory, setblankCategory] = useState("");
    const [shortdescription, setshortdescription] = useState("");
    const [norecord, setnorecord] = useState("");
    const [coloritems, setcoloritems] = useState([]);
    const [checkvalue, setCheckvalue] = useState(true);
    const [colorCategory, setcolorCategory] = useState([]);
    const [clrimgshow, setclrimgshow] = useState(false);
    const [showmodaldiv, setshowmodaldiv] = useState(0);
    const [TempCheck, setTempCheck] = useState(false);
    useEffect(() => {
        VerifyLoginToken(showhidedivfunc);
        return () => {
            setoverallstate({});
        };
    }, []);
    const showhidedivfunc = () => {
        setTempCheck(true);
        var PathName = window.location.pathname;
        var pathsplit = PathName.split('/');
        var prodActive = pathsplit[2];
        if (prodActive == "AddColor") {
            showadddiv();
        } else if (prodActive == "UpdateColor") {
            showupdatediv();
        }
        else {
            GetColorMenu();
        }
    }
    const GetColorMenu = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Colors/GetColors").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.data;
                var sugArray = [];
                if (sugData.length == 0) {
                    sugArray.push(
                        <div className="col-12 text-center mt-5">
                            <label>No Record Found</label>
                        </div>
                    );
                    setnorecord(sugArray);
                } else {
                    setcoloritems(sugData);
                }
            }
        });
    }
    const addcolordiv = () => {
        window.location.href = "/ColorsAdmin/AddColor";
    }
    const showadddiv = () => {
        $("#HdnFlag").val(1);
        localStorage.removeItem("Colorbase64");
        localStorage.removeItem("ColorTablebase64");
        $("#Overlay").show();
        $("#LoderId").show();
        setTimeout(function () {
            $("#GridDivShow").addClass("d-none");
            $("#AddColorDivShow").removeClass("d-none");
            $("#Overlay").hide();
            $("#LoderId").hide();
            $("#HdnStatus").val(1);
            getColorCategory();
        }, 500);
    }
    const getColorCategory = async () => {
        await ApiGetCall("/Colors/GetColorCategory").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                var sugData = responseRs.colorCategories;
                setcolorCategory(sugData);
            }
        });
    }
    const CancelPage = () => {
        window.location = "/ColorsAdmin";
    }
    const AddSaveColor = async () => {
        var temp = 0;
        var finalSendImg = null;
        var finalSendColorTableImg = null;
        var baseImage = null;
        var baseImageColorTable = null;
        var imgflag = $("#ImgAddAppend .slideprodimg:visible").attr('imgflag');
        var imgColorTableflag = $("#ImgColorTableAddAppend .slideprodimg:visible").attr('imgflag');
        if ($("#ImgAddAppend").html() != "") {
            if (imgflag == 1) {
                baseImage = $("#ImgAddAppend .slideprodimg:visible").attr("base64");
                var parseimg = baseImage.split(",");
                finalSendImg = parseimg[1];
            } else {
                baseImage = $("#ImgAddAppend .slideprodimg:visible").attr("src");
                var vsrcsplit = baseImage.split('/');
                var last_part = vsrcsplit[vsrcsplit.length - 1];
                finalSendImg = last_part;
            }
        }

        if ($("#ImgColorTableAddAppend").html() != "") {
            if (imgColorTableflag == 1) {
                baseImageColorTable = $("#ImgColorTableAddAppend .slideprodimg:visible").attr("base64");
                var parseimgcolortable = baseImageColorTable.split(",");
                finalSendColorTableImg = parseimgcolortable[1];
            } else {
                baseImageColorTable = $("#ImgColorTableAddAppend .slideprodimg:visible").attr("src");
                var vsrcsplit = baseImageColorTable.split('/');
                var last_part = vsrcsplit[vsrcsplit.length - 1];
                finalSendColorTableImg = last_part;
            }
        }

        var colorcategory = parseInt($("#CategoryID option:selected").val());
        if (colorname == "") {
            setblankname("Required!");
        } else {
            setblankname("");
            temp++;
        }
        if (colorcategory == 0) {
            setblankCategory("Required!");
        } else {
            setblankCategory("");
            temp++;
        }

        if ($("#ImgAddAppend").html() == "") {
            setblankImageurl("Required!");
        } else {
            setblankImageurl("");
            temp++;
        }

        if (temp >= 3) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                Name: colorname,
                CategoryId: colorcategory,
                ColorImage: finalSendImg,
                ColorTableImage: finalSendColorTableImg,
                ShortDescription: shortdescription,
                IsActive: 1
            });
            await ApiPostCall("/Colors/AddEditColor", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Color Added Successfully.");
                        setTimeout(function () {
                            window.location = "/ColorsAdmin";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        } else {
            $("#Overlay").hide();
            $("#LoderId").hide();
        }
    }
    const EditColor = (e) => {
        localStorage.removeItem("Colorbase64");
        localStorage.removeItem("ColorTablebase64");
        var vId = parseInt(e.currentTarget.attributes[3].value);
        window.location.href = "/ColorsAdmin/UpdateColor/?id=" + vId;
    }
    const showupdatediv = async () => {
        $("#HdnFlag").val(2);
        getColorCategory();
        var vId = parseInt(getUrlParameter("id"));
        $("#HdnUserId").val(vId);
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Colors/GetColor/" + vId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.data;
                if (responseRs.status == "Success") {
                    $("#HdnStatus").val(2);
                    $("#GridDivShow").addClass("d-none");
                    $("#UpdateColorDivShow").removeClass("d-none");
                    setcolorname(sugData.name);
                    $("#CategoryUpdateID").val(sugData.categoryId);
                    setshortdescription(sugData.shortDescription);
                    setFinalImage(CDNURL + "Gallery/" + sugData.colorImage);
                    $(".hdnColorImageClose").removeClass('d-none');
                    if (sugData.colorTableImage != '') {
                        setcolortableImage(<img src={CDNURL + "Gallery/" + sugData.colorTableImage} className="myupdatedimg mainimgbox w-auto" />);
                        $(".hdnColorTableImageClose").removeClass('d-none');
                    } else {
                        setcolortableImage(null);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };
    const updatesavecolor = async () => {
        var userid = parseInt($("#HdnUserId").val());
        var colorcategory = parseInt($("#CategoryUpdateID option:selected").val());
        var temp = 0;
        var finalSendImg = null;
        var finalcolortableSendImg = null;
        var imgflag = $("#ImgUpdateAppend .slideprodimg:visible").attr('imgflag');
        var imgColorTableflag = $("#ImgColorTableUpdateAppend .slideprodimg:visible").attr('imgflag');
        var baseImage = null;
        var baseColorImage = null;
        if ($("#ImgUpdateAppend").html() != "") {
            if (imgflag == 1) {
                baseImage = $("#ImgUpdateAppend .slideprodimg:visible").attr("base64");
                var parseimg = baseImage.split(",");
                finalSendImg = parseimg[1];
            } else if (imgflag == 2) {
                baseImage = $("#ImgUpdateAppend .slideprodimg:visible").attr("src");
                var vsrcsplit = baseImage.split('/');
                var last_part = vsrcsplit[vsrcsplit.length - 1];
                finalSendImg = last_part;
            }
            else {
                baseImage = $("#ImgUpdateAppend .myupdatedimg:visible").attr("src");
                var vsrcsplit = baseImage.split('/');
                var last_part = vsrcsplit[vsrcsplit.length - 1];
                finalSendImg = last_part;
            }
        }

        if ($("#ImgColorTableUpdateAppend").html() != "") {
            if (imgColorTableflag == 1) {
                baseColorImage = $("#ImgColorTableUpdateAppend .slideprodimg:visible").attr("base64");
                var parseimg = baseColorImage.split(",");
                finalcolortableSendImg = parseimg[1];
            } else if (imgColorTableflag == 2) {
                baseColorImage = $("#ImgColorTableUpdateAppend .slideprodimg:visible").attr("src");
                var vsrcsplit = baseColorImage.split('/');
                var last_part = vsrcsplit[vsrcsplit.length - 1];
                finalcolortableSendImg = last_part;
            }
            else {
                baseColorImage = $("#ImgColorTableUpdateAppend .myupdatedimg:visible").attr("src");
                var vsrcsplit = baseColorImage.split('/');
                var last_part = vsrcsplit[vsrcsplit.length - 1];
                finalcolortableSendImg = last_part;
            }
        }

        if (colorname == "") {
            setblankname("Required!");
        } else {
            setblankname("");
            temp++;
        }
        if (colorcategory == 0) {
            setblankCategory("Required!");
        } else {
            setblankCategory("");
            temp++;
        }

        if ($("#ImgUpdateAppend").html() == "") {
            setblankImageurl("Required!");
        } else {
            setblankImageurl("");
            temp++;
        }

        if (temp >= 3) {
            $("#Overlay").show();
            $("#LoderId").show();
            var raw = JSON.stringify({
                Id: userid,
                Name: colorname,
                CategoryId: colorcategory,
                ColorImage: finalSendImg,
                ColorTableImage: finalcolortableSendImg,
                ShortDescription: shortdescription,
                IsActive: 1
            });
            await ApiPostCall("/Colors/AddEditColor", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    $("#Overlay").hide();
                    $("#LoderId").hide();
                    if (responseRs.status == "Success") {
                        $(".alert-success").show();
                        $("#AlertMsg").text("Color Updated Successfully.");
                        setTimeout(function () {
                            window.location = "/ColorsAdmin";
                        }, 1500);
                    } else {
                        $(".alert-danger").show();
                        $("#AlertDangerMsg").text(responseRs.message);
                        setTimeout(function () {
                            $(".alert-danger").hide();
                            $("#AlertDangerMsg").text();
                        }, 1500);
                    }
                }
            });
        } else {
            $("#Overlay").hide();
            $("#LoderId").hide();
        }
    }
    const changeStatus = async (itemid) => {
        var vStatus;
        if ($("#VisibleId_" + itemid).is(":checked")) {
            $("#VisibleId_" + itemid).prop("checked", true);
            vStatus = 1;
        } else {
            $("#VisibleId_" + itemid).removeAttr("checked");
            vStatus = 0;
        }
        $("#Overlay").show();
        $("#LoderId").show();
        var raw = JSON.stringify({
            Status: vStatus,
        });
        await ApiPutCall("/Colors/VisibleColor/" + itemid, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                if (responseRs.status == "Success") {
                    $(".alert-success").show();
                    $("#AlertMsg").text("Updated Successfully.");
                    setTimeout(function () {
                        $(".alert-success").hide();
                        $("#AlertMsg").text("");
                    }, 1500);
                    if (responseRs.visibleData.isActive == 1) {
                        setCheckvalue(true);
                    } else {
                        setCheckvalue(false);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const removeColorImage = (iconflag) => {
        if (iconflag == 1) {
            $("#ImgAddAppend").html("");
        } else {
            $("#ImgUpdateAppend").html("");
        }
    }
    const removeColorTableImage = (iconflag) => {
        if (iconflag == 1) {
            $("#ImgColorTableAddAppend").html("");
        } else {
            $("#ImgColorTableUpdateAppend").html("");
        }
    }
    const ColorImageShow = () => {
        setclrimgshow(true);
        setshowmodaldiv(1);
    }
    const ColorTableImageShow = () => {
        setclrimgshow(true);
        setshowmodaldiv(2);
    }
    const closeColorImagePopupHandler = () => {
        setclrimgshow(false);
    }
    const savemodalphotos = () => {
        var vflag = $("#HdnFlag").val();
        var imgboxlen = $(".mainimgbox:visible").length;
        var count = 0;
        var finalhtml = "";
        $(".hdnTableClothImageClose").removeClass('d-none');
        $(".computerimgs").each(function (i) {
            i += imgboxlen;
            var base64 = $(this).attr('base64');
            var html = '<div class="col-3 py-2" id="ProductRow_' + i + '">' +
                '<div class="imagebox">' +
                '<div class="col-12">' +
                '<div class="mainimgbox text-center">' +
                '<img alt="dummy" src=' + base64 + ' class="slideprodimg" id="imgId_' + i + '" imgflag="1" base64=' + base64 + ' status="1"  />' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>';
            finalhtml += html;
            count = i;
        });
        $(".myimgredborder").each(function (i) {
            i += count + 1;
            var imggallery = $(this).attr('src');
            var html = '<div class="col-3 py-2" id="ProductRow_' + i + '">' +
                '<div class="imagebox">' +
                '<div class="col-12">' +
                '<div class="mainimgbox text-center">' +
                '<img alt="dummy" src=' + imggallery + ' class="slideprodimg" id="imgId_' + i + '" imgflag="2" status="1"  />' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>';
            finalhtml += html;
        });
        if (vflag == 1) {
            if (showmodaldiv == 1) {
                $("#ImgAddAppend").html("");
                $("<div>" + finalhtml + "</div><br/>")
                    .attr({
                        class: "crsr-pointer row"
                    }).appendTo("#ImgAddAppend");
            } else if (showmodaldiv == 2) {
                $("#ImgColorTableAddAppend").html("");
                $("<div>" + finalhtml + "</div><br/>")
                    .attr({
                        class: "crsr-pointer row"
                    }).appendTo("#ImgColorTableAddAppend");
            }
            var vlen = $("#ImgAddAppend .mainimgbox:visible").length;
            if (vlen > 1) {
                setblankImageurl("You can upload only one image");
                $("#ImgAddAppend").html("");
            }
            else {
                setblankImageurl("");
            }

            var vCOlorTablelen = $("#ImgColorTableAddAppend .mainimgbox:visible").length;
            if (vCOlorTablelen > 1) {
                setblankColorTableImageurl("You can upload only one image");
                $("#ImgColorTableAddAppend").html("");
            }
            else {
                setblankColorTableImageurl("");
            }
        } else {
            if (showmodaldiv == 1) {
                $("#ImgUpdateAppend").html("");
                $("<div>" + finalhtml + "</div><br/>")
                    .attr({
                        class: "crsr-pointer row"
                    }).appendTo("#ImgUpdateAppend");
            } else if (showmodaldiv == 2) {
                $("#ImgColorTableUpdateAppend").html("");
                $("<div>" + finalhtml + "</div><br/>")
                    .attr({
                        class: "crsr-pointer row"
                    }).appendTo("#ImgColorTableUpdateAppend");
            }
            var vlen = $("#ImgUpdateAppend .mainimgbox:visible").length;
            if (vlen > 1) {
                setblankImageurl("You can upload only one image");
                $("#ImgUpdateAppend").html("");
            }
            else {
                setblankImageurl("");
            }

            var vCOlorTablelen = $("#ImgColorTableUpdateAppend .mainimgbox:visible").length;
            if (vCOlorTablelen > 1) {
                setblankColorTableImageurl("You can upload only one image");
                $("#ImgColorTableUpdateAppend").html("");
            }
            else {
                setblankColorTableImageurl("");
            }
        }

        setclrimgshow(false);
    }

    return (
        <>
            {TempCheck == true ?
                <>
                    <input type="hidden" id="HdnUserId" />
                    <input type="hidden" id="HdnStatus" />
                    <input type="hidden" id="HdnFlag" />
                    <input type="hidden" id="HdnColorTableFlag" />
                    <LeftMenu />
                    <div fixed="true" className='container-fluid mainContainer'>
                        <div className="content-wrapper" id="GridDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv">
                                            <div className="cardDiv-header">
                                                <div className="row AlignItems">
                                                    <div className="col-md-8">
                                                        <h4>Colors</h4>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="card-action">
                                                            <button type="submit" className="loginBtn btnhover" onClick={addcolordiv}>
                                                                Add New
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3">
                                                <div className="col-12 gridHeader">
                                                    <div className="row">
                                                        <div className="col-2 text-center">
                                                            <b>Color Image</b>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <b>Name</b>
                                                        </div>
                                                        <div className="col-2 text-center">
                                                            <b>Category</b>
                                                        </div>
                                                        <div className="col-2 text-center pe-0">
                                                            <b>Is Active?</b>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <b>Action</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='scrolly-63'>
                                                    {coloritems.map((item, i) => {
                                                        var returData;
                                                        var colorImage = CDNURL + "Gallery/" + item.colorImage;
                                                        // var colorTableImage = CDNURL + "Gallery/" + item.colorTableImage;
                                                        returData = (
                                                            <div className="col-12 grid" key={i} id={`row_${item.id}`}>
                                                                <div className="row" key={i}>
                                                                    <div className="col-2 text-center mb-2">
                                                                        <img src={colorImage} alt="dummy" className="MiniIcon" />
                                                                    </div>
                                                                    <div className="col-3 text-center mt-3">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="col-2 text-center mt-3">
                                                                        {item.categoryId == 1 ?
                                                                            <>Leather Tone</>
                                                                            : item.categoryId == 2 ?
                                                                                <>Wood Grains</>
                                                                                : item.categoryId == 3 ?
                                                                                    <>Solids</>
                                                                                    :
                                                                                    <>Dura-Velvet</>
                                                                        }
                                                                    </div>
                                                                    <div className="col-2 text-center mt-3">
                                                                        {item.isActive == 1 ? (
                                                                            <input className="form-check-input me-2" defaultChecked={checkvalue} id={`VisibleId_${item.id}`}
                                                                                type="checkbox" value="" userid={item.id} onClick={(e) => changeStatus(item.id)} />
                                                                        ) : (
                                                                            <input className="form-check-input me-2" id={`VisibleId_${item.id}`}
                                                                                type="checkbox" value="" userid={item.id} onClick={(e) => changeStatus(item.id)} />
                                                                        )}
                                                                    </div>
                                                                    <div className="col-3 text-center mt-3">
                                                                        <span>
                                                                            <img src="./images/editIcon.svg" title="Edit Banner" className="iconClass me-1"
                                                                                userid={item.id} onClick={EditColor} alt="Edit Banner" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                        return returData;
                                                    })}
                                                    {norecord}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="AddColorDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/ColorsAdmin">
                                                            <img src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" alt='Back' />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Add New Color
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3 px-5">
                                                <div className="row">
                                                    <div className="col-12 pt-3">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <input type="text" className="form-control" value={colorname}
                                                                    onChange={(e) => setcolorname(e.target.value)} />
                                                                <div className='d-flex justify-content-between'>
                                                                    <span className="grayTxt me-2">
                                                                        Name<span style={{ color: "red" }}>*</span>
                                                                    </span>
                                                                    <span style={{ color: "red" }}>{blankname}</span>
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                <select id="CategoryID" className="form-select formError">
                                                                    {colorCategory.map((item, i) => {
                                                                        var returData;
                                                                        {
                                                                            item.isActive == true ?
                                                                                returData = <option value={item.id} key={i}>{item.name}</option>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        return returData;
                                                                    })}
                                                                </select>
                                                                <span className="grayTxt">Category<span style={{ color: "red" }}>*</span></span>
                                                                <br />
                                                                <span style={{ color: "red" }}>{blankCategory}</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <textarea type="text" className="form-control" value={shortdescription} onChange={(e) => setshortdescription(e.target.value)} />
                                                                <span className="grayTxt">Short Description</span>
                                                            </div>
                                                            <div className="col-12 py-2">
                                                                <div className="pt-3">
                                                                    <label className="selectImgBtn" onClick={ColorImageShow}>
                                                                        Choose Color Image<span style={{ color: "red" }}>*</span>
                                                                    </label>
                                                                    <img alt='Close Icon' src={CDNURL + "LocalImages/closeicon.png"} className="ms-3 h-25px w-10 d-none hdnColorImageClose" onClick={(e) => removeColorImage(1)} />
                                                                    <br />
                                                                    <div className="pt-2">
                                                                        <div id="ImgAddAppend" className=''></div>
                                                                    </div>
                                                                    <h5 style={{ color: "red" }}>{blankImageurl}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 py-2">
                                                                <div className="pt-3">
                                                                    <label className="selectImgBtn" onClick={ColorTableImageShow}>
                                                                        Choose Color Table Image
                                                                    </label>
                                                                    <img alt='close icon' src={CDNURL + "LocalImages/closeicon.png"} className="ms-3 h-25px w-10 d-none hdnColorTableImageClose" onClick={(e) => removeColorTableImage(1)} />
                                                                    <br />
                                                                    <div className="pt-2">
                                                                        <div id="ImgColorTableAddAppend" className=''></div>
                                                                    </div>
                                                                    <h5 style={{ color: "red" }}>{blankColorTableImageurl}</h5>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <span className="txtRed py-3 ps-2">Note: Ideal size for home page images should be 200px tall and 300px wide.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={CancelPage}>Cancel</span>
                                                    <button type="submit"
                                                        className="loginBtn btnhover" onClick={AddSaveColor}>Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper d-none" id="UpdateColorDivShow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cardDiv1">
                                            <div className="cardDiv-header pb-0 pt-1">
                                                <div className="row AlignItems">
                                                    <div className="col-1and5 text-center">
                                                        <a href="/ColorsAdmin">
                                                            <img alt='back' src={CDNURL + "/Localimages/BackBtn.svg"} className="h-25px" title="Back" />
                                                        </a>
                                                    </div>
                                                    <div className="col-11 ps-0 mt-2">
                                                        <h4>
                                                            Update Color
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pl-3 pr-3 px-5">
                                                <div className="row">
                                                    <div className="col-12 pt-3">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <input type="text" className="form-control" value={colorname}
                                                                    onChange={(e) => setcolorname(e.target.value)} />
                                                                <div className='d-flex justify-content-between'>
                                                                    <span className="grayTxt me-2">Name<span style={{ color: "red" }}>*</span></span>
                                                                    <span style={{ color: "red" }}>{blankname}</span>
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                <select id="CategoryUpdateID" className="form-select formError">
                                                                    {colorCategory.map((item, i) => {
                                                                        var returData;
                                                                        {
                                                                            item.isActive == true ?
                                                                                returData = <option value={item.id} key={i}>{item.name}</option>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        return returData;
                                                                    })}
                                                                </select>
                                                                <span className="grayTxt">Category<span style={{ color: "red" }}>*</span></span>
                                                                <br />
                                                                <span style={{ color: "red" }}>{blankCategory}</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <textarea type="text" className="form-control" value={shortdescription} onChange={(e) => setshortdescription(e.target.value)} />
                                                                <span className="grayTxt">Short Description</span>
                                                            </div>
                                                            <div className="col-12 py-2">
                                                                <div className="pt-3">
                                                                    <label className="selectImgBtn" onClick={ColorImageShow}>
                                                                        Choose Color Image<span style={{ color: "red" }}>*</span>
                                                                    </label>
                                                                    <img alt='Close' src={CDNURL + "LocalImages/closeicon.png"} className="ms-3 h-25px w-10 d-none hdnColorImageClose" onClick={(e) => removeColorImage(2)} />
                                                                    <br />
                                                                    <div className="pt-2">
                                                                        <div id="ImgUpdateAppend">
                                                                            <img src={finalImage} className="myupdatedimg mainimgbox w-auto" />
                                                                        </div>
                                                                    </div>
                                                                    <h5 style={{ color: "red" }}>{blankImageurl}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 py-2">
                                                                <div className="pt-3">
                                                                    <label className="selectImgBtn" onClick={ColorTableImageShow}>
                                                                        Choose Color Table Image
                                                                    </label>
                                                                    <img alt='close' src={CDNURL + "LocalImages/closeicon.png"} className="ms-3 h-25px w-10 d-none hdnColorTableImageClose" onClick={(e) => removeColorTableImage(2)} />
                                                                    <br />
                                                                    <div className="pt-2">
                                                                        <div id="ImgColorTableUpdateAppend">
                                                                            {colortableImage}
                                                                        </div>
                                                                    </div>
                                                                    <h5 style={{ color: "red" }}>{blankColorTableImageurl}</h5>
                                                                </div>
                                                            </div>

                                                            <div className='col-12'>
                                                                <span className="txtRed py-3 ps-2">Note: Ideal size for home page images should be 200px tall and 300px wide.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 py-5 text-end">
                                                    <span className="me-3 crsr-Pointer" onClick={CancelPage}>Cancel</span>
                                                    <button type="submit"
                                                        className="loginBtn btnhover" onClick={updatesavecolor}>Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PhotoModalPopup className="MainModal" show={clrimgshow} onHide={closeColorImagePopupHandler} onClick={savemodalphotos}></PhotoModalPopup>
                </>
                :
                <></>
            }
        </>
    )
}