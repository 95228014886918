import React from "react";
import { NavigationBar } from "../Navigationbar";
import { Footer } from "../Footer";
import { CDNURL } from "../../js/constants";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { WhatCustomerThink } from "../components/WhatCustomerThink";
import { ApiGetCall } from "../../js/connector";

export default function DiningRoomTablePad() {
  const [isVisible, setIsVisible] = useState(true);
  const [sectionTitle, setsectionTitle] = useState("");
  const [sectionDesciption, setsectionDesciption] = useState("");
  const [sectionCTAtext, setsectionCTAtext] = useState("");
  useEffect(() => {
    $('#yourElementId').text('Dining Room Table Pad');
    myFunction();
    const timer = setTimeout(() => {
      setIsVisible(false);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);
  const myFunction = async () => {
    $("#Overlay").show();
    $("#LoderId").show();
    await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var responseRs = JSON.parse(result);
        var propDetails = responseRs.propertyDetails;
        $("#Overlay").hide();
        $("#LoderId").hide();
        setsectionTitle(propDetails[34].value);
        setsectionDesciption(propDetails[35].value);
        setsectionCTAtext(propDetails[36].value);
      }
    });
  }
  const gotonavbar = () => {
    $('html, body').animate({
      scrollTop: $("#GotoNavbar").offset().top
    }, 100);
  }
  return (
    <>
      <NavigationBar />
      <div className="container-fluid px-0  extensionSection">
        <div className="row">
          <div className="col-md-6 m-auto pt-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
            <h1 className="availbleColorTxt">{sectionTitle}</h1>
            <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
            <button className="ColorBtn" onClick={gotonavbar} id="GotoNavbar">{sectionCTAtext}
              <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More"/>
            </button>
          </div>
          <div className="col-md-6 text-center">
            <img src={CDNURL + "Localimages/DiningRoomTableGroup.png"} className="img-fluid text-end" alt="Hero Image"/>
          </div>
        </div>
      </div>

      <div className="container py-5 colorPara ps-0">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Dining Room Table Pad</h1>
            <p className="prodhr mt-1" />
          </div>
        </div>
        <ul>
          <li className="liBullet ps-2 pt-3">
            Consider the numerous advantages of owning a quality dining room
            table pad. If you’re currently thinking about replacing a dining
            table with an old or worn out surface or wish to preserve a dining
            table for the next generation, Berger’s has the perfect protective
            pad for your table. We custom-manufacture{" "}
            <a className="paraLink" href="/products/tables-pads">
              table top pads
            </a>{" "}
            for any and every shape, size, or design or table.
          </li>
        </ul>
      </div>

      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2> What Makes Our Dining Room Table Pad the Best?</h2>
            <p className="prodhr mt-1" />
          </div>
          <div className="px-5">
            <ul className="colorPara">
              <li className="pt-2  ps-2">
                100 Made-in-America from quality materials
              </li>
              <li className="pt-2  ps-2">
                Sold at factory-direct prices, cutting out the middleman
              </li>
              <li className="pt-2  ps-2">
                Guaranteed best price or we’ll meet or beat another price you
                find
              </li>
              <li className="pt-2  ps-2">
                The right fit or we’ll remake your pad at no additional cost
              </li>
              <li className="pt-2  ps-2">
                More colors and styles than other companies
              </li>
              <li className="pt-2  ps-2">
                Protection from spills, heat, and damage
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>We Make it Easy to Place an Order With Us</h2>
            <p className="prodhr mt-1" />
          </div>
          <div className="px-5">
            <ul className="colorPara">
              <li className="pt-2  ps-2">
                Shop online 24/7 and place your order directly over our website
                for the ultimate in convenience. Our online resources and
                available Live Chat access will ensure you send in the correct
                measurements for a guaranteed fit.
              </li>
              <li className="pt-2  ps-2">
                Shop by phone seven days a week and speak with a knowledgeable
                Berger’s agent who can help you every step of the way. We know
                it’s important to our customers to work with a patient and
                experienced table pad specialists, which is why we are happy to
                accommodate you over the phone when you call our office.
              </li>
              <li className="pt-2  ps-2">
                Schedule an appointment for an in-home consultation, and someone
                from our company will respond promptly with all the information
                you need. We’ll be happy to come into your home to take
                measurements, make recommendations on colors or styles, or
                assist you in any way necessary.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container pb-5 ps-0">
        <div className="">
          <div className="text-center">
            <h2>Choose a Custom Dining Room Table Pad That Meets Your Needs</h2>
            <p className="prodhr mt-1" />
          </div>
          <div className="px-5">
            <ul className="colorPara">
              <li className="pt-2  ps-2">
                Dining room table pads are our best-selling product. No table in
                the house is subject to more use, more abuse, and more potential
                for accidental harm than the dining table, since family members,
                friends, and guests spend so much time socializing in the dining
                room. Consider your options and let us know if you’d like
                additional information on any of our products:
              </li>
              <li className="pt-2  ps-2">
                Ultra Elite Dining Room Table Pad. Price includes our exclusive
                MagnaLoc system at no additional cost. The Ultra Elite is ideal
                for fine dining and everyday use will protect your tabletop from
                accidental heat sources up to 550 F.
              </li>
              <li className="pt-2  ps-2">
                Mid-Grade Dining Room Table Pad. The Mid-Grade is the perfect
                protection for occasional use, includes our MagnaLocs system,
                and provides heat protection up to 350F. Mid-Grade pad comes
                with a 15-year limited manufacturer’s warranty.
              </li>
              <li className="pt-2  ps-2">
                Economy Table Pad. 1/2-inch-thick, compared with our
                competitors’ 1/4-inch products. Ideal for occasional use on
                stone or laminate surfaces. The economy pad comes with our
                5-year limited manufacturer’s warranty.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container pb-5 colorPara ps-0">
        <ul>
          <li className="liBullet ps-2">
            Feel free to browse our complete inventory of styles, colors, and
            woodgrains or simply reach out to a product specialist by phone or
            Live Chat if you have questions. We’ll be happy to provide expert
            advice and help you choose the best table pad for exceptional
            protection.
          </li>
        </ul>
      </div>
      <WhatCustomerThink />
      <Footer />
    </>
  );
}
