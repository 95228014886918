import { NavigationBar } from "./Navigationbar";
import { useState, useEffect } from "react";
import { Footer } from "./Footer";
import $ from 'jquery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CDNURL } from "../js/constants";
import React from "react";
import { WhatCustomerThink } from "./components/WhatCustomerThink";
import { ApiGetCall } from "../js/connector";
export function Tablecloth() {
    const [isVisible, setIsVisible] = useState(true);
    const [sectionTitle, setsectionTitle] = useState("");
    const [sectionDesciption, setsectionDesciption] = useState("");
    const [sectionCTAtext, setsectionCTAtext] = useState("");
    const [tableclothitems, settableclothitems] = useState([]);
    const [categoryDetailsVar, setcategoryDetailsVar] = useState([]);
    useEffect(() => {
        $('#yourElementId').text('Custom Table Cloth For All Table Types');
        myFunction();
        GetProductCat();
        const timer = setTimeout(() => {
            setIsVisible(false);
        });
        return () => {
            clearTimeout(timer);
        }
    }, []);
    const myFunction = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Customization/GetCustomProperties").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                var propDetails = responseRs.propertyDetails;
                $("#Overlay").hide();
                $("#LoderId").hide();
                setsectionTitle(propDetails[7].value);
                setsectionDesciption(propDetails[8].value);
                setsectionCTAtext(propDetails[9].value);
                GetTableClothMenu();
            }
        });
    };
    const GetTableClothMenu = async () => {
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/Category/GetTableClothItems").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                var responseRs = JSON.parse(result);
                $("#Overlay").hide();
                $("#LoderId").hide();
                var sugData = responseRs.tableClothDetails;
                if (sugData.length != 0) {
                    settableclothitems(sugData);
                }
            }
        });
    }
    const showimgname = (e) => {
        var vId = e.currentTarget.id;
        var vsplit = vId.split('_');
        var vVal = vsplit[1];
        $("#tableImgTxt_" + vVal).removeClass('d-none');
    }
    const hideimgname = (e) => {
        var vId = e.currentTarget.id;
        var vsplit = vId.split('_');
        var vVal = vsplit[1];
        $("#tableImgTxt_" + vVal).addClass('d-none');
    }
    const gotonavbar = () => {
        $('html, body').animate({
            scrollTop: $("#GotoNavbar").offset().top
        }, 100);
    }
    const GetProductCat = async () => {
        var PathName = window.location.pathname;
        // var userid = window.localStorage.getItem('useridordernow');
        // var userpath = null;
        // if (userid == null) {
            var userid = 0;
            var userpath = PathName;
        // }
        $("#Overlay").show();
        $("#LoderId").show();
        await ApiGetCall("/OrderNow/ProductOrderById/" + userid + "?path=" + userpath).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                $("#Overlay").hide();
                $("#LoderId").hide();
                const responseRs = JSON.parse(result);
                var sugData = responseRs.subProducts;
                if (responseRs.status == "Success") {
                    if (responseRs.subProducts != "") {
                        setcategoryDetailsVar(sugData);
                    }
                } else {
                    $(".alert-danger").show();
                    $("#AlertDangerMsg").text(responseRs.message);
                    setTimeout(function () {
                        $(".alert-danger").hide();
                        $("#AlertDangerMsg").text();
                    }, 1500);
                }
            }
        });
    }
    const ShowProductNow = (staticpath, id, urlpath) => {
        var urlname = "/store/" + urlpath;
        window.localStorage.setItem("useridordernow", id);
        window.localStorage.setItem("staticpath", staticpath);
        window.location = urlname;
    }


    return (
        <>
            <NavigationBar />
            <div className="container-fluid px-0  colorHeroSection">
                <div className="row">
                    <div className="col-md-6 m-auto pt-3" style={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
                        <h1 className="availbleColorTxt">{sectionTitle}</h1>
                        <h6 style={{ fontSize: "16px", fontWeight: "600" }} className="py-4" dangerouslySetInnerHTML={{ __html: sectionDesciption }}></h6>
                        <button className="ColorBtn mb-2" onClick={gotonavbar}>{sectionCTAtext}
                            <img src={CDNURL + "Localimages/colorIcon.png"} className="img-fluid ms-3" alt="Learn More"/>
                        </button>
                    </div>
                    <div className="col-md-6 pb-2 text-center">
                        <img src={CDNURL + "Localimages/TableClothGroupNew.png"} className="img-fluid text-end" alt="Hero Image"/>
                    </div>
                </div>
            </div>

            <div className="text-center py-5">
                {categoryDetailsVar.map((item, i) => {
                    var returData;
                    returData = (
                        <button key={i} type="submit" className="loginBtn pt-3 pb-2 px-3 btnhover subProductOrderNow" onClick={(e) => ShowProductNow(item.staticPath, item.id, item.urlPath)}>
                            <h5>Order {item.name} Now</h5>
                        </button>
                    );
                    return returData;
                })}
            </div>
            {/* paragraph start */}
            <div className="container pb-5 colorPara">
                <ul className="pt-3">
                    <li className="liBullet ps-2">
                        In addition to providing you with world class custom made <a className="paraLink" href="/products/table-pads">table pads</a>, we carry a wide variety of tablecloth colors to perfectly match your home decor. We carry table cloth from Cott'n-Eze and they are a durable and soft fabric with a variety of colors that will accentuate any room decor. If you're interested in any of our tablecloths please <a className="paraLink" href="/contact">contact us</a>.
                    </li>
                </ul>
            </div>
            {/* paragraph end */}

            {/* Cott'n-Eze start */}
            <div className="container pb-5">
                <div className="text-center">
                    <h2>Cott'n-Eze</h2>
                    <p className="prodhr mt-1" />
                </div>
                <ul className="colorPara pt-3">
                    <li className="liBullet ps-2">
                        The Cott'n-Eze® tablecloths are made of 100% spun polyester. They are available in a wide variety of colors. This fabric has the durability of polyester paired with the softness of cotton and it will easily outlast either of them. Designed to be resistant to stains and shrinking, these tablecloths are water absorbent and will retain their color for many years to come. This is the standard for quality in any hospitality industry.
                        <br /><i className="brownTxt">All cloths over 70 inches wide will be seamed.</i>
                    </li>
                </ul>
            </div>
            {/* Cott'n-Eze end */}

            {/* Washing Instructions start */}
            <div className="container pb-5" >
                <div className="text-center">
                    <h2>Washing Instructions</h2>
                    <p className="prodhr mt-1" />
                </div>
                <div className="row pt-3" >
                    <div className="col-12 col-md-7">
                        <span className="callUsTxt">Warm / Hot Water Wash</span><br />
                        <ul className="colorPara pt-2">
                            <li className="liBullet ps-2">Wash in warm to hot water (no more than 140°).</li>
                            <li className="liBullet ps-2 pt-2">Use a gradual cool-down or split rinse.</li>
                            <li className="liBullet ps-2 pt-2">Only use bleach on whites.</li>
                            <li className="liBullet ps-2 pt-2">Use mild detergent only. Avoid high alkaline level.</li>
                            <li className="liBullet ps-2 pt-2">Avoid fabric softeners.</li>
                            <li className="liBullet ps-2 pt-2">Minimize extraction time (30-60 seconds) to prevent wrinkles.</li>
                            <li className="liBullet ps-2 pt-2">Do not overload dryer.</li>
                            <li className="liBullet ps-2 pt-2">Keep drying temperature low (below 140°).</li>
                            <li className="liBullet ps-2 pt-2">Do not over-dry, only 10-20 minutes.</li>
                            <li className="liBullet ps-2 pt-2">Remove while still slightly damp and warm to avoid wrinkles. Pat flat and fold promptly.</li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-5 d-flex justify-content-end px-2" >
                        <img src={CDNURL + "Localimages/washingImg.svg"} className="img-fluid" alt="Washing Image"/>
                    </div>
                </div>
            </div>
            {/* Washing Instructions end */}

            {/* images start */}
            <div className="container py-5 px-4" id="GotoNavbar">
                <div className="text-center">
                    <h2>Variation of Colors</h2>
                    <p className="prodhr mt-1" />
                    <ul className="colorPara">
                        <li className="liBullet ps-2">There are varieties of colours available for your table cloth with a variations of bright and vibrant colours that flawlessly enhance your home's decor.</li>
                    </ul>
                </div>
                <div className="col-12 pt-4">
                    <div className="row">
                        {tableclothitems.map((item, i) => {
                            var returData;
                            var profileImage = CDNURL + "Gallery/" + item.clothImage;
                            {
                                item.isActive == 1 ?
                                    returData = (
                                        <div className="col-md-3 mt-2 col-lg-2 col-6 posRel">
                                            <div id={`tableImgId_${item.id}`} className="transProp tableImgEffect" onMouseEnter={showimgname} onMouseLeave={hideimgname}>
                                                <LazyLoadImage src={profileImage} className="img-fluid w-100" style={{ height: "180px" }} alt="Table Cloth Image"/>
                                                <div className="services__text d-none" id={`tableImgTxt_${item.id}`}>
                                                    <div className="dbox-relative">
                                                        <h6>{item.name}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    <></>
                            }
                            return returData;
                        })}
                    </div>
                </div>
            </div>
            {/* images end */}
            <div className="text-center py-5">
                {categoryDetailsVar.map((item, i) => {
                    var returData;
                    returData = (
                        <button key={i} type="submit" className="loginBtn pt-3 pb-2 px-3 btnhover subProductOrderNow" onClick={(e) => ShowProductNow(item.staticPath, item.id, item.urlPath)}>
                            <h5>Order {item.name} Now</h5>
                        </button>
                    );
                    return returData;
                })}
            </div>

            <WhatCustomerThink isH1Tag="no"/>

            <Footer />
        </>
    )
}